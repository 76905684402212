import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

const NumberNotFound = ({ tryAnotherNumber, next }) => {
    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} xs={12}>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <img src="/images/jab2.svg" style={{ height: 50, width: 50 }} />
                        <h3 className="ml-2 mr-2">Add Second Vaccination</h3>
                    </div>

                    <div className="d-flex flex-row align-items-center">
                        <h3 className="mr-2">Number not found</h3>
                        <FontAwesomeIcon
                            color="red"
                            size={"3x"}
                            icon={faExclamationCircle}
                        ></FontAwesomeIcon>
                    </div>
                    <div>
                        <Button color="link" onClick={tryAnotherNumber}>
                            Try another number
                        </Button>{" "}
                        or{" "}
                        <Button color="link" onClick={next}>
                            Continue
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default NumberNotFound
