import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { goBack } from "react-router-redux"

import { Container, Row, Col, ListGroup, ListGroupItem, Button } from "reactstrap"

import Header from "./components/Header"
import Footer from "./components/Footer"

class AppContainer extends Component {
    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12}>
                            <h1>Download Excel Templates</h1>
                            <h3 className="download-note">
                                <strong>
                                    Note: Do not modify the headers of these files in any
                                    way!
                                </strong>
                            </h3>
                            <h4>Please right-click and select "Save as" to download</h4>
                            <ListGroup className="list-group-flush download-list">
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Auto_Insurance_Template.xlsx">
                                        Auto Insurance Template
                                    </a>
                                </ListGroupItem>

                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Multi_Auto_Insurance_Template.xlsx">
                                        Multiple Vehicles Template
                                    </a>
                                </ListGroupItem>

                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Property_Insurance_Template.xlsx">
                                        Property Insurance Template
                                    </a>
                                </ListGroupItem>
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Health_Insurance_Template.xlsx">
                                        Health Insurance Template
                                    </a>
                                </ListGroupItem>
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Birthdays_Template.xlsx">
                                        Birthday Greetings Template
                                    </a>
                                </ListGroupItem>
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Christmas_Template.xlsx">
                                        Christmas Greetings Template
                                    </a>
                                </ListGroupItem>
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Divali_Template.xlsx">
                                        Divali Greetings Template
                                    </a>
                                </ListGroupItem>
                                <ListGroupItem className="border-0">
                                    <img src="/images/excel-24.png" />
                                    <a href="/templates/Blank_Eid_Template.xlsx">
                                        Eid Greetings Template
                                    </a>
                                </ListGroupItem>
                            </ListGroup>

                            <div className="text-center back-button">
                                <a href="#" onClick={this.props.goBack}>
                                    Back
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect(
    (state) => ({}),
    (dispatch) => ({
        goBack: () => dispatch(goBack()),
    })
)(AppContainer)

export default App
