import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, CustomInput, Label, Input, Alert } from 'reactstrap';
import Header from '../components/Header'; 
import TokenExpired from '../components/TokenExpired'
import Footer from '../components/Footer';
import Logout from '../components/Logout';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faFileExcel, faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { goBack as goBackAction} from 'connected-react-router'
import { Redirect } from 'react-router'
import _ from 'lodash'

import config from '../config'
import countries from '../countries-caribbean.json'; 

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  fetchTotalCreditFinanceUniqueCustomers as fetchTotalCreditFinanceUniqueCustomersAction,
  uploadCreditFinanceReminders as uploadCreditFinanceRemindersAction,
  setAlert,
  saveAlert, 
  sendMessage as sendMessageAction, 
  setSettings as setSettingsAction } from '../actions'

  const countryCodeToCountry = (code) => countries.find(x => x.code.toLowerCase() === code ).name

  const NewAlerts = () => {

    useEffect(() => {      
      fetchUser(email)    
    }, [email]);    

    useEffect(() => {
      fetchTotalCreditFinanceUniqueCustomers(user ? user.uid : null)
    }, [user])

    useEffect(() => {
      if(user) {
        fetchSettings(user.uid)
      }
    }, [user])

    const settings = useSelector(state => state.settings)
    const [countryCode, setCountryCode] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [documentUploadStatus, setDocumentUploadStatus] = useState(null)
    const [sendEmail, setSendEmail] = useState(false)    
    const selectedCountry = useSelector(state => !_.isEmpty(state.uniqueCreditFinanceCustomers) ? state.uniqueCreditFinanceCustomers.customers.find(x => x.countryCode === countryCode) || {countryCode, count: 0} : null )
    const [step, setStep] = useState(1)
    const [showResultPage, setShowResultPage] = useState(false)
    const email = useSelector(state => state.router.location.pathname.split("/")[3])
    const currentPath = useSelector(state => state.router.location.pathname.split("/").slice(0,-1).join("/") )
    const dispatch = useDispatch() 
    const sendMessage = (user, message, sendEmail, countryCode) => dispatch(sendMessageAction(user, message, sendEmail, countryCode))
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const fetchSettings = (uid) => dispatch(fetchSettingsAction(uid))
    const fetchTotalCreditFinanceUniqueCustomers = (uid) => dispatch(fetchTotalCreditFinanceUniqueCustomersAction(uid))
    const uploadCreditFinanceReminders = (id, code, data, type) => dispatch(uploadCreditFinanceRemindersAction(id, code, data, type))
    const user = useSelector(state => state.user)    
    const goBack = () => dispatch(goBackAction())

    const handleFileUpload = (event, type) => {

      if(event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          setDocumentUploadStatus("invalid")
          return
      }

      var fileReader = new FileReader();
      fileReader.onload = async (fileLoadedEvent) => {
          var binaryFileLoaded = fileLoadedEvent.target.result;
          uploadCreditFinanceReminders(user.uid, countryCode, binaryFileLoaded, type).then((res) => {            
            setDocumentUploadStatus(res.value.data.success ? "uploaded" : "error")
            setErrorMessage(res.value.data.message)                                               
            //this.props.fetchUser(this.props.user.email)
        })          
      }      
      
      fileReader.readAsBinaryString(event.target.files[0]);
    };

    const StepOne = () => (
      <Fragment>
        <h5 className="text-primary">Which country are you sending alerts to?</h5>
        <Input type="select" onChange={selectCountry}>
            <option value="select-country">Select your country</option>
            {Object.keys(user.countriesOfOperation).map(code => <option value={code} selected={code === countryCode ? true : false} key={code}>{countries.find(x => x.code.toLowerCase() === code).name}</option>)}
        </Input>

        <div className="text-center mt-2">
          {countryCode ? <Button color="success" onClick={() => setStep(2)}>Continue</Button> : null } 
          <Button color="link" onClick={() => goBack()}>Cancel</Button>
        </div>    
      </Fragment>  
    )

    const StepTwo = () => (
      <Fragment>
        <h4>You're sending alerts to {countryCodeToCountry(countryCode)} <img src={`/flag-icon-css/flags/4x3/${countryCode.toLowerCase()}.svg`} height={30} /></h4>
        <strong>Code: {countryCode}</strong>
        <div className="text-center mt-2">
          {countryCode ? <Button color="success" onClick={() => setStep(3)}>Continue</Button> : null } 
          <Button color="link" onClick={() => setStep(1)}>Back</Button>
        </div>    
      </Fragment>  
    )

    const Upload = ({title, icon, type}) => (
      <div className="d-flex flex-row mt-4">
      {icon || <FontAwesomeIcon icon={faArrowCircleUp} size="3x"></FontAwesomeIcon>}
      <div className="d-flex flex-column ml-2">
        <div>{title}</div>            
        <Input className="mt-2" type="file" placeholder="" key="" onChange={(evt) => handleFileUpload(evt, type)} />
      </div>
    </div>      
    )

    const CustomMessage = () => {
      const [message, setMessage] = useState("")
      const [sendMessageSuccess, setSendMessageSuccess] = useState(null)

      const messageValid = () => {        
        const re = /^[\!\?:;,\.A-Za-z\s\d]*$/
        if(message) {
            return re.exec(message) ? true : false;         
        }
        else {
            return true; 
        }
      }

      const messageBlank = () => {
        return message.trim().length <= 0 
      }

      const handleSendMessage = () => {        
        sendMessage(user, message, sendEmail, countryCode).then(res => {          
          setSendMessageSuccess(res.value.data.success || false) 

          setTimeout(() => {
            setSendMessageSuccess(null)
          }, 2000)
        });
      }      

      return (
      <Fragment>
      <div className="mt-4">
        <h5 className="text-primary"><strong>Send a Custom Message</strong></h5>
      </div>          

      {sendMessageSuccess === false ? <Alert color="danger">Problem sending messages</Alert> : null }
      {sendMessageSuccess === true ? <Alert color="success">Messages sent successfully</Alert> : null }

      <div className="d-flex flex-row align-items-start">          
        <img src="/images/custom_message.svg" style={{width: 48}} />

        <div className="d-flex flex-column ml-2">
          {selectedCountry ? 
          <div>Send a message to all {selectedCountry.count} customers in the {countryCodeToCountry(selectedCountry.countryCode)} database:</div>
          : null }
          <Form>
            <FormGroup>
              <Input className="mt-2" type="textarea" value={message} onChange={(evt) => setMessage(evt.target.value) } />
              <div className="text-right">{160 - message.length}</div>              
            </FormGroup>
          </Form>
          
          <CustomInput checked={sendEmail} type="checkbox" id="check" name="check" label="Also send messages by email" onChange={evt => setSendEmail(evt.target.checked)} />

          <div className="text-center mt-2">
            <Button color="success" disabled={messageBlank() || !messageValid()} onClick={handleSendMessage}>Send Message</Button>
            <Button color="link" onClick={() => goBack()}>Cancel</Button>
          </div>
        </div>
      </div>  
      </Fragment>    
    )
  }
        
    const FinalStep = () => (
      <Fragment>
        <h4>Messaging customers in {countryCodeToCountry(countryCode)}</h4>
        <div className="d-flex justify-content-between">
          <strong>Upload Messages</strong>
          <img src={`/flag-icon-css/flags/4x3/${countryCode.toLowerCase()}.svg`} height={30} />
        </div>
        
        {selectedCountry ?
          <div className="mt-2">You have a total of {selectedCountry ? selectedCountry.count : 0} unique customers in your {countryCodeToCountry(selectedCountry.countryCode)} database</div>
        : null }

        <div>This function allows you to upload alerts to your database. If you have no alerts to add, click <Button color="link" onClick={goBack}>cancel</Button></div>

        {documentUploadStatus === "error" ? <Alert color="danger">{ errorMessage || "Error uploading document"}</Alert> : null}
        {documentUploadStatus === "uploaded" ? <Alert color="success">Document uploaded successfully!</Alert> : null}

        <div>
          <h5 className="text-primary"><strong>Credit Reminders</strong></h5>
        </div>

        {settings['credit-account-reminders-before-due'] ? 
          <Upload 
            title="Upload BEFORE DUE CREDIT Excel file here" 
            type="creditPaymentReminderBeforeDueDate"
            /> : null } 

        {settings['credit-account-alerts-overdue'] ? 
          <Upload 
            title="Upload OVERDUE CREDIT Excel file here" 
            type="overdueCreditPayment"
            /> : null }

        {settings['credit-account-alerts-30-plus-days-overdue'] ? 
          <Upload 
            title="Upload CRITICALLY OVERDUE CREDIT Excel file here" 
            type="criticallyOverdueCreditPayment"
            /> : null } 

        <div className="mt-4">
          <h5 className="text-primary"><strong>Loan Reminders</strong></h5>
        </div>        

        {settings['loan-account-reminders-before-due'] ?
          <Upload 
            title="Upload BEFORE DUE LOAN Excel file here" 
            type="loanPaymentReminderBeforeDueDate"
            /> : null }

        {settings['loan-account-alerts-overdue'] ? 
          <Upload 
            title="Upload OVERDUE LOAN Excel file here" 
            type="overdueLoanPayment"
            /> : null } 

        {settings['loan-account-alerts-30-plus-days-overdue'] ? 
          <Upload 
            title="Upload CRITICALLY OVERDUE LOAN Excel file here" 
            type="criticallyOverdueLoanPayment"
            /> : null }

        <div className="mt-4">
          <h5 className="text-primary"><strong>Greetings</strong></h5>
        </div>       

        {settings['alert-type-greetings'] && settings['alert-type-greetings-birthday'] ?
          <Upload
            type="creditFinanceBirthdayCustomers" 
            title="Upload Birthday Excel file here" 
            icon={<img src="/images/birthday_icon.svg" style={{width: 48}}/>}
          /> : null }

        {settings['alert-type-greetings'] && settings['alert-type-greetings-christmas'] ? 
          <Upload
            type="creditFinanceChristmasCustomers" 
            title="Upload Christmas Excel file here" 
            icon={<img src="/images/christmas_tree_icon.svg" style={{width: 48}}/>}
          /> : null } 

        {settings['alert-type-greetings'] && settings['alert-type-greetings-divali']? 
          <Upload
            type="creditFinanceDivaliCustomers" 
            title="Upload Divali Excel file here" 
            icon={<img src="/images/divali_icon.svg" style={{width: 48}}/>}
            /> : null }

        {settings['alert-type-greetings'] && settings['alert-type-greetings-eid'] ? 
        <Upload
          type="creditFinanceEidCustomers" 
          title="Upload Eid Excel file here" 
          icon={<img src="/images/eid_icon.svg" style={{width: 48}}/>}
          /> : null } 

        {settings['alert-type-custom'] ? 
          <CustomMessage /> : null }

        <div className="text-center mt-2">          
          <Button color="link" onClick={() => goBack()}>Go back to Dashboard</Button>
        </div>        
      </Fragment>
    )


    const getCurrentPage = () => {
      switch(step) {
        case 1:
          return <StepOne />
        case 2:
          return <StepTwo />
        case 3:
          return <FinalStep />
      }
    }

    const selectCountry = (evt) => {
      setCountryCode(evt.target.value)
    }

    const goNext = () => {
      setShowResultPage(true)
    }

    if(!user) {
      return <TokenExpired />            
    } 

    return (
      <Container>
        <Header right={<Logout user={user} />} />        
        <Row className="justify-content-center">
              <Col sm={6} xs={12} className="">
                <h1>Send New Alerts</h1>

                {getCurrentPage()}

              </Col>
        </Row>                        
      </Container>
    )
  }

  export default NewAlerts