import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import logo from './logo.svg';
import './App.css';
import { ConnectedRouter, routerReducer, routerMiddleware, push, goBack } from 'react-router-redux'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { login, setRegistrationField, registerUser } from './actions'; 

import Header from './components/Header'; 
import Footer from './components/Footer'; 

class AppContainer extends Component {

    confirmRegistration() {
        this.props.registerUser(this.props.registration).then(user => {
        }).catch(e => {
            console.log('registration callback error', e); 
        });   
    }

    goBack() {
        this.props.setRegistrationField({'g-recaptcha-response': null, error: null})        
        this.props.goBack(); 
    }

    componentDidMount() {
        //ReactDOM.findDOMNode(this).scrollTop = 0
        window.scrollTo(0,0)
    }    

  render() {

    const { companyFullName, 
        companyShortName, 
        address1, 
        address2, 
        phone,
        birthdayMessage,
        christmasMessage,
        eidMessage, 
        divaliMessage,
        smsNumber, 
        email, 
        website,
        password, 
        confirmPassword
     } = this.props.registration;     

     if(!(this.props.registration && this.props.registration.companyFullName)) {
         return (
             <div>
                 <Header />
                 <Container>
                    <Row className="justify-content-center">
                        <Alert color="info">No information to confirm.</Alert>
                    </Row>
                 </Container>
             </div>
         )
     }
     else if (this.props.registration && this.props.registration.error) {
        return (
            <div>
            <Header />
            <Container>
               <Row className="justify-content-center">
                   <Col sm={6} xs={12} className="text-left">
                    <div>
                        <Alert color="danger">Registration error: {this.props.registration.error}.</Alert>
                   </div>
                   <div className="pt-4 pb-4 text-center">
                    <Button onClick={this.goBack.bind(this)}>Back</Button>                    
                    </div>                   
                    </Col>
               </Row>
            </Container>
        </div>            
        )
     }
     else if (this.props.registration && this.props.registration.success) {

        const { registration } = this.props; 

        return (
            <div>
            <Header />
            <Container>
               <Row className="justify-content-center">
                   <Col sm={6} xs={12} className="text-left">
                    <div>
                        <Alert color="info">Thank you. {registration.companyFullName} has been registered and your company dashboard is pending activation. Please click <Link to={`/${registration.category}/${registration.country}/${registration.email}`}>here</Link> to go to your dashboard.
                        </Alert>                        
                    </div>                                         
                    </Col>
               </Row>
            </Container>
        </div>            
        )
     }     

    return ( 

      <div className="App">
        <Header/>
        <Container>
          <Row className="justify-content-center">
            <Col sm={6} xs={12} className="text-left">
                <Alert color="info">Thank you for registering. You have submitted the following information:</Alert>
                <p><strong>Company Full Name</strong>: {companyFullName}</p>
                <p><strong>Company Short Name</strong>: {companyShortName}</p>
                <p><strong>Address Line 1</strong>: {address1}</p>
                <p><strong>Address Line 2</strong>: {address2}</p>
                <p><strong>Phone</strong>: {phone}</p>
                <p><strong>Email: </strong>{email}</p>
                <p><strong>Website: </strong>{website}</p>
                <p><strong>Birthday message</strong>: {birthdayMessage}</p>
                <p><strong>Christmas message</strong>: {christmasMessage}</p>
                <p><strong>Divali message</strong>: {divaliMessage}</p>                
                <p><strong>Eid-ul-Fitr message</strong>: {eidMessage}</p>

                <div className="pt-4 pb-4 text-center">
                    <Button color="success" onClick={this.confirmRegistration.bind(this)}>Confirm registration</Button>
                </div>

                <p className="text-center">
                    Or go <Button color="link" className="m-0 p-0" onClick={this.goBack.bind(this)}>back</Button> to make a correction!
                </p>
            </Col>
          </Row>
          <div className="text-center">
            <Footer />
          </div>          
        </Container>    
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    location: state.location, 
    registration: state.registration 
});

const mapDispatchToProps = (dispatch) => ({
    setRegistrationField: (params) => dispatch(setRegistrationField(params)), 
    registerUser: (info) => dispatch(registerUser(info)),
    goBack: () => dispatch(goBack())
});

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer); 

export default App;
