import { observer } from "mobx-react-lite"
import { Button, Table } from "reactstrap"
import { emergencyFeederOutageStore } from "../../data/emergency-feeder-outage.store"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { format } from "date-fns"

function formatTime(time) {
    if (time === "Unknown") {
        return time.toLowerCase()
    }

    const re = /(?<hour>\d+):(?<minute>\d+)$/

    const match = time.match(re)
    const is24hourFormat = match !== null

    if (is24hourFormat) {
        let hour = parseInt(match.groups.hour)
        let minute = parseInt(match.groups.minute)
        let minuteString = `${minute}`

        if (minute.toString().length === 1) {
            minuteString = `0${minute}`
        }

        let period = hour >= 12 ? "PM" : "AM"
        let formattedHour = hour === 12 ? hour : hour % 12

        return `${formattedHour}:${minuteString} ${period}`
    } else {
        return time
    }
}

export const ConfirmFeederSelection = observer(() => {
    const { previousStep, selectedFeeders, sendMultiAlerts } = emergencyFeederOutageStore

    return (
        <div className="pt-4">
            <h4>Confirm your selection:</h4>
            <div>
                <div className="py-2">
                    You have selected the following feeders and restoration times:
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Feeder</th>
                            <th>Time</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedFeeders.map((item, index) => (
                            <tr key={index}>
                                <td>{item.feeder}</td>
                                <td>
                                    {item.time === "Unknown"
                                        ? "unknown"
                                        : format(item.time, "hh:mm a")}
                                </td>
                                <td>
                                    {item.time === "Unknown"
                                        ? "unknown"
                                        : format(item.time, "EE dd MMM")}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <div className="py-2">Is this correct?</div>

            <div className="d-flex justify-content-center">
                <Button
                    color="primary"
                    onClick={sendMultiAlerts}
                    style={{ backgroundColor: "#ff9900", border: "none" }}
                >
                    Yes, alert all affected customers
                </Button>
            </div>

            <div className="d-flex justify-content-center">
                <Button color="link" onClick={previousStep}>
                    Go back and make corrections
                </Button>
            </div>
        </div>
    )
})
