import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/fontawesome-free-solid"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
export const DeliverySuccess = () => {
    const user = useSelector((state) => state.user)
    const dashboardLink = `/electric/${user.country.isoCode}/${user.email}`

    return (
        <div className="d-flex flex-column align-items-center">
            <FontAwesomeIcon
                icon={faCheckCircle}
                size={"3x"}
                color="green"
                className="my-4"
            />
            <h4>SMS alerts have been sent to customers in the affected locations</h4>
        </div>
    )
}
