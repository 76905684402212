import React, { useState } from "react"
import { Link } from "react-router-dom"
import { faCog } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import { TabBar } from "./TabBar"
import { ActivitySummary } from "./ActivitySummary"
import { AdminTab } from "./Admin"
import { DetailsTab } from "./Details"
import { observer } from "mobx-react-lite"

const StatsBox = observer(function ({
    data,
    user,
    token,
    settings,
    onTabSelected,
    setSelectedTab,
    selectedTab,
}) {
    let today = new Date()
    //const { settings } = useSelector((state) => state.settings)

    const hasStatsData = () => {
        return true
    }

    const renderTab = () => {
        switch (selectedTab) {
            case "Activity Summary":
                return <ActivitySummary setSelectedTab={setSelectedTab} />
            case "Admin":
                return <AdminTab setSelectedTab={setSelectedTab} />
            case "Details":
                return <DetailsTab />
            default:
                return null
        }
    }

    return (
        <div className="d-flex flex-column">
            <TabBar user={user} onSelect={onTabSelected} initialTab={selectedTab} />
            <div
                className="d-flex flex-column border p-4"
                style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    marginBottom: "16px",
                }}
            >
                <div className="d-flex flex-column">
                    {hasStatsData() ? (
                        renderTab()
                    ) : (
                        <Link
                            to={`/${user.category}/${user?.country?.isoCode}/${user.email}/settings`}
                        >
                            Go to Settings to get started
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
})

export default StatsBox
