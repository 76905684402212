import React, { Component, useState } from 'react';
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import Footer from '../../components/Footer';
import { settingToSetupStep } from '../../utils'

const StepFifteen = (props) =>  {
  
  let placeholderMessage = `Enter your message here. 160 characters incl. spaces, some special characters disallowed. (all changes automatically saved)`
  const [message, setMessage] = useState('')
  const nextStep = () => {
    props.saveAlert(props.user, "eidMessage", message).then(res => {
      //props.fetchSettings(props.user); 
      let stepNumber =  "16" // settingToSetupStep(getNextStep())

      if(stepNumber !== undefined) {
        props.setSetupState(props.user.uid, stepNumber).then(_ => {
          props.fetchSettings(props.user.uid);
          skipGreetings();
        });
      }
      else {
        skipGreetings()
      }
    });
  }  

  const messageValidated = () => message && message.length !== 0 && message.length < 160

  const saveSetting = (key, value) => {
    return props.setSettings(props.user, {[key]: value})
  }

  const skipGreetings = () => {
    saveSetting("setup-complete", true).then(res => {
      props.goBack()
    })
  }

    return (
    <Container>
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h3>Configure your alerts</h3>

        <Form>
          <FormGroup>
            <div className="d-flex flex-row justify-content-between">
              <h4>Eid Greetings</h4>
              <CustomInput checked={props.settings['alert-type-greetings-eid'] || false} type="switch" id="switch" name="switch" label="" onChange={(evt) => saveSetting("alert-type-greetings-eid", evt.target.checked) } />
            </div>
            <Input type="textarea" placeholder={placeholderMessage} value={message} onChange={(evt) => setMessage(evt.target.value) } />
            <div className={classnames("text-right", {"text-danger": message.length >= 150})}>{160 - message.length}</div>
          </FormGroup>
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={!messageValidated()}
              onClick={skipGreetings}>Save and finish</Button>
            <Button className="ml-4" onClick={skipGreetings}>Skip greetings for now</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepFifteen;