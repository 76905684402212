import { observer } from "mobx-react-lite"
import { Button, FormGroup, Input, Label, Table } from "reactstrap"
import TimePicker from "react-time-picker"
import DateTimePicker from "react-datetime-picker"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { useResponsive } from "react-hooks-responsive"
import classNames from "classnames"
import { emergencyFeederOutageStore } from "../../data/emergency-feeder-outage.store"
import { toJS } from "mobx"
import { format } from "date-fns"

const FeederTimes = observer(() => {
    const {
        feeders,
        restorationTimes,
        handleFeederSelected,
        handleRestorationTimeChanged,
        handleRestorationTimeUnknownChanged,
        getTimeValueForFeeder,
        isRestorationTimeUnknown,
        isFeederSelected,
        handleRestorationTimeTomorrowChanged,
        isRestorationTimeTomorrow,
        minDate,
        restorationTimesValid,
    } = emergencyFeederOutageStore

    const breakpoints = { xs: 0, sm: 480, md: 1024 }
    const { screenIsAtLeast } = useResponsive(breakpoints)

    console.log("restoration times ", toJS(restorationTimes))

    return (
        <Table className={classNames({ "w-75": screenIsAtLeast("md"), "mt-4": true })}>
            <thead>
                <tr>
                    <th></th>
                    <th>Select Feeder</th>
                    <th>Restoration Time</th>
                    <th>Time Unknown</th>
                </tr>
            </thead>
            <tbody>
                {feeders.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <FormGroup check>
                                <Input
                                    checked={isFeederSelected(item)}
                                    type="checkbox"
                                    onChange={handleFeederSelected(item)}
                                />
                                <Label check></Label>
                            </FormGroup>
                        </td>
                        <td>{item}</td>
                        <td>
                            <DateTimePicker
                                minDate={new Date()}
                                value={getTimeValueForFeeder(item)}
                                disabled={
                                    isRestorationTimeUnknown(item) ||
                                    !isFeederSelected(item)
                                }
                                disableClock={true}
                                format="MM-dd-yyyy h:mm a"
                                onChange={handleRestorationTimeChanged(item)}
                            />
                        </td>
                        <td>
                            <FormGroup className="d-flex justify-content-center">
                                <Input
                                    disabled={!isFeederSelected(item)}
                                    checked={isRestorationTimeUnknown(item)}
                                    type="checkbox"
                                    onChange={handleRestorationTimeUnknownChanged(item)}
                                />
                                <Label check></Label>
                            </FormGroup>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
})

export const SetFeederOutageTimes = observer(() => {
    const { nextStep, restorationTimesValid } = emergencyFeederOutageStore

    return (
        <div className="pt-4">
            <FormGroup tag="fieldset">
                <legend>
                    Select affected feeders and estimated restoration times from the table
                    below
                </legend>
                <FeederTimes />
            </FormGroup>
            <Button color="primary" onClick={nextStep} disabled={!restorationTimesValid}>
                Continue
            </Button>
        </div>
    )
})
