import config from "../config";
import axios from "axios";

class HTTPClient {
  get(endpoint) {
    return axios.get(`${config.serverURL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  delete(endpoint) {
    return axios.delete(`${config.serverURL}${endpoint}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  post(endpoint, body) {
    return axios.post(`${config.serverURL}${endpoint}`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

export default new HTTPClient();
