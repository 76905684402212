import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Table,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell, faCog, faUserPlus, faWrench } from "@fortawesome/free-solid-svg-icons"
import { user, vaccinationNoShows } from "../../../reducers"
import { useToasts } from "react-toast-notifications"
import vaccinationRepository from "../lib/vaccinationRepository"
import { format } from "date-fns"

export default function NoShows({ user, vaccinationNoShows }) {
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [successMessage, setSuccessMessage] = React.useState(null)
    const [processing, setProcessing] = React.useState(false)
    const { addToast } = useToasts()

    return (
        <Form>
            <div className="d-flex mt-4 flex-row align-items-start">
                <img
                    src={"/images/no_shows_icon.svg"}
                    style={{ marginTop: "20px", height: "75px" }}
                />
                <div className="pl-4 pr-4" style={{ maxWidth: "600px" }}>
                    <div className="mt-4 d-flex flex-row align-items-center">
                        <h4>No Shows</h4>
                        <div
                            className="pl-2"
                            style={{ position: "relative", top: "-8px" }}
                        >
                            <FontAwesomeIcon color="#dc3545" size="1x" icon={faBell} />
                        </div>
                    </div>

                    <div className="pt-2 pb-2">
                        <div className="pt-2 pb-2">
                            <div>Today is {format(new Date(), "eee dd MMM yyy")}</div>
                            {vaccinationNoShows.length}{" "}
                            {vaccinationNoShows.length === 1 ? "customer" : "customers"}{" "}
                            have missed their second dose appointments:
                        </div>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Mobile Number</th>
                                    <th>2nd Dose Appointment Date</th>
                                    <th>Days Missed By</th>
                                </tr>
                            </thead>

                            <tbody>
                                {vaccinationNoShows.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.mobileNumber}</td>
                                        <td>
                                            {format(
                                                new Date(
                                                    item.scheduledSecondVaccinationDate
                                                ),
                                                "dd MMM yyy"
                                            )}
                                        </td>
                                        <td>{item.daysMissedBy}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </Form>
    )
}
