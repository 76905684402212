import React, { Component, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logout from '../components/Logout';

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  setAlert,
  saveAlert as saveAlertAction,  
  setCountryPhone as setCountryPhoneAction,  
  setSettings as setSettingsAction, 
  setAdminCountry} from '../actions'

  import { goBack as goBackAction} from 'connected-react-router'  

  const MessageEditor = (props) => {    
    const [message, setMessage] = useState(props.message || "")

    const noInvalidMessageCharacters = () => {      

      const re = /^[\!\?:;,\.A-Za-z\s\d]*$/
      if(message.content) {
          return re.exec(message.content) ? true : false;         
      }
      else {
          return true; 
      }
    }
    
    const messageValidates = () => noInvalidMessageCharacters() && message.length <= 160 && message.length > 0
    
    return (
      <Fragment>
        <Form>        
        <FormGroup>              
          <Input type="textarea" value={message} onChange={(evt) => setMessage(evt.target.value) } />
          <div className="text-right">{message.length}</div>
        </FormGroup>
      </Form>          

      <div>
        <Button 
          disabled={!messageValidates()}
          color="success" 
          onClick={() => props.onSave(message) }>Save</Button>
        <Button color="danger" className="ml-1" onClick={props.onCancel}>Cancel</Button>
      </div>
    </Fragment>      
    )    
  }

  const SettingsEditMessagesContainer = () => {
    const email = useSelector(state => state.router.location.pathname.split("/")[3])
    const user = useSelector(state => state.user) 
    const settings = useSelector(state => state.settings) 

    //const [user, setUser] = useState(null)
    //const [settings, setSettings] = useState(null)
    const dispatch = useDispatch(); 
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const fetchSettings = (uid) => dispatch(fetchSettingsAction(uid))    
  
    useEffect(() => {      

      fetchUser(email)    
    }, [email]);

    useEffect(() => {      

      fetchSettings(user ? user.uid : null)    
    }, [user]);    
    
    if(user && settings) {
      
      return <SettingsEditMessages settings={settings} user={user} />
    }
    else {
      return null
    }      
  } 

const right = (<div>Logout</div>)  

const SettingsEditMessages = (props) =>  {
  
  const dispatch = useDispatch()
  const settings = useSelector(state => state.settings)
  const setSettings = (user, value) => dispatch(setSettingsAction(user, value)) 
  const saveAlert = (user, key, message) => dispatch(saveAlertAction(user.uid, key, message))
  const goBack = () => dispatch(goBackAction())  
  const [showIntroductoryMessage, setShowIntroductoryMessage] = useState(false) 
  const [showBirthdayMessage, setShowBirthdayMessage] = useState(false)
  const [showChristmasMessage, setShowChristmasMessage] = useState(false)
  const [showDivaliMessage, setShowDivaliMessage] = useState(false)
  const [showEidMessage, setShowEidMessage] = useState(false)
  const [birthdaySuccess, setBirthdaySuccess] = useState(null)
  const [christmasSuccess, setChristmasSuccess] = useState(null)
  const [eidSuccess, setEidSuccess] = useState(null)
  const [divaliSuccess, setDivaliSuccess] = useState(null)
  const [introSuccess, setIntroSuccess] = useState(null)

  const nextStep = () => {
    props.setSetupState(props.user.uid, "4").then(res => {
      
      props.fetchSettings(props.user); 
    });
  }

  const saveSetting = (key, value) => {    
    setSettings(props.user, {[key]: value}).then(res => {      
    })
  }

  const showUpdateFeedback = (result, setGreetingSuccess, setShowGreeting) => {
    if(result.value.data.success) {
      setGreetingSuccess(true)
    }
    else {
      setGreetingSuccess(false)
    }

    setShowGreeting(false)

    setTimeout(() => {
      setGreetingSuccess(null)
    }, 2000)
  }

    return (
    <Container>
      <Header right={<Logout user={props.user} />}></Header>
      <Row className="justify-content-center">
          <Col sm={6} xs={12} className="">
          <h1>Settings</h1>
          <h3>Edit Messages</h3>

          <div className="pt-4">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Button color="link" onClick={() => setShowIntroductoryMessage(!showIntroductoryMessage)}>Edit Introductory message</Button>
          </div>          

          {introSuccess === false ? <Alert color={"danger"}>Problem updating introductory message</Alert> : null } 
          {introSuccess === true ? <Alert color={"success"}>Introductory message updated!</Alert> : null } 
          {showIntroductoryMessage ? 
            <MessageEditor
              message={props.user.introductoryMessage || ""}              
              onSave={(message) => {                            
                saveAlert(props.user, "introductoryMessage", message).then(res => {
                  showUpdateFeedback(res, setIntroSuccess, setShowIntroductoryMessage)
                })
              }}
              onCancel={() => setShowIntroductoryMessage(false)} 
            type="introductoryMessage" /> : null } 
          
          <div className="">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Button color="link" onClick={() => setShowBirthdayMessage(!showBirthdayMessage)} >Edit Birthday message</Button>
          </div>  

          {birthdaySuccess === false ? <Alert color={"danger"}>Problem updating birthday message</Alert> : null } 
          {birthdaySuccess === true ? <Alert color={"success"}>Birthday message updated!</Alert> : null } 
          {showBirthdayMessage ? 
            <MessageEditor
              message={props.user.birthdayMessage || ""}              
              onSave={(message) => {                             
                saveAlert(props.user, "birthdayMessage", message).then(res => {                  
                  showUpdateFeedback(res, setBirthdaySuccess, setShowBirthdayMessage)
                })
              }}
              onCancel={() => setShowBirthdayMessage(false)} 
            type="birthdayMessage" /> : null }            

          <div className="">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Button color="link" onClick={() => setShowChristmasMessage(!showChristmasMessage)}>Edit Christmas message</Button>
          </div>

          {christmasSuccess === false ? <Alert color={"danger"}>Problem updating Christmas message</Alert> : null } 
          {christmasSuccess === true ? <Alert color={"success"}>Christmas message updated!</Alert> : null } 
          {showChristmasMessage ? 
            <MessageEditor
              message={props.user.christmasMessage || ""}              
              onSave={(message) => {                       
                saveAlert(props.user, "christmasMessage", message).then(res => {
                  showUpdateFeedback(res, setChristmasSuccess, setShowChristmasMessage)
                })
              }}
              onCancel={() => setShowChristmasMessage(false)} 
            type="christmasMessage" /> : null }          

          <div className="">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Button color="link" onClick={() => setShowDivaliMessage(!showDivaliMessage)}>Edit Divali message</Button>
          </div>

          {divaliSuccess === false ? <Alert color={"danger"}>Problem updating Divali message</Alert> : null } 
          {divaliSuccess === true ? <Alert color={"success"}>Divali message updated!</Alert> : null } 
          {showDivaliMessage ? 
            <MessageEditor
              message={props.user.divaliMessage || ""}              
              onSave={(message) => {                           
                saveAlert(props.user, "divaliMessage", message).then(res => {
                  showUpdateFeedback(res, setDivaliSuccess, setShowDivaliMessage)
                })
              }}
              onCancel={() => setShowDivaliMessage(false)} 
            type="divaliMessage" /> : null }

          <div className="">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Button color="link" onClick={() => setShowEidMessage(!showEidMessage)}>Edit Eid message</Button>
          </div>

          {eidSuccess === false ? <Alert color={"danger"}>Problem updating Eid message</Alert> : null } 
          {eidSuccess === true ? <Alert color={"success"}>Eid message updated!</Alert> : null } 
          {showEidMessage ? 
            <MessageEditor
              message={props.user.eidMessage || ""}              
              onSave={(message) => {                       
                saveAlert(props.user, "eidMessage", message).then(res => {
                  showUpdateFeedback(res, setEidSuccess, setShowEidMessage)
                })
              }}
              onCancel={() => setShowEidMessage(false)} 
            type="eidMessage" /> : null }                                     

          <div className="pt-4 pb-4">
              <Button color="link" className="ml-4" onClick={() => {                
                goBack()
              }}>Back</Button>
            </div>      
          
          </Col>                
      </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default SettingsEditMessagesContainer; 