import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWindowClose } from "@fortawesome/free-solid-svg-icons"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { Button, Input } from "reactstrap"
import { emergencyOutageStore } from "../../data/emergency-outage.store"

const LocationCard = ({ location, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="d-flex flex-row mr-2 mt-2"
            style={{ cursor: "pointer", backgroundColor: "lightgray", borderRadius: 4 }}
        >
            <div className="p-2">{location.outageLocation}</div>
            <FontAwesomeIcon
                icon={faWindowClose}
                style={{ fontSize: "24px" }}
            ></FontAwesomeIcon>
        </div>
    )
}

export const TagAffectedLocations = observer(() => {
    const {
        nextStep,
        debouncedHandleTagInputChanged,
        locations,
        setInputRef,
        setLocations,
        selectedLocations,
        handleSelectLocation,
        handleRemoveLocation,
    } = emergencyOutageStore

    const inputRef = useRef(null)
    const ref = useRef(null)

    useEffect(() => {
        setInputRef(inputRef)
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setLocations([])
            }
        }
        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [])

    return (
        <div style={{ position: "relative" }} className="mt-4">
            <h5>Tag affected locations</h5>
            <Input onChange={debouncedHandleTagInputChanged} innerRef={inputRef} />
            {locations.length > 0 ? (
                <div
                    ref={ref}
                    style={{
                        position: "absolute",
                        backgroundColor: "white",
                        padding: "8px",
                        boxShadow: "0px 4px 4px lightgray",
                        left: 0,
                        right: 0,
                    }}
                >
                    {locations.map((location) => (
                        <div
                            key={location.id}
                            style={{ cursor: "pointer" }}
                            onClick={handleSelectLocation(location)}
                        >
                            {location.outageLocation}
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="d-flex flex-row flex-wrap">
                {selectedLocations.map((item) => (
                    <LocationCard
                        onClick={handleRemoveLocation(item)}
                        key={item.id}
                        location={item}
                    ></LocationCard>
                ))}
            </div>

            <h5 className="mt-4">
                You have selected {selectedLocations.length} locations:
            </h5>
            <ul>
                {selectedLocations.map((item) => (
                    <li key={item.id}>{item.outageLocation}</li>
                ))}
            </ul>

            <div className="mt-4">
                If this is correct continue to set restoration times.
            </div>

            <Button
                color="primary"
                onClick={nextStep}
                className="mt-4"
                disabled={!selectedLocations?.length > 0}
            >
                Continue
            </Button>
        </div>
    )
})
