import React from 'react';
import { bindActionCreators } from 'redux'; 
import { connect } from 'react-redux'; 
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom'

import * as actionCreators from '../actions'; 

const Header = (props) => 
 (
      <div>
        <Navbar color="faded" light expand="md">
          <Link className="navbar-brand" to="/"><img src="/images/logo_small.png" height={50} /></Link>
          <NavbarToggler onClick={props.toggle} />
          <Collapse isOpen={props.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                  {props.right}
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
);

function mapStateToProps (state) {
    return {
        isOpen: state.navbar.open 
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggle: () => dispatch(actionCreators.toggleNavbar()) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header); 