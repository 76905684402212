import { FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap"

export const PhoneInputField = ({
    formik,
    label,
    fieldName,
    country,
    required = false,
}) => {
    return (
        <FormGroup>
            <Label>
                {label} {required ? "*" : ""}
            </Label>
            {formik.touched.phone && formik.errors[fieldName] && (
                <Alert color="danger">{formik.errors[fieldName]}</Alert>
            )}
            <InputGroup>
                <InputGroupText>+ 1</InputGroupText>
                <Input
                    type="select"
                    value={formik.values.dialCode}
                    onBlur={formik.handleBlur(fieldName)}
                    onChange={formik.handleChange(fieldName)}
                >
                    {country.areaCodes.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </Input>
                <Input
                    style={{ flex: 3 }}
                    value={formik.values[fieldName]}
                    placeholder="Local 7-digit number"
                    onChange={formik.handleChange(fieldName)}
                    onBlur={formik.handleBlur(fieldName)}
                />
            </InputGroup>
        </FormGroup>
    )
}
