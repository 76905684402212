import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { electricStore } from "../../../data/electric.store"

export const ActivitySummary = observer(({ setSelectedTab }) => {
    const {
        introMessagesCount,
        fetchIntroMessagesCount,
        fetchDisconnectionCount,
        fetchPlannedOutagesCount,
        fetchEmergencyOutagesCount,
        disconnectionCount,
        emergencyOutagesCount,
        plannedOutagesCount,
    } = electricStore
    const user = useSelector((state) => state.user)

    useEffect(() => {
        fetchIntroMessagesCount(user.uid)
        fetchDisconnectionCount(user.uid)
        fetchPlannedOutagesCount(user.uid)
        fetchEmergencyOutagesCount(user.uid)
    }, [])

    const handleGoBackToActivitySummary = (evt) => {
        evt.preventDefault()
        setSelectedTab("Activity Summary")
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center pb-4">
                <div className="pr-2">
                    <img src="/images/introductory_icon.svg" width={48} />
                </div>
                <div>{introMessagesCount} introductory messages sent</div>
            </div>

            <div className="d-flex flex-row align-items-center pb-4">
                <div className="pr-2">
                    <img src="/images/utility_pole.svg" width={48} />
                </div>
                <div>{plannedOutagesCount} planned outage alerts sent</div>
            </div>

            <div className="d-flex flex-row align-items-center pb-4">
                <div className="pr-2">
                    <img src="/images/electricity_sign.svg" width={48} />
                </div>
                <div>{disconnectionCount} disconnection alerts sent</div>
            </div>

            <div className="d-flex flex-row align-items-center pb-4">
                <div className="pr-2">
                    <img src="/images/broken_cable.svg" width={48} />
                </div>
                <div>{emergencyOutagesCount} emergency alerts sent</div>
            </div>
            <div className="d-flex flex-row">
                <img src="/images/refresh.svg" width={20} />
                <a
                    href=""
                    style={{ textDecoration: "underline", color: "black" }}
                    onClick={handleGoBackToActivitySummary}
                >
                    Dashboard
                </a>
            </div>
        </div>
    )
})
