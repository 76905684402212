import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { countryCodeToCountry, monthValueToMonthName } from '../utils'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons'

const CreditFinanceStat = ({title, stats, emailStats, country, rates, type, icon}) => {

  const [activeTab, setActiveTab] = useState("1")
  const currentPath = useSelector(state => state.router.location.pathname.split("/").slice(0).join("/") )

  const rate = rates[country]
  const countryStats = stats ? stats[country]  : null
  const countryEmailStats = emailStats ? emailStats[country] :  null
  const today = new Date()

  const monthValue = countryStats ? countryStats[`${today.getFullYear()}-${today.getMonth()}`] : 0 
  const yearValue = countryStats ? countryStats[today.getFullYear()] : 0

  const emailMonthValue = countryEmailStats ? countryEmailStats[`${today.getFullYear()}-${today.getMonth()}`] : 0
  const emailYearValue = countryEmailStats ? countryEmailStats[today.getFullYear()] : 0  

  let currency 

  if(rate) {
    currency = rate.currency === 'XCD' ? 'EC' : 'US'; 
  }  

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }    

  return (
    <div className="mt-4 mb-4 d-flex flex-row justify-content-start">
      <div className="mr-2 mt-4">{icon}</div>
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <h5><strong>{title}</strong></h5>
      </div>
      <Nav className="mt-1" tabs>
        <NavItem>
          <NavLink 
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1') }}>SMS</NavLink>
        </NavItem>

        <NavItem>
          <NavLink 
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2')}}>Email</NavLink>
        </NavItem>

        <NavItem>
          <NavLink 
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3') }}>$</NavLink>
        </NavItem>                
      </Nav>  
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="d-flex flex-row">
            {monthValue} {type} alerts sent in {monthValueToMonthName(today.getMonth())}
          </div>

          <div className="d-flex flex-row">
            {yearValue} {type} alerts sent in {today.getFullYear()}
          </div>          
        </TabPane>
        <TabPane tabId="2">
          <div className="d-flex flex-row">
            {emailMonthValue} {type} alerts sent in {monthValueToMonthName(today.getMonth())}
          </div>

          <div className="d-flex flex-row">
            {emailYearValue} {type} alerts sent in {today.getFullYear()}
          </div>          
        </TabPane>
        <TabPane tabId="3">          
          <div className="d-flex flex-row">
            Current month usage to date: ${currency} {(rate.rate * (monthValue || 0)).toFixed(2) }
          </div>
          
          <div className="d-flex flex-row">
            Current year usage to date: ${currency} {(rate.rate * (yearValue || 0)).toFixed(2) }         
          </div>
          
        </TabPane>
      </TabContent>
    </div> 
    </div> 
  )
}

export default CreditFinanceStat;