import { uniqBy } from "lodash"

export function navbar(state, action) {
    switch (action.type) {
        case "TOGGLE_NAVBAR":
            return { open: !state.open }
        default:
            return {
                open: false,
            }
    }
}

export function user(state = null, action) {
    switch (action.type) {
        case "SET_COUNTRY_OF_OPERATION_FULFILLED":
            let obj = {}
            obj["countriesOfOperation"] = Object.assign({}, state.countriesOfOperation, {
                [action.payload.data.code]: action.payload.data.value,
            })

            return Object.assign({}, state, obj)
        case "SET_COUNTRY_PHONE_FULFILLED":
            obj = {}
            obj["countryPhoneNumbers"] = Object.assign({}, state.countryPhoneNumbers, {
                [action.payload.data.code]: action.payload.data.phone,
            })

            return Object.assign({}, state, obj)
        case "SET_ALERT_FULFILLED":
            obj = {}
            obj[action.payload.data.key] = action.payload.data.message

            //return Object.assign({}, {[action.payload.key]: action.payload.message})
            return Object.assign({}, state, obj)
        case "SET_USER":
            return action.payload === null ? null : Object.assign({}, action.payload)
        case "SET_USER_ACTIVE_FULFILLED":
            return Object.assign({}, state, { active: action.payload.data.active })
        case "FETCH_USER_FULFILLED":
            return Object.assign({}, Object.values(action.payload.val())[0])
        case "FETCH_VACCINATION_USER_FULFILLED":
            return action.payload?.data?.data || {}
        case "FETCH_ELECTRIC_USER_FULFILLED":
            return action.payload?.data?.data || {}
        case "SET_MESSAGING_SERVICE_ID":
            return {
                ...state,
                ...{ messagingServiceId: action.payload.data.messagingServiceId },
            }
        case "SET_VACCINATION_MESSAGING_SERVICE_ID_FULFILLED":
            return { ...state, ...action.payload?.data?.data }
        case "SET_BLOODBANK_MESSAGING_SERVICE_ID":
            return { ...state, ...action.payload?.data?.data }
        case "SET_SUBACCOUNT_SID_FULFILLED":
            return { ...state, ...action.payload?.data?.data }
        case "REGISTER_USER_FULFILLED":
            if (action.payload.data.success) {
                return Object.assign({}, state, action.payload.data.profile)
            }
            return state
        case "LOGOUT_FULFILLED": {
            return null
        }
        default:
            return state
    }
}

export function admin(state = null, action) {
    switch (action.type) {
        case "SET_ADMIN":
            return Object.assign({}, action.payload)
        case "LOGOUT_FULFILLED": {
            return null
        }
        default:
            return state
    }
}

export function registration(state = { countriesOfOperation: {} }, action) {
    switch (action.type) {
        case "SET_REGISTRATION_FIELD":
            return Object.assign({}, state, action.payload)
        case "SET_REGISTRATION_FIELDS":
            return { ...state, ...action.payload }
        case "REGISTER_USER_FULFILLED":
            if (!action.payload.data.success) {
                return Object.assign({}, state, {
                    error: action.payload.data.errorMessage,
                })
            } else {
                return Object.assign({}, state, {
                    success: action.payload.data.success,
                })
            }
        case "SET_REGISTRATION_COUNTRY_OF_OPERATION":
            state.countriesOfOperation[action.payload.countryCode] = action.payload.value
            return { ...state }
        case "REGISTER_USER_REJECTED":
            return state
        case "SET_MESSAGING_SERVICE_FULFILLED":
            return Object.assign({}, state, {
                messagingServiceId: action.payload.data.messagingServiceId,
            })
        default:
            return state
    }
}

export function requestActivation(state = false, action) {
    switch (action.type) {
        case "REQUEST_ACCOUNT_ACTIVATION_FULFILLED":
            return action.payload.data.success
        default:
            return false
    }
}

export function logoDisplay(state = {}, action) {
    switch (action.type) {
        case "SET_LOGO_DISPLAY_FULFILLED":
            return Object.assign({}, state, action.payload.data)
        case "GET_LOGO_DISPLAY_FULFILLED":
            return Object.assign({}, state, action.payload.data)
        default:
            return state
    }
}

export function landingPageLogos(state = { success: false, logos: [] }, action) {
    switch (action.type) {
        case "GET_LANDING_PAGE_LOGOS_FULFILLED":
            return Object.assign({}, state, action.payload.data)
        default:
            return state
    }
}

export function stats(state = {}, action) {
    switch (action.type) {
        case "FETCH_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function creditFinanceStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CREDIT_FINANCE_STATS_FULFILLED":
            let stats = action.payload.val()
            let key = action.payload.ref.parent.key.split("-")[0]
            return Object.assign({}, state, { [key]: stats })
        default:
            return state
    }
}

export function creditFinanceEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CREDIT_FINANCE_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()
            let key = action.payload.ref.parent.key.split("-")[0]

            return Object.assign({}, state, { [key]: stats })
        default:
            return state
    }
}

export function propertyStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_PROPERTY_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function healthStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_HEALTH_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function birthdayStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_BIRTHDAY_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function christmasStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CHRISTMAS_STATS_FULFILLED":
            let stats = action.payload.val()

            return stats
        default:
            return state
    }
}

export function eidStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_EID_STATS_FULFILLED":
            let stats = action.payload.val()

            return stats
        default:
            return state
    }
}

export function divaliStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_DIVALI_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function customMessageStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CUSTOM_MESSAGE_STATS_FULFILLED":
            let stats = action.payload.val()
            return stats
        default:
            return state
    }
}

export function autoEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_AUTO_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()
            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }
            return stats
        default:
            return state
    }
}

export function customMessageEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CUSTOM_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()

            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }

            return stats
        case "FETCH_CUSTOM_EMAIL_STATS_REJECTED":
            return state
        default:
            return state
    }
}

export function propertyEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_PROPERTY_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()

            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }

            return stats
        case "FETCH_PROPERTY_EMAIL_STATS_REJECTED":
            return state
        default:
            return state
    }
}

export function healthEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_HEALTH_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()

            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }

            return stats
        case "FETCH_HEALTH_EMAIL_STATS_REJECTED":
            return state
        default:
            return state
    }
}

export function birthdayEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_BIRTHDAY_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()

            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }

            return stats
        default:
            return state
    }
}

export function christmasEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CHRISTMAS_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()

            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }

            return stats
        default:
            return state
    }
}

export function divaliEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_DIVALI_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()
            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }
            return stats
        default:
            return state
    }
}

export function eidEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_EID_EMAIL_STATS_FULFILLED":
            let stats = action.payload.val()
            if (stats === null) {
                let date = new Date()
                let emptyStats = {}

                emptyStats[date.getFullYear()] = 0
                emptyStats[`${date.getFullYear()}-${date.getMonth()}`] = 0

                return emptyStats
            }
            return stats
        default:
            return state
    }
}

export function counterStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_COUNTER_STATS_FULFILLED":
            let stats = action.payload.data
            return stats
        default:
            return state
    }
}

export function uniqueCustomers(state = {}, action) {
    switch (action.type) {
        case "FETCH_UNIQUE_CUSTOMERS_FULFILLED":
            let stats = action.payload.data
            return stats
        default:
            return state
    }
}

export function uniqueCreditFinanceCustomers(state = {}, action) {
    switch (action.type) {
        case "FETCH_TOTAL_CREDIT_FINANCE_UNIQUE_CUSTOMERS_FULFILLED":
            let stats = action.payload.data.result
            return stats
        default:
            return state
    }
}

export function creditFinanceAggregateStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CREDIT_FINANCE_AGGREGATE_STATS_FULFILLED":
            return action.payload.data.result
        default:
            return state
    }
}

export function creditFinanceAggregateEmailStats(state = {}, action) {
    switch (action.type) {
        case "FETCH_CREDIT_FINANCE_AGGREGATE_EMAIL_STATS_FULFILLED":
            return action.payload.data.result
        default:
            return state
    }
}

export function rates(state = {}, action) {
    switch (action.type) {
        case "FETCH_RATES_FULFILLED":
            let rates = action.payload.val()
            return rates
        case "UPDATE_RATE_FULFILLED":
            let obj = {}
            obj[action.payload.countryCode] = action.payload
            return Object.assign({}, state, obj)
        default:
            return state
    }
}

export function patientVaccinations(state = [], action) {
    switch (action.type) {
        case "FETCH_PATIENT_VACCINATIONS_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function companies(state = [], action) {
    switch (action.type) {
        case "FETCH_COMPANIES_FULFILLED":
            let rates = Object.values(action.payload.val() || {})
            return rates
        default:
            return state
    }
}

export function electricCompanies(state = [], action) {
    switch (action.type) {
        case "FETCH_ELECTRIC_COMPANIES_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function vaccinationOrganizations(state = [], action) {
    switch (action.type) {
        case "FETCH_VACCINATION_ORGANIZATIONS_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function bloodBankOrganizations(state = [], action) {
    switch (action.type) {
        case "FETCH_BLOOD_BANK_ORGANIZATIONS_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function vaccinationOrganizationMembers(state = [], action) {
    switch (action.type) {
        case "FETCH_VACCINATION_ORGANIZATION_MEMBERS_FULFILLED":
            return action.payload.data?.data || []
        case "VACCINATION_ORGANIZATION_INVITE_MEMBER_FULFILLED":
            let result = action.payload.data?.data
            console.log("invited member ", action.payload.data?.data)
            return uniqBy(
                [{ email: result.email, invitationStatus: "pending" }, ...state],
                "email"
            )
        case "VACCINATION_ORGANIZATION_DELETE_MEMBER_FULFILLED":
            let res = action.payload.data?.data
            let email = res[1].email
            console.log("deleted member ", action.payload.data?.data)
            return state.filter((item) => item.email !== email)
        default:
            return state
    }
}

export function vaccines(state = [], action) {
    switch (action.type) {
        case "FETCH_VACCINES_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function vaccinationNoShows(state = [], action) {
    switch (action.type) {
        case "FETCH_NO_SHOWS_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function vaccinationStats(state = [], action) {
    switch (action.type) {
        case "FETCH_VACCINATION_STATS_FULFILLED":
            return action.payload.data?.data || []
        default:
            return state
    }
}

export function accountVaccines(state = [], action) {
    switch (action.type) {
        case "FETCH_ACCOUNT_VACCINES_FULFILLED":
            return action.payload.data?.data || []
        case "SELECT_VACCINES_FULFILLED":
            console.log("SELECT_VACCINES FULFILLED ", action.payload, " state ", state)
            for (const vaccine of action.payload.data?.data || []) {
                let index = state.findIndex((item) => item.id === vaccine.vaccineId)
                if (index !== -1) {
                    state = [
                        ...state.slice(0, index),
                        {
                            ...state[index],
                            ...{ id: vaccine.vaccineId, selected: vaccine.selected },
                        },
                        ...state.slice(index + 1),
                    ]
                }
            }
            return state
        default:
            return state
    }
}

export function adminConfig(state = {}, action) {
    switch (action.type) {
        case "SET_ADMIN_COUNTRY":
            return Object.assign({}, state, { countryCode: action.payload })
        case "SET_ADMIN_COMPANY":
            return Object.assign({}, state, { company: action.payload })
        case "SET_ADMIN_RATE":
            return Object.assign({}, state, { rate: action.payload })
        case "SET_ADMIN_CURRENCY":
            return Object.assign({}, state, { currency: action.payload })
        default:
            return state
    }
}

export function settings(state = {}, action) {
    switch (action.type) {
        case "FETCH_SETTINGS_FULFILLED":
            console.log("fetch settings fulfilled ", action.payload.val())
            return { ...action.payload.val() }
        case "FETCH_SETTINGS_REJECTED":
            return state
        case "SET_SETTINGS_FULFILLED":
            return Object.assign({}, state, action.payload)
        default:
            return state
    }
}

export function uploadCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadMultipleVehicleCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_MULTIPLE_VEHICLE_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadPropertyCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_PROPERTY_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadHealthCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_HEALTH_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadBirthdayCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_BIRTHDAY_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadDivaliCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_DIVALI_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadEidCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_EID_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function uploadChristmasCustomers(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_CHRISTMAS_CUSTOMERS_FULFILLED":
            return action.payload.data
        default:
            return state
    }
}

export function message(state = { content: "", success: false }, action) {
    switch (action.type) {
        case "SET_MESSAGE":
            return Object.assign({}, state, action.payload)
        case "RESET_MESSAGE":
            return { content: "", success: false }
        case "SEND_MESSAGE_FULFILLED":
            return Object.assign({}, state, action.payload.data)
        default:
            return state
    }
}

export function uploadImage(state = {}, action) {
    switch (action.type) {
        case "UPLOAD_IMAGE_FULFILLED":
            return Object.assign({}, state, action.payload.data)
        case "UPLOAD_IMAGE_REJECTED":
            return Object.assign({}, state, action.payload.data)
        default:
            return state
    }
    return state
}
