import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"

import _ from "lodash"
import config from "../../../../config"

export default class SettingsForm extends Component {
    resolveLogoImage = () => {
        let { user } = this.props
        return user.logo
    }

    constructor(props) {
        super(props)

        let { settings } = this.props
        this.state = {
            imageUploadStatus: null,
            reminder1: settings.reminder1 || null,
            reminder2: settings.reminder2 || null,
            reminder3: settings.reminder3 || null,
        }
    }

    handleFileChange = (event) => {
        if (event.target.files[0].type.indexOf("image") !== 0) {
            this.setState({ imageUploadStatus: "invalid" })
            return
        }

        this.logoImage = URL.createObjectURL(event.target.files[0])
        this.setState({ logoImage: this.logoImage })

        let data = new FormData()
        data.append("user", JSON.stringify(this.props.user))
        data.append("file", event.target.files[0])
        this.props.uploadImage(this.props.user, data).then((res) => {
            this.setState({
                imageUploadStatus: res.value.data.success ? "uploaded" : "error",
            })
            this.props.fetchUser(this.props.user.email)
        })
    }

    handleReminderUploadOption = (event) => {
        if (event.target.value !== "set-reminder") {
            this.props.setSettings(this.props.user, {
                uploadReminderInterval: event.target.value,
            })
        }
        console.log(event.target.value)
    }

    render() {
        const { user, settings } = this.props
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col sm={6} xs={12} className="">
                        <h1>Settings</h1>

                        <div className="pt-4">
                            <h3 className="font-weight-bold">
                                1. Upload your company logo
                            </h3>
                            <div>
                                Use a high-resolution PNG with a transparent background
                                for best results
                            </div>
                        </div>

                        <Form inline className="">
                            {this.state.logoImage || this.props.user.logo ? (
                                <div className="mb-2 pr-2">
                                    <img
                                        src={this.resolveLogoImage()}
                                        style={{ width: 100 }}
                                    />
                                </div>
                            ) : null}
                            <FormGroup>
                                <Label>
                                    <Input
                                        type="file"
                                        name="logo"
                                        onChange={this.handleFileChange}
                                    />{" "}
                                    Upload logo
                                </Label>
                            </FormGroup>
                        </Form>

                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "uploaded" ? (
                                <Alert color="success">Image uploaded successfully</Alert>
                            ) : null}{" "}
                        </div>
                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "error" ? (
                                <Alert color="danger">Problem uploading image</Alert>
                            ) : null}{" "}
                        </div>
                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "invalid" ? (
                                <Alert color="danger">This is not a valid image</Alert>
                            ) : null}{" "}
                        </div>

                        <Form className="pt-4">
                            <FormGroup tag="fieldset">
                                <legend>
                                    <h3 className="font-weight-bold">
                                        2. Message Settings
                                    </h3>
                                    <div>(All settings are automatically saved)</div>
                                </legend>

                                <h4 className="font-weight-bold pt-2">
                                    URL for information
                                </h4>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings && settings.infoURL === "website"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    infoURL: "website",
                                                })
                                            }
                                        />
                                        Website URL
                                    </Label>
                                </FormGroup>

                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings &&
                                                settings.infoURL === "websiteShortURL"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    infoURL: "websiteShortURL",
                                                })
                                            }
                                        />
                                        Website Short URL
                                    </Label>
                                </FormGroup>

                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings &&
                                                settings.infoURL === "outagesShortURL"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    infoURL: "outagesShortURL",
                                                })
                                            }
                                        />
                                        Outages Short URL
                                    </Label>
                                </FormGroup>

                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings &&
                                                settings.infoURL === "facebook"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    infoURL: "facebook",
                                                })
                                            }
                                        />
                                        Facebook URL
                                    </Label>
                                </FormGroup>

                                <FormGroup check className="pt-2">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings &&
                                                settings.infoURL === "instagram"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    infoURL: "instagram",
                                                })
                                            }
                                        />
                                        Instagram URL
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                        </Form>

                        <h4 className="font-weight-bold pt-2">Location Options</h4>
                        <Form>
                            <FormGroup check>
                                <Label check className="">
                                    <Input
                                        type="checkbox"
                                        checked={settings.useParishes ? true : false}
                                        onChange={(evt) => {
                                            this.props.setSettings(user, {
                                                useParishes: evt.target.checked,
                                            })
                                        }}
                                    />
                                    Use Parishes
                                </Label>
                            </FormGroup>
                        </Form>

                        <div style={{ paddingLeft: "20px" }}>
                            Check this box if you want to specify the parish in your
                            outage alerts (note: this may result in increased SMS charges)
                        </div>

                        <Form className="pt-2">
                            <FormGroup check>
                                <Label check className="">
                                    <Input
                                        type="checkbox"
                                        checked={settings.useFeeders ? true : false}
                                        onChange={(evt) => {
                                            this.props.setSettings(user, {
                                                useFeeders: evt.target.checked,
                                            })
                                        }}
                                    />
                                    Use Feeders
                                </Label>
                            </FormGroup>
                        </Form>

                        <div style={{ paddingLeft: "20px" }}>
                            Only applicable for utility where outages can be specified by
                            feeders
                        </div>

                        <h4 className="font-weight-bold pt-2">Email</h4>

                        <Form className="">
                            <FormGroup check>
                                <Label check className="">
                                    <Input
                                        type="checkbox"
                                        checked={settings.email ? true : false}
                                        onChange={(evt) => {
                                            this.props.setSettings(user, {
                                                email: evt.target.checked,
                                            })
                                        }}
                                    />
                                    Also send messages by email
                                </Label>
                            </FormGroup>
                        </Form>

                        <div className="text-center " style={{ paddingTop: "48px" }}>
                            <Button
                                onClick={this.props.goBack}
                                color="link"
                                style={{ cursor: "pointer" }}
                            >
                                Go back to your dashboard
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
