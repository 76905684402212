import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"

import config from "./config"

export function firebaseInit() {
    // Initialize Firebase
    var devConfig = {
        apiKey: "AIzaSyB3ENHT2Y1mHU_Sn4xfLqIJOg46MB-Ya0s",
        authDomain: "ytm-dev.firebaseapp.com",
        databaseURL: "https://ytm-dev.firebaseio.com",
        projectId: "ytm-dev",
        storageBucket: "ytm-dev.appspot.com",
        messagingSenderId: "599057831302",
    }

    var prodConfig = {
        apiKey: "AIzaSyBS953Yn0cxOtc9i5tcYrF9XC4avqQDqt0",
        authDomain: "yes-text-me-2e74c.firebaseapp.com",
        databaseURL: "https://yes-text-me-2e74c.firebaseio.com",
        storageBucket: "yes-text-me-2e74c.appspot.com",
        messagingSenderId: "687907763120",
    }

    if (config.development) {
        firebase.initializeApp(devConfig)
        console.log("development")
    } else {
        firebase.initializeApp(prodConfig)
    }
}

export default firebase
