import React, { Component, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap"
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardText,
} from "reactstrap"
import classnames from "classnames"
import { countryCodeToCountry, monthValueToMonthName } from "../utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faUsers } from "@fortawesome/free-solid-svg-icons"

const CreditFinanceCountrySummary = ({
    creditFinanceAggregateStats,
    creditFinanceAggregateEmailStats,
    country,
    rate,
}) => {
    const [activeTab, setActiveTab] = useState("1")
    const currentPath = useSelector((state) =>
        state.router.location.pathname.split("/").slice(0).join("/")
    )

    const stats = creditFinanceAggregateStats
        ? creditFinanceAggregateStats[country.countryCode] || {}
        : {}
    const emailStats = creditFinanceAggregateEmailStats
        ? creditFinanceAggregateEmailStats[country.countryCode] || {}
        : {}
    const today = new Date()

    const monthValue = stats[today.getMonth()] || 0
    const yearValue = stats[today.getFullYear()] || 0

    const emailMonthValue = emailStats[today.getMonth()] || 0
    const emailYearValue = emailStats[today.getFullYear()] || 0

    let currency

    if (rate) {
        currency = rate.currency === "XCD" ? "EC" : "US"
    }

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <div className="mt-5 mb-5">
            <div className="d-flex flex-row mt-2 mb-2">
                <img
                    src={`/flag-icon-css/flags/4x3/${country.countryCode.toLowerCase()}.svg`}
                    height={30}
                />
                <Link
                    className="ml-2"
                    to={currentPath + "/country-detail/" + country.countryCode}
                >
                    {countryCodeToCountry(country.countryCode)}
                </Link>
            </div>
            <Nav className="mt-1" tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                            toggle("1")
                        }}
                    >
                        SMS
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                            toggle("2")
                        }}
                    >
                        Email
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                            toggle("3")
                        }}
                    >
                        $
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faUsers} size="1x"></FontAwesomeIcon>
                        <div className="ml-1">{country.count} unique customers</div>
                    </div>

                    <div className="d-flex flex-row">
                        {monthValue} total messages sent in{" "}
                        {monthValueToMonthName(today.getMonth())}
                    </div>

                    <div className="d-flex flex-row">
                        {yearValue} total messages sent in {today.getFullYear()}
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faUsers} size="1x"></FontAwesomeIcon>
                        <div className="ml-1">{country.count} unique customers</div>
                    </div>

                    <div className="d-flex flex-row">
                        {emailMonthValue} total messages sent in{" "}
                        {monthValueToMonthName(today.getMonth())}
                    </div>

                    <div className="d-flex flex-row">
                        {emailYearValue} total messages sent in {today.getFullYear()}
                    </div>
                </TabPane>
                <TabPane tabId="3">
                    <div className="d-flex flex-row">
                        <FontAwesomeIcon icon={faUsers} size="1x"></FontAwesomeIcon>
                        <div className="ml-1">{country.count} unique customers</div>
                    </div>

                    <div className="d-flex flex-row">
                        Current month usage to date: ${currency}{" "}
                        {(rate.rate * (monthValue || 0)).toFixed(2)}
                    </div>

                    <div className="d-flex flex-row">
                        Current year usage to date: ${currency}{" "}
                        {(rate.rate * (yearValue || 0)).toFixed(2)}
                    </div>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default CreditFinanceCountrySummary
