import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom'

let months = { 
  0: "January", 
  1: "February", 
  2: "March", 
  3: "April", 
  4: "May", 
  5: "June", 
  6: "July", 
  7: "August", 
  8: "September", 
  9: "October",
  10: "November",
  11: "December"
}; 

const CreditFinanceDashboardSummary = ({creditFinanceAggregateStats, uniqueCreditFinanceCustomers}) => {

  let today = new Date(); 

  const calcTotals = () =>  {
    let keys = Object.keys(creditFinanceAggregateStats || {});      
    let monthValue = 0;
    let yearValue = 0; 

    keys.forEach(key => {
      let value = creditFinanceAggregateStats[key]; 
      monthValue += value[today.getFullYear()] || 0; 
      yearValue += value[today.getMonth()] || 0;
    });

    return {monthValue, yearValue}
  }

  let data = calcTotals();

  return (
    <div className="mt-4 mb-4">
      <h5 className="mt-2 mb-4"><strong>CONSOLIDATED ACTIVITY SUMMARY</strong></h5>
      <div>Total Countries: {uniqueCreditFinanceCustomers.customers ? uniqueCreditFinanceCustomers.customers.length : 0}</div>
      <div>Total unique customers: {uniqueCreditFinanceCustomers.total || 0}</div>
      <div>Total Messages sent in {months[today.getMonth()]}: {data.monthValue}</div>
      <div>Total Messages sent in {today.getFullYear()}:  {data.yearValue}</div>
    </div>
  );
}

export default CreditFinanceDashboardSummary;