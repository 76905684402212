import React from "react"
import Header from "../../../components/Header"
import { Button, Container, Row, Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { push, goBack as goBackAction } from "react-router-redux"

import {
    logout as logoutAction,
    fetchVaccines,
    fetchPatientVaccinations,
} from "../../../actions"

export default function DownloadTemplates() {
    const { user, vaccines, patientVaccinations } = useSelector((state) => state)
    const [vaccination, setVaccination] = React.useState()
    const [numberNotFound, setNumberNotFound] = React.useState(false)
    const [showMissingNumberForm, setShowMissingNumberForm] = React.useState(false)
    const [mobileNumber, setMobileNumber] = React.useState()
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())

    React.useEffect(() => {
        dispatch(fetchVaccines())
    }, [])

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header right={right}></Header>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} xs={12}>
                        <h4>Download Excel Templates</h4>
                        <h5>
                            Note: DO NOT MODIFY the headings of these files in any way!
                        </h5>
                        <p>
                            Please right-click on the link and select "Save as..." to
                            download
                        </p>
                        <div className="d-flex flex-row align-items-center mt-4 mb-4">
                            <img src="/images/excel-24.png" />
                            <div>
                                <a href="/templates/PreviousCustomersTemplate.xlsx">
                                    Previous customers template
                                </a>
                            </div>
                        </div>
                        <Button onClick={goBack} color="link">
                            Back to Admin
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
