import React, { useState } from 'react'
import { ReactReduxContext } from 'react-redux';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';


const handleChange = (event) => {
  
}

const MessagingServiceUI = (props) => {
  const [messagingServiceId, setMessagingServiceId] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(null);

  const saveMessagingServiceId = () => {
    props.setMessagingServiceId(props.user, messagingServiceId).then(res => {      
      setSaveSuccess(res.value.data.success);

      setTimeout(() => {
        setSaveSuccess(false)
      }, 5000)
    });
  }

  React.useEffect(() => {
    if(props.user) {
      setMessagingServiceId(props.user.messagingServiceId);
    }
  }, [props.user])

  return (
    <div>
      <h4>Twilio numbers and activation</h4>

      <Form>
        {saveSuccess ? <Alert color="success">MessagingServiceId saved</Alert> : null } 
        <FormGroup>
          <Label>
            <span>MessagingServiceId</span>
            <Input value={messagingServiceId || ""} onChange={(event) => setMessagingServiceId(event.target.value) }></Input>            
          </Label>

        </FormGroup>

        <FormGroup>
          <Button onClick={saveMessagingServiceId}>Assign MessagingServiceId</Button>
        </FormGroup>
      </Form>
    </div>
  )
}

export default MessagingServiceUI;