import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import Header from '../components/Header'; 
import { Link } from 'react-router-dom'
import { goBack as goBackAction} from 'connected-react-router'

const TokenExpired = () => {

  return (
    <Container>
      <Header />        
      <Row className="justify-content-center">
            <Col sm={6} xs={12} className="">              
              <Alert>Token has expired</Alert>
              <div className="text-center">
                <Link to="/auth/login">Login</Link>              
              </div>
            </Col>
      </Row>                        
    </Container>
  )
}

  export default TokenExpired