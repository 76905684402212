import React, { useState, useEffect, Fragment} from 'react';
import { Container, Row, Col, Button, Alert, Form, FormGroup, CustomInput, Label, Input } from 'reactstrap';
import Header from '../components/Header'; 
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { goBack as goBackAction} from 'connected-react-router'
import qs from 'qs'
import TokenExpired from '../components/TokenExpired'

import config from '../config'
import countries from '../countries-caribbean.json'; 

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction,
  uploadCreditFinanceCustomers as uploadCreditFinanceCustomersAction, 
  setAlert,
  saveAlert,  
  setSettings as setSettingsAction } from '../actions'

const isValidCountryCode = (code) => {
  return countries.find(x => x.code.toLowerCase() === code) !== undefined 
}

const countryCodeToCountry = (code) => countries.find(x => x.code.toLowerCase() === code ).name

  const NewCustomersCountrySelected = () => {

    const [documenentUploadStatus, setDocumentUploadStatus] = useState(null)
    const [totalCustomerCount, setTotalCustomerCount] = useState(null) 
    const [newCustomerCount, setNewCustomerCount] = useState(null) 
    const [discardedCustomers, setDiscardedCustomers] = useState(false)

    const dispatch = useDispatch()
    const uploadCreditFinanceCustomers = (user, code, data) => dispatch(uploadCreditFinanceCustomersAction(user, code, data))


    const UploadUI = () => (
      <Fragment>
        <h5 className="text-primary">You're adding customers for {countries.find(x => x.code.toLowerCase() === queryCode).name}</h5>
        <img src={`/flag-icon-css/flags/4x3/${queryCode.toLowerCase()}.svg`} height={30} />
        <div>Code: {queryCode}</div>
      
        <div>
          <Input type="file" onChange={handleFileChange} />{' '}
        </div>                
      
        <div className="text-center mt-2">                  
          <Button color="link" onClick={() => goBack()}>Cancel</Button>
        </div>  
      </Fragment>
    )

    const UploadSuccess = () => (
      <Fragment>        
        <img src={`/flag-icon-css/flags/4x3/${queryCode.toLowerCase()}.svg`} height={30} />
        <div>You have successfully added {newCustomerCount} new customers to your {countries.find(x => x.code.toLowerCase() === queryCode ).name} database.</div>
      
        <div>
          Your {countries.find(x => x.code.toLowerCase() === queryCode ).name} database now contains {totalCustomerCount} unique customers.                    
        </div>                
      
        <div className="d-flex flex-column mt-2">                  
          <Button color="link" onClick={() => goBack()} style={{textAlign: "left", padding: "0px"}}>Previous screen</Button>
          <Link to={`/${user.category}/${user.country}/${user.email}`} color="link">Back to dashboard</Link>
        </div>  
      </Fragment>
    )    

    const handleFileChange = (event) => {

      if(event.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          setDocumentUploadStatus("invalid")
          return
      }

      var fileReader = new FileReader();
      fileReader.onload = async (fileLoadedEvent) => {
          var binaryFileLoaded = fileLoadedEvent.target.result;
          uploadCreditFinanceCustomers(user.uid, queryCode, binaryFileLoaded).then((res) => {            
            setDocumentUploadStatus(res.value.data.success ? "uploaded" : "error")

            setNewCustomerCount(res.value.data.newCustomerCount) 
            setTotalCustomerCount(res.value.data.totalCustomerCount)
            setDiscardedCustomers(res.value.data.discardedCustomers)
            //this.props.fetchUser(this.props.user.email)
        })          
      }      
      
      fileReader.readAsBinaryString(event.target.files[0]);
    };    

    useEffect(() => {      
      fetchUser(email)    
    }, [email]);    

    const [countryCode, setCountryCode] = useState(null)
    const email = useSelector(state => state.router.location.pathname.split("/")[3])
    const queryCode = useSelector(state => qs.parse(state.router.location.search)['?code'])
    
    const currentPath = useSelector(state => state.router.location.pathname.split("/").slice(0,-1).join("/") )    
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const user = useSelector(state => state.user)
    const goBack = () => dispatch(goBackAction())

    const selectCountry = (evt) => {
      setCountryCode(evt.target.value)
    }

    if(!user || !isValidCountryCode(queryCode)) {
      return <TokenExpired />
    } 

    return (
      <Container>
        <Header />        
        <Row className="justify-content-center">
              <Col sm={6} xs={12} className="">
                <h1>Add New Customers</h1>
                {discardedCustomers ? <Alert color="danger">Your upload included customers from countries outside of {countryCodeToCountry(queryCode)}. These customers were not uploaded.</Alert> : null}
                {documenentUploadStatus === "uploaded" ? <UploadSuccess /> :  <UploadUI />}

              </Col>
        </Row>                        
      </Container>
    )
  }

  export default NewCustomersCountrySelected