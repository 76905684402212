import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import { format, formatDistanceToNow } from "date-fns"

import * as Yup from "yup"
import { useFormik, yupToFormErrors } from "formik"
import { useToasts } from "react-toast-notifications"
import { formatIncompletePhoneNumber } from "libphonenumber-js"

let VaccinationSchema = Yup.object({
    mobileNumber: Yup.string()
        .matches(/^\d+$/, "Please enter a valid 7-digit mobile number")
        .length(7, "Please enter a valid 7-digit mobile number"),
    email: Yup.string().email("Please enter a valid email"),
    secondShotAdministeredToday: Yup.boolean().required(),
})

export default function SecondVaccinationCompleteForm({
    resetForm,
    vaccination,
    user,
    vaccines,
    goBack,
    onSubmit,
    vaccinationRepository,
}) {
    const [errorMessage, setErrorMessage] = React.useState("")
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [loadingResult, setLoadingResult] = React.useState(false)
    const [vaccineChangeModal, setVaccineChangeModal] = React.useState(false)
    const [vaccineFinishChangeModal, setVaccineFinishChangeModal] = React.useState(false)

    const { addToast } = useToasts()

    const formik = useFormik({
        validationSchema: VaccinationSchema,
        initialValues: {
            accountId: user.uid,
            mobileNumber: vaccination.mobileNumber,
            email: vaccination.email,
            secondShotAdministeredToday: false,
            vaccinationDate: new Date(),
            vaccinationIndex: 2,
            vaccineId: vaccination.vaccineId,
        },
        onSubmit: (values) => {
            delete values.secondShotAdministeredToday

            addVaccination(values).then((res) => {
                setTimeout(() => {
                    resetForm()
                }, 1500)
            })
        },
    })

    React.useEffect(() => {
        if (formik.values.vaccineId !== vaccination.vaccineId) {
            setVaccineChangeModal(true)
        }
    }, [formik.values.vaccineId])

    global.formik = formik

    const submitAndClose = () => {
        let values = formik.values
        delete values.noDateForSecondShot
        delete values.confirmMobileNumber
        addVaccination(values)
            .then(() => {
                goBack()
            })
            .catch((e) => {})
    }

    const addVaccination = (values) => {
        setLoadingResult(true)
        return vaccinationRepository
            .addVaccination(values)
            .then((res) => {
                setLoadingResult(false)
                setSubmitSuccess(true)
                window.scrollTo(0, 0)
                console.log("add vaccination result", res)
                addToast("Vaccination event successfully added", {
                    autoDismiss: true,
                    appearance: "success",
                })
                return Promise.resolve()
            })
            .catch((err) => {
                console.log("err ", err)
                setLoadingResult(false)
                window.scrollTo(0, 0)
                setErrorMessage(err?.response.data.errorMessage || "Server Error")
                console.log("Add vaccination error ", err?.response)
                addToast(err?.response.data.errorMessage || "Server Error", {
                    autoDismiss: true,
                    appearance: "error",
                })
                return Promise.reject()
            })
    }

    const submitForm = () => {
        if (formik.values.vaccineId === vaccination.vaccineId) {
            formik.handleSubmit()
        } else {
            setVaccineFinishChangeModal(true)
        }
    }

    const submitFormAfterConfirmation = () => {
        setVaccineFinishChangeModal(false)
        formik.handleSubmit()
    }

    console.log("form errors ", formik.errors)
    const setCurrentDate = () => {
        formik.setFieldValue("vaccinationDate", new Date())
    }

    const isFormValid = () => {
        return formik.isValid && formik.values.secondShotAdministeredToday === true
    }

    const resetVaccineSelection = () => {
        formik.setFieldValue("vaccineId", vaccination.vaccineId)
        setVaccineChangeModal(false)
    }

    const resetFinishVaccineSelection = () => {
        formik.setFieldValue("vaccineId", vaccination.vaccineId)
        setVaccineFinishChangeModal(false)
    }

    const getVaccineById = (vaccineId) => {
        return vaccines.find((item) => item.id === vaccineId)
    }

    return (
        <Container>
            <Modal
                isOpen={vaccineChangeModal}
                toggle={() => setVaccineChangeModal(!vaccineChangeModal)}
            >
                <ModalHeader toggle={() => setVaccineChangeModal(!vaccineChangeModal)}>
                    Vaccine changed
                </ModalHeader>
                <div className="m-2">
                    You have selected the {getVaccineById(formik.values.vaccineId)?.name}{" "}
                    vaccine. This is different from the first shot vaccine. Are you sure?
                </div>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={resetVaccineSelection}>
                        No
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => setVaccineChangeModal(!vaccineChangeModal)}
                    >
                        Yes
                    </Button>{" "}
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={vaccineFinishChangeModal}
                toggle={() => setVaccineChangeModal(!vaccineFinishChangeModal)}
            >
                <ModalHeader
                    toggle={() => setVaccineChangeModal(!vaccineFinishChangeModal)}
                >
                    Vaccine changed
                </ModalHeader>
                <div className="m-2">
                    You are proceeding with the{" "}
                    {getVaccineById(formik.values.vaccineId)?.name} vaccine
                </div>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={resetFinishVaccineSelection}>
                        Cancel
                    </Button>
                    <Button onClick={submitFormAfterConfirmation} color="primary">
                        Ok
                    </Button>{" "}
                </ModalFooter>
            </Modal>

            <Row className="justify-content-center">
                <Col md={6} xs={12}>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <img src="/images/jab2.svg" style={{ height: 50, width: 50 }} />
                        <h3 className="ml-2 mr-2">Add Second Vaccination</h3>
                        {submitSuccess ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                size={"3x"}
                                color={"green"}
                            />
                        ) : null}
                    </div>

                    <Form>
                        {(errorMessage && (
                            <Alert color="danger" className="mt-2 mb-2">
                                {errorMessage}
                            </Alert>
                        )) ||
                            null}

                        <FormGroup>
                            <h3>{vaccination.mobileNumber}</h3>
                            <div>
                                1st vaccination was on{" "}
                                {format(
                                    new Date(vaccination.vaccinationDate),
                                    "d MMM yyy"
                                )}{" "}
                            </div>
                            <div>
                                {formatDistanceToNow(
                                    new Date(vaccination.vaccinationDate),
                                    {
                                        suffix: true,
                                    }
                                )}{" "}
                                ago
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">Vaccine Name</Label>
                            {formik.touched.vaccineId && formik.errors.vaccineId && (
                                <Alert color="danger">{formik.errors.vaccineId}</Alert>
                            )}
                            <Input
                                value={formik.values.vaccineId}
                                type="select"
                                placeholder="Vaccine Name"
                                onBlur={formik.handleBlur("vaccineId")}
                                onChange={formik.handleChange("vaccineId")}
                            >
                                {vaccines.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <CustomInput
                                checked={formik.values.secondShotAdministeredToday}
                                type="checkbox"
                                id="check"
                                name="check"
                                label="2nd Shot administered today"
                                onChange={(evt) =>
                                    formik.setFieldValue(
                                        "secondShotAdministeredToday",
                                        evt.target.checked
                                    )
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex flex-row align-items-center">
                                    {loadingResult ? (
                                        <Spinner color="success" className="mr-2" />
                                    ) : null}
                                    <Button
                                        onClick={submitForm}
                                        disabled={!isFormValid()}
                                        color="success"
                                        type="button"
                                    >
                                        Finish and add another
                                    </Button>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Button
                                        onClick={submitForm}
                                        disabled={!isFormValid()}
                                        color="link"
                                        type="button"
                                    >
                                        Finish and close
                                    </Button>
                                </div>

                                <Button color="link" onClick={goBack}>
                                    Cancel
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
