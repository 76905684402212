import React, { Component } from "react"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import "../../../App.css"
import { goBack } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"
import { setRegistrationField, registerVaccinationUser } from "../../../actions"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

class AppContainer extends Component {
    confirmRegistration() {
        this.props
            .registerUser(this.props.registration)
            .then((user) => {})
            .catch((e) => {
                console.log("registration callback error", e)
            })
    }

    goBack() {
        this.props.setRegistrationField({ "g-recaptcha-response": null, error: null })
        this.props.goBack()
    }

    componentDidMount() {
        //ReactDOM.findDOMNode(this).scrollTop = 0
        window.scrollTo(0, 0)
    }

    render() {
        const {
            organizationLongName,
            organizationShortName,
            address1,
            address2,
            covid19hotlineFlow,
            covid19hotlineOther,
            covid19hotlineDigicel,
            ministryWebsite,
            ministryWebsiteShortURL,
            vaccinationWebsite,
            vaccinationWebsiteShortURL,
            ministryPhone,
            email,
            facebook,
            instagram,
        } = this.props.registration

        if (!(this.props.registration && this.props.registration.organizationLongName)) {
            return (
                <div>
                    <Header right={<div>Ministry of Health</div>} />
                    <Container>
                        <Row className="justify-content-center">
                            <Alert color="info">No information to confirm.</Alert>
                        </Row>
                    </Container>
                </div>
            )
        } else if (this.props.registration && this.props.registration.error) {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="text-left">
                                <div>
                                    <Alert color="danger">
                                        Registration error:{" "}
                                        {this.props.registration.error}.
                                    </Alert>
                                </div>
                                <div className="pt-4 pb-4 text-center">
                                    <Button onClick={this.goBack.bind(this)}>Back</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else if (this.props.registration && this.props.registration.success) {
            const { registration } = this.props

            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="text-left">
                                <div>
                                    <p>
                                        Congratulations. You have successfully registered{" "}
                                        {registration.organizationLongName}
                                    </p>
                                    <p>Your account now requires activation</p>

                                    <Link
                                        to={`/${registration.category}/${registration.country}/${registration.email}`}
                                    >
                                        Go to your dashboard
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }

        return (
            <div className="App">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12} className="text-left">
                            <Alert color="info">
                                Thank you for registering. You have submitted the
                                following information:
                            </Alert>
                            <p>
                                <strong>Organization Long Name</strong>:{" "}
                                {organizationLongName}
                            </p>
                            <p>
                                <strong>Organization Short Name</strong>:{" "}
                                {organizationShortName}
                            </p>
                            <p>
                                <strong>Email: </strong>
                                {email}
                            </p>
                            <p>
                                <strong>Address Line 1</strong>: {address1}
                            </p>
                            <p>
                                <strong>Address Line 2</strong>: {address2}
                            </p>
                            <p>
                                <strong>Covid-19 Hotline Digicel</strong>:{" "}
                                {covid19hotlineDigicel}
                            </p>
                            <p>
                                <strong>Covid-19 Hotline Flow</strong>:{" "}
                                {covid19hotlineFlow}
                            </p>
                            <p>
                                <strong>Covid-19 Hotline Other</strong>:{" "}
                                {covid19hotlineOther}
                            </p>
                            <p>
                                <strong>Ministry main number</strong>: {ministryPhone}
                            </p>
                            <p>
                                <strong>Ministry main website</strong>: {ministryWebsite}
                            </p>
                            <p>
                                <strong>Ministry main website short URL</strong>:{" "}
                                {ministryWebsiteShortURL}
                            </p>
                            <p>
                                <strong>Vaccination website</strong>: {vaccinationWebsite}
                            </p>
                            <p>
                                <strong>Vaccination website short URL</strong>:{" "}
                                {vaccinationWebsiteShortURL}
                            </p>
                            <p>
                                <strong>Facebook</strong>: {facebook}
                            </p>
                            <p>
                                <strong>Instagram</strong>: {instagram}
                            </p>
                            <div className="pt-4 pb-4 text-center">
                                <Button
                                    color="success"
                                    onClick={this.confirmRegistration.bind(this)}
                                >
                                    Confirm registration
                                </Button>
                            </div>

                            <p className="text-center">
                                Or go{" "}
                                <Button
                                    color="link"
                                    className="m-0 p-0"
                                    onClick={this.goBack.bind(this)}
                                >
                                    back
                                </Button>{" "}
                                to make a correction!
                            </p>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    location: state.location,
    registration: state.registration,
})

const mapDispatchToProps = (dispatch) => ({
    setRegistrationField: (params) => dispatch(setRegistrationField(params)),
    registerUser: (info) => dispatch(registerVaccinationUser(info)),
    goBack: () => dispatch(goBack()),
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
