import React, { Component } from "react"
import { connect } from "react-redux"
import logo from "../logo.svg"
import "../App.css"
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
    push,
    goBack,
} from "react-router-redux"
import { Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    TextArea,
    Alert,
} from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import Header from "../components/Header"
import Footer from "../components/Footer"

import { login, setRegistrationField, registerUser } from "../actions"
import classnames from "classnames"
import ReCAPTCHA from "react-google-recaptcha"

class AppContainer extends Component {
    state = { viewPassword: null }

    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const category = parts[parts.length - 2].toLowerCase()
        this.props.setRegistrationField({ category })
    }

    handleBackPress = () => {
        this.props.setRegistrationField({ error: null })
        this.goBack()
    }

    submitForm(evt) {
        evt.preventDefault()
        //this.props.registerUser(this.props.registration);
        this.props.push("/auth/credit-finance/register/confirm")
    }

    passwordsMatch() {
        const { password, confirmPassword } = this.props.registration

        if (password && confirmPassword) {
            return password === confirmPassword
        } else if (!password && !confirmPassword) {
            return true
        } else {
            return false
        }
    }

    allFieldsFilled() {
        const {
            companyFullName,
            companyShortName,
            address1,
            address2,
            phone,
            email,
            password,
            confirmPassword,
        } = this.props.registration

        return (
            companyFullName &&
            companyFullName.length > 0 &&
            companyShortName &&
            companyShortName.length > 0 &&
            address1 &&
            address1.length > 0 &&
            address2 &&
            address2.length > 0 &&
            phone &&
            phone.length > 0 &&
            email &&
            email.length > 0 &&
            password === confirmPassword &&
            password &&
            password.length > 0 &&
            this.props.registration["g-recaptcha-response"] &&
            this.props.registration["g-recaptcha-response"].length > 0
        )
    }

    toggleViewPassword = () => {
        this.setState({ viewPassword: !this.state.viewPassword })
    }

    getPasswordInputType = () => {
        if (this.state.viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    getEyeIcon = () => {
        if (this.state.viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }

    render() {
        const { setRegistrationField, registration } = this.props
        const country = registration.country ? registration.country.toLowerCase() : ""

        const flagUrl = `/flag-icon-css/flags/4x3/${country}.svg`

        return (
            <div className="">
                <Header right={<img src={flagUrl} height="30"></img>} />
                <Container>
                    <Row className="justify-content-center">
                        <h1>Register</h1>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={4} xs={12} className="">
                            <Form onSubmit={this.submitForm.bind(this)}>
                                <FormGroup>
                                    <Label for="companyFullName">Company Long Name</Label>
                                    <Input
                                        value={registration.companyFullName}
                                        required
                                        type="text"
                                        placeholder="Company Long Name"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                companyFullName: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="companyShortName">
                                        Company Short Name
                                    </Label>
                                    <Input
                                        value={registration.companyShortName}
                                        required
                                        type="text"
                                        placeholder="Max 11 chars w spaces"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                companyShortName: evt.target.value.slice(
                                                    0,
                                                    11
                                                ),
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="address1">Address Line 1</Label>
                                    <Input
                                        value={registration.address1}
                                        required
                                        type="text"
                                        placeholder="Address Line 1"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                address1: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="address2">Address Line 2</Label>
                                    <Input
                                        value={registration.address2}
                                        required
                                        type="text"
                                        placeholder="Address Line 2"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                address2: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="phone">Telephone</Label>
                                    <Input
                                        value={registration.phone}
                                        required
                                        type="number"
                                        placeholder="Enter your local 7-digit number"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                phone: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        value={registration.email}
                                        required
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                email: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="email">Website</Label>
                                    <Input
                                        value={registration.website}
                                        type="url"
                                        name="website"
                                        id="website"
                                        placeholder="http:// or https://"
                                        onChange={(evt) =>
                                            setRegistrationField({
                                                website: evt.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <InputGroup>
                                        <Input
                                            required
                                            type={this.getPasswordInputType()}
                                            name="password"
                                            placeholder="Password"
                                            onChange={(evt) =>
                                                setRegistrationField({
                                                    password: evt.target.value,
                                                })
                                            }
                                        />
                                        <InputGroupAddon
                                            className="view-password"
                                            addonType="append"
                                            onClick={this.toggleViewPassword}
                                        >
                                            <FontAwesomeIcon
                                                icon={this.getEyeIcon()}
                                            ></FontAwesomeIcon>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="password2">Confirm Password</Label>
                                    {!this.passwordsMatch() ? (
                                        <Alert color="danger">
                                            Passwords do not match
                                        </Alert>
                                    ) : null}

                                    <InputGroup>
                                        <Input
                                            required
                                            type={this.getPasswordInputType()}
                                            name="confirmPassword"
                                            placeholder="Confirm password"
                                            onChange={(evt) =>
                                                setRegistrationField({
                                                    confirmPassword: evt.target.value,
                                                })
                                            }
                                        />
                                        <InputGroupAddon
                                            className="view-password"
                                            addonType="append"
                                            onClick={this.toggleViewPassword}
                                        >
                                            <FontAwesomeIcon
                                                icon={this.getEyeIcon()}
                                            ></FontAwesomeIcon>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>

                                <ReCAPTCHA
                                    className="captcha"
                                    ref={(e) => console.log("")}
                                    sitekey="6LcfRjsUAAAAAOF5AgszQeZRBqtfNZVhf9MnU95h"
                                    onChange={(response) => {
                                        setRegistrationField({
                                            "g-recaptcha-response": response,
                                        })
                                    }}
                                />

                                <div className="text-center p-4">
                                    <Button
                                        color="success"
                                        disabled={!this.allFieldsFilled()}
                                    >
                                        Register
                                    </Button>
                                    <Link to="/auth/register" className="ml-5" href="#">
                                        Cancel
                                    </Link>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    location: state.location,
    registration: state.registration,
})

const mapDispatchToProps = (dispatch) => ({
    setRegistrationField: (params) => dispatch(setRegistrationField(params)),
    registerUser: (info) => dispatch(registerUser(info)),
    push: (location) => dispatch(push(location)),
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
