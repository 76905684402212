import { useEffect, useState } from "react"
import {
    fetchSettings as fetchSettingsAction,
    setSettings as setSettingsAction,
} from "../../actions"
import { useDispatch, useSelector } from "react-redux"

export function useRateLimiterPresenter() {
    const [timeout, setTimeout] = useState()
    const [messageLimit, setMessageLimit] = useState()
    const dispatch = useDispatch()
    const { admin, user, settings } = useSelector((state) => state)

    const fetchSettings = (uid) => dispatch(fetchSettingsAction(uid))
    const setSettings = (user, values) => dispatch(setSettingsAction(user, values))

    const saveSettings = () => {
        return setSettings(
            { uid: "admin" },
            {
                rlMessageLimit: parseInt(messageLimit) || settings.rlMessageLimit,
                rlTimeoutMinutes: parseInt(timeout) || settings.rlTimeoutMinutes,
            }
        )
    }

    useEffect(() => {
        if (user) {
            fetchSettings("admin")
        }
    }, [user])

    return {
        admin,
        settings,
        setSettings,
        timeout,
        setTimeout,
        messageLimit,
        saveSettings,
        setMessageLimit,
    }
}
