import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import Footer from '../../components/Footer';

const StepThree = (props) =>  {
  
  const nextStep = () => {
    props.setSetupState(props.user.uid, "4").then(res => {      
      props.fetchSettings(props.user.uid); 
    });
  }

  const saveSetting = (key, value) => {
    props.setSettings(props.user, {[key]: value}).then(res => {      
    })
  }

    return (
    <Container>
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h3>Payment Reminders</h3>
        <h5>Choose your alerts</h5>

        <strong>Introductory Alerts are sent by default</strong>

        <Form>
          <FormGroup>
            <h1 className="text-primary">Credit</h1>
            <div>
              <CustomInput 
                type="switch" 
                label="Credit account reminders BEFORE due" 
                id="switch1" 
                checked={props.settings['credit-account-reminders-before-due'] || false}
                onChange={(evt) => saveSetting("credit-account-reminders-before-due", evt.target.checked)} ></CustomInput>
            </div>

            <div>
              <CustomInput 
                type="switch" 
                label="Credit account alerts OVERDUE" 
                id="switch2"
                checked={props.settings['credit-account-alerts-overdue'] || false}
                onChange={(evt) => saveSetting("credit-account-alerts-overdue", evt.target.checked)} ></CustomInput>
            </div>

            <div>
              <CustomInput                
                type="switch" 
                label="Credit account alerts 30+ Days OVERDUE" 
                id="switch3"
                checked={props.settings['credit-account-alerts-30-plus-days-overdue'] || false} 
                onChange={(evt) => saveSetting("credit-account-alerts-30-plus-days-overdue", evt.target.checked)} ></CustomInput>
            </div>            
          </FormGroup>

          <FormGroup>
            <h1 className="text-primary">Loan</h1>
            <div>
              <CustomInput 
                type="switch" 
                label="Loan account reminders BEFORE due" 
                id="switch4" 
                checked={props.settings['loan-account-reminders-before-due'] || false} 
                onChange={(evt) => saveSetting("loan-account-reminders-before-due", evt.target.checked)} ></CustomInput>
            </div>

            <div>
              <CustomInput 
                type="switch" 
                label="Loan account alerts OVERDUE" 
                id="switch5"
                checked={props.settings['loan-account-alerts-overdue'] || false} 
                onChange={(evt) => saveSetting("loan-account-alerts-overdue", evt.target.checked)} ></CustomInput>
            </div>

            <div>
              <CustomInput 
                type="switch" 
                label="Loan account alerts 30+ Days OVERDUE" 
                id="switc6"
                checked={props.settings['loan-account-alerts-30-plus-days-overdue'] || false} 
                onChange={(evt) => saveSetting("loan-account-alerts-30-plus-days-overdue", evt.target.checked)} ></CustomInput>
            </div>            
          </FormGroup>       

          <div>
          All changes are saved automatically
          </div>   
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={false}
              onClick={nextStep}>Next</Button>
            <Button className="ml-4" onClick={() => {
              
              props.goBack()
            }}>Cancel</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepThree; 