import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Alert, Form, Input } from "reactstrap"
import { electricStore } from "../../../data/electric.store"
import { electricUploadManager } from "../../../data/electric.upload-manager"

export const AdminTab = observer(({ setSelectedTab }) => {
    const {
        handleUploadChanged,
        errorMessage,
        successMessage,
        handleCustomerUploadChanged,
    } = electricUploadManager

    const { customerCount, locationCount, fetchLocationCount } = electricStore

    const user = useSelector((state) => state.user)
    const { addToast } = useToasts()

    useEffect(() => {
        fetchLocationCount(user.uid)
        electricUploadManager.setAddToast(addToast)
    }, [])

    const handleGoBackToActivitySummary = (evt) => {
        evt.preventDefault()
        setSelectedTab("Activity Summary")
    }

    return (
        <div className="d-flex flex-column">
            {errorMessage?.length > 0 ? (
                <Alert color="danger">{errorMessage}</Alert>
            ) : null}

            {successMessage?.length > 0 ? <Alert>{successMessage}</Alert> : null}

            <h2>1. Locations Database</h2>
            <div>There are {locationCount} locations listed in your database</div>
            <Link
                className="py-2"
                to={`/electric/${user.country.isoCode}/${user.email}/download-templates`}
            >
                Download templates here
            </Link>
            <div>Specify locations below</div>

            <div className="d-flex row py-4 pl-2">
                <div>
                    <img src="/images/add_location_only.png" width={75} />
                </div>
                <div className="pl-4">
                    <div>Upload locations excel file here</div>

                    <Input type="file" onChange={handleUploadChanged(user)} />
                </div>
            </div>

            {/*
            <div className="d-flex row py-4 pl-2">
                <div>
                    <img src="/images/add_parish_and_location_icon.png" width={75} />
                </div>
                <div className="pl-4">
                    <div>Upload Parishes & Locations Excel file here</div>

                    <Input type="file" onChange={handleUploadChanged(user)} />
                </div>
            </div>

            <div className="d-flex row py-4 pl-2">
                <div>
                    <img src="/images/add_feeders_and_locations.png" width={75} />
                </div>
                <div className="pl-4">
                    <div>Upload Feeders & Locations Excel file here (if applicable)</div>

                    <Input type="file" onChange={handleUploadChanged(user)} />
                </div>
            </div> */}

            <h2>2. Customers Database</h2>
            <div>There are {customerCount} customers in the database</div>
            <Link
                className="py-2"
                to={`/electric/${user.country.isoCode}/${user.email}/download-templates`}
            >
                Download templates here
            </Link>

            <div id="add-customers" className="d-flex row py-4 pl-2">
                <div>
                    <img src="/images/add_customers_icon.svg" width={75} />
                </div>
                <div className="pl-4">
                    <div>Add customers to your database</div>

                    <Input type="file" onChange={handleCustomerUploadChanged(user)} />
                </div>
            </div>
            <div className="d-flex flex-row">
                <img src="/images/refresh.svg" width={20} />
                <a
                    href=""
                    style={{ textDecoration: "underline", color: "black" }}
                    onClick={handleGoBackToActivitySummary}
                >
                    Dashboard
                </a>
            </div>
        </div>
    )
})
