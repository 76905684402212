import StepSix from '../CreditFinance/SettingsWizard/StepSix'
import StepSeven from '../CreditFinance/SettingsWizard/StepSeven'
import StepEight from '../CreditFinance/SettingsWizard/StepEight'
import StepNine from '../CreditFinance/SettingsWizard/StepNine'
import StepTen from '../CreditFinance/SettingsWizard/StepTen'
import StepEleven from '../CreditFinance/SettingsWizard/StepEleven'
import countryCodeToCountry from './countryCodeToCountry'
import monthValueToMonthName from './monthValueToMonthName'

const settingToSetupStep = (item) => {
  const dict = {
    "introductory-message": "5",
    "credit-account-reminders-before-due": "6",
    "credit-account-alerts-overdue": "7",
    "credit-account-alerts-30-plus-days-overdue": "8",
    "loan-account-reminders-before-due": "9",
    "loan-account-alerts-overdue": "10",
    "loan-account-alerts-30-plus-days-overdue": "11", 
    "alert-type-greetings-birthday": "12",
    "alert-type-greetings-christmas": "13",
    "alert-type-greetings-divali": "14",
    "alert-type-greetings-eid": "15"       
  }  

  return dict[item]
}

export { settingToSetupStep, countryCodeToCountry, monthValueToMonthName}; 