import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap"
import Header from "./components/Header"
import Footer from "./components/Footer"
import firebase from "./firebase"
import { setRegistrationField } from "./actions"

class AppContainer extends Component {
    state = {
        flag: "",
    }

    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const code = parts[parts.length - 1].toLowerCase()

        this.setState({ flag: `/flag-icon-css/flags/4x3/${code}.svg` })

        const category = parts[parts.length - 2]

        this.props.dispatch(setRegistrationField({ category }))
        this.props.dispatch(setRegistrationField({ country: code }))
    }

    render() {
        return (
            <div className="">
                <Header right={<img src={this.state.flag} height="30"></img>} />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={7} xs={12} className="">
                            <h1 className="text-center">First time here?</h1>
                            <h2 className="text-center">
                                <Link to="/auth/register">Please Register</Link>
                            </h2>

                            <h3 className="text-center mt-5">Already a customer?</h3>
                            <h4 className="text-center">
                                <Link to="/auth/login">Please login.</Link>
                            </h4>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
