import * as Yup from "yup"

export const RegistrationSchema = Yup.object({
    longName: Yup.string().required("Utility Long Name is required"),
    shortName: Yup.string()
        .max(11, "Utility Short Name must be less than 11 characters")
        .required("Utility Short Name is required"),
    address1: Yup.string(),
    address2: Yup.string(),
    mainPhone: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .length(7, "7 digit local number")
        .required("Main phone is required"),
    emergencyPhone1: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .length(7, "7 digit local number")
        .required("At least one Emergency phone number is required"),
    emergencyPhone2: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .length(7, "7 digit local number"),
    /*customerServicePhone: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .min(3)
        .required("Customer service phone is required"),*/

    website: Yup.string().url("Not a valid website").required("Website is required"),
    websiteShortURL: Yup.string().url("Not a valid website"),
    //vaccinationWebsite: Yup.string().url("Not a valid website").required("Vaccination website is required"),
    //vaccinationWebsiteShortURL: Yup.string().url("Not a valid website"),
    facebook: Yup.string().matches(/^((?!http:\/\/).)*$/, "URLs not accepted"),
    instagram: Yup.string().matches(/^((?!http:\/\/).)*$/, "URLs not accepted"),
    address1: Yup.string().required("Address is required"),
    //address2: Yup.string().required("Address is required"),
    email: Yup.string().email("Not a valid email").required("Email is required"),
    "g-recaptcha-response": Yup.string().required("Captcha is required"),
    //facebook: Yup.string().url("Not a vaid website").required("Facebook page URL is required"),
    password: Yup.string().required("Password is required").min(6),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
})
