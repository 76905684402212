import React, { Component } from "react"
import { connect } from "react-redux"
import "../App.css"
import { push } from "react-router-redux"
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"

import countries from "../countries.json"

import { setRegistrationField, setRegistrationCountryOfOperation } from "../actions"

class AppContainer extends Component {
    state = {
        email: "",
        password: "",
    }

    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const category = parts[parts.length - 2].toLowerCase()
        this.props.dispatch(setRegistrationField({ category }))
    }

    selectCountry(evt) {
        const code = evt.target.value
        this.props.dispatch(push(this.props.match.url + "/" + code))
        this.props.dispatch(setRegistrationField({ country: code }))
        this.props.dispatch(
            setRegistrationCountryOfOperation({ countryCode: code, value: true })
        )
    }

    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row>
                        <Col sm={3} xs={12} className="">
                            <h1>Register</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12} className="">
                            <h4 className="mb-4">Select headquarters country</h4>
                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={this.selectCountry.bind(this)}
                                >
                                    <option value="select-country">
                                        Select your country
                                    </option>
                                    {countries.map((country) => (
                                        <option
                                            value={country.code.toLowerCase()}
                                            key={country.code.toLowerCase()}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
