import { observer } from "mobx-react-lite"
import { useSelector } from "react-redux"
import { electricUploadManager } from "../../data/electric.upload-manager"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { DeliverySuccess } from "./DeliverySuccess"
import { OutageConfirmSelection } from "./OutageConfirmSelection"
import { PagerNavigation } from "./PagerNavigation"
import { SetOutageTimes } from "./SetOutageTimes"
import { TagAffectedLocations } from "./TagAffectedLocations"

const StepOne = () => {
    return (
        <>
            <TagAffectedLocations />
            <PagerNavigation />
        </>
    )
}

const StepTwo = () => {
    return (
        <>
            <SetOutageTimes />
            <PagerNavigation />
        </>
    )
}

const StepThree = () => {
    return (
        <>
            <OutageConfirmSelection />
        </>
    )
}

const StepFour = () => {
    return (
        <>
            <DeliverySuccess />
        </>
    )
}

export const EmergencyOutagesByLocation = observer(({ goBack }) => {
    const { step } = emergencyOutageStore

    const renderStep = () => {
        if (step === 0) return <StepOne />
        else if (step === 1) return <StepTwo />
        else if (step === 2) return <StepThree />
        else if (step === 3) return <StepFour />
    }

    return (
        <>
            <div className="d-flex flex-row align-items-center">
                <img src="/images/location_icon.svg" width={75}></img>
                <h4 className="pl-4">By Location</h4>
            </div>
            {renderStep()}
        </>
    )
})
