import React from "react"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import config from "../config"

const Footer = () => (
    <Row className="justify-content-center" style={{ marginTop: 100, fontSize: "0.6em" }}>
        <Col xs={12}>
            <p className="p-0 m-0">
                Yes Text Me! &copy; 2022 EIM Communications LLC. All Rights Reserved
            </p>

            <div className="py-2">
                <div>Icons made by:</div>
                <div>Pixel perfect from www.flaticon.com</div>
                <div>Freepik from www.flaticon.com</div>
            </div>
            <div>Version {config.version}</div>
            <Link to="/terms">Terms of use</Link>
        </Col>
    </Row>
)

export default Footer
