import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Input,
} from "reactstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { goBack as goBackAction } from "connected-react-router"
import config from "../config"

const links1 = [
    {
        title: "New Customer Template",
        link: "/credit-templates/New_Customers_Template.xlsx",
    },
]

const links2 = [
    {
        title: "CREDIT reminders BEFORE DUE template",
        link: "/credit-templates/CREDIT_Reminders_BEFORE_DUE_Template.xlsx",
    },
    {
        title: "CREDIT alerts OVERDUE template",
        link: "/credit-templates/CREDIT_Alerts_OVERDUE_Template.xlsx",
    },
    {
        title: "CREDIT alerts CRITICALLY OVERDUE template",
        link: "/credit-templates/CREDIT_Alerts_CRITICALLY_OVERDUE_Template.xlsx",
    },
]

const links3 = [
    {
        title: "LOAN reminders BEFORE DUE template",
        link: "/credit-templates/LOAN_Reminders_BEFORE_DUE_Template.xlsx",
    },
    {
        title: "LOAN alerts OVERDUE template",
        link: "/credit-templates/LOAN_Alerts_OVERDUE_Template.xlsx",
    },
    {
        title: "LOAN alerts CRITICALLY OVERDUE template",
        link: "/credit-templates/LOAN_Alerts_CRITICALLY_OVERDUE_Template.xlsx",
    },
]

const links4 = [
    { title: "Birthdays template", link: "/credit-templates/BIRTHDAYS_Template.xlsx" },
    { title: "Divali template", link: "/credit-templates/" },
    { title: "Eid template", link: "/credit-templates/" },
]

const allLinks = [links1, links2, links3, links4]

const LinkSection = ({ links }) => (
    <div className="mt-4">
        {links.map((item, index) => (
            <div key={index} class="mt-2 d-flex flex-row">
                <FontAwesomeIcon
                    color="gray"
                    className="mr-2"
                    icon={faFileExcel}
                ></FontAwesomeIcon>
                <Link to={item.link}>{item.title}</Link>
            </div>
        ))}
    </div>
)

const DownloadTemplates = () => {
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())

    return (
        <Container>
            <Header />
            <Row className="justify-content-center">
                <Col sm={6} xs={12} className="">
                    <h1>Download Templates</h1>
                    <strong>Do not modify the headers of these files in any way!</strong>
                    <div className="mt-1 mb-1">
                        Please right click and select "Save as" to download.
                    </div>
                    {allLinks.map((item) => (
                        <LinkSection links={item} />
                    ))}

                    <div className="text-center">
                        <Button color="link" onClick={() => goBack()}>
                            Go back to dashboard
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default DownloadTemplates
