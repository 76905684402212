import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import '../App.css';
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Alert, Input, Label, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons'
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 
import AutoStats from '../components/AutoStats'; 
import PropertyStats from '../components/PropertyStats';
import HealthStats from '../components/HealthStats'; 
import BirthdayStats from '../components/BirthdayStats';
import ChristmasStats from '../components/ChristmasStats'; 
import EidStats from '../components/EidStats';
import DivaliStats from '../components/DivaliStats'; 
import CustomMessageStats from '../components/CustomMessageStats';
import CreditFinanceDashboardSummary from '../components/CreditFinanceDashboardSummary';
import MessagingServiceUI from '../components/MessagingServiceUI'; 
import _ from 'lodash';

import { 
    fetchStats, 
    fetchCustomMessageStats,
    fetchPropertyStats, 
    fetchHealthStats, 
    fetchBirthdayStats, 
    fetchChristmasStats, 
    fetchEidStats, 
    fetchDivaliStats, 
    fetchEmailStats, 
    fetchUser, 
    logout, 
    setUserActive, 
    setLogoDisplay,
    getLogoDisplay,
    registerUser,
    fetchSettings,
    fetchUniqueCustomers,
    fetchTotalCreditFinanceUniqueCustomers,
    fetchCreditFinanceAggregateStats,
    fetchCreditFinanceAggregateEmailStats,
    setMessagingServiceId,
    fetchCounterStats} from '../actions'; 

import countries from '../countries-caribbean.json';    
import CreditFinanceCountrySummary from '../components/CreditFinanceCountrySummary';

class AppContainer extends Component { 
    state = { showCustomerTable : false }

    toggleCustomerTable = () => this.setState({showCustomerTable: !this.state.showCustomerTable})

    componentDidMount() {                
        if(this.props.user) {            
            this.props.fetchStats(this.props.user);        
            this.props.fetchSettings(this.props.user.uid);
            this.props.fetchTotalCreditFinanceUniqueCustomers(this.props.user.uid);
            this.props.fetchCreditFinanceAggregateStats(this.props.user.uid);
            this.props.fetchCreditFinanceAggregateEmailStats(this.props.user.uid);
        }

        if(this.props.admin) {
            const email = _.last(this.props.match.url.split('/')); 
            this.props.fetchUser(email).then((res) => {
                const registeredUser = Object.values(res.value.val())[0]; 

                Promise.all(
                    [this.props.fetchStats(registeredUser), 
                    this.props.fetchUniqueCustomers(registeredUser),  
                    this.props.fetchTotalCreditFinanceUniqueCustomers(registeredUser.uid),                
                    this.props.fetchCustomMessageStats(registeredUser),    
                    this.props.fetchPropertyStats(registeredUser),
                    this.props.fetchHealthStats(registerUser),
                    this.props.fetchBirthdayStats(registeredUser), 
                    this.props.fetchChristmasStats(registeredUser), 
                    this.props.fetchEidStats(registeredUser),
                    this.props.fetchDivaliStats(registeredUser),                    
                    this.props.fetchEmailStats(registeredUser, 'auto'), 
                    this.props.fetchEmailStats(registeredUser, 'property'),
                    this.props.fetchEmailStats(registeredUser, 'health'), 
                    this.props.fetchEmailStats(registeredUser, 'birthday'), 
                    this.props.fetchEmailStats(registeredUser, 'christmas'), 
                    this.props.fetchEmailStats(registeredUser, 'divali'), 
                    this.props.fetchEmailStats(registeredUser, 'eid'),
                    this.props.getLogoDisplay(registeredUser)                    
                ]).then((results) => {        
                    }).catch(error => {
                      console.log('problem ', error)
                    })
            }); 
        }

    }

    selectCountry(evt) {
        const code = evt.target.value; 
        this.props.dispatch(push(this.props.match.url + '/' + code)); 

    }

    logout(evt) {
        evt.preventDefault(); 
        this.props.logout().then(() => {            
            this.props.dispatch(push('/')); 
        }); 
    }

    toggleActivation(evt) {
        const proceed = window.confirm("Proceed with this operation?"); 
        
        if(proceed) {
            this.props.setUserActive(this.props.user, !this.props.user.active); 
        }
    }

    hasLogoData = () => {
        return this.props.logoDisplay.data !== undefined && this.props.logoDisplay.data !== null
    }

    countryCodeToCountry = (code) => countries.find(x => x.code.toLowerCase() === code ).name

    renderCountries = () => {
        return (
            this.props.uniqueCreditFinanceCustomers.customers.map (item => (                
                <tr key={item.countryCode}>
                    <td>
                        <span>{this.countryCodeToCountry(item.countryCode)}</span>
                        <img className="p-2" src={`/flag-icon-css/flags/4x3/${item.countryCode.toLowerCase()}.svg`} height={30} />                        
                    </td>
                    <td className="text-center">{item.count}</td>                        
                </tr>                                  
            ))            
        )
    }

    getCountries = () => {
        if(this.props.uniqueCreditFinanceCustomers && this.props.uniqueCreditFinanceCustomers.customers) {
            return this.props.uniqueCreditFinanceCustomers.customers;
        }
        else {
            return []
        }
    }

    render() {
        const { user, 
                admin, 
                rates, 
                autoStats, 
                autoEmailStats,
                birthdayStats, 
                birthdayEmailStats,
                customMessageEmailStats,
                customMessageStats,
                eidStats, 
                eidEmailStats, 
                divaliStats, 
                divaliEmailStats, 
                christmasStats, 
                christmasEmailStats, 
                healthStats,
                healthEmailStats,
                propertyStats,
                uniqueCustomers,
                propertyEmailStats } = this.props; 

        let right = null; 
        if((user && user.active) || (user && admin))   {
            right = (
            <div className="d-flex">
                <div className="mr-2">
                    <div className="text-truncate" style={{minWidth: 0}}>{user.email}</div>
                    <div><a href="#" onClick={this.logout.bind(this)}>Logout</a></div>
                </div>
                <div><img src={`/flag-icon-css/flags/4x3/${user.country}.svg`} height={30} /></div>
            </div>
            )
        }

        else if(!user) {
            return (
                <div>
                <Header right={right} />
                <Container>
    
                <Row className="justify-content-center">
                    <Col sm={6} xs={12} className="">
                    <h1>Dashboard</h1>

                    <Alert color="info"><Link to="/auth/login">Login</Link> required.</Alert>            
                    
                    </Col>                
                </Row>
                <div className="text-center">
                    <Footer />
                </div>                
                </Container>    
            </div>                
            )            
        }

        if((user && !user.active) && !admin) {
            return (
                <div>
                <Header right={right} />
                <Container>
    
                <Row className="justify-content-center">
                    <Col sm={6} xs={12} className="">
                    <h1>Dashboard</h1>

                    <Alert color="info">Your account activation is pending</Alert>            
                    
                    </Col>                
                </Row>
                <div className="text-center">
                    <Footer />
                </div>                
                </Container>    
            </div>                
            )
        }

        if (this.props.settings && !this.props.settings['setup-complete'] && !admin) {
          return (
            <div>
            <Header right={right} />
            <Container>

            <Row className="justify-content-center">
                <Col sm={6} xs={12} className="">
                <h1>Dashboard</h1>
                <strong>{this.props.user.companyFullName}</strong>                           

                <div>                
                    <FontAwesomeIcon icon={faUsers} size="2x"></FontAwesomeIcon>
                    {!_.isEmpty(this.props.uniqueCreditFinanceCustomers) ? <Button onClick={this.toggleCustomerTable} color="link" className="ml-2">{this.props.uniqueCreditFinanceCustomers.total} unique customers from {Object.keys(this.props.uniqueCreditFinanceCustomers.customers).length} countries</Button> : null}                                            
                </div>

                <div>
                    <div className="d-flex justify-content-end align-items-center">
                        <span>Account Settings</span>                        
                        <Link to={`/${user.category}/${user.country}/${user.email}/settings-setup`}><FontAwesomeIcon className="ml-2" icon={faCog}></FontAwesomeIcon></Link>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center">
                    <h3>Service not yet setup</h3>
                    <h5>Click Settings to get started</h5>
                </div>
                </Col>                
            </Row>
            <div className="text-center">
                <Footer />
            </div>                
            </Container>    
        </div>                   
          )
        }

        return (
        <div>
            <Header right={right} />
            <Container>

            <Row className="justify-content-center">
                <Col sm={6} xs={12} className="">
                    <h1>Dashboard</h1>
                    {user ? 
                        <div className="d-flex justify-content-between">
                            <h4>{user.companyFullName}</h4>
                            <div><Link to={`/${user.category}/${user.country}/${user.email}/settings`}>Account Settings  <FontAwesomeIcon icon={faCog} /></Link></div>
                        </div> : null }

                    <div>                
                        <FontAwesomeIcon icon={faUsers} size="2x"></FontAwesomeIcon>
                    {!_.isEmpty(this.props.uniqueCreditFinanceCustomers) ? <Button onClick={this.toggleCustomerTable} color="link" className="ml-2">{this.props.uniqueCreditFinanceCustomers.total} unique customers from {Object.keys(this.props.uniqueCreditFinanceCustomers.customers).length} countries</Button> : null}                        
                    </div>      

                    {!_.isEmpty(this.props.uniqueCreditFinanceCustomers) && this.state.showCustomerTable ? 
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <td><strong>Country</strong></td>
                                <td><strong>Unique Customers</strong></td>                        
                            </tr>  
                        </thead>
                        <tbody>
                            {this.renderCountries()}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><strong>Total</strong></td>
                                <td className="text-center"><strong>{this.props.uniqueCreditFinanceCustomers.total}</strong></td>
                            </tr>
                        </tfoot>
                    </table>                                        
                    
                    : null }                 
                        
                    <CreditFinanceDashboardSummary 
                        creditFinanceAggregateStats={this.props.creditFinanceAggregateStats} 
                        uniqueCreditFinanceCustomers={this.props.uniqueCreditFinanceCustomers}
                        />    

                    {autoStats && autoEmailStats && rates && user ? <AutoStats title="Auto Insurance" emailData={autoEmailStats} data={autoStats} rate={rates[user.country]} /> : null}             
                    {propertyStats && propertyEmailStats && rates && user ? <PropertyStats title="Property Insurance" emailData={propertyEmailStats} data={propertyStats} rate={rates[user.country]} /> : null}             
                    {healthStats && healthEmailStats && rates && user ? <HealthStats title="Health Insurance" emailData={healthEmailStats} data={healthStats} rate={rates[user.country]} /> : null}             
                    {birthdayStats && birthdayEmailStats && rates && user ? <BirthdayStats title="Birthdays" emailData={birthdayEmailStats} data={birthdayStats} rate={rates[user.country]} /> : null}             
                    {christmasStats && christmasEmailStats && rates && user ? <ChristmasStats title="Christmas Greetings" emailData={christmasEmailStats} data={christmasStats} rate={rates[user.country]} /> : null} 
                    {divaliStats && divaliEmailStats && rates && user ? <DivaliStats title="Divali Greetings" emailData={divaliEmailStats} data={divaliStats} rate={rates[user.country]} /> : null} 
                    {eidStats && eidEmailStats && rates && user ? <EidStats title="Eid-ul-Fitr Greetings" emailData={eidEmailStats} data={eidStats} rate={rates[user.country]} /> : null}                                                                                                             
                    {customMessageStats && customMessageEmailStats && rates && user ? <CustomMessageStats title="Custom Messages" data={customMessageStats} emailData={customMessageEmailStats} rate={rates[user.country]} uniqueCustomers={uniqueCustomers.count}/> : null }

                    <div className="text-center mt-5 mb-5">
                        {admin ? <Button onClick={this.toggleActivation.bind(this)} color="success">{user && user.active? "Deactivate Account" : "Activate Account"}</Button>: null}
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/download-templates`}>Download Templates</Link>                        
                        : null }                        
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/new-customers-select-country`}>Add New Customers</Link>                        
                        : null }
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/new-alerts`}>Send New Alerts</Link>                        
                        : null }                                                
                    </div>
                    
                    <h5 className="mt-4"><strong>Country Summaries</strong></h5>

                    {this.getCountries().map(country => <CreditFinanceCountrySummary
                        country={country}                         
                        rate={rates[user.country]}
                        creditFinanceAggregateStats={this.props.creditFinanceAggregateStats} 
                        creditFinanceAggregateEmailStats={this.props.creditFinanceAggregateEmailStats} 
                        uniqueCreditFinanceCustomers={this.props.uniqueCreditFinanceCustomers}
                    /> )}

                    <div className="text-center mt-5 mb-5">
                        {admin ? <Button onClick={this.toggleActivation.bind(this)} color="success">{user && user.active? "Deactivate Account" : "Activate Account"}</Button>: null}
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/download-templates`}>Download Templates</Link>                        
                        : null }                        
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/new-customers-select-country`}>Add New Customers</Link>                        
                        : null }
                        {user ? 
                        <Link className="ml-2" to={`/${user.category}/${user.country}/${user.email}/new-alerts`}>Send New Alerts</Link>                        
                        : null }                                                
                    </div>                    
                  

                    {admin ?
                        <div>
                            <Form className="form-check mt-2 mb-2">
                                <Input type="checkbox" checked={ this.hasLogoData() || false} onChange={(evt)=> {
                                    this.props.setLogoDisplay(user, evt.target.checked);
                                }} />
                                <Label>Enable logo display on landing page.</Label>                            
                            </Form>
                            <MessagingServiceUI user={this.props.user} setMessagingServiceId={this.props.setMessagingServiceId} />
                        </div>
                        
                    : null }

                </Col>                
            </Row>
            <div className="text-center">
                <Footer />
            </div>            
            </Container>    
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user, 
    autoStats: state.stats, 
    rates: state.rates, 
    admin: state.admin, 
    uniqueCustomers: state.uniqueCustomers,
    uniqueCreditFinanceCustomers: state.uniqueCreditFinanceCustomers,
    customMessageStats: state.customMessageStats,
    propertyStats: state.propertyStats, 
    birthdayStats: state.birthdayStats,
    healthStats: state.healthStats,
    christmasStats: state.christmasStats, 
    divaliStats: state.divaliStats, 
    eidStats: state.eidStats,    
    autoEmailStats: state.autoEmailStats, 
    customMessageEmailStats: state.customMessageEmailStats,
    propertyEmailStats: state.propertyEmailStats, 
    healthEmailStats: state.healthEmailStats,
    birthdayEmailStats: state.birthdayEmailStats, 
    christmasEmailStats: state.christmasEmailStats, 
    divaliEmailStats: state.divaliEmailStats, 
    eidEmailStats: state.eidEmailStats,
    logoDisplay: state.logoDisplay,
    creditFinanceAggregateStats: state.creditFinanceAggregateStats,
    creditFinanceAggregateEmailStats: state.creditFinanceAggregateEmailStats,
    settings: state.settings
})

const mapDispatchToProps = (dispatch) => ({
    fetchStats: (user) => dispatch(fetchStats(user)),
    fetchUser: (email) => dispatch(fetchUser(email)),
    setUserActive: (user, value) => dispatch(setUserActive(user, value)), 
    setLogoDisplay: (user, value) => dispatch(setLogoDisplay(user, value)),
    getLogoDisplay: (user) => dispatch(getLogoDisplay(user)),
    fetchCustomMessageStats: (user) => dispatch(fetchCustomMessageStats(user)),
    fetchPropertyStats: (user) => dispatch(fetchPropertyStats(user)),
    fetchHealthStats: (user) => dispatch(fetchHealthStats(user)),
    fetchBirthdayStats: (user) => dispatch(fetchBirthdayStats(user)),
    fetchChristmasStats: (user) => dispatch(fetchChristmasStats(user)),    
    fetchEidStats: (user) => dispatch(fetchEidStats(user)),    
    fetchDivaliStats: (user) => dispatch(fetchDivaliStats(user)),    
    fetchUniqueCustomers: (user) => dispatch(fetchUniqueCustomers(user)),
    fetchTotalCreditFinanceUniqueCustomers: (uid) => dispatch(fetchTotalCreditFinanceUniqueCustomers(uid)),
    fetchEmailStats: (user, type) => dispatch(fetchEmailStats(user, type)),
    setMessagingServiceId: (uid, value) => dispatch(setMessagingServiceId(uid, value)),
    fetchSettings: (uid) => dispatch(fetchSettings(uid)),
    fetchCreditFinanceAggregateStats: (uid) => dispatch(fetchCreditFinanceAggregateStats(uid)),
    fetchCreditFinanceAggregateEmailStats: (uid) => dispatch(fetchCreditFinanceAggregateEmailStats(uid)),
    logout: () => dispatch(logout()), 
    dispatch
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer); 

export default App;
