import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Alert, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
export const PassowrdInputField = ({
    formik,
    label,
    fieldName,
    placeholder,
    required = false,
}) => {
    const [viewPassword, setViewPassword] = useState(false)

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword)
    }

    const getPasswordInputType = () => {
        if (viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    const getEyeIcon = () => {
        if (viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }
    return (
        <FormGroup>
            <Label for="email">
                {label} {required ? "*" : ""}
            </Label>
            {formik.touched[fieldName] && formik.errors[fieldName] && (
                <Alert color="danger">{formik.errors[fieldName]}</Alert>
            )}
            <InputGroup>
                <Input
                    required
                    type={getPasswordInputType()}
                    name={fieldName}
                    placeholder={placeholder}
                    onChange={formik.handleChange(fieldName)}
                    onBlur={formik.handleBlur(fieldName)}
                />
                <InputGroupAddon
                    className="view-password"
                    addonType="append"
                    onClick={toggleViewPassword}
                >
                    <FontAwesomeIcon icon={getEyeIcon()}></FontAwesomeIcon>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    )
}
