import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Input } from "reactstrap"
import { electricStore } from "../../data/electric.store"
import { electricUploadManager } from "../../data/electric.upload-manager"

export const PlannedOutagesByLocationUpload = ({ goBack }) => {
    const user = useSelector((state) => state.user)
    const { handlePlannedOutageByLocationUploadChanged } = electricUploadManager

    return (
        <>
            <div className="py-4">
                <Link
                    style={{
                        color: "black",
                        textDecoration: "underline",
                        fontSize: "0.9em",
                    }}
                    to={`/electric/${user?.country?.isoCode}/${user?.email}/download-templates`}
                >
                    Get Planned Outages Template
                </Link>
            </div>

            <div id="add-disconnections" className="d-flex row py-2 pl-2">
                <div>
                    <img src="/images/upload_outage_bylocation.png" width={75} />
                </div>
                <div className="pl-4">
                    <div>Upload Planned Outage by Location Excel file</div>

                    <Input
                        type="file"
                        onChange={handlePlannedOutageByLocationUploadChanged(user)}
                    />
                </div>
            </div>

            <div className="d-flex flex-column pt-5">
                <div
                    onClick={goBack}
                    style={{ cursor: "pointer", color: "#007bff" }}
                    className="align-items-center"
                >
                    <img src="/images/back_icon.png" width={20} />
                    <span className="ml-2">Back</span>
                </div>
            </div>
        </>
    )
}
