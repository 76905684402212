import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { electricUploadManager } from "../../data/electric.upload-manager"
import { emergencyFeederOutageStore } from "../../data/emergency-feeder-outage.store"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { DeliverySuccess } from "./DeliverySuccess"
import { ConfirmFeederSelection } from "./FeederOutageConfirmSelection"
import { OutageConfirmSelection } from "./OutageConfirmSelection"
import { PagerNavigation } from "./PagerNavigation"
import { SetFeederOutageTimes } from "./SetFeederOutageTimes"

const StepOne = () => {
    return (
        <>
            <SetFeederOutageTimes />
            <PagerNavigation />
        </>
    )
}

const StepTwo = () => {
    return (
        <>
            <ConfirmFeederSelection />
        </>
    )
}

const StepThree = () => {
    return (
        <>
            <DeliverySuccess />
        </>
    )
}

export const EmergencyOutagesByFeeder = observer(({ goBack }) => {
    const { step, fetchFeeders } = emergencyFeederOutageStore

    useEffect(() => {
        fetchFeeders()
    }, [])

    const renderStep = () => {
        if (step === 0) return <StepOne />
        else if (step === 1) return <StepTwo />
        else if (step === 2) return <StepThree />
    }

    return (
        <>
            <div className="d-flex flex-row align-items-center">
                <img src="/images/feeder_icon.svg" width={75}></img>
                <h4 className="pl-4">By Feeder</h4>
            </div>
            {renderStep()}
        </>
    )
})
