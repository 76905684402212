import React from "react"
import Header from "./components/Header"
import { Button, Container, Row, Col, Form, FormGroup, Input, Alert } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import countries from "./caribbean-countries.json"
import { push, goBack as goBackAction } from "react-router-redux"

import { logout as logoutAction, fetchVaccines } from "./actions"
import { useFormik } from "formik"
import * as Yup from "yup"

import axios from "./lib/axios"

let MessageSchema = Yup.object({
    mobileNumber: Yup.string()
        .matches(/^\d+$/, "Please enter a valid 7-digit mobile number")
        .length(7, "Please enter a valid 7-digit mobile number")
        .required("Mobile number is required"),
    alphaSenderId: Yup.string().required("Alpha Sender Id is required"),
    messagingServiceSid: Yup.string().required("MessagingServiceSid is required"),
    messageContent: Yup.string().required("Message Content is required"),
})

export default function AdminMessaging() {
    const { user, admin } = useSelector((state) => state)
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())

    const [messagingServiceSid, setMessagingServiceSid] = React.useState()
    const [senderId, setSenderId] = React.useState()

    const [messageContent, setMessageContent] = React.useState()
    const [mobileNumer, setMobileNumber] = React.useState()

    const { addToast } = useToasts()

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        {/* 
                        <img
                            src={`/flag-icon-css/flags/4x3/${
                                user.countryCode || user.country
                            }.svg`}
                            height={30}
                        />*/}
                    </div>
                </div>
            </div>
        )
    }

    const clearForm = () => {
        formik.setFieldValue("country", "")
        formik.setFieldTouched("country", false)
        formik.setFieldValue("messagingServiceSid", "")
        formik.setFieldTouched("messagingServiceSid", false)
        formik.setFieldValue("alphaSenderId", "")
        formik.setFieldTouched("alphaSenderId", false)
        formik.setFieldValue("messageContent", "")
        formik.setFieldTouched("messageContent", false)
        formik.setFieldValue("mobileNumber", "")
        formik.setFieldTouched("mobileNumber", false)
    }

    const handleSubmit = (values) => {
        let country = countries.find(
            (item) => item.code.toLocaleLowerCase() === values.country
        )

        let payload = { ...values }
        payload.mobileNumber = country.dial_code + values.mobileNumber

        axios
            .post("/api/admin-send-message", payload)
            .then((res) => {
                clearForm()
                if (res.data.success) {
                    addToast("Message sent successfully", {
                        autoDismiss: true,
                        appearance: "success",
                    })
                } else {
                    addToast("Error sending message", {
                        autoDismiss: true,
                        appearance: "error",
                    })
                }
            })
            .catch((err) => {
                console.log("err ", err)
                clearForm()
                addToast("Error sending message", {
                    autoDismiss: true,
                    appearance: "error",
                })
            })
    }

    const formik = useFormik({
        validationSchema: MessageSchema,
        initialValues: {},
        validateOnMount: true,
        onSubmit: handleSubmit,
    })

    global.formik = formik

    return (
        <>
            <Header right={right}></Header>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} xs={12} className="p-4">
                        <h4>Admin Messaging</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6} xs={12} className="">
                        <Form onSubmit={formik.handleSubmit}>
                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={formik.handleChange("country")}
                                    value={formik.values.country}
                                    onBlur={formik.handleBlur("country")}
                                >
                                    <option value="select-country">
                                        Select your country
                                    </option>
                                    {countries.map((country) => (
                                        <option
                                            value={country.code.toLowerCase()}
                                            key={country.code.toLowerCase()}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                {formik.touched.mobileNumber &&
                                    formik.errors.mobileNumber && (
                                        <Alert color="danger">
                                            {formik.errors.mobileNumber}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.mobileNumber}
                                    onChange={formik.handleChange("mobileNumber")}
                                    onBlur={formik.handleBlur("mobileNumber")}
                                    type="number"
                                    placeholder="7 digit mobile number"
                                ></Input>
                            </FormGroup>

                            <FormGroup>
                                {formik.touched.messagingServiceSid &&
                                    formik.errors.messagingServiceSid && (
                                        <Alert color="danger">
                                            {formik.errors.messagingServiceSid}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.messagingServiceSid}
                                    onChange={formik.handleChange("messagingServiceSid")}
                                    onBlur={formik.handleBlur("messagingServiceSid")}
                                    type="text"
                                    placeholder="Messaging Service Sid"
                                ></Input>
                            </FormGroup>

                            <FormGroup>
                                {formik.touched.alphaSenderId &&
                                    formik.errors.alphaSenderId && (
                                        <Alert color="danger">
                                            {formik.errors.alphaSenderId}
                                        </Alert>
                                    )}
                                <Input
                                    type="text"
                                    placeholder="Alpha Sender Id"
                                    onBlur={formik.handleBlur("alphaSenderId")}
                                    onChange={formik.handleChange("alphaSenderId")}
                                    value={formik.values.alphaSenderId}
                                ></Input>
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    value={formik.values.messageContent}
                                    onChange={formik.handleChange("messageContent")}
                                    onBlur={formik.handleBlur("messageContent")}
                                    type="textarea"
                                    placeholder="Message Content"
                                ></Input>
                            </FormGroup>

                            <FormGroup>
                                <Button color="success" disabled={!formik.isValid}>
                                    Send
                                </Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={6} xs={12}>
                        <Button onClick={goBack} color="link">
                            Back to Admin
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
