import _ from "lodash"
import { makeAutoObservable, toJS } from "mobx"
import axios from "../../../lib/axios"
import { electricStore } from "./electric.store"

let instance

class EmergencyFeederOutageStore {
    feeders = []
    step = 0
    restorationTimes = {}

    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!")
        }
        instance = this
        makeAutoObservable(this)
    }

    addToast = (text, options) => {}
    setAddToast = (func) => {
        this.addToast = func
    }

    resetFields = () => {
        this.step = 0
        this.restorationTimes = {}
    }

    setStep = (step) => {
        this.step = step
    }

    nextStep = () => {
        this.step = this.step + 1
    }

    previousStep = () => {
        this.step = this.step > 0 ? this.step - 1 : 0
    }

    fetchFeeders = async () => {
        const uid = electricStore.user.uid
        let result = await axios.get(`/api/electric/feeders?uid=${uid}`)

        console.log("fetch feeders result ", result)

        let feederSet = new Set(result.data.map((item) => item.outageFeeder))
        this.setFeeders(Array.from(feederSet))
    }

    setFeeders = (feeders) => {
        this.feeders = feeders
    }

    setRestorationTime = (feeder, time) => {
        let oldvalue = this.restorationTimes[feeder]
        this.restorationTimes[feeder] = {
            ...this.restorationTimes[feeder],
            time,
            restorationTimeTomorrow:
                time === "Unknown" ? false : oldvalue?.restorationTimeTomorrow,
        }
    }

    minDate = (feeder) => {
        if (this.restorationTimes[feeder]?.restorationTimeTomorrow) {
            return null
        } else {
            return new Date()
        }
    }

    setRestorationTimeTomorrow = (feeder, value) => {
        let oldvalue = this.restorationTimes[feeder]
        this.restorationTimes[feeder] = {
            ...this.restorationTimes[feeder],
            restorationTimeTomorrow: value,
        }

        if (this.restorationTimes[feeder].time === "Unknown") {
            this.restorationTimes[feeder].time = value ? "" : oldvalue?.time
        } else {
            this.restorationTimes[feeder].time = value ? oldvalue?.time : ""
        }
    }

    handleFeederSelected = (feeder) => {
        return (evt) => {
            if (evt.target.checked) {
                this.setRestorationTime(feeder, "")
            } else {
                delete this.restorationTimes[feeder]
            }
        }
    }

    handleRestorationTimeChanged = (feeder) => {
        return (value) => {
            console.log("setting restoration time ", value)
            this.setRestorationTime(feeder, value)
        }
    }

    handleRestorationTimeUnknownChanged = (feeder) => {
        return (evt) => {
            if (evt.target.checked) {
                this.setRestorationTime(feeder, "Unknown")
            } else {
                this.setRestorationTime(feeder, "")
            }
        }
    }

    handleRestorationTimeTomorrowChanged = (feeder) => {
        return (evt) => {
            this.setRestorationTimeTomorrow(feeder, evt.target.checked)
        }
    }

    getTimeValueForFeeder = (feeder) => {
        if (
            this.restorationTimes[feeder]?.time === "Unknown" ||
            !this.restorationTimes[feeder]?.time
        ) {
            return ""
        } else {
            return this.restorationTimes[feeder]?.time
        }
    }

    isRestorationTimeUnknown = (feeder) => {
        return this.restorationTimes[feeder]?.time === "Unknown"
    }

    isRestorationTimeTomorrow = (feeder) => {
        return this.restorationTimes[feeder]?.restorationTimeTomorrow
    }

    isFeederSelected = (feeder) => {
        return feeder in this.restorationTimes
    }

    get selectedFeeders() {
        const feeders = Object.keys(this.restorationTimes)
        const values = Object.values(this.restorationTimes)

        return values.map((value, index) => ({ ...value, feeder: feeders[index] }))
    }

    get restorationTimesValid() {
        let values = []

        for (let key in this.restorationTimes) {
            if (
                this.restorationTimes[key].time &&
                this.restorationTimes[key].time !== ""
            ) {
                values.push(true)
            } else {
                values.push(false)
            }
        }

        return values.length > 0 && values.find((item) => item === false) === undefined
    }

    sendMultiAlerts = async () => {
        console.log("alerts ", toJS(this.restorationTimes))
        const uid = electricStore.user.uid
        try {
            let result = await axios.post(`/api/electric/send-feeder-emergency-alerts`, {
                accountId: uid,
                restorationTimes: this.restorationTimes,
            })

            if (result.success) {
                this.nextStep()
            } else {
                this.addToast(result.errorMessage, {
                    autoDismiss: true,
                    appearance: "error",
                })
            }
        } catch (err) {
            this.addToast(
                "Problem sending alerts: " + err?.response?.data?.errorMessage,
                {
                    autoDismiss: true,
                    appearance: "error",
                }
            )
        }
    }
}

export const emergencyFeederOutageStore = new EmergencyFeederOutageStore()
