import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    FormGroup,
    Spinner,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt, faRedo } from "@fortawesome/free-solid-svg-icons"

let emailToDelete = ""
export default function AddMemberForm({
    user,
    inviteMember,
    deleteMember,
    acceptedInvites,
    pendingInvites,
}) {
    const [confirmDelete, setConfirmDelete] = React.useState(false)
    const [inviteEmail, setInviteEmail] = React.useState("")
    const [loadingPending, setLoadingPending] = React.useState(false)
    const [loadingAccepted, setLoadingAccepted] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState()

    const handleInvite = (email) => {
        setLoadingPending(true)
        inviteMember(email, user.uid)
            .then((res) => {
                console.log("invite res", res)
                setInviteEmail("")
                setErrorMessage(null)
                setLoadingPending(false)
            })
            .catch((err) => {
                setErrorMessage(err.response.data.errorMessage)
                setLoadingPending(false)
                console.log("invite res ", err.response)
            })
    }

    const showConfirmationModal = (email) => {
        emailToDelete = email
        setConfirmDelete(true)
    }

    const handleDelete = (email) => {
        setConfirmDelete(false)
        setLoadingAccepted(true)
        deleteMember(email, user.uid).then((res) => {
            emailToDelete = null
            setLoadingAccepted(false)
        })
    }

    return (
        <Container>
            <Modal isOpen={confirmDelete} toggle={() => setConfirmDelete(!confirmDelete)}>
                <ModalHeader toggle={() => setConfirmDelete(!confirmDelete)}>
                    Remove User
                </ModalHeader>
                <div className="m-2" style={{ wordWrap: "break-word" }}>
                    Do you really want to remove {emailToDelete}?
                </div>
                <ModalBody></ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setConfirmDelete(false)}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(emailToDelete)}>
                        Yes
                    </Button>{" "}
                </ModalFooter>
            </Modal>
            <Row>
                <Col md={10} xs={12}>
                    <h3>{user.organizationLongName}</h3>
                </Col>

                <Col md={10} xs={12} className="pt-4">
                    {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
                    <h4 className="font-weight-bold">Add Users</h4>
                    <Form inline>
                        <FormGroup>
                            <Input
                                style={{ minWidth: "250px" }}
                                placeholder="Email"
                                value={inviteEmail}
                                onChange={(evt) => setInviteEmail(evt.target.value)}
                            ></Input>
                        </FormGroup>
                        <Button
                            onClick={() => handleInvite(inviteEmail)}
                            color="success"
                            className="ml-2"
                            disabled={inviteEmail.length === 0}
                        >
                            Invite User
                        </Button>
                    </Form>
                </Col>

                <Col md={10} xs={12} className="pt-4">
                    <h4 className="font-weight-bold">
                        {loadingAccepted ? <Spinner color="secondary" /> : null}
                        User List ({acceptedInvites.length}{" "}
                        <span className={loadingAccepted ? "ml-2" : "ml-0"}>
                            {acceptedInvites.length === 1 ? "User" : "Users"})
                        </span>
                    </h4>
                    <div>
                        {acceptedInvites.map((invite, index) => (
                            <div
                                key={index}
                                className="d-flex flex-row align-items-center"
                            >
                                <div style={{ width: "200px", wordWrap: "break-word" }}>
                                    {invite.email}
                                </div>
                                <div className="ml-2">
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                                <Button
                                    onClick={() => showConfirmationModal(invite.email)}
                                    color="link"
                                >
                                    Remove User
                                </Button>
                            </div>
                        ))}
                    </div>
                </Col>

                <Col md={10} xs={12} className="pt-4">
                    <h4 className="font-weight-bold">
                        {loadingPending ? <Spinner color="secondary" /> : null}
                        <span className={loadingPending ? "ml-2" : "ml-0"}>
                            Users Pending
                        </span>
                    </h4>
                    {pendingInvites.map((invite, index) => (
                        <div key={index} className="d-flex flex-row align-items-center">
                            <div style={{ width: "200px", wordWrap: "break-word" }}>
                                {invite.email}
                            </div>
                            <div className="ml-2">
                                <FontAwesomeIcon icon={faRedo} />
                            </div>
                            <Button
                                color="link"
                                onClick={() => handleInvite(invite.email)}
                            >
                                Resend Invite
                            </Button>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    )
}
