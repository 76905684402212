import React, {Component} from 'react'
import {useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import {logout as logoutAction} from '../actions'
import config from '../config'

const Logout = ({user, onLogout}) => {

  const dispatch = useDispatch() 

  const doLogout = (evt) => {
    evt.preventDefault() 
    dispatch(logoutAction()).then(() => {            
        dispatch(push('/')); 
    }); 
  }

  return (<div className="d-flex">
      <div className="mr-2">
          <div className="text-truncate" style={{minWidth: 0}}>{user ? user.email : ''} </div>
          <div><a href="#" onClick={doLogout}>Logout</a></div>
          {/*<img className="logo-content" src={`${config.serverURL}/files/${user.logo}`} /> */}
      </div>      
  </div>)
}

export default Logout