import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { Button, Table } from "reactstrap"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { format } from "date-fns"
function formatTime(time) {
    if (time === "Unknown") {
        return time
    }

    const re = /(?<hour>\d+):(?<minute>\d+)$/

    const match = time.match(re)
    const is24hourFormat = match !== null

    if (is24hourFormat) {
        let hour = parseInt(match.groups.hour)
        let minute = parseInt(match.groups.minute)
        let minuteString = `${minute}`

        if (minute.toString().length === 1) {
            minuteString = `0${minute}`
        }

        let period = hour >= 12 ? "PM" : "AM"
        let formattedHour = hour === 12 ? hour : hour % 12

        return `${formattedHour}:${minuteString} ${period}`
    } else {
        return time
    }
}

const ConfirmSingleTimeSelection = observer(() => {
    const {
        previousStep,
        restorationTime,
        selectedLocations,
        sendAlerts,
        restorationTimeUnknown,
        restorationTimeTomorrow,
    } = emergencyOutageStore

    const getRestorationTime = () => {
        if (restorationTimeUnknown) {
            return "unknown"
        } else {
            return `${format(restorationTime.time, "hh:mm a")} on ${format(
                restorationTime.time,
                "EE dd MMM"
            )}`
            return `${formatTime(restorationTime.time)}${
                restorationTimeTomorrow ? " tomorrow" : ""
            }`
        }
    }

    return (
        <div className="pt-4">
            <h4>Confirm your selection:</h4>
            <div>
                <div className="py-2">
                    You have set a restoration time of {getRestorationTime()} for the
                    following locations:
                </div>
                <ul>
                    {selectedLocations.map((item) => (
                        <li key={item.id}>{item.outageLocation}</li>
                    ))}
                </ul>
            </div>

            <div className="py-2">Is this correct?</div>

            <div className="d-flex justify-content-center">
                <Button
                    color="primary"
                    onClick={sendAlerts}
                    style={{ backgroundColor: "#ff9900", border: "none" }}
                >
                    Yes, alert all affected customers
                </Button>
            </div>

            <div className="d-flex justify-content-center">
                <Button color="link" onClick={previousStep}>
                    Go back and make corrections
                </Button>
            </div>
        </div>
    )
})

const ConfirmMultiTimeSelection = observer(() => {
    const {
        previousStep,
        restorationTime,
        multiSelectedLocations,
        restorationTimes,
        sendMultiAlerts,
    } = emergencyOutageStore

    console.log("multiSelectedLocations ", toJS(multiSelectedLocations))

    return (
        <div className="pt-4">
            <h4>Confirm your selection:</h4>
            <div>
                <div className="py-2">
                    You have set the following locations and restoration times:
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Time</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {multiSelectedLocations.map((item) => (
                            <tr key={item.outageLocation.id}>
                                <td>{item.outageLocation.outageLocation}</td>
                                <td>
                                    {item.time === "Unknown"
                                        ? "unknown"
                                        : format(item.time, "hh:mm a")}{" "}
                                </td>
                                <td>
                                    {item.time === "Unknown"
                                        ? "unknown"
                                        : format(item.time, "EE dd MMM")}{" "}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <div className="py-2">Is this correct?</div>

            <div className="d-flex justify-content-center">
                <Button
                    color="primary"
                    onClick={sendMultiAlerts}
                    style={{ backgroundColor: "#ff9900", border: "none" }}
                >
                    Yes, alert all affected customers
                </Button>
            </div>

            <div className="d-flex justify-content-center">
                <Button color="link" onClick={previousStep}>
                    Go back and make corrections
                </Button>
            </div>
        </div>
    )
})

export const OutageConfirmSelection = observer(() => {
    const { sameTimes, differentTimes, restorationTimeUnknown } = emergencyOutageStore

    if (sameTimes || restorationTimeUnknown) {
        return <ConfirmSingleTimeSelection />
    }

    if (differentTimes) {
        return <ConfirmMultiTimeSelection />
    }

    return null
})
