import config from "../config"
import { electricStore } from "../features/electric/data/electric.store"
const axios = require("axios")

const instance = axios.create({
    baseURL: config.serverURL,
})

instance.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${electricStore.token}`
    return config
})

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

export const getAxiosClient = () =>
    axios.create({
        baseURL: config.serverURL,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })

export default instance
