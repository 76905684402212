import axios from "../../../lib/httpclient"

class VaccinationRepository {
    addVaccination(vaccination) {
        return axios.post("/api/vaccination/vaccinations", vaccination)
    }

    addSecondVaccination(vaccination) {
        return axios.post("/api/vaccination/vaccinations/second", vaccination)
    }

    fetchVaccinationStats(accountId) {
        return axios.get(`/api/vaccination/stats/${accountId}`)
    }

    fetchPatientVaccinations(mobileNumber) {
        return axios.get(`/api/vaccination/patients/${mobileNumber}`)
    }

    fetchVaccinationByMobileNumber(mobileNumber, accountId, vaccinationIndex) {
        return axios.get(
            `/api/vaccination/${accountId}/${mobileNumber}?vaccinationIndex=${vaccinationIndex}`
        )
    }

    fetchNoShows(accountId) {
        return axios.get(`/api/vaccination/${accountId}/no-shows`)
    }

    uploadVaccinationCustomers(payload) {
        return axios.post("/api/vaccination/upload-customers", payload)
    }

    processReminders() {
        return axios.get("/api/vaccination/process-reminders")
    }
}

export default new VaccinationRepository()
