import React from "react"
import numeral from "numeral"

function getFormatString(value) {
    if (value > 1000 && value < 10000) {
        return "1000,0"
    } else if (value > 10000 && value < 100000) {
        return "10000,0"
    } else if (value > 100000 && value < 1000000) {
        return "100000,0"
    } else if (value > 1000000 && value < 10000000) {
        return "1000000,0"
    }
}

const SMSCounter = ({ count }) => (
    <div
        className="d-flex flex-row justify-content-start justify-content-lg-start mt-2"
        style={{ width: 300 }}
    >
        <div>
            <img src="/images/counter.svg" height="45" />
        </div>
        <div className="pl-2" style={{ textAlign: "left" }}>
            <h6 className="m-0">
                {numeral(count).format(getFormatString(count))} messages sent
            </h6>
            <p style={{ fontSize: "0.7em" }}>since Sept 2017</p>
        </div>
    </div>
)

export default SMSCounter
