import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Input, Button } from 'reactstrap';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Logout from '../../components/Logout';
import config from '../../config';

const StepOne = (props) => {
  
  const [logoImage, setLogoImage] = useState("");
  const [imageUploadStatus, setImageUploadStatus] = useState(null)

  const resolveLogoImage = () => {
    let { user } = props
    return logoImage || `${config.serverURL}/files/${user.logo}`
  }

  const nextStep = () => {
    props.setSetupState(props.user.uid, "2").then(res => {      
      props.fetchSettings(props.user.uid); 
    });
  }

  const handleFileChange = (event) => {
    if(event.target.files[0].type.indexOf("image") !== 0) {
      //this.setState({"imageUploadStatus": "invalid"})
      setImageUploadStatus("invalid")
      return
  }

  let image = URL.createObjectURL(event.target.files[0])
  //this.setState({logoImage: this.logoImage})
  setLogoImage(image)

  let data = new FormData()
  data.append("user", JSON.stringify(props.user))
  data.append("file", event.target.files[0])
  props.uploadImage(props.user, data).then((res) => {            
      //this.setState({imageUploadStatus: res.value.data.success ? "uploaded" : "error"})      
      setImageUploadStatus(res.value.data.success ? "uploaded" : "error")
      props.fetchUser(props.user.email)
  })
}  

  return (
  <Container>  
  <Row className="justify-content-center">
      <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        
        <h2>Upload your company logo</h2>

        <div>
          <Input type="file" onChange={handleFileChange} />{' '} Upload an image that's not larger than 1 MB
        </div>

        <div>
        {logoImage || props.user.logo ? <img src={resolveLogoImage()} style={{width: 100}} /> : null }
        </div>

        <div className="pt-4 pb-4">
          <Button 
            color="success" 
            disabled={imageUploadStatus !== 'uploaded'}
            onClick={nextStep}>Next</Button>
          <Button className="ml-4" onClick={() => {            
            props.goBack()
          }}>Cancel</Button>
        </div>
      </Col>                
  </Row>
  <div className="text-center">
      <Footer />
  </div>                
</Container> 
);
}

export default StepOne; 