import { makeAutoObservable } from "mobx"
import axios from "../../../lib/axios"

let instance

class ElectricStore {
    customerCount = 0
    locationCount = 0
    disconnectionCount = 0
    disconnectionCountDetail = {}
    disconnectionEmailCountDetail = {}
    plannedOutagesCount = 0
    plannedOutagesCountDetail = {}
    plannedOutagesEmailCountDetail = {}
    emergencyOutagesCount = 0
    emergencyOutagesCountDetail = {}
    emergencyOutagesEmailCountDetail = {}
    introMessagesCount = 0
    introMessagesCountDetail = {}
    introEmailsCountDetail = {}
    user = {}
    downloadableMonths = []
    token = ""

    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!")
        }
        instance = this
        makeAutoObservable(this)
    }

    fetchUniqueCustomerCount = async (uid) => {
        console.log("fetching customer count")
        let result = await axios.get(`/api/electric/companies/count?uid=${uid}`)
        console.log("unique electric customer count ", result)
        this.setCustomerCount(result.data.count)
    }

    fetchLocationCount = async (uid) => {
        console.log("fetching location count")
        let result = await axios.get(`/api/electric/location-count?uid=${uid}`)
        console.log("location count ", result)
        this.setLocationCount(result.data.count)
    }

    fetchDownloadableMonths = async (uid) => {
        console.log("fetching location count")
        let result = await axios.get(`/api/electric/downloadable-months?uid=${uid}`)
        console.log("downloadable months ", result)
        this.setDownloadableMonths(result.data)
    }

    fetchDisconnectionCount = async (uid) => {
        console.log("fetching disconnection count")
        let result = await axios.get(`/api/electric/disconnection-count?uid=${uid}`)
        console.log("disconnection count ", result)
        this.setDisconnectionCount(result.data.count)
        this.setDisconnectionCountDetail(result.data)
    }

    fetchDisconnectionEmailCount = async (uid) => {
        console.log("fetching disconnection email count")
        let result = await axios.get(`/api/electric/disconnection-email-count?uid=${uid}`)
        console.log("disconnection email count ", result)
        this.setDisconnectionEmailCountDetail(result.data)
    }

    fetchPlannedOutagesCount = async (uid) => {
        console.log("fetching outages count")
        let result = await axios.get(`/api/electric/planned-outages-count?uid=${uid}`)
        console.log("outages count ", result)
        this.setPlannedOutagesCount(result.data.count)
        this.setPlannedOutagesCountDetail(result.data)
    }

    fetchPlannedOutagesEmailCount = async (uid) => {
        console.log("fetching outages email count")
        let result = await axios.get(
            `/api/electric/planned-outages-email-count?uid=${uid}`
        )
        console.log("outages email count ", result)
        this.setPlannedOutagesEmailCountDetail(result.data)
    }

    fetchEmergencyOutagesCount = async (uid) => {
        console.log("fetching outages count")
        let result = await axios.get(`/api/electric/emergency-outages-count?uid=${uid}`)
        console.log("emergency count ", result)
        this.setEmergencyOutagesCount(result.data.count)
        this.setEmergencyOutagesCountDetail(result.data)
    }

    fetchEmergencyOutagesEmailCount = async (uid) => {
        console.log("fetching outages email count")
        let result = await axios.get(
            `/api/electric/emergency-outages-email-count?uid=${uid}`
        )
        console.log("emergency email count ", result)
        this.setEmergencyOutagesEmailCountDetail(result.data)
    }

    fetchIntroMessagesCount = async (uid) => {
        let result = await axios.get(`/api/electric/intro-messages-count?uid=${uid}`)
        console.log("intro messages count ", result)
        this.setIntroMessagesCount(result.data.count)
        this.setIntroMessagesCountDetail(result.data)
    }

    fetchIntroEmailsCount = async (uid) => {
        let result = await axios.get(`/api/electric/intro-emails-count?uid=${uid}`)
        console.log("intro emails count ", result)
        this.setIntroEmailsCountDetail(result.data)
    }

    uploadLocations = async ({ accountId, xlsx }) => {
        return await axios.post(`/api/electric/location-upload`, {
            accountId,
            xlsx,
        })
    }

    uploadCustomers = async ({ accountId, xlsx }) => {
        return await axios.post(`/api/electric/customer-upload`, {
            accountId,
            xlsx,
        })
    }

    uploadDisconnections = async ({ accountId, xlsx }) => {
        return await axios.post(`/api/electric/disconnections-upload`, {
            accountId,
            xlsx,
        })
    }

    uploadPlannedOutagesByLocation = async ({ accountId, xlsx }) => {
        return await axios.post(`/api/electric/planned-outages-location-upload`, {
            accountId,
            xlsx,
        })
    }

    uploadPlannedOutagesByFeeder = async ({ accountId, xlsx }) => {
        return await axios.post(`/api/electric/planned-outages-feeder-upload`, {
            accountId,
            xlsx,
        })
    }

    setDownloadableMonths = (data) => {
        this.downloadableMonths = data
    }

    setDisconnectionCountDetail = (info) => {
        this.disconnectionCountDetail = info
    }

    setDisconnectionEmailCountDetail = (info) => {
        this.disconnectionEmailCountDetail = info
    }

    setEmergencyOutagesCountDetail = (info) => {
        this.emergencyOutagesCountDetail = info
    }

    setEmergencyOutagesEmailCountDetail = (info) => {
        this.emergencyOutagesEmailCountDetail = info
    }

    setIntroMessagesCountDetail = (info) => {
        this.introMessagesCountDetail = info
    }

    setIntroEmailsCountDetail = (info) => {
        this.introEmailsCountDetail = info
    }

    setPlannedOutagesCountDetail = (info) => {
        this.plannedOutagesCountDetail = info
    }

    setPlannedOutagesEmailCountDetail = (info) => {
        this.plannedOutagesEmailCountDetail = info
    }

    setCustomerCount = (count) => {
        this.customerCount = count
    }

    setLocationCount = (count) => {
        this.locationCount = count
    }

    setIntroMessagesCount = (count) => {
        this.introMessagesCount = count
    }

    setDisconnectionCount = (count) => {
        this.disconnectionCount = count
    }

    setPlannedOutagesCount = (count) => {
        this.plannedOutagesCount = count
    }

    setEmergencyOutagesCount = (count) => {
        this.emergencyOutagesCount = count
    }

    setToken(token) {
        this.token = token
    }

    setUser(user) {
        console.log("this ", this)
        this.user = user
    }
}

export const electricStore = new ElectricStore()
