import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import { ListGroup, ListGroupItem } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { goBack as goBackAction } from "connected-react-router"
import Header from "../../../components/Header"
import _ from "lodash"
import { subMonths, format } from "date-fns"
import config from "../../../config"
import { electricStore } from "../data/electric.store"
import { observer } from "mobx-react-lite"

const DownloadData = observer(() => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const goBack = () => dispatch(goBackAction())
    const months = _.range(13).map((item) => subMonths(new Date(), item))
    const formattedMonths = months.map((item) => format(item, "MMMM, yyyy"))
    const { fetchDownloadableMonths, downloadableMonths } = electricStore

    useEffect(() => {
        fetchDownloadableMonths(user.uid)
    }, [])

    return (
        <Container>
            <Header />
            <Row className="justify-content-center">
                <Col sm={6} xs={12} className="">
                    <h2>Download Data</h2>
                    <strong>
                        Click on the month to download the corresponding Excel file
                    </strong>

                    <ListGroup>
                        {_.take(downloadableMonths, 13).map((item, index) => (
                            <ListGroupItem key={index}>
                                <a
                                    href={`${
                                        config.serverURL
                                    }/api/electric/download-data?uid=${
                                        user.uid
                                    }&date=${new Date(item).getTime()}`}
                                >
                                    {item}
                                </a>
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                    <div className="text-center pt-5">
                        <Button color="link" onClick={() => goBack()}>
                            Go back to dashboard
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
})

export default DownloadData
