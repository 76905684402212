import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useToasts } from "react-toast-notifications"

let VaccinationSchema = Yup.object({
    mobileNumber: Yup.string()
        .required()
        .matches(/^\d+$/, "Must be a valid phone number")
        .length(7, "Your 7 digit mobile number"),
    email: Yup.string().email("Must be a valid email"),
})

export default function SecondVaccinationForm({
    user,
    vaccines,
    goBack,
    onSubmit,
    vaccinationRepository,
}) {
    const [errorMessage, setErrorMessage] = React.useState()
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [loadingResult, setLoadingResult] = React.useState(false)

    const { addToast } = useToasts()

    const formik = useFormik({
        validationSchema: VaccinationSchema,
        initialValues: {
            mobileNumber: "",
        },
        onSubmit: (values) => {
            console.log("submitted values ", values)
            setLoadingResult(true)
            onSubmit(values.mobileNumber)
        },
    })

    global.formik = formik

    const submitAndClose = () => {
        let values = formik.values
        delete values.noDateForSecondShot
        delete values.confirmMobileNumber
        addVaccination(values)
            .then(() => {
                goBack()
            })
            .catch((e) => {})
    }

    console.log("form errors ", formik.errors)
    const setCurrentDate = () => {
        formik.setFieldValue("vaccinationDate", new Date())
    }

    const isFormValid = () => {
        return formik.isValid
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} xs={12}>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <img src="/images/jab2.svg" style={{ height: 50, width: 50 }} />
                        <h3 className="ml-2 mr-2">Add Second Vaccination</h3>
                        {submitSuccess ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                size={"3x"}
                                color={"green"}
                            />
                        ) : null}
                    </div>

                    <Form onSubmit={formik.handleSubmit}>
                        {(errorMessage && (
                            <Alert color="danger" className="mt-2 mb-2">
                                {errorMessage}
                            </Alert>
                        )) ||
                            null}

                        <FormGroup>
                            <Input
                                type="number"
                                value={formik.values.mobileNumber}
                                placeholder={"7 digit mobile number"}
                                onChange={formik.handleChange("mobileNumber")}
                                onBlur={formik.handleBlur("mobileNumber")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex flex-row align-items-center">
                                    {loadingResult ? (
                                        <Spinner color="success" className="mr-2" />
                                    ) : null}
                                    <Button
                                        disabled={!isFormValid()}
                                        color="success"
                                        type="submit"
                                    >
                                        Next
                                    </Button>
                                </div>

                                <Button color="link" onClick={goBack}>
                                    Cancel
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
