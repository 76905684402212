import React, { Component } from "react"
import { connect } from "react-redux"
import "../App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"

import countries from "../countries-caribbean.json"

import { setRegistrationField, setRegistrationCountryOfOperation } from "../actions"

const CountryItem = ({ dispatch, country, checked }) => (
    <FormGroup key={country.code} className="mt-2 mb-2" check>
        <Label check style={{ fontSize: "1.2em" }}>
            <Input
                checked={checked}
                onChange={(event) => {
                    dispatch(
                        setRegistrationCountryOfOperation({
                            countryCode: country.code.toLowerCase(),
                            value: event.target.checked,
                        })
                    )
                }}
                type="checkbox"
            />{" "}
            {country.name}
        </Label>
    </FormGroup>
)

class AppContainer extends Component {
    state = {
        email: "",
        password: "",
    }

    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const category = parts[parts.length - 3].toLowerCase()
        const country = parts[parts.length - 1].toLowerCase()
        this.props.dispatch(setRegistrationField({ category }))
        this.props.dispatch(setRegistrationField({ country }))
    }

    selectCountry(evt) {
        const code = evt.target.value
        this.props.dispatch(push(this.props.match.url + "/" + code))
        this.props.dispatch(setRegistrationField({ country: code }))
    }

    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row>
                        <Col sm={3} xs={12} className="">
                            <h1>Register</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12} className="">
                            <h4 className="mb-4">
                                Select countries where you do business
                            </h4>
                            {countries.map((country) => (
                                <CountryItem
                                    key={country.code}
                                    checked={
                                        this.props.registration.countriesOfOperation[
                                            country.code.toLowerCase()
                                        ]
                                            ? true
                                            : false
                                    }
                                    dispatch={this.props.dispatch}
                                    country={country}
                                />
                            ))}
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-4">
                        <Link
                            className="btn btn-success"
                            to={this.props.match.url + "/confirmation"}
                        >
                            Continue
                        </Link>
                    </Row>

                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
    registration: state.registration,
}))(AppContainer)

export default App
