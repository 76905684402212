import React from "react"
import { format } from "date-fns"
import Config from "../../../config"

export default function StatsBox({ data, user, token }) {
    let today = new Date()

    return (
        <div>
            <div
                className="border border-bottom-0 p-2"
                style={{
                    fontWeight: "bolder",
                    borderBottomColor: "white",
                    position: "relative",
                    backgroundColor: "lightgray",
                    maxWidth: "200px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    left: "30px",
                }}
            >
                {data.name}
            </div>
            <div
                className="border p-4"
                style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    marginBottom: "16px",
                }}
            >
                <div className="px-4 pb-4">
                    <div className="d-flex flex-row align-items-center">
                        <img src="/images/jab1.svg" width="50" />
                        <div className="d-flex flex-column ml-4">
                            <div className="mb-2">
                                {data.vax1CountMonth} first doses recorded in{" "}
                                {format(today, "MMM")}
                            </div>
                            <div className="mb-2">
                                {data.vax1CountYear} first doses recorded in{" "}
                                {format(today, "yyyy")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-4 pb-4">
                    <div className="d-flex flex-row align-items-center">
                        <img src="/images/phone_reminder.svg" width="50" />
                        <div className="d-flex flex-column ml-4">
                            <div className="mb-2">
                                {data.notificationCountMonth} second dose messages sent in{" "}
                                {format(today, "MMM")}
                            </div>
                            <div className="mb-2">
                                {data.notificationCountYear} second dose messages sent in{" "}
                                {format(today, "yyyy")}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-4 pb-4">
                    <div className="d-flex flex-row align-items-center">
                        <img src="/images/jab2.svg" width="50" />
                        <div className="d-flex flex-column ml-4">
                            <div className="mb-2">
                                {data.vax2CountMonth} second doses recorded in{" "}
                                {format(today, "MMM")}
                            </div>
                            <div className="mb-2">
                                {data.vax2CountYear} second doses recorded in{" "}
                                {format(today, "yyyy")}
                            </div>
                        </div>
                    </div>
                </div>
                {!user?.delegate ? (
                    <div className="d-flex flex-row justify-content-end">
                        <a
                            href={`${Config.serverURL}/api/vaccination/download-data/${user.uid}?token=${token}`}
                        >
                            Download data
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
