import React from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { push, goBack as goBackAction } from "react-router-redux"
import {
    logout as logoutAction,
    fetchVaccinationOrganizationMembers,
    vaccinationOrganizationDeleteMember,
    vaccinationOrganizationInviteMember,
} from "../../../actions"
import AddMemberForm from "../components/AddMemberForm"

export default function AddMembers() {
    const user = useSelector((state) => state.user)
    const acceptedInvites = useSelector((state) =>
        state.vaccinationOrganizationMembers?.filter(
            (member) => member.invitationStatus === "accepted"
        )
    )
    const pendingInvites = useSelector((state) =>
        state.vaccinationOrganizationMembers?.filter(
            (member) => member.invitationStatus === "pending"
        )
    )

    console.log("pending invites ", pendingInvites)
    console.log("accepted invites ", acceptedInvites)
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())
    const fetchOrganizationMembers = (accountId) =>
        dispatch(fetchVaccinationOrganizationMembers(accountId))

    React.useEffect(() => {
        if (user) {
            fetchOrganizationMembers(user.uid)
        } else {
            dispatch(push("/"))
        }
    }, [user])

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header right={right}></Header>
            {user && pendingInvites && acceptedInvites && (
                <>
                    <AddMemberForm
                        user={user}
                        inviteMember={(email, accountId) =>
                            dispatch(
                                vaccinationOrganizationInviteMember(email, accountId)
                            )
                        }
                        deleteMember={(email, accountId) =>
                            dispatch(
                                vaccinationOrganizationDeleteMember(email, accountId)
                            )
                        }
                        pendingInvites={pendingInvites}
                        acceptedInvites={acceptedInvites}
                    ></AddMemberForm>
                    <div className="text-center">
                        <Button
                            onClick={goBack}
                            color="link"
                            style={{ cursor: "pointer" }}
                        >
                            Go back to your dashboard
                        </Button>
                    </div>
                </>
            )}
            <div className="text-center">
                <Footer />
            </div>
        </>
    )
}
