import React, { Component } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Input,
    Label,
    Form,
    FormGroup,
} from "reactstrap"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { useToasts } from "react-toast-notifications"

import {
    logout as logoutAction,
    setElectricUserActive,
    setLogoDisplay,
    getLogoDisplay,
    setElectricMessagingServiceSid as persistMessagingServiceId,
    setElectricSubAccountSid as persistSubAccountSid,
    fetchElectricUser,
} from "../../../actions"
import _, { debounce } from "lodash"
import { observer } from "mobx-react-lite"
import { electricStore } from "../data/electric.store"
import { electricUploadManager } from "../data/electric.upload-manager"
import StatsBox from "./components/StatsBox"

let timeout

function Dashboard() {
    const [messagingServiceId, setMessagingServiceId] = React.useState("")
    const [subAccountSid, setSubAccountSid] = React.useState("")
    const [token, setToken] = React.useState()
    const [selectedTab, setSelectedTab] = React.useState("Activity Summary")

    const { fetchUniqueCustomerCount, customerCount } = electricStore
    const { setErrorMessage, setSuccessMessage } = electricUploadManager

    const dispatch = useDispatch()
    const { user, admin, router, logoDisplay, settings } = useSelector((state) => state)
    const { addToast } = useToasts()

    React.useEffect(() => {
        if (user) {
            //fetchOrganizationMembers(user.uid)
            setSuccessMessage("")
            setErrorMessage("")
            setMessagingServiceId(user.messagingServiceSid || "")
            setSubAccountSid(user.subAccountSid || "")
            dispatch(getLogoDisplay(user))
            fetchUniqueCustomerCount(user.uid)
        }
    }, [user])

    React.useEffect(() => {
        setToken(localStorage.getItem("token"))
    }, [])

    React.useEffect(() => {
        if (admin) {
            console.log("user ", user)
            const email = _.last(router.location.pathname.split("/"))
            dispatch(fetchElectricUser(email))
        }
    }, [admin])

    const selectCountry = (evt) => {
        const code = evt.target.value
        dispatch(push(router.location.pathname + "/" + code))
    }

    const logout = (evt) => {
        dispatch(logoutAction()).then(() => {
            dispatch(push("/"))
        })
    }

    const toggleActivation = (evt) => {
        const proceed = window.confirm("Proceed with this operation?")

        if (proceed) {
            dispatch(setElectricUserActive(user, !user.active))
        }
    }

    const getVerifiedMembers = () => {
        return vaccinationOrganizationMembers.filter(
            (item) => item.invitationStatus === "accepted"
        )
    }

    const hasLogoData = () => {
        return logoDisplay.data !== undefined && logoDisplay.data !== null
    }

    const handleMessagingServiceIdChange = async (evt) => {
        evt.persist()

        setMessagingServiceId(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistMessagingServiceId(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting Messaging Service Id: " + e.message)
            }
        }, 1000)
    }

    const handleSubAccountChange = async (evt) => {
        evt.persist()

        setSubAccountSid(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistSubAccountSid(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting SubAccount SID: " + e.message)
            }
        }, 1000)
    }

    const handleAddCustomers = () => {
        setSelectedTab("Admin")
    }

    const handleAlertOptionChanged = (evt) => {
        let href = ""
        if (evt.target.value === "disconnection-alerts") {
            href = `/electric/${user?.country?.isoCode}/${user?.email}/disconnection-alerts`
        } else if (evt.target.value === "emergency-alerts") {
            href = `/electric/${user?.country?.isoCode}/${user?.email}/emergency-alerts`
        } else {
            href = `/electric/${user?.country?.isoCode}/${user?.email}/planned-outage-alerts`
        }

        console.log("option ", evt.target.value)
        dispatch(push(href))
    }

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div>
                        <img src={user.logo} height="50" />
                    </div>
                    <div className="mr-2 d-flex flex-column align-items-center">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <a href="#" onClick={logout}>
                                Logout
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (!user) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Dashboard</h1>

                            <Alert color="info">
                                <Link to="/auth/login">Login</Link> required.
                            </Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    if (user && !user.active && !admin) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Dashboard</h1>

                            <Alert color="info">Your account is pending activation</Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    return (
        <div>
            <Header right={right} />
            <Container>
                <Row className="justify-content-center">
                    <Col md={10} xs={12} className="">
                        <h2>Dashboard</h2>
                        {user ? (
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{user.longName}</h4>
                            </div>
                        ) : null}

                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row align-items-center">
                                <div className="">
                                    <img src="/images/customers.png" width="30" />
                                </div>

                                <div className="pl-2">
                                    {customerCount} unique customers
                                </div>
                            </div>

                            <div className="p-2">
                                <a href="#add-customers" onClick={handleAddCustomers}>
                                    Add Customers
                                </a>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                                <Input
                                    onChange={handleAlertOptionChanged}
                                    type="select"
                                    placeholder="SEND NEW ALERTS"
                                    style={{
                                        width: "200px",
                                        backgroundColor: "rgb(255, 153, 0)",
                                    }}
                                    className="p-2"
                                >
                                    <option
                                        label=""
                                        style={{ textTransform: "uppercase" }}
                                    >
                                        SEND NEW ALERTS
                                    </option>
                                    <option
                                        value="planned-outage-alerts"
                                        label="Planned Outage Alerts"
                                    >
                                        Planned Outage Alerts
                                    </option>
                                    <option
                                        value="disconnection-alerts"
                                        label="Disconnection Alerts"
                                    >
                                        Disconnection Alerts
                                    </option>
                                    <option
                                        value="emergency-alerts"
                                        label="Emergency Alerts"
                                    >
                                        Emergency Alerts
                                    </option>
                                </Input>
                                <Link
                                    className="pt-2"
                                    to={`/electric/${user?.country?.isoCode}/${user?.email}/download-templates`}
                                >
                                    Get Templates
                                </Link>
                            </div>

                            <StatsBox
                                user={user}
                                settings={settings}
                                onTabSelected={setSelectedTab}
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                            />
                        </div>

                        {admin ? (
                            <div className="d-flex flex-row justify-content-around">
                                <div className="d-flex align-items-center mb-4">
                                    <Button onClick={toggleActivation} color="success">
                                        {user && user.active
                                            ? "Deactivate Account"
                                            : "Activate Account"}
                                    </Button>
                                </div>
                            </div>
                        ) : null}

                        {admin ? (
                            <Form className="mt-2 mb-2">
                                <FormGroup check style={{ paddingLeft: 0 }}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={hasLogoData() || false}
                                            onChange={(evt) => {
                                                dispatch(
                                                    setLogoDisplay(
                                                        user,
                                                        evt.target.checked
                                                    )
                                                )
                                            }}
                                        />{" "}
                                        Enable logo display on landing page.
                                    </Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Messaging Service Id</Label>
                                    <Input
                                        value={messagingServiceId}
                                        placeholder="Messaging Service Id"
                                        onChange={handleMessagingServiceIdChange}
                                    />
                                </FormGroup>

                                {/*
                                <FormGroup>
                                    <Label>Subaccount SID</Label>
                                    <Input
                                        value={subAccountSid}
                                        placeholder="Subaccount SID"
                                        onChange={handleSubAccountChange}
                                    />
                                          </FormGroup> */}
                            </Form>
                        ) : null}
                    </Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}

export default observer(Dashboard)
