import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import "../../../App.css"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useToasts } from "react-toast-notifications"

let VaccinationSchema = Yup.object({
    vaccineId: Yup.string().required("Vaccine must be selected"),
    vaccinationDate: Yup.date("Please enter a valid date")
        .required("Please enter a valid date")
        .nullable(),
    nextVaccinationDate: Yup.date("Please enter a valid date").nullable(),
    noDateForSecondShot: Yup.boolean(),
    mobileNumber: Yup.string()
        .required("Please enter a valid 7-digit mobile number")
        .matches(/^\d+$/, "Must be a valid phone number")
        .length(7, "Please enter a valid 7-digit mobile number"),
    confirmMobileNumber: Yup.string()
        .required("Mobile numbers must match")
        .oneOf([Yup.ref("mobileNumber"), null], "Mobile numbers must match"),
    email: Yup.string().email("Please enter a valid email"),
})

export default function FirstVaccinationForm({
    user,
    vaccines,
    goBack,
    vaccinationRepository,
}) {
    const [errorMessage, setErrorMessage] = React.useState()
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [loadingResult, setLoadingResult] = React.useState(false)

    const { addToast } = useToasts()

    const addVaccination = (values) => {
        setLoadingResult(true)
        return vaccinationRepository
            .addVaccination(values)
            .then((res) => {
                setLoadingResult(false)
                setSubmitSuccess(true)
                window.scrollTo(0, 0)
                console.log("add vaccination result", res)
                addToast("Vaccination event successfully added", {
                    autoDismiss: true,
                    appearance: "success",
                })
                return Promise.resolve()
            })
            .catch((err) => {
                setLoadingResult(false)
                window.scrollTo(0, 0)
                setErrorMessage(err.response.data.errorMessage || "Server Error")
                console.log("Add vaccination error ", err.response)
                addToast(err.response.data.errorMessage || "Server Error", {
                    autoDismiss: true,
                    appearance: "error",
                })
                return Promise.reject()
            })
    }

    const resetForm = () => {
        formik.values = {}
        formik.resetForm()
        setSubmitSuccess(false)
    }

    const formik = useFormik({
        validationSchema: VaccinationSchema,
        initialValues: {
            accountId: user.uid,
            vaccinationIndex: 1,
            vaccinationDate: null,
            nextVaccinationDate: null,
            noDateForSecondShot: false,
            mobileNumber: "",
            email: "",
            confirmMobileNumber: "",
            vaccineId: "astrazeneca",
        },
        onSubmit: (values) => {
            delete values.noDateForSecondShot
            delete values.confirmMobileNumber
            console.log("submitted values ", values)

            addVaccination(values).then(() => {
                setTimeout(() => {
                    resetForm()
                }, 1500)
            })
        },
    })

    React.useEffect(() => {
        if (formik.values.noDateForSecondShot) {
            formik.setFieldValue("nextVaccinationDate", null)
        }
    }, [formik.values.noDateForSecondShot])

    global.formik = formik

    const submitAndClose = () => {
        let values = formik.values
        delete values.noDateForSecondShot
        delete values.confirmMobileNumber
        addVaccination(values)
            .then(() => {
                goBack()
            })
            .catch((e) => {})
    }

    console.log("form errors ", formik.errors)
    const setCurrentDate = () => {
        formik.setFieldValue("vaccinationDate", new Date())
    }

    const isFormValid = () => {
        return (
            (formik.isValid && formik.values.nextVaccinationDate) ||
            (formik.isValid && formik.values.noDateForSecondShot)
        )
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} xs={12}>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <img src="/images/jab1.png" style={{ height: 50, width: 50 }} />
                        <h3 className="ml-2 mr-2">Add First Vaccination</h3>
                        {submitSuccess ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                size={"3x"}
                                color={"green"}
                            />
                        ) : null}
                    </div>

                    <Form onSubmit={formik.handleSubmit}>
                        {(errorMessage && (
                            <Alert color="danger" className="mt-2 mb-2">
                                {errorMessage}
                            </Alert>
                        )) ||
                            null}
                        <FormGroup>
                            <Label className="font-weight-bold">Vaccine Name</Label>
                            {formik.touched.vaccineId && formik.errors.vaccineId && (
                                <Alert color="danger">{formik.errors.vaccineId}</Alert>
                            )}
                            <Input
                                value={formik.values.vaccineId}
                                type="select"
                                placeholder="Vaccine Name"
                                onBlur={formik.handleBlur("vaccineId")}
                                onChange={formik.handleChange("vaccineId")}
                            >
                                {vaccines.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">
                                Date of first vaccination
                            </Label>
                            {formik.touched.vaccinationDate &&
                                formik.errors.vaccinationDate && (
                                    <Alert color="danger">
                                        {formik.errors.vaccinationDate}
                                    </Alert>
                                )}
                            <div className="d-flex flex-row align-items-center">
                                <DatePicker
                                    placeholderText="Date of first vaccination"
                                    selected={formik.values.vaccinationDate}
                                    onBlur={formik.handleBlur("vaccinationDate")}
                                    onChange={(date) => {
                                        formik.setFieldValue("vaccinationDate", date)
                                    }}
                                />
                                <FontAwesomeIcon
                                    onClick={setCurrentDate}
                                    size="2x"
                                    icon={faCalendarAlt}
                                    style={{ marginLeft: 8, cursor: "pointer" }}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">Mobile Number</Label>
                            {formik.touched.mobileNumber &&
                                formik.errors.mobileNumber && (
                                    <Alert color="danger">
                                        {formik.errors.mobileNumber}
                                    </Alert>
                                )}
                            <Input
                                type="number"
                                placeholder="7-digit MOBILE number"
                                value={formik.values.mobileNumber}
                                onBlur={formik.handleBlur("mobileNumber")}
                                onChange={formik.handleChange("mobileNumber")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">
                                Confirm Mobile Number
                            </Label>
                            {formik.touched.confirmMobileNumber &&
                                formik.errors.confirmMobileNumber && (
                                    <Alert color="danger">
                                        {formik.errors.confirmMobileNumber}
                                    </Alert>
                                )}
                            <Input
                                type="number"
                                placeholder="Confirm number"
                                value={formik.values.confirmMobileNumber}
                                onBlur={formik.handleBlur("confirmMobileNumber")}
                                onChange={formik.handleChange("confirmMobileNumber")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">Email</Label>
                            {formik.touched.email && formik.errors.email && (
                                <Alert color="danger">{formik.errors.email}</Alert>
                            )}
                            <Input
                                placeholder="Email (Optional)"
                                value={formik.values.email}
                                onBlur={formik.handleBlur("email")}
                                onChange={formik.handleChange("email")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">
                                Date of second vaccination
                            </Label>
                            {formik.touched.nextVaccinationDate &&
                                formik.errors.nextVaccinationDate && (
                                    <Alert color="danger">
                                        {formik.errors.nextVaccinationDate}
                                    </Alert>
                                )}
                            <DatePicker
                                disabled={formik.values.noDateForSecondShot}
                                placeholderText="Scheduled date for second shot"
                                selected={formik.values.nextVaccinationDate}
                                onBlur={formik.handleBlur("nextVaccinationDate")}
                                onChange={(date) => {
                                    formik.setFieldValue("nextVaccinationDate", date)
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <CustomInput
                                checked={formik.values.noDateForSecondShot}
                                type="checkbox"
                                id="check"
                                name="check"
                                label="No scheduled second shot"
                                onBlur={formik.handleBlur("noDateForSecondShot")}
                                onChange={(evt) =>
                                    formik.setFieldValue(
                                        "noDateForSecondShot",
                                        evt.target.checked
                                    )
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex flex-row align-items-center">
                                    {loadingResult ? (
                                        <Spinner color="success" className="mr-2" />
                                    ) : null}
                                    <Button
                                        disabled={!isFormValid()}
                                        color="success"
                                        type="submit"
                                    >
                                        Submit and add another 1st Vax
                                    </Button>
                                </div>

                                <Button
                                    disabled={!isFormValid()}
                                    color="link"
                                    onClick={submitAndClose}
                                >
                                    Submit and close
                                </Button>

                                <Button color="link" onClick={goBack}>
                                    Cancel
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
