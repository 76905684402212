import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push as pushAction } from "react-router-redux"
import { Container } from "reactstrap"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import * as Yup from "yup"
import { useFormik, validateYupSchema } from "formik"

import {
    setRegistrationFields as setRegistrationFieldsAction,
    setRegistrationField as setRegistrationFieldAction,
    registerUser as registerUserAction,
} from "../../../actions"

import countries from "../../../countries-caribbean.json"
import { RegistrationSchema } from "../data/electric.schema"
import { RegisterForm } from "./components/RegisterForm"

var randomstring = require("randomstring")

function initialValues() {
    let value = randomstring.generate({ length: 4 })
    return {
        longName: `My Long Name${value}`,
        shortName: `Short${value}`,
        mainPhone: randomstring.generate({ length: 7, charset: "numeric" }),
        customerServicePhone: randomstring.generate({ length: 7, charset: "numeric" }),
        emergencyPhone1: randomstring.generate({ length: 7, charset: "numeric" }),
        emergencyPhone2: randomstring.generate({ length: 7, charset: "numeric" }),
        address1: `address${value}`,
        address2: `address${value}`,
        facebook: `facebook${value}`,
        instagram: `instagram${value}`,
        website: `https://mywebsite${value}.com`,
        websiteShortURL: `https://short${value}.com`,
        outagesShortURL: `https://out${value}.com`,
        email: `shortname${value}@mailinator.com`,
    }
}

export default function ElectricRegistration() {
    const { registration } = useSelector((state) => state)
    const dispatch = useDispatch()

    const setRegistrationFields = (params) =>
        dispatch(setRegistrationFieldsAction(params))
    const setRegistrationField = (params) => dispatch(setRegistrationFieldAction(params))
    const registerUser = (info) => dispatch(registerUserAction(info))
    const push = (location) => dispatch(pushAction(location))

    useEffect(() => {
        if (!registration.country) {
            push("/electric")
        }
    }, [])

    const currentCountry = countries.find(
        (item) => item.code === registration?.country?.toUpperCase()
    )

    console.log("currentCountry ", currentCountry)

    const submitForm = (evt) => {
        evt.preventDefault()
        push("/auth/register/confirm")
    }

    const passwordsMatch = () => {
        const { password, confirmPassword } = registration

        if (password && confirmPassword) {
            return password === confirmPassword
        } else if (!password && !confirmPassword) {
            return true
        } else {
            return false
        }
    }

    const allFieldsFilled = () => {
        const {
            bloodBankLongName,
            bloodBankShortName,
            address1,
            address2,
            email,
            password,
            confirmPassword,
            ministryPhone,
        } = registration

        return (
            bloodBankLongName &&
            bloodBankLongName.length > 0 &&
            bloodBankShortName &&
            bloodBankShortName.length > 0 &&
            address1 &&
            address1.length > 0 &&
            address2 &&
            address2.length > 0 &&
            phone &&
            phone.length > 0 &&
            email &&
            email.length > 0 &&
            password === confirmPassword &&
            password &&
            password.length > 0
        )
    }

    const country = registration?.country ? registration.country.toLowerCase() : ""

    const flagUrl = `/flag-icon-css/flags/4x3/${country}.svg`

    const formik = useFormik({
        initialValues: {
            //...initialValues(),
            ...registration,
            mainPhone: registration.mainPhone ? registration.mainPhone.slice(-7) : "",
            emergencyPhone1: registration?.emergencyPhone1
                ? registration?.emergencyPhone1?.slice(-7)
                : "",
            emergencyPhone2: registration?.emergencyPhone2
                ? registration?.emergencyPhone2?.slice(-7)
                : "",
            dialCode: currentCountry?.areaCodes?.[0],
        },
        onSubmit: (values) => {
            delete values.confirmPassword
            values.mainPhone = `1${values.dialCode}${values.mainPhone}`
            if (values.emergencyPhone1) {
                values.emergencyPhone1 = `1${values.dialCode}${values.emergencyPhone1}`
            }

            if (values.emergencyPhone2) {
                values.emergencyPhone2 = `1${values.dialCode}${values.emergencyPhone2}`
            }

            delete values.dialCode
            setRegistrationFields(values)
            push("/auth/electric/register/confirm")
        },
        validationSchema: RegistrationSchema,
    })

    const inputRef = useRef()
    console.log(
        "errors ",
        formik.errors,
        " values ",
        formik.values,
        " registration ",
        registration
    )

    if (!registration.country) {
        return null
    }

    return (
        <div className="">
            <Header
                right={
                    <div>
                        <img src={flagUrl} style={{ height: "48px" }} />
                    </div>
                }
            />
            <Container>
                <RegisterForm country={currentCountry} formik={formik} />
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}
