import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, FormGroup } from 'reactstrap';
import Footer from '../../components/Footer';
import { settingToSetupStep } from '../../utils'

const StepSeven = (props) =>  {
  
  const [message, setMessage] = useState(`A reminder that $xxx.xx is xx days past due on your credit account. Kindly make pmt or call ${props.user.companyShortName} at <xxx xxxx> to make arrangements; disregard if already pd`)

  const previousStep = () => {
    props.setSetupState(props.user.uid, settingToSetupStep(getPreviousStep())).then(_ => {
      props.fetchSettings(props.user.uid);
    });
  }

  const settingsList =  [    
    'credit-account-alerts-30-plus-days-overdue',
    'loan-account-reminders-before-due',
    'loan-account-alerts-overdue',
    'loan-account-alerts-30-plus-days-overdue',
    'alert-type-greetings-birthday',
    'alert-type-greetings-christmas',
    'alert-type-greetings-divali',
    'alert-type-greetings-eid'    
]

const previousSettingsList =  [    
  'credit-account-reminders-before-due',
  'introductory-message'
]

const getNextStep = () => {
  for(let item of settingsList) {    
    if( props.settings[item]) {
      return item; 
    }      
  }
}

const getPreviousStep = () => {
  for(let item of previousSettingsList) {    
    if( props.settings[item]) {
      return item; 
    }      
  }
} 

const saveSetting = (key, value) => {
  return props.setSettings(props.user, {[key]: value})
}  

const skipGreetings = () => {
  saveSetting("setup-complete", true).then(res => {
    props.goBack()
  })
}  


  const nextStep = () => {
    props.saveAlert(props.user, "overdueCreditPayment", message).then(res => {      
      //props.fetchSettings(props.user); 
      let stepNumber = settingToSetupStep(getNextStep())      

      if(stepNumber !== undefined) {
        props.setSetupState(props.user.uid, stepNumber).then(_ => {
          props.fetchSettings(props.user.uid);
        });
      }
      else {
        skipGreetings()
      }
    });
  }

    return (
    <Container>
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h3>Configure your alerts</h3>

        <Form>
          <FormGroup>
            <span>OVERDUE CREDIT payment alert will be in the following format:</span>

          <div className="text-primary">{message}</div>

          </FormGroup>
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={false}
              onClick={nextStep}>Save and go to next</Button>
            <Button className="ml-4" onClick={() => {              
              props.goBack()
            }}>Cancel</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepSeven; 