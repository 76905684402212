import React from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { Button, Spinner, Container, Col, Row, Card } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { push, goBack as goBackAction } from "react-router-redux"
import { format } from "date-fns"

import { logout as logoutAction, fetchVaccines, fetchNoShows } from "../../../actions"
import AddPreviousCustomersForm from "../components/AddPreviousCustomersForm"
import NoShows from "../components/NoShows"

export default function AdminFunctions() {
    const { user, vaccines, vaccinationNoShows } = useSelector((state) => state)
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const renderItems = () => {
        return vaccinationNoShows.map((item, index) => (
            <Card key={index} className="p-4">
                <h4>{item.mobileNumber}</h4>
                <div>
                    <span style={{ fontWeight: 500 }}>
                        Scheduled 2nd Vaccination Date
                    </span>{" "}
                    <span style={{ color: "gray" }}>
                        {format(
                            new Date(item.scheduledSecondVaccinationDate),
                            "dd MMM yyy"
                        )}
                    </span>
                </div>
                <div>
                    <span style={{ fontWeight: 500 }}>Vaccine</span>{" "}
                    <span style={{ color: "gray" }}>{item.vaccineName}</span>
                </div>
            </Card>
        ))
    }

    return (
        <>
            <Header right={right}></Header>
            {user && vaccinationNoShows ? (
                <>
                    <Container>
                        <Row>
                            <Col md={12} xs={12}>
                                <h3>ADMIN FUNCTIONS</h3>
                                {/*<AddPreviousCustomersForm user={user} />*/}
                                <NoShows vaccinationNoShows={vaccinationNoShows} />
                            </Col>
                        </Row>
                    </Container>
                    <div className="text-center">
                        <Button
                            onClick={goBack}
                            color="link"
                            style={{ cursor: "pointer" }}
                        >
                            Go back to your dashboard
                        </Button>
                    </div>
                </>
            ) : (
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                </div>
            )}
            <div className="text-center">
                <Footer />
            </div>
        </>
    )
}
