import { Button, Col, Form, Row } from "reactstrap"
import { PassowrdInputField } from "./PasswordInputField"
import { PhoneInputField } from "./PhoneInputField"
import { TextInputField } from "./TextInputField"
import ReCAPTCHA from "react-google-recaptcha"
import { useEffect, useState } from "react"

export const RegisterForm = ({ formik, country }) => {
    const [readonly, setReadonly] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setReadonly(false)
        }, 1000)
    }, [])

    return (
        <Row className="justify-content-md-center justify-content-center p-2">
            <Col md={4} xs={12}>
                <div className="d-flex flex-column">
                    <h3>Utility registration</h3>
                    <div>* Required fields</div>
                </div>

                <Form onSubmit={formik.handleSubmit} className="mt-4">
                    <TextInputField
                        label={"Utility long name"}
                        fieldName="longName"
                        placeholder="Utility long name"
                        formik={formik}
                    />

                    <TextInputField
                        label={"Utility short name"}
                        fieldName="shortName"
                        placeholder="Max 11 chars w spaces"
                        formik={formik}
                    />

                    <TextInputField
                        formik={formik}
                        label={"Address Line 1"}
                        placeholder="Address line 1"
                        fieldName={"address1"}
                        required={true}
                    />

                    <TextInputField
                        formik={formik}
                        label={"Address Line 2"}
                        placeholder="Address line 2"
                        fieldName={"address2"}
                        required={false}
                    />

                    <PhoneInputField
                        required={true}
                        label={"Main phone number"}
                        placeholder="Local 7-digit #"
                        formik={formik}
                        fieldName="mainPhone"
                        country={country}
                    />

                    <PhoneInputField
                        required={true}
                        label={"Emergency and outages number 1"}
                        placeholder="Local 7-digit #"
                        formik={formik}
                        fieldName="emergencyPhone1"
                        country={country}
                    />

                    <PhoneInputField
                        label={"Emergency and outages number 2"}
                        placeholder="Local 7-digit #"
                        formik={formik}
                        fieldName="emergencyPhone2"
                        country={country}
                    />

                    <TextInputField
                        label={"Customer service number (optional)"}
                        formik={formik}
                        fieldName="customerServicePhone"
                        placeholder="Customer service #"
                        country={country}
                    />

                    <TextInputField
                        formik={formik}
                        required={true}
                        label={"Email"}
                        fieldName="email"
                        type="email"
                        placeholder={"Email"}
                    />

                    <TextInputField
                        formik={formik}
                        required={true}
                        label={"Website"}
                        type="text"
                        fieldName={"website"}
                        placeholder={"Website"}
                    />

                    <TextInputField
                        formik={formik}
                        label={"Short URL - Website (Optional)"}
                        type="text"
                        fieldName={"websiteShortURL"}
                        placeholder={"Short URL - Website"}
                    />

                    <TextInputField
                        formik={formik}
                        label={"Short URL - Outages (Optional)"}
                        type="text"
                        fieldName={"outagesShortURL"}
                        placeholder={"Short URL - Outages"}
                    />

                    <TextInputField
                        formik={formik}
                        required={false}
                        label={"Facebook (Optional)"}
                        type="text"
                        fieldName={"facebook"}
                        placeholder={"Facebook name"}
                    />

                    <TextInputField
                        formik={formik}
                        required={false}
                        label={"Instagram (Optional)"}
                        type="text"
                        readonly={readonly}
                        fieldName={"instagram"}
                        placeholder={"Instagram"}
                    />

                    <PassowrdInputField
                        formik={formik}
                        required={true}
                        fieldName={"password"}
                        label="Password"
                        placeholder="Password"
                    />

                    <PassowrdInputField
                        formik={formik}
                        required={true}
                        fieldName={"confirmPassword"}
                        label="Confirm password"
                        placeholder="Password"
                    />

                    <ReCAPTCHA
                        className="captcha"
                        ref={(e) => {
                            console.log("reCaptcha : ", e)
                        }}
                        sitekey="6LcfRjsUAAAAAOF5AgszQeZRBqtfNZVhf9MnU95h"
                        onChange={(response) => {
                            console.log("captcha response", response)
                            if (response) {
                                formik.setFieldValue("g-recaptcha-response", response)
                                //setRegistrationField({ 'g-recaptcha-response': response })
                            }
                        }}
                    />

                    <div className="text-center p-4">
                        <Button type="submit" color="success" disabled={!formik.isValid}>
                            Register
                        </Button>
                    </div>
                </Form>
            </Col>
        </Row>
    )
}
