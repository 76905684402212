import React, { Component } from 'react';
import { Container, Row, Col, Button, Alert, Input, Label, Form, FormGroup } from 'reactstrap';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Logout from '../../components/Logout';

const StepTwo = (props) =>  {
  
  const nextStep = () => {
    props.setSetupState(props.user.uid, "3").then(res => {
      
      props.fetchSettings(props.user.uid); 
    });
  }

  const saveSetting = (key, value) => {
    props.setSettings(props.user, {[key]: value}).then(res => {      
    })
  }

  const enableGreetings = (value) => {
    const items = {
      "alert-type-greetings": value,
      "alert-type-greetings-birthday" : value,
      "alert-type-greetings-christmas" : value,
      "alert-type-greetings-divali" : value,
      "alert-type-greetings-eid" : value
    }

    props.setSettings(props.user, items).then(res => {
      
    });
  }

    return (
    <Container>    
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h2>What types of alerts do you want to send?</h2>

        <Form>
          <FormGroup check>
            <div>
              <Label check>
                  <Input type="checkbox" checked={props.settings['alert-type-reminder-due-overdue-payments'] || false} onChange={(evt) => saveSetting("alert-type-reminder-due-overdue-payments", evt.target.checked)} />{' '}
                  Reminders for due / overdue payments
              </Label>
            </div>

            <div>
              <Label check className="disabled-checkbox">
                  <Input type="checkbox" checked={props.settings['alert-type-predetermined-schedule'] || false} disabled={true} onChange={(evt) => saveSetting("alert-type-predetermined-schedule", evt.target.checked)} />{' '}
                  Alerts on a pre-determined schedule of dates
              </Label>
            </div>

            <div>
              <Label check className="disabled-checkbox">
                  <Input type="checkbox" disabled={true} onChange={(evt) => saveSetting("alert-type-location", evt.target.checked)} />{' '}
                  Location-specific alerts
              </Label>
            </div>

            <div>
              <Label check>
                  <Input type="checkbox" checked={props.settings['alert-type-greetings'] || false} onChange={(evt) => enableGreetings(evt.target.checked)} />{' '}
                  Greetings
              </Label>
            </div>

            <div>
              <Label check>
                  <Input type="checkbox" checked={props.settings['alert-type-custom'] || false} onChange={(evt) => saveSetting("alert-type-custom", evt.target.checked)} />{' '}
                  Custom alerts (default alert type)
              </Label>                                        
            </div>
          </FormGroup>
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={false}
              onClick={nextStep}>Next</Button>
            <Button className="ml-4" onClick={() => {
              
              props.goBack()
            }}>Cancel</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepTwo; 