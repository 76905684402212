import React, { Component, useState } from 'react';
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import Footer from '../../components/Footer';

const StepFour = (props) =>  {
  
  const [message, setMessage] = useState(`Dear valued customer, thanks for choosing ${props.user.companyShortName} for your credit and financing needs. Please call us at <xxx xxxx> and we will help with what you need!`)
  const nextStep = () => {
    props.setAlert(props.user, "introductoryMessage", message).then(res => {
      
      //props.fetchSettings(props.user); 
      props.setSetupState(props.user.uid, "5").then(_ => {
        props.fetchSettings(props.user.uid);
      });
    });
  }

  const saveSetting = (key, value) => {
    props.setSettings(props.user, {[key]: value}).then(res => {
      
    })
  }

    return (
    <Container>
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h3>Configure an introductory message</h3>

        <Form>
          <FormGroup>
            <span>Modify the text in the box below to set your introductory message. Click Continue when finished.</span>
            <Input type="textarea" value={message} onChange={(evt) => setMessage(evt.target.value) } />
            <div className="text-right">{message.length}</div>
          </FormGroup>
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={false}
              onClick={nextStep}>Continue</Button>
            <Button className="ml-4" onClick={() => {
              
              props.goBack()
            }}>Cancel</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepFour; 