import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Input,
    Label,
    Form,
    FormGroup,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import Header from "./components/Header"
import Footer from "./components/Footer"
import AutoStats from "./components/AutoStats"
import PropertyStats from "./components/PropertyStats"
import HealthStats from "./components/HealthStats"
import BirthdayStats from "./components/BirthdayStats"
import ChristmasStats from "./components/ChristmasStats"
import EidStats from "./components/EidStats"
import DivaliStats from "./components/DivaliStats"
import CustomMessageStats from "./components/CustomMessageStats"
import MessagingServiceUI from "./components/MessagingServiceUI"

import {
    fetchStats,
    fetchCustomMessageStats,
    fetchPropertyStats,
    fetchHealthStats,
    fetchBirthdayStats,
    fetchChristmasStats,
    fetchEidStats,
    fetchDivaliStats,
    fetchEmailStats,
    fetchUser,
    logout,
    setUserActive,
    setLogoDisplay,
    getLogoDisplay,
    registerUser,
    setSMSNumber,
    fetchCounterStats,
    fetchUniqueCustomers,
    setMessagingServiceId,
} from "./actions"

import _, { debounce } from "lodash"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import parsePhoneNumber, { isValidNumber, isValidPhoneNumber } from "libphonenumber-js"

class AppContainer extends Component {
    constructor(props) {
        super(props)

        this.state = { messagingServiceId: "" }
    }

    componentDidMount() {
        if (this.props.user) {
            this.setState({
                messagingServiceId: this.props.user.messagingServiceId || "",
            })
            this.props.fetchStats(this.props.user)
        }

        if (this.props.admin) {
            const email = _.last(this.props.match.url.split("/"))
            this.props.fetchUser(email).then((res) => {
                const registeredUser = Object.values(res.value.val())[0]
                this.setState({ messagingServiceId: registeredUser.messagingServiceId })

                Promise.all([
                    this.props.fetchStats(registeredUser),
                    this.props.fetchUniqueCustomers(registeredUser),
                    this.props.fetchCustomMessageStats(registeredUser),
                    this.props.fetchPropertyStats(registeredUser),
                    this.props.fetchHealthStats(registeredUser),
                    this.props.fetchBirthdayStats(registeredUser),
                    this.props.fetchChristmasStats(registeredUser),
                    this.props.fetchEidStats(registeredUser),
                    this.props.fetchDivaliStats(registeredUser),
                    this.props.fetchEmailStats(registeredUser, "auto"),
                    this.props.fetchEmailStats(registeredUser, "property"),
                    this.props.fetchEmailStats(registeredUser, "health"),
                    this.props.fetchEmailStats(registeredUser, "birthday"),
                    this.props.fetchEmailStats(registeredUser, "christmas"),
                    this.props.fetchEmailStats(registeredUser, "divali"),
                    this.props.fetchEmailStats(registeredUser, "eid"),
                    this.props.getLogoDisplay(registeredUser),
                ])
                    .then((results) => {})
                    .catch((error) => {
                        console.log("problem ", error)
                    })
            })
        }
    }

    selectCountry(evt) {
        const code = evt.target.value
        this.props.dispatch(push(this.props.match.url + "/" + code))
    }

    logout(evt) {
        evt.preventDefault()
        this.props.logout().then(() => {
            this.props.dispatch(push("/"))
        })
    }

    toggleActivation(evt) {
        const proceed = window.confirm("Proceed with this operation?")

        if (proceed) {
            this.props.setUserActive(this.props.user, !this.props.user.active)
        }
    }

    hasLogoData = () => {
        return (
            this.props.logoDisplay.data !== undefined &&
            this.props.logoDisplay.data !== null
        )
    }

    handleMessagingServiceIdChange = async (evt) => {
        evt.persist()

        this.setState({ messagingServiceId: evt.target.value })

        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async () => {
            try {
                let result = setMessagingServiceId(
                    this.props.user,
                    this.state.messagingServiceId
                )
                let payload = await result.payload
            } catch (e) {
                alert("Error setting Messaging Service Id: " + e.message)
            }
        }, 1000)
        //this.setState({smsNumber: evt.target.value})
    }

    render() {
        const {
            user,
            admin,
            rates,
            autoStats,
            autoEmailStats,
            birthdayStats,
            birthdayEmailStats,
            customMessageEmailStats,
            customMessageStats,
            eidStats,
            eidEmailStats,
            divaliStats,
            divaliEmailStats,
            christmasStats,
            christmasEmailStats,
            healthStats,
            healthEmailStats,
            propertyStats,
            uniqueCustomers,
            setSMSNumber,
            propertyEmailStats,
        } = this.props

        let right = null
        if ((user && user.active) || (user && admin)) {
            right = (
                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user.email}
                        </div>
                        <div>
                            <a href="#" onClick={this.logout.bind(this)}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.country}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            )
        } else if (!user) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={12} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    <Link to="/auth/login">Login</Link> required.
                                </Alert>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }

        if (user && !user.active && !admin) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={12} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    Your account activation is pending
                                </Alert>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }

        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} xs={12} md={8} className="">
                            <h1>Dashboard</h1>
                            {user ? (
                                <div className="d-flex justify-content-between">
                                    <h4>{user.companyFullName}</h4>
                                    <div>
                                        <Link
                                            to={`/${user.category}/${user.country}/${user.email}/settings`}
                                        >
                                            Account Settings{" "}
                                            <FontAwesomeIcon icon={faCog} />
                                        </Link>
                                    </div>
                                </div>
                            ) : null}
                            {autoStats && autoEmailStats && rates && user ? (
                                <AutoStats
                                    title="Auto Insurance"
                                    emailData={autoEmailStats}
                                    data={autoStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {propertyStats && propertyEmailStats && rates && user ? (
                                <PropertyStats
                                    title="Property Insurance"
                                    emailData={propertyEmailStats}
                                    data={propertyStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {healthStats && healthEmailStats && rates && user ? (
                                <HealthStats
                                    title="Health Insurance"
                                    emailData={healthEmailStats}
                                    data={healthStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {birthdayStats && birthdayEmailStats && rates && user ? (
                                <BirthdayStats
                                    title="Birthdays"
                                    emailData={birthdayEmailStats}
                                    data={birthdayStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {christmasStats && christmasEmailStats && rates && user ? (
                                <ChristmasStats
                                    title="Christmas Greetings"
                                    emailData={christmasEmailStats}
                                    data={christmasStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {divaliStats && divaliEmailStats && rates && user ? (
                                <DivaliStats
                                    title="Divali Greetings"
                                    emailData={divaliEmailStats}
                                    data={divaliStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {eidStats && eidEmailStats && rates && user ? (
                                <EidStats
                                    title="Eid-ul-Fitr Greetings"
                                    emailData={eidEmailStats}
                                    data={eidStats}
                                    rate={rates[user.country]}
                                />
                            ) : null}
                            {customMessageStats &&
                            customMessageEmailStats &&
                            rates &&
                            user ? (
                                <CustomMessageStats
                                    title="Custom Messages"
                                    data={customMessageStats}
                                    emailData={customMessageEmailStats}
                                    rate={rates[user.country]}
                                    uniqueCustomers={uniqueCustomers.count}
                                />
                            ) : null}

                            <div className="text-center">
                                {admin ? (
                                    <Button
                                        onClick={this.toggleActivation.bind(this)}
                                        color="success"
                                    >
                                        {user && user.active
                                            ? "Deactivate Account"
                                            : "Activate Account"}
                                    </Button>
                                ) : null}
                                {user ? (
                                    <Link
                                        className="ml-2"
                                        to={`/${user.category}/${user.country}/${user.email}/update-database`}
                                    >
                                        Update Database
                                    </Link>
                                ) : null}
                                {user ? (
                                    <Link
                                        className="ml-2"
                                        to={`/${user.category}/${user.country}/${user.email}/download-templates`}
                                    >
                                        Download Templates
                                    </Link>
                                ) : null}
                            </div>

                            {admin ? (
                                <div>
                                    <Form className="form-check mt-2 mb-2">
                                        <Input
                                            type="checkbox"
                                            checked={this.hasLogoData() || false}
                                            onChange={(evt) => {
                                                this.props.setLogoDisplay(
                                                    user,
                                                    evt.target.checked
                                                )
                                            }}
                                        />
                                        <Label>
                                            Enable logo display on landing page.
                                        </Label>
                                    </Form>
                                    <MessagingServiceUI
                                        user={this.props.user}
                                        setMessagingServiceId={
                                            this.props.setMessagingServiceId
                                        }
                                    />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    autoStats: state.stats,
    rates: state.rates,
    admin: state.admin,
    uniqueCustomers: state.uniqueCustomers,
    customMessageStats: state.customMessageStats,
    propertyStats: state.propertyStats,
    birthdayStats: state.birthdayStats,
    healthStats: state.healthStats,
    christmasStats: state.christmasStats,
    divaliStats: state.divaliStats,
    eidStats: state.eidStats,
    autoEmailStats: state.autoEmailStats,
    customMessageEmailStats: state.customMessageEmailStats,
    propertyEmailStats: state.propertyEmailStats,
    healthEmailStats: state.healthEmailStats,
    birthdayEmailStats: state.birthdayEmailStats,
    christmasEmailStats: state.christmasEmailStats,
    divaliEmailStats: state.divaliEmailStats,
    eidEmailStats: state.eidEmailStats,
    logoDisplay: state.logoDisplay,
})

const mapDispatchToProps = (dispatch) => ({
    fetchStats: (user) => dispatch(fetchStats(user)),
    fetchUser: (email) => dispatch(fetchUser(email)),
    setUserActive: (user, value) => dispatch(setUserActive(user, value)),
    setLogoDisplay: (user, value) => dispatch(setLogoDisplay(user, value)),
    getLogoDisplay: (user) => dispatch(getLogoDisplay(user)),
    fetchCustomMessageStats: (user) => dispatch(fetchCustomMessageStats(user)),
    fetchPropertyStats: (user) => dispatch(fetchPropertyStats(user)),
    fetchHealthStats: (user) => dispatch(fetchHealthStats(user)),
    fetchBirthdayStats: (user) => dispatch(fetchBirthdayStats(user)),
    fetchChristmasStats: (user) => dispatch(fetchChristmasStats(user)),
    fetchEidStats: (user) => dispatch(fetchEidStats(user)),
    fetchDivaliStats: (user) => dispatch(fetchDivaliStats(user)),
    fetchUniqueCustomers: (user) => dispatch(fetchUniqueCustomers(user)),
    fetchEmailStats: (user, type) => dispatch(fetchEmailStats(user, type)),
    setMessagingServiceId: (user, value) => dispatch(setMessagingServiceId(user, value)),
    logout: () => dispatch(logout()),
    dispatch,
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
