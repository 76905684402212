import { format } from "date-fns"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { electricStore } from "../../../data/electric.store"

const StatsDetailTab = ({ title, onTabSelected, selected }) => {
    return (
        <div
            onClick={() => onTabSelected(title)}
            className="d-flex flex-column border p-2"
            style={{
                position: "relative",
                backgroundColor: selected ? "white" : "lightgray",
                left: "20px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                cursor: "pointer",
                borderBottomColor: "white !important",
            }}
        >
            {title}
        </div>
    )
}

const StatsDetailBox = ({ title, children }) => {
    const [selectedTab, setSelectedTab] = useState("SMS")
    const user = useSelector((state) => state.user)

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row pt-2">
                <StatsDetailTab
                    selected={selectedTab === "SMS"}
                    title={"SMS"}
                    onTabSelected={setSelectedTab}
                ></StatsDetailTab>
                <StatsDetailTab
                    selected={selectedTab === "Email"}
                    title={"Email"}
                    onTabSelected={setSelectedTab}
                ></StatsDetailTab>
            </div>
            <div
                className="d-flex flex-column border p-4"
                style={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    marginBottom: "16px",
                }}
            >
                <div>{children(selectedTab)}</div>
                <div className="d-flex flex-column"></div>
            </div>
        </div>
    )
}

export const DetailsTab = observer(({ setSelectedTab }) => {
    const {
        introMessagesCount,
        introEmailsCountDetail,
        introMessagesCountDetail,
        fetchIntroMessagesCount,
        fetchIntroEmailsCount,
        fetchDisconnectionCount,
        fetchDisconnectionEmailCount,
        fetchPlannedOutagesCount,
        fetchPlannedOutagesEmailCount,
        fetchEmergencyOutagesCount,
        fetchEmergencyOutagesEmailCount,
        disconnectionCount,
        disconnectionCountDetail,
        disconnectionEmailCountDetail,
        emergencyOutagesCount,
        emergencyOutagesCountDetail,
        emergencyOutagesEmailCountDetail,
        plannedOutagesCount,
        plannedOutagesCountDetail,
        plannedOutagesEmailCountDetail,
    } = electricStore
    const user = useSelector((state) => state.user)
    const currentMonth = format(new Date(), "MMMM")
    const currentYear = format(new Date(), "yyyy")
    const dataDownloadLink = `/electric/${user.country.isoCode}/${user.email}/download-data`

    useEffect(() => {
        fetchIntroMessagesCount(user.uid)
        fetchIntroEmailsCount(user.uid)
        fetchDisconnectionCount(user.uid)
        fetchDisconnectionEmailCount(user.uid)
        fetchEmergencyOutagesEmailCount(user.uid)
        fetchPlannedOutagesEmailCount(user.uid)
        fetchPlannedOutagesCount(user.uid)
        fetchEmergencyOutagesCount(user.uid)
    }, [])

    const handleGoBackToActivitySummary = (evt) => {
        evt.preventDefault()
        setSelectedTab("Activity Summary")
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center">
                <div className="pr-2">
                    <img src="/images/introductory_icon.svg" width={48} />
                </div>
                <h5>Introductory Messages</h5>
            </div>

            <div className="d-flex flex-row align-items-start pb-4">
                <StatsDetailBox title={"Introductory Messages"}>
                    {(selectedTab) => {
                        if (selectedTab === "SMS") {
                            return (
                                <>
                                    <div>
                                        {introMessagesCountDetail.year} introductory
                                        messages sent in {currentYear}
                                    </div>
                                    <div>
                                        {introMessagesCountDetail.month} introductory
                                        messages sent in {currentMonth}
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div>
                                        {introEmailsCountDetail.year} introductory
                                        messages sent in {currentYear}
                                    </div>
                                    <div>
                                        {introEmailsCountDetail.month} introductory
                                        messages sent in {currentMonth}
                                    </div>
                                </>
                            )
                        }
                    }}
                </StatsDetailBox>
            </div>
            <div className="d-flex flex-row align-items-center">
                <div className="pr-2">
                    <img src="/images/utility_pole.svg" width={48} />
                </div>
                <h5>Planned Outage Alerts</h5>
            </div>

            <div className="d-flex flex-row align-items-start pb-4">
                <StatsDetailBox title={"Planned Outage Alerts"}>
                    {(selectedTab) => {
                        if (selectedTab === "SMS") {
                            return (
                                <>
                                    <div>
                                        {plannedOutagesCountDetail.year} planned outage
                                        alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {plannedOutagesCountDetail.month} planned outage
                                        alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div>
                                        {plannedOutagesEmailCountDetail.year} planned
                                        outage alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {plannedOutagesEmailCountDetail.month} planned
                                        outage alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        }
                    }}
                </StatsDetailBox>
            </div>

            <div className="d-flex flex-row align-items-center">
                <div className="pr-2">
                    <img src="/images/electricity_sign.svg" width={48} />
                </div>
                <h5>Disconnection Alerts</h5>
            </div>

            <div className="d-flex flex-row align-items-start pb-4">
                <StatsDetailBox title={"Disconnection Alerts"}>
                    {(selectedTab) => {
                        if (selectedTab === "SMS") {
                            return (
                                <>
                                    <div>
                                        {disconnectionCountDetail.year} disconnection
                                        alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {disconnectionCountDetail.month} disconnection
                                        alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div>
                                        {disconnectionEmailCountDetail.year} disconnection
                                        alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {disconnectionEmailCountDetail.month}{" "}
                                        disconnection alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        }
                    }}
                </StatsDetailBox>
            </div>

            <div className="d-flex flex-row align-items-center">
                <div className="pr-2">
                    <img src="/images/broken_cable.svg" width={48} />
                </div>
                <h5>Emergency Alerts</h5>
            </div>

            <div className="d-flex flex-row align-items-start pb-4">
                <StatsDetailBox title={"Emergency Alerts"}>
                    {(selectedTab) => {
                        if (selectedTab === "SMS") {
                            return (
                                <>
                                    <div>
                                        {emergencyOutagesCountDetail.year} emergency
                                        alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {emergencyOutagesCountDetail.month} emergency
                                        alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div>
                                        {emergencyOutagesEmailCountDetail.year} emergency
                                        alerts sent in {currentYear}
                                    </div>
                                    <div>
                                        {emergencyOutagesEmailCountDetail.month} emergency
                                        alerts sent in {currentMonth}
                                    </div>
                                </>
                            )
                        }
                    }}
                </StatsDetailBox>
            </div>
            <div className="d-flex flex-row justify-content-center">
                <Link
                    to={dataDownloadLink}
                    style={{ textDecoration: "underline", color: "black" }}
                >
                    Download Data
                </Link>
            </div>
        </div>
    )
})
