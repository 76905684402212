import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, CustomInput, Label, Input } from 'reactstrap';
import Header from '../components/Header'; 
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { goBack as goBackAction} from 'connected-react-router'
import config from '../config'
import Logout from '../components/Logout'

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  setAlert,
  saveAlert,  
  setSettings as setSettingsAction } from '../actions'
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import TokenExpired from '../components/TokenExpired';

const right = (<div>Logout</div>)
const CreditFinanceSettings = (props) =>  {

    const email = useSelector(state => state.router.location.pathname.split("/")[3])

    useEffect(() => {      
      fetchUser(email)    
    }, [email]);

    
    useEffect(() => {
      fetchSettings(user ? user.uid : null)
    }, [user])

    const [logoImage, setLogoImage] = useState(null)
    const [imageUploadStatus, setImageUploadStatus] = useState(null)

    const user = useSelector(state => state.user)
    const settings = useSelector(state => state.settings)
    
    const currentPath = useSelector(state => state.router.location.pathname)

    const dispatch = useDispatch()
    const setSettings = (user, values) => dispatch(setSettingsAction(user, values))
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const fetchSettings = (uid) => dispatch(fetchSettingsAction(uid))
    const uploadImage = (user, data) => dispatch(uploadImageAction(user, data))
    const goBack = () => dispatch(goBackAction())

    const resolveLogoImage = () => {
      //let { user } = props
      return logoImage || `${config.serverURL}/files/${user.logo}`
    }

    const handleFileChange = (event) => {
        if(event.target.files[0].type.indexOf("image") !== 0) {
          //this.setState({"imageUploadStatus": "invalid"})
          setImageUploadStatus("invalid")
          return
        }
    
        let image = URL.createObjectURL(event.target.files[0])        
        setLogoImage(image)
      
        let data = new FormData()
        data.append("user", JSON.stringify(user))
        data.append("file", event.target.files[0])
        uploadImage(user, data).then((res) => {            
            //this.setState({imageUploadStatus: res.value.data.success ? "uploaded" : "error"})
            
            setImageUploadStatus(res.value.data.success ? "uploaded" : "error")
            fetchUser(user.email)
        })
    }    

    const renderUI = (user, settings) => {
      return (
        <Container>
          <Header right={<Logout user={user} />}></Header>
          <Row className="justify-content-center">
              <Col sm={6} xs={12} className="">
              <h1>Settings</h1>
    
              <div className="pt-4 pb-1">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Link to={currentPath + "/countries-phone-numbers"}>Edit business countries & phone numbers</Link>
              </div>
    
              <div className="pt-1 pb-1">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Link to={currentPath + "/payment-reminders"}>Edit messages types</Link>
              </div>
    
              <div className="pt-1 pb-1">
                <FontAwesomeIcon className="mr-2" icon={faPencilAlt}></FontAwesomeIcon>
                <Link to={currentPath + "/messages"}>Edit messages</Link>
              </div>                    
    
              <CustomInput checked={settings.email || false} type="checkbox" id="check" name="check" label="Also send messages by email" onChange={evt => setSettings(user, {email: evt.target.checked})} />
    
              <h5 className="pt-2 pb-2">Edit company logo</h5>
              {logoImage || user.logo ? <div className="mb-2 pr-2"><img src={resolveLogoImage()} style={{width: 100}} /></div> : null }
              <Form inline className="pt-2 pb-2">            
                  
                  <FormGroup>
                      <Label>
                          <Input type="file" onChange={handleFileChange} />{' '}
                      </Label>
                  </FormGroup>                              
              </Form>          
    
              <div className="pt-1 pb-1">
                  <Button className="ml-4" color="link" onClick={() => {
                    
                    goBack()
                  }}>Back to dashboard</Button>
              </div>      
              
              </Col>                
          </Row>
          <div className="text-center">
              <Footer />
          </div>                
      </Container> 
      );    
    }    

    if(user && settings)
      return renderUI(user, settings)
    else 
      return <TokenExpired />
}

export default CreditFinanceSettings; 