import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    CustomInput,
    Label,
    Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { goBack as goBackAction } from "connected-react-router"
import Header from "../../../components/Header"

const links1 = [
    {
        title: "Outage Locations",
        link: "/electric-templates/template_locations.xlsx",
    },
]

const links2 = [
    {
        title: "Customers",
        link: "/electric-templates/template_electric_customer.xlsx",
    },
]

const links3 = [
    {
        title: "Planned Outage Alerts by Location",
        link: "/electric-templates/template_electric_plannedoutage_bylocation.xlsx",
    },
    {
        title: "Planned Outage Alerts by Feeder",
        link: "/electric-templates/template_electric_plannedoutage_byfeeder.xlsx",
    },
]

const links4 = [
    {
        title: "Disconnection Alerts by Location",
        link: "/electric-templates/template_electric_disconnections.xlsx",
    },
]

const allLinks = [
    { title: "LOCATIONS TEMPLATE", links: links1 },
    { title: "CUSTOMERS TEMPLATE", links: links2 },
    { title: "PLANNED OUTAGE TEMPLATES", links: links3 },
    { title: "DISCONNECTIONS TEMPLATE", links: links4 },
]

const LinkSection = ({ links, title }) => (
    <div className="mt-4">
        <h3>{title}</h3>
        {links.map((item, index) => (
            <div key={index} className="mt-2 d-flex flex-row align-items-center">
                <img src="/images/excel_icon.png" width={20} />

                <a className="pl-2" href={item.link}>
                    {item.title}
                </a>
            </div>
        ))}
    </div>
)

const DownloadTemplates = () => {
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())

    return (
        <Container>
            <Header />
            <Row className="justify-content-center">
                <Col sm={6} xs={12} className="">
                    <h2>Download Templates</h2>
                    <strong>Do not modify the headers of these files in any way!</strong>
                    <div className="mt-1 mb-1">
                        Click the template link to download it
                    </div>
                    {allLinks.map((item, index) => (
                        <LinkSection links={item.links} title={item.title} key={index} />
                    ))}

                    <div className="text-center pt-5">
                        <Button color="link" onClick={() => goBack()}>
                            Go back to dashboard
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default DownloadTemplates
