import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Select,
  Option,
} from "reactstrap";

import _ from "lodash";
import { setVaccinationMessagingServiceId } from "../../../actions";

let timeout;
let reminderTimeout;

export default function VaccinationSettingsForm({
  selectVaccines,
  user,
  settings,
  fetchUser,
  vaccines,
  setSettings,
  goBack,
  uploadImage,
}) {
  const [logoImage, setLogoImage] = React.useState();
  const [imageUploadStatus, setImageUploadStatus] = React.useState();
  const [reminder1, setReminder1] = React.useState(settings.vaxReminder1 || 40);
  const [reminder2, setReminder2] = React.useState(settings.vaxReminder2 || 20);
  const [reminder3, setReminder3] = React.useState(settings.vaxReminder3 || 6);
  const [reminder1Enabled, setReminder1Enabled] = React.useState(
    !_.isNull(settings.vaxReminder1)
  );
  const [reminder2Enabled, setReminder2Enabled] = React.useState(
    !_.isNull(settings.vaxReminder2)
  );
  const [reminder3Enabled, setReminder3Enabled] = React.useState(
    !(_.isNull(settings.vaxReminder3) || _.isUndefined(settings.vaxReminder3))
  );
  const [infoURLType, setInfoURLType] = React.useState(
    settings.infoURLType || ""
  );

  const [selectedVaccines, setSelectedVaccines] = React.useState({});
  console.log("settings", settings);
  React.useEffect(() => {
    let obj = {};
    if (reminder1Enabled) {
      obj["vaxReminder1"] = reminder1 ? parseInt(reminder1) : null;
    } else {
      obj["vaxReminder1"] = null;
    }

    if (reminder2Enabled) {
      obj["vaxReminder2"] = reminder2 ? parseInt(reminder2) : null;
    } else {
      obj["vaxReminder2"] = null;
    }

    if (reminder3Enabled) {
      obj["vaxReminder3"] = reminder3 ? parseInt(reminder3) : null;
    } else {
      obj["vaxReminder3"] = null;
    }

    console.log("settings ", obj);
    setSettings(user, obj);
  }, [
    reminder1Enabled,
    reminder2Enabled,
    reminder3Enabled,
    reminder1,
    reminder2,
    reminder3,
  ]);

  React.useEffect(() => {
    if (infoURLType) {
      setSettings(user, { infoURLType });
    } else {
      setSettings(user, { infoURLType: null });
    }
  }, [infoURLType]);

  const updateReminders = () => {};

  const resolveLogoImage = () => {
    return logoImage || user.logo;
  };

  const handleFileChange = (event) => {
    if (event.target.files[0].type.indexOf("image") !== 0) {
      setImageUploadStatus("invalid");
      return;
    }

    setLogoImage(URL.createObjectURL(event.target.files[0]));

    let data = new FormData();
    data.append("user", JSON.stringify(user));
    data.append("file", event.target.files[0]);
    uploadImage(user, data).then((res) => {
      setImageUploadStatus(res.value.data.success ? "uploaded" : "error");
      if (user.category !== "vaccination") {
        fetchUser(user.email);
      }
    });
  };

  const handleVaccineSelection = (vaccine, evt) => {
    console.log("vaccine selection ", vaccine, " ", evt.target.checked);
    let currentSelection = { ...selectedVaccines };
    currentSelection[vaccine.id] = evt.target.checked;
    setSelectedVaccines(currentSelection);

    if (timeout) {
      console.log("timeout ", timeout);
      clearTimeout(timeout);
    } else {
      console.log("timeout ", timeout);
    }

    return setTimeout(() => {
      let keys = Object.keys(currentSelection);
      let values = Object.values(currentSelection);
      let merged = _.flatten(
        _.zipWith(keys, values, (key, value) => ({ id: key, selected: value }))
      );
      console.log("merged ", merged);
      selectVaccines(user.uid, merged);
    }, 1500);
  };

  React.useEffect(() => {
    let obj = {};
    for (const vaccine of vaccines) {
      obj[vaccine.id] = vaccine.selected;
    }
    setSelectedVaccines(obj);
  }, [vaccines]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={10} xs={12} className="">
          <h1>Settings</h1>

          <div>
            {imageUploadStatus && imageUploadStatus == "uploaded" ? (
              <Alert color="success">Image uploaded successfully</Alert>
            ) : null}{" "}
          </div>
          <div>
            {imageUploadStatus && imageUploadStatus == "error" ? (
              <Alert color="danger">Problem uploading image</Alert>
            ) : null}{" "}
          </div>
          <div>
            {imageUploadStatus && imageUploadStatus == "invalid" ? (
              <Alert color="danger">This is not a valid image</Alert>
            ) : null}{" "}
          </div>

          <h4 className="mt-4" style={{ fontWeight: "bold" }}>
            Upload your logo
          </h4>
          <div>
            (Use a high-resolution PNG with a transparent background for best
            results)
          </div>
          <Form inline className="pt-2 pb-2">
            {logoImage || user.logo ? (
              <div className="mb-2 pr-2">
                <img src={resolveLogoImage()} style={{ width: 100 }} />
              </div>
            ) : null}
            <FormGroup>
              <Label>
                <Input type="file" onChange={handleFileChange} />{" "}
              </Label>
            </FormGroup>
          </Form>

          <Form>
            <h4 className="mt-4" style={{ fontWeight: "bold" }}>
              Vaccines database
            </h4>
            {vaccines.map((vaccine) => (
              <FormGroup check key={vaccine.id}>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={selectedVaccines[vaccine.id] || false}
                    onChange={(evt) =>
                      (timeout = handleVaccineSelection(vaccine, evt))
                    }
                  />
                  {vaccine.name}
                </Label>
              </FormGroup>
            ))}
          </Form>

          <h4 className="mt-4" style={{ fontWeight: "bold" }}>
            Reminders
          </h4>

          <Form inline>
            <FormGroup>
              <Label>
                <Input
                  type="checkbox"
                  checked={reminder1Enabled}
                  onChange={(evt) => setReminder1Enabled(evt.target.checked)}
                />
                Reminder
                <Input
                  type="select"
                  value={reminder1}
                  className="ml-2 mr-2"
                  disabled={!reminder1Enabled}
                  onChange={(evt) => setReminder1(evt.target.value)}
                >
                  {_.range(21, 41)
                    .reverse()
                    .map((n) => (
                      <option key={n} value={n}>
                        {n}
                      </option>
                    ))}
                </Input>
                <span>days prior to appointment</span>
              </Label>
            </FormGroup>
          </Form>

          <Form inline>
            <FormGroup>
              <Label>
                <Input
                  type="checkbox"
                  checked={reminder2Enabled}
                  onChange={(evt) => setReminder2Enabled(evt.target.checked)}
                />
                Reminder
                <Input
                  value={reminder2}
                  type="select"
                  className="ml-2 mr-2"
                  disabled={!reminder2Enabled}
                  onChange={(evt) => setReminder2(evt.target.value)}
                >
                  {_.range(7, 21)
                    .reverse()
                    .map((n) => (
                      <option key={n} value={n}>
                        {n}
                      </option>
                    ))}
                </Input>
                <span>days prior to appointment</span>
              </Label>
            </FormGroup>
          </Form>

          <Form inline>
            <FormGroup>
              <Label>
                <Input
                  type="checkbox"
                  checked={reminder3Enabled}
                  onChange={(evt) => setReminder3Enabled(evt.target.checked)}
                />
                Reminder
                <Input
                  value={reminder3}
                  type="select"
                  className="ml-2 mr-2"
                  disabled={!reminder3Enabled}
                  onChange={(evt) => setReminder3(evt.target.value)}
                >
                  {_.range(1, 7)
                    .reverse()
                    .map((n) => (
                      <option key={n} value={n}>
                        {n}
                      </option>
                    ))}
                </Input>
                <span>days prior to appointment</span>
              </Label>
            </FormGroup>
          </Form>

          <h4 className="mt-4" style={{ fontWeight: "bold" }}>
            URL for info
          </h4>

          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  value=""
                  checked={infoURLType === "ministryWebsite"}
                  onChange={() => setInfoURLType("ministryWebsite")}
                />
                Main website
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={infoURLType === "ministryWebsiteShortURL"}
                  onChange={() => setInfoURLType("ministryWebsiteShortURL")}
                />
                Main website short URL
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={infoURLType === "vaccinationWebsite"}
                  onChange={() => setInfoURLType("vaccinationWebsite")}
                />
                Vax or info website
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={infoURLType === "vaccinationWebsiteShortURL"}
                  onChange={() => setInfoURLType("vaccinationWebsiteShortURL")}
                />
                Vax or info website short URL
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={infoURLType === "facebook"}
                  onChange={() => setInfoURLType("facebook")}
                />
                Facebook
              </Label>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={infoURLType === "instagram"}
                  onChange={() => setInfoURLType("instagram")}
                />
                Instagram
              </Label>
            </FormGroup>
          </Form>

          <Form inline>
            <FormGroup>
              <Input
                id="emailCheck"
                type="checkbox"
                checked={settings.email || false}
                onChange={(evt) => {
                  if (evt.target.checked) {
                    setSettings(user, { email: evt.target.checked });
                  } else {
                    setSettings(user, { email: false });
                  }
                }}
              />
              <Label check className="p-2" for="emailCheck">
                Also send message by email
              </Label>
            </FormGroup>
          </Form>

          <Alert color="info">All settings are saved automatically</Alert>
          <div className="text-center">
            <Button onClick={goBack} color="link" style={{ cursor: "pointer" }}>
              Go back to your dashboard
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
