import React, { Component } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Alert,
    Input,
    Label,
    Form,
    FormGroup,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell, faCog, faUserPlus, faWrench } from "@fortawesome/free-solid-svg-icons"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { useToasts } from "react-toast-notifications"

import {
    fetchStats,
    logout as logoutAction,
    setUserActive,
    setBloodBankUserActive,
    fetchVaccinationStats,
    setLogoDisplay,
    getLogoDisplay,
    fetchBloodBankUser,
    fetchVaccinationOrganizationMembers,
    fetchNoShows,
    setBloodBankMessagingServiceId as persistMessagingServiceId,
    setSubAccountSid as persistSubAccountSid,
} from "../../../actions"
import _, { debounce } from "lodash"
//import StatsBox from "../components/StatsBox"
import bloodBankRepository from "../lib/bloodbankRepository"

let timeout

export default function Dashboard() {
    const [messagingServiceId, setMessagingServiceId] = React.useState("")
    const [subAccountSid, setSubAccountSid] = React.useState("")
    const [token, setToken] = React.useState()

    const dispatch = useDispatch()
    const {
        user,
        admin,
        router,
        logoDisplay,
        vaccinationOrganizationMembers,
        vaccinationStats,
        vaccinationNoShows,
    } = useSelector((state) => state)

    const { addToast } = useToasts()

    const fetchOrganizationMembers = (accountId) =>
        dispatch(fetchVaccinationOrganizationMembers(accountId))

    React.useEffect(() => {
        if (user) {
            //fetchOrganizationMembers(user.uid)
            setMessagingServiceId(user.messagingServiceId || "")
            setSubAccountSid(user.subAccountSid || "")
            dispatch(getLogoDisplay(user))
            //dispatch(fetchVaccinationStats(user.uid))
            //dispatch(fetchNoShows(user.uid))
        }
    }, [user])

    React.useEffect(() => {
        setToken(localStorage.getItem("token"))
    }, [])

    React.useEffect(() => {
        if (admin) {
            const email = _.last(router.location.pathname.split("/"))
            dispatch(fetchBloodBankUser(email))
        }
    }, [admin])

    const selectCountry = (evt) => {
        const code = evt.target.value
        dispatch(push(router.location.pathname + "/" + code))
    }

    const logout = (evt) => {
        dispatch(logoutAction()).then(() => {
            dispatch(push("/"))
        })
    }

    const toggleActivation = (evt) => {
        const proceed = window.confirm("Proceed with this operation?")

        if (proceed) {
            dispatch(setBloodBankUserActive(user, !user.active))
        }
    }

    const getVerifiedMembers = () => {
        return vaccinationOrganizationMembers.filter(
            (item) => item.invitationStatus === "accepted"
        )
    }

    const hasLogoData = () => {
        return logoDisplay.data !== undefined && logoDisplay.data !== null
    }

    const handleMessagingServiceIdChange = async (evt) => {
        evt.persist()

        setMessagingServiceId(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistMessagingServiceId(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting Messaging Service Id: " + e.message)
            }
        }, 1000)
    }

    const handleSubAccountChange = async (evt) => {
        evt.persist()

        setSubAccountSid(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistSubAccountSid(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting SubAccount SID: " + e.message)
            }
        }, 1000)
    }

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>
                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <a href="#" onClick={logout}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    } else if (!user) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Dashboard</h1>

                            <Alert color="info">
                                <Link to="/auth/login">Login</Link> required.
                            </Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    if (user && !user.active && !admin) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Dashboard</h1>

                            <Alert color="info">Your account is pending activation</Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    return (
        <div>
            <Header right={right} />
            <Container>
                <Row className="justify-content-center">
                    <Col md={10} xs={12} className="">
                        <h4>{user.organizationLongName}</h4>
                        {user ? (
                            <div className="d-flex justify-content-between align-items-center">
                                <h2>Dashboard</h2>
                                {!user.delegate ? (
                                    <div className="d-flex flex-column">
                                        <div>
                                            <Link
                                                to={`/${user.category}/${user.countryCode}/${user.email}/settings`}
                                            >
                                                <FontAwesomeIcon size="1x" icon={faCog} />{" "}
                                                Settings
                                            </Link>
                                        </div>
                                        <div>
                                            <Link
                                                to={`/${user.category}/${user.countryCode}/${user.email}/users`}
                                            >
                                                <FontAwesomeIcon
                                                    size="1x"
                                                    icon={faUserPlus}
                                                />{" "}
                                                {getVerifiedMembers().length} users
                                            </Link>
                                        </div>
                                        <div>
                                            <Link
                                                to={`/${user.category}/${user.countryCode}/${user.email}/admin-functions`}
                                            >
                                                <FontAwesomeIcon
                                                    size="1x"
                                                    icon={faWrench}
                                                />{" "}
                                                Admin
                                                <span
                                                    className="pl-2"
                                                    style={{
                                                        position: "relative",
                                                        top: "-4px",
                                                    }}
                                                >
                                                    {vaccinationNoShows.length > 0 ? (
                                                        <FontAwesomeIcon
                                                            color="#dc3545"
                                                            size="1x"
                                                            icon={faBell}
                                                        />
                                                    ) : null}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="d-flex justify-content-around mt-4">
                            {user ? (
                                <>
                                    <div className="mb-4 pl-4 pr-4"></div>

                                    <div className="mb-4 pl-4 pr-4"></div>
                                    {/*
                                    <Link
                                        className="ml-2"
                                        to={`/${user.category}/${user.countryCode}/${user.email}/no-shows`}
                                    >
                                        No-shows
                                    </Link> */}
                                </>
                            ) : null}
                        </div>

                        {admin ? (
                            <div className="d-flex flex-row justify-content-around">
                                <div className="d-flex align-items-center mb-4">
                                    <Button onClick={toggleActivation} color="success">
                                        {user && user.active
                                            ? "Deactivate Account"
                                            : "Activate Account"}
                                    </Button>
                                </div>
                            </div>
                        ) : null}

                        {admin ? (
                            <Form className="mt-2 mb-2">
                                <FormGroup check style={{ paddingLeft: 0 }}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={hasLogoData() || false}
                                            onChange={(evt) => {
                                                dispatch(
                                                    setLogoDisplay(
                                                        user,
                                                        evt.target.checked
                                                    )
                                                )
                                            }}
                                        />{" "}
                                        Enable logo display on landing page.
                                    </Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Messaging Service Id</Label>
                                    <Input
                                        value={messagingServiceId}
                                        placeholder="Messaging Service Id"
                                        onChange={handleMessagingServiceIdChange}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Subaccount SID</Label>
                                    <Input
                                        value={subAccountSid}
                                        placeholder="Subaccount SID"
                                        onChange={handleSubAccountChange}
                                    />
                                </FormGroup>
                            </Form>
                        ) : null}
                    </Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}
