import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { uniqueCustomers } from '../reducers';

let months = { 
    0: "January", 
    1: "February", 
    2: "March", 
    3: "April", 
    4: "May", 
    5: "June", 
    6: "July", 
    7: "August", 
    8: "September", 
    9: "October",
    10: "November",
    11: "December"
}; 

export default class Stats extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };
      }
    
      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }   

    render() {
        const { title, data, rate, emailData, uniqueCustomers } = this.props; 

        let currency = null; 

        if(rate) {
            currency = rate.currency === 'XCD' ? 'EC' : 'US'; 
        }

        const today = new Date(); 
        const year = today.getFullYear(); 
        const month = today.getMonth(); 

        return (
            <Row className="stats-panel">
                <Col sm={2} xs={12}>
                    <img src="/images/custom_message.svg" width="75" />
                </Col>

                <Col sm={10} xs={12}>
                <h4>{title}</h4>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                        >
                        Activity
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                        >
                        Emails
                        </NavLink>
                    </NavItem>                    
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                        >
                        $
                        </NavLink>
                    </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                        <Col sm="12">
                            <p>{uniqueCustomers || 0} Unique customers in database</p>
                            <p>{data[`${year}-${month}`] || 0} Reminders sent out in {months[month]}</p>
                            <p>{data[`${year}`] || 0} Reminders sent out in {year}</p>
                        </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <Col sm="12">
                            <p>{uniqueCustomers || 0} Unique customers in database</p>
                            <p>Current month usage to date: ${currency} {(rate.rate * (data[`${year}-${month}`] || 0)).toFixed(2) }</p>
                            <p>Current year usage to date: ${currency} {(rate.rate * (data[`${year}`] || 0)).toFixed(2) }</p>
                        </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="3">
                        <Row>
                        <Col sm="12">
                            <p>{uniqueCustomers || 0} Unique customers in database</p>
                            <p>{emailData[`${year}-${month}`] || 0} Reminders sent out in {months[month]}</p>
                            <p>{emailData[`${year}`] || 0} Reminders sent out in {year}</p>
                        </Col>
                        </Row>
                    </TabPane>                    
                    </TabContent>     
                    </Col>             
            </Row>
        )        
    }    
}