import { emergencyOutageStore } from "../../data/emergency-outage.store"

export const PagerNavigation = () => {
    const { previousStep, step } = emergencyOutageStore

    return (
        <div className="d-flex flex-column pt-5">
            {step > 0 ? (
                <div
                    onClick={previousStep}
                    style={{ cursor: "pointer", color: "#007bff" }}
                    className="align-items-center"
                >
                    <img src="/images/back_icon.png" width={20} />
                    <span className="ml-2">Back</span>
                </div>
            ) : null}
        </div>
    )
}
