import React, { Component, useEffect } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push as pushAction } from "react-router-redux"
import {
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    TextArea,
    Alert,
} from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import * as Yup from "yup"
import { useFormik } from "formik"

let RegistrationSchema = Yup.object({
    organizationLongName: Yup.string().required("Organization Long Name is required"),
    organizationShortName: Yup.string()
        .max(11, "Organization Short Name must be less than 11 characters")
        .required("Organization Short Name is required"),
    //covid19hotlineFlow: Yup.string().matches(/^\d+$/, "Not a valid phone number").length(7, "7 digit local number").required("Covid-19 FLOW Hotline is required"),
    //covid19hotlineDigicel: Yup.string().matches(/^\d+$/, "Not a valid phone number").length(7, "7 digit local number").required("Covid-19 Digicel Hotline is required"),
    ministryPhone: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .length(7, "7 digit local number")
        .required("Ministry main phone is required"),
    ministryWebsite: Yup.string()
        .url("Not a valid website")
        .required("Ministry main website is required"),
    //ministryWebsiteShortURL: Yup.string().url("Not a valid website"),
    //vaccinationWebsite: Yup.string().url("Not a valid website").required("Vaccination website is required"),
    //vaccinationWebsiteShortURL: Yup.string().url("Not a valid website"),
    address1: Yup.string().required("Address is required"),
    //address2: Yup.string().required("Address is required"),
    email: Yup.string().email("Not a valid email").required("Email is required"),
    //facebook: Yup.string().url("Not a vaid website").required("Facebook page URL is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
})

import {
    setRegistrationFields as setRegistrationFieldsAction,
    setRegistrationField as setRegistrationFieldAction,
    registerUser as registerUserAction,
} from "../../../actions"
import ReCAPTCHA from "react-google-recaptcha"

export default function HealthRegistration() {
    const [viewPassword, setViewPassword] = React.useState(false)
    const { registration } = useSelector((state) => state)
    const dispatch = useDispatch()

    const setRegistrationFields = (params) =>
        dispatch(setRegistrationFieldsAction(params))
    const setRegistrationField = (params) => dispatch(setRegistrationFieldAction(params))
    const registerUser = (info) => dispatch(registerUserAction(info))
    const push = (location) => dispatch(pushAction(location))

    useEffect(() => {
        if (!registration.country) {
            push("/")
        }
    }, [])

    const submitForm = (evt) => {
        evt.preventDefault()
        push("/auth/register/confirm")
    }

    const passwordsMatch = () => {
        const { password, confirmPassword } = registration

        if (password && confirmPassword) {
            return password === confirmPassword
        } else if (!password && !confirmPassword) {
            return true
        } else {
            return false
        }
    }

    const allFieldsFilled = () => {
        const {
            companyFullName,
            companyShortName,
            address1,
            address2,
            email,
            password,
            confirmPassword,
            ministryPhone,
        } = registration

        return (
            companyFullName &&
            companyFullName.length > 0 &&
            companyShortName &&
            companyShortName.length > 0 &&
            address1 &&
            address1.length > 0 &&
            address2 &&
            address2.length > 0 &&
            ministryPhone &&
            ministryPhone.length > 0 &&
            email &&
            email.length > 0 &&
            password === confirmPassword &&
            password &&
            password.length > 0 &&
            registration["g-recaptcha-response"] &&
            registration["g-recaptcha-response"].length > 0
        )
    }

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword)
    }

    const getPasswordInputType = () => {
        if (viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    const getEyeIcon = () => {
        if (viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }
    const country = registration?.country ? registration.country.toLowerCase() : ""

    const flagUrl = `/flag-icon-css/flags/4x3/${country}.svg`

    const formik = useFormik({
        initialValues: {
            ...registration,
        },
        onSubmit: (values) => {
            delete values.confirmPassword
            setRegistrationFields(values)
            push("/auth/vaccination/register/confirm")
        },
        validationSchema: RegistrationSchema,
    })

    return (
        <div className="">
            <Header right={<div>Ministry of Health</div>} />
            <Container>
                <Row className="justify-content-md-center justify-content-center p-2">
                    <Col md={4} xs={12}>
                        <div className="d-flex flex-column">
                            <h1>Registration</h1>
                            <div>Fields labeled * are required</div>
                        </div>

                        <Form onSubmit={formik.handleSubmit} className="mt-4">
                            <FormGroup>
                                <Label for="organizationLongName">
                                    Organization Long Name *
                                </Label>
                                {formik.touched.organizationLongName &&
                                    formik.errors.organizationLongName && (
                                        <Alert color="danger">
                                            {formik.errors.organizationLongName}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.organizationLongName}
                                    type="text"
                                    placeholder="Organization Long Name"
                                    onChange={formik.handleChange("organizationLongName")}
                                    onBlur={formik.handleBlur("organizationLongName")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="companyShortName">
                                    Organization Short Name *
                                </Label>
                                {formik.touched.organizationShortName &&
                                    formik.errors.organizationShortName && (
                                        <Alert color="danger">
                                            {formik.errors.organizationShortName}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.organizationShortName}
                                    type="text"
                                    placeholder="Organization Short Name"
                                    onChange={formik.handleChange(
                                        "organizationShortName"
                                    )}
                                    onBlur={formik.handleBlur("organizationShortName")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address1">Address Line 1 *</Label>
                                {formik.touched.address1 && formik.errors.address1 && (
                                    <Alert color="danger">{formik.errors.address1}</Alert>
                                )}
                                <Input
                                    value={formik.values.address1}
                                    type="text"
                                    placeholder="Address Line 1"
                                    onChange={formik.handleChange("address1")}
                                    onBlur={formik.handleBlur("address1")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address1">Address Line 2</Label>
                                {formik.touched.address2 && formik.errors.address2 && (
                                    <Alert color="danger">{formik.errors.address2}</Alert>
                                )}
                                <Input
                                    value={formik.values.address2}
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={formik.handleChange("address2")}
                                    onBlur={formik.handleBlur("address2")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="covid19hotlineDigicel">
                                    Covid-19 Hotline DIGICEL
                                </Label>
                                {formik.touched.covid19hotlineDigicel &&
                                    formik.errors.covid19hotlineDigicel && (
                                        <Alert color="danger">
                                            {formik.errors.covid19hotlineDigicel}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.covid19hotlineDigicel}
                                    type="number"
                                    placeholder="Local 7-digit number"
                                    onChange={formik.handleChange(
                                        "covid19hotlineDigicel"
                                    )}
                                    onBlur={formik.handleBlur("covid19hotlineDigicel")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="covid19hotlineFlow">
                                    Covid-19 Hotline FLOW
                                </Label>
                                {formik.touched.covid19hotlineFlow &&
                                    formik.errors.covid19hotlineFlow && (
                                        <Alert color="danger">
                                            {formik.errors.covid19hotlineFlow}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.covid19hotlineFlow}
                                    type="number"
                                    placeholder="Local 7-digit number"
                                    onChange={formik.handleChange("covid19hotlineFlow")}
                                    onBlur={formik.handleBlur("covid19hotlineFlow")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="covid19hotlineOther">
                                    Covid-19 Hotline Other
                                </Label>
                                {formik.touched.covid19hotlineOther &&
                                    formik.errors.covid19hotlineOther && (
                                        <Alert color="danger">
                                            {formik.errors.covid19hotlineOther}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.covid19hotlineOther}
                                    type="number"
                                    placeholder="Local 7-digit number"
                                    onChange={formik.handleChange("covid19hotlineOther")}
                                    onBlur={formik.handleBlur("covid19hotlineOther")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Ministry main number *</Label>
                                {formik.touched.ministryPhone &&
                                    formik.errors.ministryPhone && (
                                        <Alert color="danger">
                                            {formik.errors.ministryPhone}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.ministryPhone}
                                    type="number"
                                    placeholder="Local 7-digit number"
                                    onChange={formik.handleChange("ministryPhone")}
                                    onBlur={formik.handleBlur("ministryPhone")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Ministry main <strong>website *</strong>
                                </Label>
                                {formik.touched.ministryWebsite &&
                                    formik.errors.ministryWebsite && (
                                        <Alert color="danger">
                                            {formik.errors.ministryWebsite}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.ministryWebsite}
                                    type="text"
                                    placeholder="Ministry main website"
                                    onChange={formik.handleChange("ministryWebsite")}
                                    onBlur={formik.handleBlur("ministryWebsite")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Ministry <strong>website</strong> short URL (optional)
                                </Label>
                                {formik.touched.ministryWebsiteShortURL &&
                                    formik.errors.ministryWebsiteShortURL && (
                                        <Alert color="danger">
                                            {formik.errors.ministryWebsiteShortURL}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.ministryWebsiteShortURL}
                                    type="text"
                                    placeholder="Ministry main website Short URL"
                                    onChange={formik.handleChange(
                                        "ministryWebsiteShortURL"
                                    )}
                                    onBlur={formik.handleBlur("ministryWebsiteShortURL")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Vaccination or info <strong>website</strong>
                                </Label>
                                {formik.touched.vaccinationWebsite &&
                                    formik.errors.vaccinationWebsite && (
                                        <Alert color="danger">
                                            {formik.errors.vaccinationWebsite}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.vaccinationWebsite}
                                    type="text"
                                    placeholder="Vaccination Website"
                                    onChange={formik.handleChange("vaccinationWebsite")}
                                    onBlur={formik.handleBlur("vaccinationWebsite")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Vax <strong>website</strong> short URL (optional)
                                </Label>
                                {formik.touched.vaccinationWebsiteShortURL &&
                                    formik.errors.vaccinationWebsiteShortURL && (
                                        <Alert color="danger">
                                            {formik.errors.vaccinationWebsiteShortURL}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.vaccinationWebsiteShortURL}
                                    type="text"
                                    placeholder="Vax Website short URL"
                                    onChange={formik.handleChange(
                                        "vaccinationWebsiteShortURL"
                                    )}
                                    onBlur={formik.handleBlur(
                                        "vaccinationWebsiteShortURL"
                                    )}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="facebook">Facebook</Label>
                                {formik.touched.facebook && formik.errors.facebook && (
                                    <Alert color="danger">{formik.errors.facebook}</Alert>
                                )}
                                <Input
                                    value={formik.values.facebook}
                                    type="text"
                                    placeholder="Facebook"
                                    onChange={formik.handleChange("facebook")}
                                    onBlur={formik.handleBlur("facebook")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="instagram">Instagram (optional)</Label>
                                {formik.touched.instagram && formik.errors.instagram && (
                                    <Alert color="danger">
                                        {formik.errors.instagram}
                                    </Alert>
                                )}
                                <Input
                                    value={formik.values.instagram}
                                    type="text"
                                    placeholder="Instagram"
                                    onChange={formik.handleChange("instagram")}
                                    onBlur={formik.handleBlur("instagram")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email *</Label>
                                {formik.touched.email && formik.errors.email && (
                                    <Alert color="danger">{formik.errors.email}</Alert>
                                )}
                                <Input
                                    value={formik.values.email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={formik.handleChange("email")}
                                    onBlur={formik.handleBlur("email")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                {formik.touched.password && formik.errors.password && (
                                    <Alert color="danger">{formik.errors.password}</Alert>
                                )}

                                <InputGroup>
                                    <Input
                                        required
                                        type={getPasswordInputType()}
                                        name="password"
                                        placeholder="Password"
                                        onChange={formik.handleChange("password")}
                                        onBlur={formik.handleBlur("password")}
                                    />
                                    <InputGroupAddon
                                        className="view-password"
                                        addonType="append"
                                        onClick={toggleViewPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={getEyeIcon()}
                                        ></FontAwesomeIcon>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label>Confirm Password</Label>
                                {formik.touched.confirmPassword &&
                                    formik.errors.confirmPassword && (
                                        <Alert color="danger">
                                            {formik.errors.confirmPassword}
                                        </Alert>
                                    )}

                                <InputGroup>
                                    <Input
                                        required
                                        type={getPasswordInputType()}
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        onChange={formik.handleChange("confirmPassword")}
                                        onBlur={formik.handleBlur("confirmPassword")}
                                    />
                                    <InputGroupAddon
                                        className="view-password"
                                        addonType="append"
                                        onClick={toggleViewPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={getEyeIcon()}
                                        ></FontAwesomeIcon>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>

                            <ReCAPTCHA
                                className="captcha"
                                ref={(e) => {
                                    console.log("reCaptcha : ", e)
                                }}
                                sitekey="6LcfRjsUAAAAAOF5AgszQeZRBqtfNZVhf9MnU95h"
                                onChange={(response) => {
                                    console.log("captcha response", response)
                                    if (response) {
                                        formik.setFieldValue(
                                            "g-recaptcha-response",
                                            response
                                        )
                                        //setRegistrationField({ 'g-recaptcha-response': response })
                                    }
                                }}
                            />

                            <div className="text-center p-4">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={!formik.isValid}
                                >
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}
