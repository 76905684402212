import React from "react"

function showPopup(url) {
    let top = window.innerHeight / 2 - 150
    let left = window.innerWidth / 2 - 300
    let newwindow = window.open(
        url,
        "_blank",
        `location=no,left=${left},top=${top},height=300,width=600`
    )
    if (window.focus) {
        newwindow.focus()
    }
    return true
}

const IntroVideo = () => (
    <div className="d-flex flex-row justify-content-start justify-content-lg-start mt-2 mb-4">
        <div>
            <img
                src="/images/video_button.svg"
                height="40"
                onClick={() => showPopup("https://static.yestextme.com/video.mp4")}
                style={{ marginLeft: "3px" }}
            />
        </div>
        <div className="pl-2" style={{ textAlign: "left" }}>
            <h6
                className="m-0"
                onClick={() => showPopup("https://static.yestextme.com/video.mp4")}
                style={{ width: 150 }}
            >
                Which customer do{" "}
                <span className="font-weight-bold font-italic">you</span> want? (video)
            </h6>
        </div>
    </div>
)

export default IntroVideo
