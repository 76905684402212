import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"

import _ from "lodash"
import config from "../config"

export default class SettingsForm extends Component {
    resolveLogoImage = () => {
        let { user } = this.props
        return user.logo
    }

    constructor(props) {
        super(props)

        let { settings } = this.props
        this.state = {
            imageUploadStatus: null,
            reminder1: settings.reminder1 || null,
            reminder2: settings.reminder2 || null,
            reminder3: settings.reminder3 || null,
        }
    }

    handleFileChange = (event) => {
        if (event.target.files[0].type.indexOf("image") !== 0) {
            this.setState({ imageUploadStatus: "invalid" })
            return
        }

        this.logoImage = URL.createObjectURL(event.target.files[0])
        this.setState({ logoImage: this.logoImage })

        let data = new FormData()
        data.append("user", JSON.stringify(this.props.user))
        data.append("file", event.target.files[0])
        this.props.uploadImage(this.props.user, data).then((res) => {
            this.setState({
                imageUploadStatus: res.value.data.success ? "uploaded" : "error",
            })
            this.props.fetchUser(this.props.user.email)
        })
    }

    handleReminderUploadOption = (event) => {
        if (event.target.value !== "set-reminder") {
            this.props.setSettings(this.props.user, {
                uploadReminderInterval: event.target.value,
            })
        }
    }

    render() {
        const { user, settings } = this.props
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col sm={6} xs={12} className="">
                        <h1>Settings</h1>
                        <Form>
                            <FormGroup tag="fieldset">
                                <legend>Select message content</legend>
                                <FormGroup>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings && settings.message === "default"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    message: "default",
                                                })
                                            }
                                        />{" "}
                                        <strong>Default message</strong>: A free reminder
                                        from {user.companyShortName} that your
                                        &lt;type&gt; insurance expires &lt;day date month
                                        year&gt;. The premium is $xxx. Please disregard
                                        this reminder if already paid.
                                    </Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings &&
                                                settings.message === "without-premium"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    message: "without-premium",
                                                })
                                            }
                                        />{" "}
                                        <strong>Message without premium amount</strong>: A
                                        free reminder from {user.companyShortName} that
                                        your &lt;type&gt; insurance expires &lt;day date
                                        month year&gt;. Please disregard this reminder if
                                        already paid.
                                    </Label>
                                </FormGroup>

                                <FormGroup>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="radio1"
                                            checked={
                                                settings.message ===
                                                "without-premium-with-phone"
                                            }
                                            onChange={(evt) =>
                                                this.props.setSettings(user, {
                                                    message: "without-premium-with-phone",
                                                })
                                            }
                                        />{" "}
                                        <strong>
                                            Message without premium amount with a phone
                                            number
                                        </strong>
                                        : A free reminder from {user.companyShortName}{" "}
                                        that your &lt;type&gt; insurance expires &lt;day
                                        date month year&gt;. Pls call {user.phone} to
                                        confirm premium; disregard this msg if already
                                        paid.
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup tag="fieldset">
                                <legend>
                                    Select message schedule: (intro message included by
                                    default)
                                </legend>
                            </FormGroup>
                        </Form>

                        <Form inline>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    ref={(input) => (this.reminderCheck1 = input)}
                                    checked={settings.reminder1 ? true : false}
                                    onChange={(evt) => {
                                        if (evt.target.checked) {
                                            this.props.setSettings(user, {
                                                reminder1: parseInt(
                                                    this.reminderInput1.props.value
                                                ),
                                            })
                                        } else {
                                            this.props.setSettings(user, {
                                                reminder1: null,
                                            })
                                        }
                                    }}
                                />
                                <Label check className="p-2">
                                    Reminder
                                </Label>

                                <Input
                                    value={
                                        this.state.reminder1 || settings.reminder1 || 50
                                    }
                                    ref={(input) => (this.reminderInput1 = input)}
                                    type="select"
                                    onChange={(evt) => {
                                        this.setState({ reminder1: evt.target.value })
                                        if (this.reminderCheck1.props.checked) {
                                            this.props.setSettings(user, {
                                                reminder1: parseInt(evt.target.value),
                                            })
                                        }
                                    }}
                                >
                                    {_.range(50, 25).map((index) => (
                                        <option key={index} value={index}>
                                            {index}
                                        </option>
                                    ))}
                                </Input>

                                <div className="pl-2">days prior to expiry</div>
                            </FormGroup>
                        </Form>

                        <Form inline>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    ref={(input) => (this.reminderCheck2 = input)}
                                    checked={settings.reminder2 ? true : false}
                                    onChange={(evt) => {
                                        if (evt.target.checked) {
                                            this.props.setSettings(user, {
                                                reminder2: parseInt(
                                                    this.reminderInput2.props.value
                                                ),
                                            })
                                        } else {
                                            this.props.setSettings(user, {
                                                reminder2: null,
                                            })
                                        }
                                    }}
                                />
                                <Label check className="p-2">
                                    Reminder
                                </Label>

                                <Input
                                    value={
                                        this.state.reminder2 || settings.reminder2 || 25
                                    }
                                    type="select"
                                    ref={(input) => (this.reminderInput2 = input)}
                                    onChange={(evt) => {
                                        this.setState({ reminder2: evt.target.value })
                                        if (this.reminderCheck2.props.checked) {
                                            this.props.setSettings(user, {
                                                reminder2: parseInt(evt.target.value),
                                            })
                                        }
                                    }}
                                >
                                    {_.range(25, 7).map((index) => (
                                        <option key={index}>{index}</option>
                                    ))}
                                </Input>

                                <div className="pl-2">days prior to expiry</div>
                            </FormGroup>
                        </Form>

                        <Form inline>
                            <FormGroup>
                                <Input
                                    type="checkbox"
                                    ref={(input) => (this.reminderCheck3 = input)}
                                    checked={settings.reminder3 ? true : false}
                                    onChange={(evt) => {
                                        if (evt.target.checked) {
                                            this.props.setSettings(user, {
                                                reminder3: parseInt(
                                                    this.reminderInput3.props.value
                                                ),
                                            })
                                        } else {
                                            this.props.setSettings(user, {
                                                reminder3: null,
                                            })
                                        }
                                    }}
                                />
                                <Label check className="p-2">
                                    Reminder
                                </Label>

                                <Input
                                    value={
                                        this.state.reminder3 || settings.reminder3 || 7
                                    }
                                    type="select"
                                    name="select"
                                    ref={(input) => (this.reminderInput3 = input)}
                                    onChange={(evt) => {
                                        this.setState({ reminder3: evt.target.value })
                                        if (this.reminderCheck3.props.checked) {
                                            this.props.setSettings(user, {
                                                reminder3: parseInt(evt.target.value),
                                            })
                                        }
                                    }}
                                >
                                    {_.range(7, 0).map((index) => (
                                        <option key={index}>{index}</option>
                                    ))}
                                </Input>

                                <div className="pl-2">days prior to expiry</div>
                            </FormGroup>
                        </Form>

                        <Form inline>
                            <FormGroup>
                                <Input
                                    id="emailCheck"
                                    type="checkbox"
                                    ref={(input) => (this.emailCheck = input)}
                                    checked={settings.email || false}
                                    onChange={(evt) => {
                                        if (evt.target.checked) {
                                            this.props.setSettings(user, {
                                                email: evt.target.checked,
                                            })
                                        } else {
                                            this.props.setSettings(user, { email: false })
                                        }
                                    }}
                                />
                                <Label check className="p-2" for="emailCheck">
                                    Also send message by email
                                </Label>
                            </FormGroup>
                        </Form>

                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "uploaded" ? (
                                <Alert color="success">Image uploaded successfully</Alert>
                            ) : null}{" "}
                        </div>
                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "error" ? (
                                <Alert color="danger">Problem uploading image</Alert>
                            ) : null}{" "}
                        </div>
                        <div>
                            {this.state.imageUploadStatus &&
                            this.state.imageUploadStatus == "invalid" ? (
                                <Alert color="danger">This is not a valid image</Alert>
                            ) : null}{" "}
                        </div>

                        <Form inline className="pt-5 pb-5">
                            {this.state.logoImage || this.props.user.logo ? (
                                <div className="mb-2 pr-2">
                                    <img
                                        src={this.resolveLogoImage()}
                                        style={{ width: 100 }}
                                    />
                                </div>
                            ) : null}
                            <FormGroup>
                                <Label>
                                    <Input
                                        type="file"
                                        name="logo"
                                        onChange={this.handleFileChange}
                                    />{" "}
                                    Upload logo
                                </Label>
                            </FormGroup>
                        </Form>

                        <Form inline>
                            <legend>Set an upload reminder to self</legend>
                            {/*<FormGroup>
                                <Input
                                    id="uploadReminderCheck"
                                    type="checkbox"
                                    ref={(input) => (this.uploadReminderCheck = input)}
                                    checked={settings.uploadReminder || false}
                                    onChange={(evt) => {
                                        if (evt.target.checked) {
                                            this.props.setSettings(user, {
                                                uploadReminder: evt.target.checked,
                                            })
                                        } else {
                                            this.props.setSettings(user, {
                                                uploadReminder: false,
                                            })
                                        }
                                    }}
                                />
                                <Label check className="p-2" for="uploadReminderCheck">
                                    Send me a reminder each month for new uploads
                                </Label>
                                  </FormGroup> */}
                        </Form>

                        {/*settings.uploadReminder ? (
                            <>
                                <h4>Send reminder on</h4>

                                <Form inline>
                                    <FormGroup
                                        style={{
                                            width: "100%",
                                            paddingBottom: 16,
                                            paddingTop: 0,
                                        }}
                                    >
                                        <Input
                                            style={{ width: "100%" }}
                                            type="select"
                                            onChange={this.handleReminderUploadOption}
                                            value={settings.uploadReminderInterval}
                                        >
                                            <option value="set-reminder">
                                                Set Reminder on the
                                            </option>
                                            <option value="first-day">
                                                First day of the month
                                            </option>
                                            <option value="first-monday">
                                                First Monday of the month
                                            </option>
                                        </Input>
                                    </FormGroup>
                                </Form>
                            </>
                                      ) : null */}

                        <Form>
                            <FormGroup tag="fieldset">
                                <h6>Send reminder on:</h6>
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onChange={(evt) => {
                                            if (evt.target.checked) {
                                                this.props.setSettings(user, {
                                                    uploadReminder: false,
                                                    uploadReminderInterval: null,
                                                })
                                            }
                                        }}
                                        checked={!settings.uploadReminder}
                                    />{" "}
                                    <Label check>No Reminder</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onChange={(evt) => {
                                            if (evt.target.checked) {
                                                this.props.setSettings(user, {
                                                    uploadReminder: true,
                                                    uploadReminderInterval: "first-day",
                                                })
                                            }
                                        }}
                                        checked={
                                            settings.uploadReminderInterval ===
                                            "first-day"
                                        }
                                    />{" "}
                                    <Label check>First day of the month</Label>
                                </FormGroup>
                                <FormGroup check disabled>
                                    <Input
                                        name="radio1"
                                        type="radio"
                                        onChange={(evt) => {
                                            if (evt.target.checked) {
                                                this.props.setSettings(user, {
                                                    uploadReminder: true,
                                                    uploadReminderInterval:
                                                        "first-monday",
                                                })
                                            }
                                        }}
                                        checked={
                                            settings.uploadReminderInterval ===
                                            "first-monday"
                                        }
                                    />{" "}
                                    <Label check>First Monday of the month</Label>
                                </FormGroup>
                            </FormGroup>
                        </Form>

                        <Alert color="info">All settings are saved automatically</Alert>
                        <div className="text-center">
                            <Button
                                onClick={this.props.goBack}
                                color="link"
                                style={{ cursor: "pointer" }}
                            >
                                Go back to your dashboard
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
