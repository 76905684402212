import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { push, goBack } from "react-router-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, Alert } from "reactstrap"
import Header from "./components/Header"
import Footer from "./components/Footer"

import {
    fetchUser,
    logout,
    uploadCustomers,
    uploadMultipleVehicleCustomers,
    uploadPropertyCustomers,
    uploadHealthCustomers,
    uploadBirthdayCustomers,
    uploadChristmasCustomers,
    uploadDivaliCustomers,
    uploadEidCustomers,
    fetchStats,
    fetchCustomMessageStats,
    fetchBirthdayStats,
    fetchPropertyStats,
    fetchHealthStats,
    fetchEmailStats,
    fetchDivaliStats,
    fetchEidStats,
    fetchChristmasStats,
    fetchUniqueCustomers,
    setMessage,
    resetMessage,
    sendMessage,
} from "./actions"

import UpdateDatabaseForm from "./components/UpdateDatabaseForm"
import _ from "lodash"

class AppContainer extends Component {
    constructor(props) {
        super(props)
    }

    logout(evt) {
        evt.preventDefault()
        this.props.logout().then(() => {
            this.props.dispatch(push("/"))
        })
    }

    render() {
        const { user } = this.props

        let right = null
        if (user) {
            right = (
                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user.email}
                        </div>
                        <div>
                            <a href="#" onClick={this.logout.bind(this)}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.country}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            )
        }

        if (user && !user.active) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    Your account is pending activation
                                </Alert>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else if (!user) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    <Link to="/auth/login">Login</Link> required.
                                </Alert>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }

        return (
            <div>
                <Header right={right} />
                <Container>
                    {this.props.user ? (
                        <UpdateDatabaseForm
                            user={this.props.user}
                            uploadCustomers={this.props.uploadCustomers}
                            uploadMultipleVehicleCustomers={
                                this.props.uploadMultipleVehicleCustomers
                            }
                            uploadPropertyCustomers={this.props.uploadPropertyCustomers}
                            uploadHealthCustomers={this.props.uploadHealthCustomers}
                            uploadBirthdayCustomers={this.props.uploadBirthdayCustomers}
                            uploadChristmasCustomers={this.props.uploadChristmasCustomers}
                            uploadDivaliCustomers={this.props.uploadDivaliCustomers}
                            uploadEidCustomers={this.props.uploadEidCustomers}
                            fetchEidStats={this.props.fetchEidStats}
                            fetchDivaliStats={this.props.fetchDivaliStats}
                            fetchStats={this.props.fetchStats}
                            fetchCustomMessageStats={this.props.fetchCustomMessageStats}
                            fetchPropertyStats={this.props.fetchPropertyStats}
                            fetchHealthStats={this.props.fetchHealthStats}
                            fetchChristmasStats={this.props.fetchChristmasStats}
                            fetchBirthdayStats={this.props.fetchBirthdayStats}
                            fetchEmailStats={this.props.fetchEmailStats}
                            onMessageChanged={this.props.setMessage}
                            sendMessage={this.props.sendMessage}
                            resetMessage={this.props.resetMessage}
                            uniqueCustomers={this.props.uniqueCustomers}
                            fetchUniqueCustomers={this.props.fetchUniqueCustomers}
                            message={this.props.message}
                            goBack={() => {
                                this.props.dispatch(goBack())
                            }}
                        />
                    ) : null}
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    settings: state.settings,
    message: state.message,
    uniqueCustomers: state.uniqueCustomers,
})

const mapDispatchToProps = (dispatch) => ({
    fetchUser: (email) => dispatch(fetchUser(email)),
    uploadCustomers: (user, data) => dispatch(uploadCustomers(user, data)),
    uploadMultipleVehicleCustomers: (user, data) =>
        dispatch(uploadMultipleVehicleCustomers(user, data)),
    uploadPropertyCustomers: (user, data) =>
        dispatch(uploadPropertyCustomers(user, data)),
    uploadHealthCustomers: (user, data) => dispatch(uploadHealthCustomers(user, data)),
    uploadBirthdayCustomers: (user, data) =>
        dispatch(uploadBirthdayCustomers(user, data)),
    uploadChristmasCustomers: (user, data) =>
        dispatch(uploadChristmasCustomers(user, data)),
    uploadDivaliCustomers: (user, data) => dispatch(uploadDivaliCustomers(user, data)),
    uploadEidCustomers: (user, data) => dispatch(uploadEidCustomers(user, data)),
    fetchStats: (user) => dispatch(fetchStats(user)),
    fetchCustomMessageStats: (user) => dispatch(fetchCustomMessageStats(user)),
    fetchBirthdayStats: (user) => dispatch(fetchBirthdayStats(user)),
    fetchPropertyStats: (user) => dispatch(fetchPropertyStats(user)),
    fetchHealthStats: (user) => dispatch(fetchHealthStats(user)),
    fetchChristmasStats: (user) => dispatch(fetchChristmasStats(user)),
    fetchDivaliStats: (user) => dispatch(fetchDivaliStats(user)),
    fetchEidStats: (user) => dispatch(fetchEidStats(user)),
    fetchEmailStats: (user, type) => dispatch(fetchEmailStats(user, type)),
    fetchUniqueCustomers: (user) => dispatch(fetchUniqueCustomers(user)),
    logout: () => dispatch(logout()),
    setMessage: (message) => dispatch(setMessage(message)),
    resetMessage: () => dispatch(resetMessage()),
    sendMessage: (user, message, sendEmail) =>
        dispatch(sendMessage(user, message, sendEmail)),
    dispatch,
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
