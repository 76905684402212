import ReactDOM from "react-dom"
import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import { Provider, useSelector } from "react-redux"
import { createBrowserHistory } from "history"
import { Route, browserHistory } from "react-router"
//import { ConnectedRouter, routerReducer, routerMiddleware, syncHistoryWithStore } from 'react-router-redux'
import { connectRouter, ConnectedRouter, routerMiddleware } from "connected-react-router"
import promiseMiddleware from "redux-promise-middleware"
import "./index.css"
import App from "./App"
import AboutIndividuals from "./AboutIndividuals"
import AboutOrganizations from "./AboutOrganizations"
import Category from "./Category"
import RateLimiterAdminCategory from "./features/rate-limiter/screens/AdminCategory"
import ElectricRegistration from "./features/electric/screens/Register"
import ElectricRegisterConfirm from "./features/electric/screens/RegisterConfirm"
import ElectricCategory from "./features/electric/screens/Category"
import ElectricCountry from "./features/electric/screens/Country"
import ElectricDashboard from "./features/electric/screens/Dashboard"
import ElectricAdminCategory from "./features/electric/screens/AdminCategory"
import ElectricSettings from "./features/electric/screens/ElectricSettings"
import ElectricDisconnectionAlerts from "./features/electric/screens/DisconnectionAlerts"
import ElectricPlannedOutageAlerts from "./features/electric/screens/PlannedOutageAlerts"
import ElectricEmergencyOutageAlerts from "./features/electric/screens/EmergencyAlerts"
import ElectricDownloadTemplates from "./features/electric/screens/DownloadTemplates"
import ElectricDownloadData from "./features/electric/screens/DownloadData"
import BloodBankCategory from "./features/bloodbank/screens/Category"
import BloodBankCountry from "./features/bloodbank/screens/Country"
import BloodBankRegister from "./features/bloodbank/screens/Register"
import BloodBankRegisterConfirm from "./features/bloodbank/screens/RegisterConfirm"
import BloodBankDashboard from "./features/bloodbank/screens/Dashboard"
import VaccinationCategory from "./features/vaccination/screens/Category"
import VaccinationCountry from "./features/vaccination/screens/Country"
import VaccinationRegister from "./features/vaccination/screens/Register"
import VaccinationRegisterConfirm from "./features/vaccination/screens/RegisterConfirm"
import VaccinationDashboard from "./features/vaccination/screens/Dashboard"
import VaccinationSettings from "./features/vaccination/screens/Settings"
import AdminFunctions from "./features/vaccination/screens/AdminFunctions"
import DownloadTemplate from "./features/vaccination/screens/DownloadTemplate"
import Dashboard from "./Dashboard"
import CreditFinanceDashboard from "./CreditFinance/Dashboard"
import Country from "./Country"
import Login from "./Login"
import Register from "./Register"
import RegisterConfirm from "./RegisterConfirm"
import Admin from "./Admin"
import BloodBankAdminCategory from "./features/bloodbank/screens/AdminCategory"
import AdminCategory from "./features/vaccination/screens/AdminCategory"
import AdminMessaging from "./AdminMessaging"
import Terms from "./Terms"
import Settings from "./Settings2"
import UpdateDatabase from "./UpdateDatabase"
import ResetPassword from "./ResetPassword"
import ChangePassword from "./ChangePassword"
import DownloadTemplates from "./DownloadTemplates"
import CreditFinanceRegistrationStart from "./CreditFinance/RegistrationStart"
import CreditFinanceRegistrationSelectCountry from "./CreditFinance/RegistrationSelectCountry"
import CreditFinanceRegistrationSelectCountriesOfOperation from "./CreditFinance/RegistrationSelectCountriesOfOperation"
import CreditFinanceRegistrationSelectCountriesOfOperationConfirmation from "./CreditFinance/RegistrationCountriesOfOperationConfirmation"
import CreditFinanceRegister from "./CreditFinance/Register"
import CreditFinanceRegisterConfirm from "./CreditFinance/RegisterConfirm"
import CreditFinanceSettings from "./CreditFinance/Settings"
import CreditFinanceSettingsCountriesPhones from "./CreditFinance/SettingsCountriesPhones"
import CreditFinanceSettingsPaymentReminders from "./CreditFinance/SettingsPaymentReminders"
import CreditFinanceSettingsMessages from "./CreditFinance/SettingsEditMessages"
import CreditFinanceDownloadTemplates from "./CreditFinance/DownloadTemplates"
import CreditFinanceNewCustomersSelectCountry from "./CreditFinance/NewCustomersSelectCountry"
import CreditFinanceNewCustomersCountrySelected from "./CreditFinance/NewCustomersCountrySelected"
import CreditFinanceNewAlerts from "./CreditFinance/NewAlerts"
import CreditFinanceStatsCountryDetail from "./CreditFinance/CreditFinanceStatsCountryDetail"

import SettingsWizard from "./CreditFinance/SettingsWizard"
import * as reducers from "./reducers"
import { firebaseInit } from "./firebase"
import registerServiceWorker from "./registerServiceWorker"

import "bootstrap/dist/css/bootstrap.css"
import AddMembers from "./features/vaccination/screens/AddMembers"
import AuthProvider from "./contexts/AuthProvider"
import PrivateRoute from "./components/PrivateRoute"
import FirstVaccination from "./features/vaccination/screens/FirstVaccination"
import SecondVaccination from "./features/vaccination/screens/SecondVaccination"
import { ToastProvider } from "react-toast-notifications"

console.log("process.env.node.env", process.env)

const Main = () => {
    const user = useSelector((state) => state.user)
    const admin = useSelector((state) => state.admin)

    return <div>{renderRoutes()}</div>
}

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory()

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history)

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
    combineReducers({
        router: connectRouter(history),
        ...reducers,
    }),
    compose(applyMiddleware(middleware, promiseMiddleware))
)

global.store = store

firebaseInit()

function renderRoutes() {
    return (
        <ConnectedRouter history={history}>
            <div>
                <Route exact path="/" component={App} />

                <Route exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/admin-messaging">
                    <AdminMessaging />
                </PrivateRoute>

                <Route exact path="/admin/bloodbank" component={BloodBankAdminCategory} />
                <Route exact path="/admin/insurance" component={AdminCategory} />
                <Route exact path="/admin/vaccination" component={AdminCategory} />
                <Route exact path="/admin/credit-finance" component={AdminCategory} />
                <Route exact path="/admin/electric" component={ElectricAdminCategory} />
                <PrivateRoute exact path="/admin/rate-limiter">
                    <RateLimiterAdminCategory />
                </PrivateRoute>
                <Route exact path="/auth/login" component={Login} />
                <Route
                    exact
                    path="/auth/credit-finance/select-country"
                    component={CreditFinanceRegistrationSelectCountry}
                />
                <Route
                    exact
                    path="/auth/credit-finance/select-country/:code"
                    component={CreditFinanceRegistrationSelectCountriesOfOperation}
                />
                <Route
                    exact
                    path="/auth/credit-finance/select-country/:code/confirmation"
                    component={
                        CreditFinanceRegistrationSelectCountriesOfOperationConfirmation
                    }
                />
                <Route
                    exact
                    path="/auth/credit-finance/register"
                    component={CreditFinanceRegister}
                />
                <Route
                    exact
                    path="/auth/credit-finance/register/confirm"
                    component={CreditFinanceRegisterConfirm}
                />
                <Route exact path="/auth/register" component={Register} />
                <Route exact path="/auth/register/confirm" component={RegisterConfirm} />
                <Route exact path="/about-individuals" component={AboutIndividuals} />
                <Route exact path="/about-organizations" component={AboutOrganizations} />
                <Route
                    exact
                    path="/credit-finance"
                    component={CreditFinanceRegistrationStart}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email"
                    component={CreditFinanceDashboard}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/settings-setup"
                    component={SettingsWizard}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/settings"
                    component={CreditFinanceSettings}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/settings/countries-phone-numbers"
                    component={CreditFinanceSettingsCountriesPhones}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/settings/payment-reminders"
                    component={CreditFinanceSettingsPaymentReminders}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/settings/messages"
                    component={CreditFinanceSettingsMessages}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/download-templates"
                    component={CreditFinanceDownloadTemplates}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/new-customers-select-country"
                    component={CreditFinanceNewCustomersSelectCountry}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/new-customers-country-selected"
                    component={CreditFinanceNewCustomersCountrySelected}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/new-alerts"
                    component={CreditFinanceNewAlerts}
                />
                <Route
                    exact
                    path="/credit-finance/:code/:email/country-detail/:country"
                    component={CreditFinanceStatsCountryDetail}
                />
                <Route exact path="/insurance" component={Category} />
                <Route exact path="/insurance/:code" component={Country} />
                <Route exact path="/insurance/:code/:email" component={Dashboard} />
                <Route
                    exact
                    path="/insurance/:code/:email/settings"
                    component={Settings}
                />
                <Route
                    exact
                    path="/insurance/:code/:email/update-database"
                    component={UpdateDatabase}
                />
                <Route
                    exact
                    path="/insurance/:code/:email/download-templates"
                    component={DownloadTemplates}
                />
                <Route exact path="/other" component={Category} />
                <Route exact path="/other/:code" component={Country} />
                <Route exact path="/other/:code/:email" component={Dashboard} />
                <Route exact path="/other/:code/:email/settings" component={Settings} />
                <Route
                    exact
                    path="/other/:code/:email/update-database"
                    component={UpdateDatabase}
                />
                <Route
                    exact
                    path="/other/:code/:email/download-templates"
                    component={DownloadTemplates}
                />
                <Route exact path="/vaccination" component={VaccinationCategory} />
                <Route exact path="/vaccination/:code" component={VaccinationCountry} />

                <PrivateRoute exact path="/vaccination/:code/:email">
                    <VaccinationDashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/vaccination/:code/:email/first-vaccination">
                    <FirstVaccination />
                </PrivateRoute>

                <PrivateRoute exact path="/vaccination/:code/:email/second-vaccination">
                    <SecondVaccination />
                </PrivateRoute>

                <PrivateRoute exact path="/vaccination/:code/:email/admin-functions">
                    <AdminFunctions />
                </PrivateRoute>

                <PrivateRoute exact path="/vaccination/:code/:email/download-template">
                    <DownloadTemplate />
                </PrivateRoute>

                <Route
                    exact
                    path="/vaccination/:code/:email/settings"
                    component={VaccinationSettings}
                />
                <Route
                    exact
                    path="/vaccination/:code/:email/users"
                    component={AddMembers}
                />
                <Route
                    exact
                    path="/auth/vaccination/register"
                    component={VaccinationRegister}
                />
                <Route
                    exact
                    path="/auth/vaccination/register/confirm"
                    component={VaccinationRegisterConfirm}
                />
                <Route exact path="/blood-bank" component={BloodBankCategory} />
                <Route exact path="/blood-bank/:code" component={BloodBankCountry} />
                <Route
                    exact
                    path="/auth/bloodbank/register"
                    component={BloodBankRegister}
                />
                <Route
                    exact
                    path="/auth/bloodbank/register/confirm"
                    component={BloodBankRegisterConfirm}
                />
                <PrivateRoute exact path="/blood-bank/:code/:email">
                    <BloodBankDashboard />
                </PrivateRoute>

                <Route exact path="/electric" component={ElectricCategory} />
                <Route exact path="/electric/:code" component={ElectricCountry} />
                <Route
                    exact
                    path="/auth/electric/register"
                    component={ElectricRegistration}
                />
                <Route
                    exact
                    path="/auth/electric/register/confirm"
                    component={ElectricRegisterConfirm}
                />
                <PrivateRoute exact path="/electric/:code/:email">
                    <ElectricDashboard />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/settings">
                    <ElectricSettings />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/disconnection-alerts">
                    <ElectricDisconnectionAlerts />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/planned-outage-alerts">
                    <ElectricPlannedOutageAlerts />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/emergency-alerts">
                    <ElectricEmergencyOutageAlerts />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/download-templates">
                    <ElectricDownloadTemplates />
                </PrivateRoute>

                <PrivateRoute exact path="/electric/:code/:email/download-data">
                    <ElectricDownloadData />
                </PrivateRoute>

                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/change-password" component={ChangePassword} />
                <Route exact path="/terms" component={Terms} />
            </div>
        </ConnectedRouter>
    )
}

ReactDOM.render(
    <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <AuthProvider>
            <ToastProvider>
                <Main />
            </ToastProvider>
        </AuthProvider>
    </Provider>,
    document.getElementById("root")
)

registerServiceWorker()
