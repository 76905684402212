import React, { Component } from 'react';
import { } from 'react-redux';
import {connect} from 'react-redux';
import '../../App.css';
import { push, goBack } from 'react-router-redux'
import { Link } from 'react-router-dom'
import Header from '../../components/Header'; 
import Footer from '../../components/Footer';
import TokenExpired from '../../components/TokenExpired';
import Logout from '../../components/Logout';

import StepOne from '../../CreditFinance/SettingsWizard/StepOne';
import StepTwo from '../../CreditFinance/SettingsWizard/StepTwo';
import StepThree from '../../CreditFinance/SettingsWizard/StepThree';
import StepFour from '../../CreditFinance/SettingsWizard/StepFour';
import StepFive from '../../CreditFinance/SettingsWizard/StepFive';
import StepSix from '../../CreditFinance/SettingsWizard/StepSix';
import StepSeven from '../../CreditFinance/SettingsWizard/StepSeven';
import StepEight from '../../CreditFinance/SettingsWizard/StepEight';
import StepNine from '../../CreditFinance/SettingsWizard/StepNine';
import StepTen from '../../CreditFinance/SettingsWizard/StepTen';
import StepEleven from '../../CreditFinance/SettingsWizard/StepEleven';
import StepTwelve from '../../CreditFinance/SettingsWizard/StepTwelve';
import StepThirteen from '../../CreditFinance/SettingsWizard/StepThirteen';
import StepFourteen from '../../CreditFinance/SettingsWizard/StepFourteen';
import StepFifteen from '../../CreditFinance/SettingsWizard/StepFifteen';

import { Container, Row, Col, Button, Alert, Input, Label, Form } from 'reactstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons'

import { 
    getSetupState, 
    setSetupState, 
    fetchSettings, 
    uploadImage, 
    fetchUser, 
    setAlert,
    saveAlert,
    setSettings } from '../../actions'

const right = (<div>Logout</div>)

const settingsList =  [
    'credit-account-reminders-before-due',
    'credit-account-alerts-overdue',
    'credit-account-alerts-30-plus-days-overdue',
    'loan-account-reminders-before-due',
    'loan-account-alerts-overdue',
    'loan-account-alerts-30-plus-days-overdue'
]

class SettingsWizard extends Component {
    componentDidMount() {
        if(this.props.user) {
            this.props.fetchSettings(this.props.user.uid);
        }
    }

    getCurrentStep = () => {
        switch(this.props.settings["setup-step"]) {
            case "1":
                return <StepOne 
                            user={this.props.user} 
                            uploadImage={this.props.uploadImage} 
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "2":
                return <StepTwo
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            setSettings={this.props.setSettings}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />; 
            case "3":
                return <StepThree
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            setSettings={this.props.setSettings}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
                            
            case "4":
                return <StepFour
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            setAlert={this.props.setAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "5":
                return <StepFive
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "6":
                return <StepSix
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "7":
                return <StepSeven
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "8":
                return <StepEight
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "9":
                return <StepNine
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;
            case "10":
                return <StepTen
                            user={this.props.user}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            fetchSettings={this.props.fetchSettings} />;    
            case "11":
                return <StepEleven
                            user={this.props.user}
                            setSettings={this.props.setSettings}
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            fetchSettings={this.props.fetchSettings} />;    
            case "12":
                return <StepTwelve
                            user={this.props.user}
                            setSettings={this.props.setSettings}                            
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            fetchSettings={this.props.fetchSettings} />;
            case "13":
                return <StepThirteen
                            user={this.props.user}
                            setSettings={this.props.setSettings}                            
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            fetchSettings={this.props.fetchSettings} />;
            case "14":
                return <StepFourteen
                            user={this.props.user}
                            setSettings={this.props.setSettings}                            
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            fetchSettings={this.props.fetchSettings} />;
                            
            case "15":
                return <StepFifteen
                            user={this.props.user}
                            setSettings={this.props.setSettings}                            
                            setSetupState={this.props.setSetupState}
                            fetchUser={this.props.fetchUser}
                            goBack={this.props.goBack}
                            saveAlert={this.props.saveAlert}
                            settings={this.props.settings}
                            fetchSettings={this.props.fetchSettings} />;                         
                            
        }
    }

    render() {     
        if(!this.props.user) {
            return <TokenExpired />
        }

        return (
            <div>
                <Header right={<Logout user={this.props.user} />} />                
                {this.getCurrentStep()}
            </div>                
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    settings: state.settings
})

const mapDispatchToProps = (dispatch) => ({
    getSetupState: (user) => dispatch(getSetupState(user)),    
    fetchSettings: (uid) => dispatch(fetchSettings(uid)),
    setSetupState: (user, value) => dispatch(setSetupState(user, value)),
    uploadImage: (user, data) => dispatch(uploadImage(user, data)),
    fetchUser: (email) => dispatch(fetchUser(email)),
    goBack: () => dispatch(goBack()),
    setSettings: (user, values) => dispatch(setSettings(user, values)),
    setAlert: (user, key, message) => dispatch(setAlert(user.uid, key, message)),
    saveAlert: (user, key, message) => dispatch(saveAlert(user.uid, key, message)),
    dispatch
})

const App = connect(mapStateToProps, mapDispatchToProps)(SettingsWizard); 


export default App;