import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

import Header from "./components/Header"
import Footer from "./components/Footer"

class AppContainer extends Component {
    componentDidMount() {}
    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12}>
                            <h1>Terms of Use ("Terms")</h1>

                            <p>Last updated: 11th November 2019</p>
                            <p>
                                These Terms of Use refer to Yes, Text Me! and all services
                                provided via the Service at yestextme.com. By accessing or
                                using the Service you agree to be bound by these Terms.
                                Organizations using the Service are bound by the terms of
                                the Non-Exclusive License Agreement that is signed by the
                                parties.
                            </p>

                            <h5>
                                <u>Content</u>
                            </h5>

                            <p>
                                The Service allows users to send and receive SMS reminders
                                and alerts.
                            </p>

                            <p>
                                As a user of the Service, you agree that the Service
                                utilizes information that is provided by you of your own
                                volition. This information is transmitted through various
                                third party networks and services in the provision of the
                                Service, in order for the Service to operate. Apart from
                                such transmission, your information will not be knowingly
                                shared with third parties and we shall not be responsible
                                or liable, directly or indirectly, for any damage or loss
                                caused or alleged to be caused by or in connection with
                                use of your information by the Service, or use of your
                                information by others.
                            </p>

                            <p>
                                As a user of the Service, you agree that the Service or
                                its owner(s) and provider(s) shall not be responsible or
                                liable, directly or indirectly, for any damage or loss
                                caused or alleged to be caused by or in connection with
                                use of or reliance on the Service.
                            </p>

                            <p>
                                The Service and its owner(s) and provider(s) has no
                                control over, and assumes no responsibility whatsoever for
                                the operations, content, privacy policies, or practices of
                                any third party web sites, networks or services.
                            </p>

                            <h5>
                                <u>Changes</u>
                            </h5>

                            <p>
                                We reserve the right, at our sole discretion, to modify or
                                replace these Terms at any time, by posting the revisions
                                or modifications here.
                            </p>

                            <h5>
                                <u>Termination</u>
                            </h5>

                            <p>
                                We reserve the right to terminate or suspend access to the
                                Service immediately and at any time without prior notice
                                or liability, for any reason. All provisions of the Terms
                                which by their nature should survive termination shall
                                survive termination, including, without limitation,
                                ownership provisions, warranty disclaimers, indemnity and
                                limitations of liability.
                            </p>

                            <h5>
                                <u>Contact Us</u>
                            </h5>

                            <p>
                                Yes, Text Me! is a service of EIM Communications LLC,{" "}
                                <a target="_blank" href="https://myeiminc.com">
                                    https://myeiminc.com
                                </a>
                                .<br />
                                Contact us for sales at Mick Rodgers Consulting/Chatoyer
                                Inc, Basseterre, St Kitts & Nevis. <br />
                                Mobile: <a href="tel:+18696650907">+1 (869) 665-0907</a>
                                <br />
                                Email:{" "}
                                <a href="mailto:mick.rodgers@chatoyerinc.com">
                                    mick.rodgers@chatoyerinc.com
                                </a>
                            </p>

                            <div className="text-center">
                                <Link to="/">Back</Link>
                            </div>
                        </Col>
                    </Row>

                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
