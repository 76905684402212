import { Link } from "react-router-dom"
import { faCog } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"

const Tab = ({ label, onSelect, selectedTab }) => {
    const selected = selectedTab === label

    const handleSelect = () => {
        onSelect(label)
    }

    return (
        <div
            onClick={handleSelect}
            className="border border-bottom-0 p-2"
            style={{
                cursor: "pointer",
                fontWeight: "bolder",
                borderBottomColor: "white",
                position: "relative",
                backgroundColor: selected ? "white" : "lightgray",
                maxWidth: "200px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                left: "30px",
            }}
        >
            {label}
        </div>
    )
}

export const TabBar = ({
    user,
    onSelect,
    initialTab: selectedTab = "Activity Summary",
}) => {
    const handleSelect = (label) => {
        onSelect(label)
    }

    return (
        <div className="d-flex flex-row pt-4">
            <Tab
                label={"Activity Summary"}
                onSelect={handleSelect}
                selectedTab={selectedTab}
            />
            <Tab label={"Details"} onSelect={handleSelect} selectedTab={selectedTab} />
            <Tab label={"Admin"} onSelect={handleSelect} selectedTab={selectedTab} />
            <div
                className="border-bottom-0 p-2 ml-auto"
                style={{
                    fontWeight: "bolder",
                    borderBottomColor: "white",
                    position: "relative",
                    borderStyle: "none",
                    maxWidth: "200px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                }}
            >
                <Link
                    to={`/${user.category}/${user?.country?.isoCode}/${user.email}/settings`}
                >
                    <FontAwesomeIcon
                        className="ml-2"
                        size="2x"
                        icon={faCog}
                    ></FontAwesomeIcon>
                </Link>
            </div>
        </div>
    )
}
