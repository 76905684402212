import { makeAutoObservable } from "mobx"
import { string } from "yup"
import axios from "../../../lib/axios"
import { electricStore } from "./electric.store"

let instance

class ElectricUploadManager {
    processing = false
    errorMessage = ""
    successMessage = ""

    addToast = (text, options) => {}

    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!")
        }
        instance = this
        makeAutoObservable(this)
    }

    setAddToast = (func) => {
        this.addToast = func
    }

    setProcessing = (value) => {
        this.processing = value
    }

    setErrorMessage = (value) => {
        this.errorMessage = value
    }

    setSuccessMessage = (value) => {
        this.successMessage = value
    }

    handleUploadChanged = (user) => {
        return (event) => {
            var fileToLoad = event.target.files[0]

            if (
                fileToLoad.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.setErrorMessage("Not a valid excel file")
                return
            } else {
                this.setErrorMessage(null)
            }

            var fileReader = new FileReader()
            fileReader.onload = async (fileLoadedEvent) => {
                var binaryFileLoaded = fileLoadedEvent.target.result

                console.log("file upload result ", fileLoadedEvent)

                electricStore
                    .uploadLocations({
                        accountId: user.uid,
                        xlsx: binaryFileLoaded,
                    })
                    .then((res) => {
                        console.log("Upload response ", res)
                        this.setSuccessMessage(
                            `You have successfully uploaded ${res.locations.length} locations to your database`
                        )

                        this.addToast(
                            `You have successfully uploaded ${res.locations.length} locations to your database`,
                            {
                                autoDismiss: true,
                                appearance: "success",
                            }
                        )
                    })
                    .catch((e) => {
                        console.log("Uplod error", e.response)
                        this.setErrorMessage(e.response?.data?.errorMessage)
                        this.addToast("Error uploading locations", {
                            autoDismiss: true,
                            appearance: "error",
                        })
                    })

                this.setProcessing(true)
            }
            fileReader.readAsBinaryString(fileToLoad)
        }
    }

    handleCustomerUploadChanged = (user) => {
        return (event) => {
            var fileToLoad = event.target.files[0]

            if (
                fileToLoad.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.setErrorMessage("Not a valid excel file")
                return
            } else {
                this.setErrorMessage(null)
            }

            var fileReader = new FileReader()
            fileReader.onload = async (fileLoadedEvent) => {
                var binaryFileLoaded = fileLoadedEvent.target.result

                console.log("file upload result ", fileLoadedEvent)

                electricStore
                    .uploadCustomers({
                        accountId: user.uid,
                        xlsx: binaryFileLoaded,
                    })
                    .then((res) => {
                        console.log("Upload response ", res)
                        this.setSuccessMessage(
                            `You have successfully uploaded ${res.customers.length} customers to your database`
                        )

                        this.addToast(
                            `You have successfully uploaded ${res.customers.length} customers to your database`,
                            {
                                autoDismiss: true,
                                appearance: "success",
                            }
                        )
                    })
                    .catch((e) => {
                        console.log("Upload error", e.response)
                        this.setErrorMessage(e.response?.data?.errorMessage)
                        this.addToast("Error uploading customers", {
                            autoDismiss: true,
                            appearance: "error",
                        })
                    })

                this.setProcessing(true)
            }
            fileReader.readAsBinaryString(fileToLoad)
        }
    }

    handleDisconnectionsUploadChanged = (user) => {
        return (event) => {
            var fileToLoad = event.target.files[0]

            if (
                fileToLoad.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.setErrorMessage("Not a valid excel file")
                return
            } else {
                this.setErrorMessage(null)
            }

            var fileReader = new FileReader()
            fileReader.onload = async (fileLoadedEvent) => {
                var binaryFileLoaded = fileLoadedEvent.target.result

                console.log("file upload result ", fileLoadedEvent)

                electricStore
                    .uploadDisconnections({
                        accountId: user.uid,
                        xlsx: binaryFileLoaded,
                    })
                    .then((res) => {
                        console.log("Upload response ", res)
                        this.setSuccessMessage(
                            `You have successfully uploaded ${res.disconnections.length} Disconnection Notices, and alerts have been sent to the corresponding customers.`
                        )

                        this.addToast(
                            `You have successfully uploaded ${res.disconnections.length} Disconnection Notices, and alerts have been sent to the corresponding customers.`,
                            {
                                autoDismiss: true,
                                appearance: "success",
                            }
                        )
                    })
                    .catch((e) => {
                        console.log("Upload error", e.response)
                        this.setErrorMessage(e.response?.data?.errorMessage)
                        this.addToast("Error uploading disconnections", {
                            autoDismiss: true,
                            appearance: "error",
                        })
                    })

                this.setProcessing(true)
            }
            fileReader.readAsBinaryString(fileToLoad)
        }
    }

    handlePlannedOutageByLocationUploadChanged = (user) => {
        return (event) => {
            var fileToLoad = event.target.files[0]

            if (
                fileToLoad.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.setErrorMessage("Not a valid excel file")
                return
            } else {
                this.setErrorMessage(null)
            }

            var fileReader = new FileReader()
            fileReader.onload = async (fileLoadedEvent) => {
                var binaryFileLoaded = fileLoadedEvent.target.result

                console.log("file upload result ", fileLoadedEvent)

                electricStore
                    .uploadPlannedOutagesByLocation({
                        accountId: user.uid,
                        xlsx: binaryFileLoaded,
                    })
                    .then((res) => {
                        console.log("Upload response ", res)
                        this.setSuccessMessage(
                            `You have successfully uploaded ${res.outages.length} planned outage notices, and alerts have been sent to the corresponding customers.`
                        )

                        this.addToast(
                            `You have successfully uploaded ${res.outages.length} planned outage notices, and alerts have been sent to the corresponding customers.`,
                            {
                                autoDismiss: true,
                                appearance: "success",
                            }
                        )
                    })
                    .catch((e) => {
                        console.log("Upload error", e.response)
                        this.setErrorMessage(e.response?.data?.errorMessage)
                        this.addToast("Error uploading planned outages", {
                            autoDismiss: true,
                            appearance: "error",
                        })
                    })

                this.setProcessing(true)
            }
            fileReader.readAsBinaryString(fileToLoad)
        }
    }

    handlePlannedOutageByFeederUploadChanged = (user) => {
        return (event) => {
            var fileToLoad = event.target.files[0]

            if (
                fileToLoad.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.setErrorMessage("Not a valid excel file")
                return
            } else {
                this.setErrorMessage(null)
            }

            var fileReader = new FileReader()
            fileReader.onload = async (fileLoadedEvent) => {
                var binaryFileLoaded = fileLoadedEvent.target.result

                console.log("file upload result ", fileLoadedEvent)

                electricStore
                    .uploadPlannedOutagesByFeeder({
                        accountId: user.uid,
                        xlsx: binaryFileLoaded,
                    })
                    .then((res) => {
                        console.log("Upload response ", res)
                        this.setSuccessMessage(
                            `You have successfully uploaded ${res.outages.length} planned outage notices, and alerts have been sent to the corresponding customers.`
                        )

                        this.addToast(
                            `You have successfully uploaded ${res.outages.length} planned outage notices, and alerts have been sent to the corresponding customers.`,
                            {
                                autoDismiss: true,
                                appearance: "success",
                            }
                        )
                    })
                    .catch((e) => {
                        console.log("Upload error", e.response)
                        this.setErrorMessage(e.response?.data?.errorMessage)
                        this.addToast("Error uploading feeder outages", {
                            autoDismiss: true,
                            appearance: "error",
                        })
                    })

                this.setProcessing(true)
            }
            fileReader.readAsBinaryString(fileToLoad)
        }
    }
}

export const electricUploadManager = new ElectricUploadManager()
