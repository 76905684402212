import { Alert, FormGroup, Input, Label } from "reactstrap"

export const TextInputField = ({
    formik,
    label,
    fieldName,
    placeholder,
    type,
    readonly,
    required = false,
}) => {
    console.log("textinput readonly ", readonly)
    return (
        <FormGroup>
            <Label for="email">
                {label} {required ? "*" : ""}
            </Label>
            {formik.touched[fieldName] && formik.errors[fieldName] && (
                <Alert color="danger">{formik.errors[fieldName]}</Alert>
            )}
            <Input
                value={formik.values[fieldName]}
                role="presentation"
                autoComplete="off"
                readOnly={readonly}
                type={type || "text"}
                name={fieldName}
                id={fieldName}
                placeholder={placeholder}
                onChange={formik.handleChange(fieldName)}
                onBlur={formik.handleBlur(fieldName)}
            />
        </FormGroup>
    )
}
