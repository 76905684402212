import { observer } from "mobx-react-lite"
import { Button, FormGroup, Input, Label, Table } from "reactstrap"
import TimePicker from "react-time-picker"
import DateTimePicker from "react-datetime-picker"
import { emergencyOutageStore } from "../../data/emergency-outage.store"
import { useResponsive } from "react-hooks-responsive"
import classNames from "classnames"

const LocationTimes = observer(() => {
    const {
        selectedLocations,
        multiMinDate,
        setMultiRestorationTime,
        setMultiRestorationTimeTomorrow,
        restorationTimes,
    } = emergencyOutageStore

    const breakpoints = { xs: 0, sm: 480, md: 1024 }
    const { screenIsAtLeast } = useResponsive(breakpoints)

    const getTimeValueForLocation = (location) => {
        if (
            restorationTimes[location]?.time === "Unknown" ||
            !restorationTimes[location]?.time
        ) {
            return ""
        } else {
            return restorationTimes[location]?.time
        }
    }

    return (
        <Table className={classNames({ "w-75": screenIsAtLeast("md"), "mt-4": true })}>
            <tbody>
                {selectedLocations.map((item) => (
                    <tr key={item.id}>
                        <td>{item.outageLocation}</td>
                        <td>
                            <DateTimePicker
                                format="MM-dd-yyyy h:mm a"
                                minDate={new Date()}
                                disabled={
                                    restorationTimes[item.outageLocation]?.time ===
                                    "Unknown"
                                }
                                disableClock={true}
                                value={getTimeValueForLocation(item.outageLocation)}
                                onChange={(value) => setMultiRestorationTime(item, value)}
                            />
                        </td>
                        <td>
                            <div className="d-flex flex-row justify-content-between">
                                <div>Or</div>
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        checked={
                                            restorationTimes[item.outageLocation]
                                                ?.time === "Unknown"
                                        }
                                        onChange={(evt) =>
                                            evt.target.checked
                                                ? setMultiRestorationTime(item, "Unknown")
                                                : setMultiRestorationTime(item, "")
                                        }
                                    />
                                    <Label check>Unknown</Label>
                                </FormGroup>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
})

export const SetOutageTimes = observer(() => {
    const {
        setSameTimes,
        setDifferentTimes,
        sameTimes,
        differentTimes,
        setRestorationTimeUnknown,
        setRestorationTimeTomorrow,
        minDate,
        restorationTimeUnknown,
        restorationTimeTomorrow,
        setRestorationTime,
        restorationTime,
        isRestorationTimeValid,
        nextStep,
    } = emergencyOutageStore

    console.log("minDate ", minDate)
    return (
        <div className="pt-4">
            <FormGroup tag="fieldset">
                <legend>Set estimated restoration times</legend>

                {!restorationTimeUnknown ? (
                    <FormGroup check>
                        <Input
                            checked={sameTimes}
                            name="radio1"
                            type="radio"
                            onChange={(evt) =>
                                evt.target.value === "on"
                                    ? setSameTimes(true)
                                    : setSameTimes(false)
                            }
                        />{" "}
                        <Label check>Set same time for all locations</Label>
                    </FormGroup>
                ) : null}
                {!restorationTimeUnknown ? (
                    <FormGroup check>
                        <Input
                            name="radio1"
                            type="radio"
                            checked={differentTimes}
                            onChange={(evt) =>
                                evt.target.value === "on"
                                    ? setDifferentTimes(true)
                                    : setDifferentTimes(false)
                            }
                        />{" "}
                        <Label check>Set different times</Label>
                    </FormGroup>
                ) : null}
                {sameTimes && !restorationTimeUnknown ? (
                    <FormGroup className="pt-3">
                        <DateTimePicker
                            amPmAriaLabel="AM / PM"
                            disableClock={true}
                            minDate={new Date()}
                            format="MM-dd-yyyy h:mm a"
                            value={restorationTime.time}
                            onChange={setRestorationTime}
                        />
                    </FormGroup>
                ) : null}

                {differentTimes && !restorationTimeUnknown ? <LocationTimes /> : null}

                {!differentTimes ? (
                    <FormGroup check className="">
                        <Input
                            checked={restorationTimeUnknown}
                            name="radio1"
                            type="checkbox"
                            onChange={(evt) =>
                                setRestorationTimeUnknown(evt.target.checked)
                            }
                        />{" "}
                        <Label check>Restoration time is unknown</Label>
                    </FormGroup>
                ) : null}
            </FormGroup>
            <Button color="primary" onClick={nextStep} disabled={!isRestorationTimeValid}>
                Continue
            </Button>
        </div>
    )
})
