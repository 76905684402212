// A wrapper for <Route> that redirects to the login

import { useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Route, browserHistory } from "react-router"

export default function PrivateRoute({ children, ...rest }) {
    const user = useSelector((state) => state.user)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}
