import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { push } from "react-router-redux"
import { fetchCounterStats, getLandingPageLogos } from "./actions"
import { Link } from "react-router-dom"
import { Container, Row, Col, FormGroup, Input } from "reactstrap"
import { setRegistrationField } from "./actions"
import Footer from "./components/Footer"
import SMSCounter from "./components/Counter"
import IntroVideo from "./components/IntroVideo"
import config from "./config"

class AppContainer extends Component {
    componentDidMount() {
        this.props.fetchCounterStats()
        this.props.getLandingPageLogos().then((res) => {})

        window.addEventListener("unload", () => {
            localStorage.setItem("appCloseTime", new Date())
        })
    }

    componentWillUnmount() {}

    nextSection(evt) {
        const section = evt.target.value
        if (section !== "get-started") {
            this.props.dispatch(push(`/${section}`))
            this.props.dispatch(setRegistrationField({ category: section }))
        } else {
            evt.preventDefault()
        }
    }

    learnMoreIndividual() {
        this.props.dispatch(push(`/about-individuals`))
    }

    learnMoreOrganization(evt) {
        if (
            evt.target.nodeName !== "A" &&
            evt.target.nodeName !== "SELECT" &&
            evt.target.nodeName !== "H6" &&
            evt.target.nodeName != "IMG"
        ) {
            this.props.dispatch(push(`/about-organizations`))
        }
    }

    renderLogo = (item) => <img className="logo-content" src={item.logo} />

    renderLinkedLogo = (item) => (
        <a href={item.website} target="_blank">
            <img className="logo-content" src={item.logo} />
        </a>
    )

    getDashboardLink = (user) => {
        if (this.props.admin) {
            return { link: `/admin`, title: "Admin Dashboard" }
        } else if (user) {
            return {
                link: `/${user.category}/${
                    user?.country?.isoCode || user.country || user.countryCode
                }/${user.email}`,
                title: "Dashboard",
            }
        } else {
            return { link: "/auth/login", title: "Login" }
        }
    }

    renderLogos = () => (
        <div className="">
            {this.props.logos
                .filter((item) => item.logo !== undefined && item.logo !== null)
                .map((item, index) => (
                    <div key={index} className="logo-container">
                        {item.website
                            ? this.renderLinkedLogo(item)
                            : this.renderLogo(item)}
                    </div>
                ))}
        </div>
    )

    render() {
        const { user, counterStats } = this.props
        const dashboardUrl = user
            ? `/${user.category}/${
                  user?.country?.isoCode || user.country || user.countryCode
              }/${user.email}`
            : null

        return (
            <div className="App">
                {/* <Header/> */}
                <Container>
                    <Row className="justify-content-center">
                        <img src={"/images/logo_small.png"} height="50" className="m-4" />
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <div>
                                <h1
                                    className="text-uppercase"
                                    style={{ fontWeight: 700, fontSize: "1.9em" }}
                                >
                                    Yes, Text Me!
                                </h1>
                            </div>
                            <div>
                                <h2 style={{ fontWeight: 300 }}>
                                    Text messages you'll want to get :)
                                </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-4">
                        <Col
                            sm={3}
                            xs={12}
                            className="info-left"
                            onClick={this.learnMoreIndividual.bind(this)}
                            style={{ cursor: "pointer" }}
                        >
                            <img
                                src="/images/individuals_icon.svg"
                                width="150"
                                style={{ marginBottom: 20 }}
                            />
                            <h2 className="text-uppercase" style={{ fontWeight: 300 }}>
                                Individuals
                            </h2>
                            <h5>Get important text reminders &amp; alerts</h5>
                            {/* <Link to="/about-individuals">Learn more</Link> */}
                        </Col>
                        <Col
                            sm={3}
                            xs={12}
                            className="info-right"
                            onClick={this.learnMoreOrganization.bind(this)}
                        >
                            <img
                                src="/images/organisations_icon.svg"
                                width="150"
                                style={{ marginBottom: 20 }}
                            />

                            <h2 className="text-uppercase" style={{ fontWeight: 300 }}>
                                Organizations
                            </h2>
                            <h5>Send text reminders &amp; alerts to your customers</h5>
                            <h5></h5>

                            <div style={{ paddingLeft: 30 }}>
                                <div
                                    className="d-flex flex-row justify-content-start justify-content-lg-start mt-4"
                                    style={{ width: 300 }}
                                >
                                    <div>
                                        <img
                                            src="/images/utilities_icon_LP.svg"
                                            height="45"
                                        />
                                    </div>
                                    <div className="pl-2" style={{ textAlign: "left" }}>
                                        <Link
                                            to="/electric"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <h6
                                                className="m-0 "
                                                style={{ maxWidth: 200 }}
                                            >
                                                <span
                                                    style={{
                                                        color: "#f64a35",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    NEW!
                                                </span>{" "}
                                                Targeted outage alerts for electric
                                                utilities
                                            </h6>
                                        </Link>
                                        <p style={{ fontSize: "0.7em" }}></p>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-row justify-content-start justify-content-lg-start mt-4"
                                    style={{ width: 300 }}
                                >
                                    <div>
                                        <img
                                            src="/images/multiple_vehicles_icon_landingpage.png"
                                            height="45"
                                        />
                                    </div>
                                    <div className="pl-2" style={{ textAlign: "left" }}>
                                        <Link
                                            to="/insurance"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <h6
                                                className="m-0 "
                                                style={{ maxWidth: 200 }}
                                            >
                                                <span
                                                    style={{
                                                        color: "orange",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    NEW!
                                                </span>{" "}
                                                Vehicle fleet messaging for insurance
                                                companies
                                            </h6>
                                        </Link>
                                        <p style={{ fontSize: "0.7em" }}></p>
                                    </div>
                                </div>

                                <SMSCounter count={counterStats.count} />
                            </div>

                            <FormGroup className="mt-3">
                                <Input
                                    type="select"
                                    name="select"
                                    id="select"
                                    onChange={this.nextSection.bind(this)}
                                >
                                    <option value="get-started">Get Started</option>
                                    {/*<option value="blood-bank">Blood Bank</option> */}
                                    <option value="electric">Electric Utility</option>
                                    <option value="credit-finance">
                                        Credit & Finance
                                    </option>
                                    <option value="insurance">Insurance</option>
                                    <option value="vaccination">Min. of Health</option>
                                    <option value="other">Other</option>
                                </Input>
                            </FormGroup>

                            {/*this.props.user ? <Link to={dashboardUrl}>Dashboard</Link> : <Link to="/auth/login">Login</Link>*/}
                            <Link to={this.getDashboardLink(user).link}>
                                {this.getDashboardLink(user).title}
                            </Link>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={12} xs={12}>
                            <p className="used-by">
                                <em>Used by:</em>
                            </p>
                            {this.renderLogos()}
                        </Col>
                    </Row>
                    <Footer />
                </Container>
            </div>
        )
    }
}

const App = connect(
    (state) => ({
        user: state.user,
        admin: state.admin,
        counterStats: state.counterStats,
        logos: state.landingPageLogos.logos,
    }),
    (dispatch) => ({
        dispatch: dispatch,
        getLandingPageLogos: () => dispatch(getLandingPageLogos()),
        fetchCounterStats: () => dispatch(fetchCounterStats()),
    })
)(AppContainer)

export default App
