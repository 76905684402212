import React, { Component } from "react"
import { connect } from "react-redux"
import "../../../App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    Input,
    Alert,
} from "reactstrap"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import {
    logout,
    fetchCompanies,
    setAdminCompany,
    setAdminCountry,
    setAdminCurrency,
    setAdminRate,
    fetchVaccinationOrganizations,
    updateRate,
    setUser,
} from "../../../actions"

import _ from "lodash"
import { adminConfig } from "../../../reducers/index"

class AppContainer extends Component {
    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const category = parts[parts.length - 1]

        this.props.fetchCompanies(category).then((snap) => {})

        if (category === "vaccination") {
            this.props.fetchVaccinationOrganizations().then(() => {})
        }

        if (adminConfig.countryCode) {
            this.props.setAdminRate(
                this.props.rates[this.props.adminConfig.countryCode].rate
            )
        }

        if (adminConfig.company) {
            const index = this.props.companies.findIndex(
                (item) => item.uid === adminConfig.company
            )
            this.selectedCompany = this.props.companies[index]
        }
    }

    getCompanyByUid(uid) {
        let index = this.props.companies.findIndex((item) => item.uid === uid)
        let selectedCompany = this.props.companies[index]
        if (index === -1) {
            index = this.props.vaccinationOganizations.findIndex(
                (item) => item.uid === uid
            )
            selectedCompany = this.props.vaccinationOganizations[index]
        }

        return selectedCompany
    }

    companySelected(evt) {
        let selectedCompany = this.getCompanyByUid(evt.target.value)
        if (selectedCompany.category === "vaccination") {
            selectedCompany.companyFullName = selectedCompany.organizationLongName
            selectedCompany.companyShortName = selectedCompany.organizationShortName
        }
        this.props.setAdminCompany(selectedCompany)
        this.props.setUser(selectedCompany)
    }

    countrySelected(evt) {
        this.props.setAdminCountry(evt.target.value)
        this.props.setAdminRate(this.props.rates[evt.target.value].rate)
        this.props.setAdminCurrency(this.props.rates[evt.target.value].currency)
    }

    currencySelected(evt) {
        this.props.setAdminCurrency(evt.target.value)
    }

    rateSelected(evt) {
        this.props.setAdminRate(evt.target.value)
    }

    logout(evt) {
        evt.preventDefault()
        this.props.logout().then(() => {
            this.props.dispatch(push("/"))
        })
    }

    updata() {
        const oldData = Object.assign({}, this.props.adminConfig)
        delete oldData["company"]
        const values = Object.assign({}, oldData, {
            countryName: this.props.rates[this.props.adminConfig.countryCode].countryName,
        })
        values.rate = parseFloat(values.rate)
        this.props.updateRate(values).then((res) => {
            if (res.action.type === "UPDATE_RATE_FULFILLED") {
                alert("Rate updated successfully")
            } else if (res.action.type === "UPDATE_RATE_REJECTED") {
                alert("Update failed")
            }
        })
    }

    render() {
        const { admin, adminConfig, companies, rates, vaccinationOganizations } =
            this.props

        const sortedRates = _.sortBy(rates, "countryName")

        let right = null
        if (admin) {
            right = (
                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {admin.email}
                        </div>
                        <div>
                            <a href="#" onClick={this.logout.bind(this)}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div>
                        <img src={`/flag-icon-css/flags/4x3/vc.svg`} height={30} />
                    </div>
                </div>
            )
        }

        if (!this.props.admin) {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <h1>Admin</h1>
                        </Row>

                        <Row className="justify-content-center">
                            <Col sm={3} xs={12}>
                                <Alert color="danger">Not Authorized</Alert>
                                <div className="text-center">
                                    <Link to="/auth/login">Login</Link>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <h1 className="text-capitalize">
                            {_.last(this.props.match.url.split("/"))}
                        </h1>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={3} xs={12}>
                            {adminConfig.company ? (
                                <Alert>
                                    <Link
                                        to={`/${adminConfig.company.category}/${
                                            adminConfig.company.country ||
                                            adminConfig.company.countryCode
                                        }/${adminConfig.company.email}`}
                                    >
                                        View {adminConfig.company.companyShortName}{" "}
                                        Dashboard
                                    </Link>
                                </Alert>
                            ) : null}

                            <FormGroup>
                                <Input
                                    type="select"
                                    value={
                                        adminConfig && adminConfig.company
                                            ? adminConfig.company.uid
                                            : ""
                                    }
                                    onChange={this.companySelected.bind(this)}
                                >
                                    <option value="select-company">Select Company</option>
                                    {companies.map((company) => (
                                        <option key={company.uid} value={company.uid}>
                                            {company.companyShortName}
                                        </option>
                                    ))}
                                    {vaccinationOganizations.map((organization) => (
                                        <option
                                            key={organization.uid}
                                            value={organization.uid}
                                        >
                                            {organization.organizationShortName}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={this.countrySelected.bind(this)}
                                    value={adminConfig.countryCode}
                                >
                                    <option value="select-country">Select Country</option>
                                    {sortedRates.map((rate, index) => (
                                        <option key={index} value={rate.countryCode}>
                                            {rate.countryName}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={this.currencySelected.bind(this)}
                                    value={adminConfig.currency}
                                >
                                    <option value="select-company">
                                        Select Currency
                                    </option>
                                    <option value="USD">USD</option>
                                    <option value="XCD">XCD</option>
                                </Input>
                            </FormGroup>

                            {rates[adminConfig.countryCode] ? (
                                <FormGroup>
                                    <Label for="rate">Rate</Label>
                                    <InputGroup>
                                        <InputGroupAddon>$</InputGroupAddon>
                                        <Input
                                            value={adminConfig.rate}
                                            type="number"
                                            name="rate"
                                            id="rate"
                                            onChange={this.rateSelected.bind(this)}
                                        ></Input>
                                    </InputGroup>
                                </FormGroup>
                            ) : null}

                            <div className="text-center">
                                <Button onClick={this.updata.bind(this)} color="success">
                                    Update Data
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    fetchCompanies: (category) => dispatch(fetchCompanies(category)),
    fetchVaccinationOrganizations: () => dispatch(fetchVaccinationOrganizations()),
    setAdminCompany: (company) => dispatch(setAdminCompany(company)),
    setAdminCountry: (country) => dispatch(setAdminCountry(country)),
    setAdminCurrency: (currency) => dispatch(setAdminCurrency(currency)),
    setAdminRate: (rate) => dispatch(setAdminRate(rate)),
    setUser: (user) => dispatch(setUser(user)),
    updateRate: (rate) => dispatch(updateRate(rate)),
    dispatch,
})

const App = connect(
    (state) => ({
        location: state.location,
        rates: state.rates,
        admin: state.admin,
        adminConfig: state.adminConfig,
        companies: state.companies,
        vaccinationOganizations: state.vaccinationOrganizations,
    }),
    mapDispatchToProps
)(AppContainer)

export default App
