import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, CustomInput, Label, Input } from 'reactstrap';
import Header from '../components/Header'; 
import TokenExpired from '../components/TokenExpired'
import Footer from '../components/Footer';
import Logout from '../components/Logout'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { goBack as goBackAction} from 'connected-react-router'

import config from '../config'
import countries from '../countries-caribbean.json'; 

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  setAlert,
  saveAlert,  
  setSettings as setSettingsAction } from '../actions'

  const NewCustomersSelectCountry = () => {

    useEffect(() => {      
      fetchUser(email)    
    }, [email]);    

    const [countryCode, setCountryCode] = useState(null)
    const email = useSelector(state => state.router.location.pathname.split("/")[3])
    const currentPath = useSelector(state => state.router.location.pathname.split("/").slice(0,-1).join("/") )
    const dispatch = useDispatch() 
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const user = useSelector(state => state.user)
    const goBack = () => dispatch(goBackAction())

    const selectCountry = (evt) => {
      setCountryCode(evt.target.value)
    }

    const nextStep = () => {

    }

    if(!user) {
      return <TokenExpired />
    } 

    return (
      <Container>
        <Header right={<Logout user={user} />} />
        <Row className="justify-content-center">
              <Col sm={6} xs={12} className="">
                <h1>Add New Customers</h1>
                <h5 className="text-primary">Which country are you adding customers for?</h5>
                <Input type="select" onChange={selectCountry} >
                    <option value="select-country">Select your country</option>
                    {Object.keys(user.countriesOfOperation).map(code => <option value={code} key={code}>{countries.find(x => x.code.toLowerCase() === code).name}</option>)}
                </Input>

                <div className="text-center mt-2">
                  {countryCode ? <Link className="btn btn-success" to={currentPath + '/new-customers-country-selected?code=' + countryCode}>Continue</Link> : null } 
                  <Button color="link" onClick={() => goBack()}>Cancel</Button>
                </div>
              </Col>
        </Row>                        
      </Container>
    )
  }

  export default NewCustomersSelectCountry