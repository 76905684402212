import firebase from "../firebase"
import config from "../config"
import { getAxiosClient } from "../lib/axios"
import axios from "../lib/httpclient"
import vaccinationRepository from "../features/vaccination/lib/vaccinationRepository"

export function toggleNavbar() {
    return {
        type: "TOGGLE_NAVBAR",
    }
}

export function setUser(user) {
    return {
        type: "SET_USER",
        payload: user,
    }
}

export function setAdmin(user) {
    return {
        type: "SET_ADMIN",
        payload: user,
    }
}

export function login(email, password) {
    return {
        type: "LOGIN",
        payload: firebase.auth().signInWithEmailAndPassword(email, password),
    }
}

export function logout() {
    return {
        type: "LOGOUT",
        payload: firebase.auth().signOut(),
    }
}

export function fetchCompanies(category) {
    return {
        type: "FETCH_COMPANIES",
        payload: firebase
            .database()
            .ref(`/registrations`)
            .orderByChild("category")
            .equalTo(category)
            .once("value"),
    }
}

export function fetchElectricCompanies() {
    return {
        type: "FETCH_ELECTRIC_COMPANIES",
        payload: axios.get("/api/electric/companies"),
    }
}

export function fetchVaccinationOrganizations() {
    return {
        type: "FETCH_VACCINATION_ORGANIZATIONS",
        payload: axios.get("/api/vaccination/organizations"),
    }
}

export function fetchBloodBankOrganizations() {
    return {
        type: "FETCH_BLOOD_BANK_ORGANIZATIONS",
        payload: axios.get("/api/bloodbank/organizations"),
    }
}

export function fetchVaccinationOrganizationMembers(accountId) {
    return {
        type: "FETCH_VACCINATION_ORGANIZATION_MEMBERS",
        payload: axios.get(`/api/vaccination/organization/${accountId}/members/`),
    }
}

export function fetchVaccines() {
    return {
        type: "FETCH_VACCINES",
        payload: axios.get("/api/vaccination/vaccines"),
    }
}

export function fetchVaccinationStats(accountId) {
    return {
        type: "FETCH_VACCINATION_STATS",
        payload: vaccinationRepository.fetchVaccinationStats(accountId),
    }
}

export function fetchPatientVaccinations(mobileNumber) {
    return {
        type: "FETCH_PATIENT_VACCINATIONS",
        payload: vaccinationRepository.fetchPatientVaccinations(mobileNumber),
    }
}

export function fetchVaccinesForAccount(uid) {
    return {
        type: "FETCH_ACCOUNT_VACCINES",
        payload: axios.get(`/api/vaccination/vaccines/${uid}`),
    }
}

export function fetchNoShows(accountId) {
    return {
        type: "FETCH_NO_SHOWS",
        payload: vaccinationRepository.fetchNoShows(accountId),
    }
}

export function selectVaccines(uid, vaccines) {
    return {
        type: "SELECT_VACCINES",
        payload: axios.post(`/api/vaccination/select-vaccines`, { uid, vaccines }),
    }
}

export function fetchRates(user) {
    return {
        type: "FETCH_RATES",
        payload: firebase
            .database()
            .ref(`/rates`)
            .orderByChild("countryName")
            .once("value"),
    }
}

export function updateRate(values) {
    return {
        type: "UPDATE_RATE",
        payload: new Promise((resolve, reject) => {
            firebase
                .database()
                .ref(`/rates/${values.countryCode}`)
                .set(values)
                .then(() => {
                    resolve(values)
                })
                .catch((error) => {
                    reject(error)
                })
        }),
    }
}

export function setAdminCountry(country) {
    return {
        type: "SET_ADMIN_COUNTRY",
        payload: country,
    }
}

export function setAdminCompany(company) {
    return {
        type: "SET_ADMIN_COMPANY",
        payload: company,
    }
}

export function setAdminCurrency(currency) {
    return {
        type: "SET_ADMIN_CURRENCY",
        payload: currency,
    }
}

export function setAdminRate(rate) {
    return {
        type: "SET_ADMIN_RATE",
        payload: rate,
    }
}

export function setCountryOfOperation(uid, code, value) {
    return {
        type: "SET_COUNTRY_OF_OPERATION",
        payload: axios.post(`/api/data/set-country-of-operation`, {
            uid,
            code,
            value,
        }),
    }
}

export function setUserActive(user, value) {
    return {
        type: "SET_USER_ACTIVE",
        payload: axios.post(`/api/activate`, { uid: user.uid, state: value }),
    }
}

export function requestAccountActivation(user, value) {
    return {
        type: "REQUEST_ACCOUNT_ACTIVATION",
        payload: axios.post(`/api/request-activation`, {
            company: user.companyShortName,
        }),
    }
}

/*
export function setMessagingServiceId(uid, value) {
    return {
        type: "SET_MESSAGING_SERVICE", 
        payload: axios.post(`/api/messaging-service`, {uid: uid, value: value})
    }
}*/

export function setVaccinationUserActive(user, value) {
    return {
        type: "SET_USER_ACTIVE",
        payload: axios.post(`/api/vaccination/activate`, {
            uid: user.uid,
            state: value,
        }),
    }
}

export function setBloodBankUserActive(user, value) {
    return {
        type: "SET_USER_ACTIVE",
        payload: axios.post(`/api/bloodbank/activate`, {
            uid: user.uid,
            state: value,
        }),
    }
}

export function setElectricUserActive(user, value) {
    return {
        type: "SET_USER_ACTIVE",
        payload: axios.post(`/api/electric/activate`, {
            uid: user.uid,
            state: value,
        }),
    }
}

export function fetchUser(email) {
    return {
        type: "FETCH_USER",
        payload: firebase
            .database()
            .ref(`/registrations`)
            .orderByChild("email")
            .equalTo(email)
            .once("value"),
    }
}

export function fetchElectricUser(email) {
    return {
        type: "FETCH_ELECTRIC_USER",
        payload: axios.get(`/api/electric/user?email=${encodeURIComponent(email)}`),
    }
}

export function fetchVaccinationUser(email) {
    return {
        type: "FETCH_VACCINATION_USER",
        payload: axios.get(
            `/api/vaccination/organizations?email=${encodeURIComponent(email)}`
        ),
    }
}

export function fetchBloodBankUser(email) {
    return {
        type: "FETCH_BLOOD_BANK_USER",
        payload: axios.get(`/api/bloodbank/user?email=${encodeURIComponent(email)}`),
    }
}

export function vaccinationOrganizationInviteMember(email, accountId) {
    return {
        type: "VACCINATION_ORGANIZATION_INVITE_MEMBER",
        payload: axios.post(`/api/vaccination/organization/invite-member`, {
            email,
            accountId,
        }),
    }
}

export function vaccinationOrganizationDeleteMember(email, accountId) {
    return {
        type: "VACCINATION_ORGANIZATION_DELETE_MEMBER",
        payload: axios.delete(
            `/api/vaccination/organization/${accountId}/member/${encodeURIComponent(
                email
            )}`
        ),
    }
}

export function fetchStats(user) {
    return {
        type: "FETCH_STATS",
        payload: firebase.database().ref(`/stats/${user.uid}`).once("value"),
    }
}

export function fetchCreditFinanceStats(user, type) {
    return {
        type: "FETCH_CREDIT_FINANCE_STATS",
        payload: firebase.database().ref(`/${type}-stats/${user.uid}`).once("value"),
    }
}

export function fetchCreditFinanceEmailStats(user, type) {
    return {
        type: "FETCH_CREDIT_FINANCE_EMAIL_STATS",
        payload: firebase
            .database()
            .ref(`/${type}-email-stats/${user.uid}`)
            .once("value"),
    }
}

export function fetchChristmasStats(user) {
    return {
        type: "FETCH_CHRISTMAS_STATS",
        payload: firebase.database().ref(`/christmas-stats/${user.uid}`).once("value"),
    }
}

export function fetchBirthdayStats(user) {
    return {
        type: "FETCH_BIRTHDAY_STATS",
        payload: firebase.database().ref(`/birthday-stats/${user.uid}`).once("value"),
    }
}

export function fetchDivaliStats(user) {
    return {
        type: "FETCH_DIVALI_STATS",
        payload: firebase.database().ref(`/divali-stats/${user.uid}`).once("value"),
    }
}

export function fetchEidStats(user) {
    return {
        type: "FETCH_EID_STATS",
        payload: firebase.database().ref(`/eid-stats/${user.uid}`).once("value"),
    }
}

export function fetchPropertyStats(user) {
    return {
        type: "FETCH_PROPERTY_STATS",
        payload: firebase.database().ref(`/property-stats/${user.uid}`).once("value"),
    }
}

export function fetchHealthStats(user) {
    return {
        type: "FETCH_HEALTH_STATS",
        payload: firebase.database().ref(`/health-stats/${user.uid}`).once("value"),
    }
}

export function fetchCustomMessageStats(user) {
    return {
        type: "FETCH_CUSTOM_MESSAGE_STATS",
        payload: firebase.database().ref(`/custom-stats/${user.uid}`).once("value"),
    }
}

export function fetchEmailStats(user, type) {
    return {
        type: `FETCH_${type.toUpperCase()}_EMAIL_STATS`,
        payload: firebase
            .database()
            .ref(`/${type}-email-stats/${user.uid}`)
            .once("value"),
    }
}

export function setRegistrationField(params) {
    return {
        type: "SET_REGISTRATION_FIELD",
        payload: params,
    }
}

export function setRegistrationCountryOfOperation(params) {
    return {
        type: "SET_REGISTRATION_COUNTRY_OF_OPERATION",
        payload: params,
    }
}

export function setRegistrationFields(params) {
    return {
        type: "SET_REGISTRATION_FIELDS",
        payload: params,
    }
}

export function setMessage(message) {
    return {
        type: "SET_MESSAGE",
        payload: { content: message },
    }
}

export function resetMessage() {
    return {
        type: "RESET_MESSAGE",
        payload: {},
    }
}

export function sendMessage(user, message, sendEmail, countryCode) {
    return {
        type: "SEND_MESSAGE",
        payload: axios.post(`/api/send-message`, {
            userId: user.uid,
            category: user.category,
            message: message,
            sendEmail: sendEmail,
        }),
    }
}

export function registerUser(info) {
    return {
        type: "REGISTER_USER",
        payload: axios.post(`/api/register`, info),
    }
}

export function registerVaccinationUser(info) {
    return {
        type: "REGISTER_USER",
        payload: axios.post(`/api/vaccination/register`, info),
    }
}

export function registerBloodBankUser(info) {
    return {
        type: "REGISTER_USER",
        payload: axios.post(`/api/bloodbank/register`, info),
    }
}

export function registerElectricUser(info) {
    return {
        type: "REGISTER_USER",
        payload: axios.post(`/api/electric/register`, info),
    }
}

export function getLogoDisplay(user) {
    return {
        type: "GET_LOGO_DISPLAY",
        payload: axios.get(`/api/logo-display/${user.uid}`),
    }
}

export function getLandingPageLogos() {
    return {
        type: "GET_LANDING_PAGE_LOGOS",
        payload: axios.get(`/api/landing-page-logos`),
    }
}

export function setLogoDisplay(user, value) {
    return {
        type: "SET_LOGO_DISPLAY",
        payload: axios.post(`/api/logo-display`, { user, value }),
    }
}

export function setSMSNumber(user, value) {
    return {
        type: "SET_SMS_NUMBER",
        payload: axios.post(`/api/set-sms-number`, { user, value }),
    }
}

export function setMessagingServiceId(user, messagingServiceId) {
    return {
        type: "SET_MESSAGING_SERVICE_ID",
        payload: axios.post(`/api/messaging-service-id`, {
            user,
            messagingServiceId,
        }),
    }
}

export function setElectricMessagingServiceSid(uid, messagingServiceSid) {
    return {
        type: "SET_MESSAGING_SERVICE_ID",
        payload: axios.post(`/api/electric/messaging-service-id`, {
            uid,
            messagingServiceSid,
        }),
    }
}

export function setVaccinationMessagingServiceId(uid, messagingServiceId) {
    return {
        type: "SET_VACCINATION_MESSAGING_SERVICE_ID",
        payload: axios.post(`/api/vaccination/messaging-service-id`, {
            uid,
            messagingServiceId,
        }),
    }
}

export function setBloodBankMessagingServiceId(uid, messagingServiceId) {
    return {
        type: "SET_BLOODBANK_MESSAGING_SERVICE_ID",
        payload: axios.post(`/api/bloodbank/messaging-service-id`, {
            uid,
            messagingServiceId,
        }),
    }
}

export function setSubAccountSid(uid, subAccountSid) {
    return {
        type: "SET_SUBACCOUNT_SID",
        payload: axios.post(`/api/bloodbank/subaccount-sid`, {
            uid,
            subAccountSid,
        }),
    }
}

export function setElectricSubAccountSid(uid, subAccountSid) {
    return {
        type: "SET_SUBACCOUNT_SID",
        payload: axios.post(`/api/electric/subaccount-sid`, {
            uid,
            subAccountSid,
        }),
    }
}

export function fetchSettings(uid) {
    return {
        type: "FETCH_SETTINGS",
        payload: firebase.database().ref(`/settings/${uid}`).once("value"),
    }
}

export function setSettings(user, values) {
    return {
        type: "SET_SETTINGS",
        payload: new Promise((resolve, reject) => {
            firebase
                .database()
                .ref(`/settings/${user.uid}`)
                .update(values)
                .then(() => {
                    resolve(values)
                })
                .catch((error) => {
                    reject(error)
                })
        }),
    }
}

export function uploadCustomers(user, data) {
    return {
        type: "UPLOAD_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-customers", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadMultipleVehicleCustomers(user, data) {
    return {
        type: "UPLOAD_MULTIPLE_VEHICLE_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-multiple-vehicle-customers", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadCreditFinanceCustomers(id, code, data) {
    return {
        type: "UPLOAD_CREDIT_FINANCE_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-credit-finance-customers", {
            id,
            xlsx: data,
            code,
        }),
    }
}

export function uploadCreditFinanceReminders(id, code, data, type) {
    return {
        type: "UPLOAD_CREDIT_FINANCE_REMINDERS",
        payload: axios.post("/api/data/xlsx-credit-finance-reminders", {
            id,
            xlsx: data,
            code,
            type,
        }),
    }
}

export function uploadPropertyCustomers(user, data) {
    return {
        type: "UPLOAD_PROPERTY_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-property-customers", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadHealthCustomers(user, data) {
    return {
        type: "UPLOAD_HEALTH_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-health-customers", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadBirthdayCustomers(user, data) {
    return {
        type: "UPLOAD_BIRTHDAY_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-birthdays", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadChristmasCustomers(user, data) {
    return {
        type: "UPLOAD_CHRISTMAS_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-christmas", {
            id: user.uid,
            xlsx: data,
        }),
    }
}

export function uploadDivaliCustomers(user, data) {
    return {
        type: "UPLOAD_DIVALI_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-divali", { id: user.uid, xlsx: data }),
    }
}

export function uploadEidCustomers(user, data) {
    return {
        type: "UPLOAD_EID_CUSTOMERS",
        payload: axios.post("/api/data/xlsx-eid", { id: user.uid, xlsx: data }),
    }
}

export function fetchCounterStats(user) {
    return {
        type: "FETCH_COUNTER_STATS",
        payload: axios.get("/api/data/counter"),
    }
}

export function fetchUniqueCustomers(user) {
    return {
        type: "FETCH_UNIQUE_CUSTOMERS",
        payload: axios.get(
            `/api/data/unique-customer-count/${user.category}/${user.uid}`
        ),
    }
}

export function fetchTotalCreditFinanceUniqueCustomers(uid) {
    return {
        type: "FETCH_TOTAL_CREDIT_FINANCE_UNIQUE_CUSTOMERS",
        payload: axios.get(`/api/data/total-credit-finance-unique-customer-count/${uid}`),
    }
}

export function fetchCreditFinanceAggregateStats(uid) {
    return {
        type: "FETCH_CREDIT_FINANCE_AGGREGATE_STATS",
        payload: axios.post(`/api/data/credit-finance-aggregate-stats`, { uid }),
    }
}

export function fetchCreditFinanceAggregateEmailStats(uid) {
    return {
        type: "FETCH_CREDIT_FINANCE_AGGREGATE_EMAIL_STATS",
        payload: axios.post(`/api/data/credit-finance-aggregate-email-stats`, {
            uid,
        }),
    }
}

export function uploadImage(user, data) {
    return {
        type: "UPLOAD_IMAGE",
        payload: axios.post(`/api/data/upload-image`, data),
    }
}

export function setSetupState(uid, value) {
    return {
        type: "SET_SETUP_STATE",
        payload: axios.post(`/api/settings-setup`, { uid, value }),
    }
}

export function getSetupState(user) {
    return {
        type: "GET_SETUP_STATE",
        payload: axios.get(`/api/data/setup-state?uid=${user.uid}`),
    }
}

export function saveAlert(uid, key, message) {
    return {
        type: "SET_ALERT",
        payload: axios.post(`/api/data/set-alert`, { uid, key, message }),
    }
}

export function setCountryPhone(uid, code, phone) {
    return {
        type: "SET_COUNTRY_PHONE",
        payload: axios.post(`/api/data/set-country-phone`, { uid, code, phone }),
    }
}

export function setAlert(uid, key, message) {
    return {
        type: "SET_ALERT",
        payload: Promise.resolve({ data: { key, message } }),
    }
}
