import React, { Component } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, Alert, Input } from "reactstrap"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import { useToasts } from "react-toast-notifications"

import {
    logout as logoutAction,
    setElectricUserActive,
    setLogoDisplay,
    getLogoDisplay,
    setElectricMessagingServiceSid as persistMessagingServiceId,
    setElectricSubAccountSid as persistSubAccountSid,
    fetchElectricUser,
} from "../../../actions"
import _, { debounce } from "lodash"
import { observer } from "mobx-react-lite"
import { electricStore } from "../data/electric.store"
import { electricUploadManager } from "../data/electric.upload-manager"
import { PlannedOutagesByLocationUpload } from "./components/PlannedOutagesByLocationUpload"
import { PlannedOutagesByFeederUpload } from "./components/PlannedOutagesByFeederUpload"

let timeout

function ElectricPlannedOutageAlerts() {
    const [messagingServiceId, setMessagingServiceId] = React.useState("")
    const [subAccountSid, setSubAccountSid] = React.useState("")
    const [token, setToken] = React.useState()
    const [selectedTab, setSelectedTab] = React.useState("Activity Summary")
    const [alertType, setAlertType] = React.useState("")

    const { fetchUniqueCustomerCount, fetchDisconnectionCount } = electricStore
    const {
        handleDisconnectionsUploadChanged,
        errorMessage,
        successMessage,
        setAddToast,
        setErrorMessage,
        setSuccessMessage,
    } = electricUploadManager

    const dispatch = useDispatch()
    const { user, admin, router, logoDisplay, settings } = useSelector((state) => state)
    const { addToast } = useToasts()

    console.log("settings ", settings)

    React.useEffect(() => {
        if (user) {
            //fetchOrganizationMembers(user.uid)
            setSuccessMessage("")
            setErrorMessage("")
            setMessagingServiceId(user.messagingServiceSid || "")
            setSubAccountSid(user.subAccountSid || "")
            dispatch(getLogoDisplay(user))
            fetchUniqueCustomerCount(user.uid)
            fetchDisconnectionCount(user.uid)
        }
    }, [user])

    React.useEffect(() => {
        setToken(localStorage.getItem("token"))
        setAddToast(addToast)
    }, [])

    React.useEffect(() => {
        if (admin) {
            console.log("outage user ", user)
            dispatch(fetchElectricUser(user.email))
        }
    }, [admin])

    const selectCountry = (evt) => {
        const code = evt.target.value
        dispatch(push(router.location.pathname + "/" + code))
    }

    const logout = (evt) => {
        dispatch(logoutAction()).then(() => {
            dispatch(push("/"))
        })
    }

    const toggleActivation = (evt) => {
        const proceed = window.confirm("Proceed with this operation?")

        if (proceed) {
            dispatch(setElectricUserActive(user, !user.active))
        }
    }

    const getVerifiedMembers = () => {
        return vaccinationOrganizationMembers.filter(
            (item) => item.invitationStatus === "accepted"
        )
    }

    const hasLogoData = () => {
        return logoDisplay.data !== undefined && logoDisplay.data !== null
    }

    const handleMessagingServiceIdChange = async (evt) => {
        evt.persist()

        setMessagingServiceId(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistMessagingServiceId(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting Messaging Service Id: " + e.message)
            }
        }, 1000)
    }

    const handleSubAccountChange = async (evt) => {
        evt.persist()

        setSubAccountSid(evt.target.value)

        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(async () => {
            try {
                dispatch(persistSubAccountSid(user.uid, evt.target.value))
            } catch (e) {
                alert("Error setting SubAccount SID: " + e.message)
            }
        }, 1000)
    }

    const handleAddCustomers = () => {
        setSelectedTab("Admin")
    }

    const handleAlertOptionChanged = (evt) => {
        setSuccessMessage("")
        setErrorMessage("")
        setAlertType(evt.target.value)
    }

    const handleGoBack = () => {
        setAlertType("")
    }

    const renderUploadComponent = () => {
        if (alertType === "location") {
            return <PlannedOutagesByLocationUpload goBack={handleGoBack} />
        } else if (alertType === "feeder") {
            return <PlannedOutagesByFeederUpload goBack={handleGoBack} />
        } else {
            return <></>
        }
    }

    const dashboardLink = `/electric/${user?.country?.isoCode}/${user?.email}`

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <div>
                        <img src={user.logo} height="50" />
                    </div>
                    <div className="mr-2 d-flex flex-column align-items-center">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <a href="#" onClick={logout}>
                                Logout
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (!user) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Alerts</h1>

                            <Alert color="info">
                                <Link to="/auth/login">Login</Link> required.
                            </Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    if (user && !user.active && !admin) {
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10} xs={12} className="">
                            <h1>Alerts</h1>

                            <Alert color="info">Your account is pending activation</Alert>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }

    return (
        <div>
            <Header right={right} />
            <Container>
                <Row className="justify-content-center">
                    <Col md={10} xs={12} className="">
                        <h2>Alerts</h2>
                        {user ? (
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{user.shortName}</h4>
                            </div>
                        ) : null}

                        <div className="d-flex flex-column mt-4">
                            <a href="#">
                                <h4>
                                    <span className="text-uppercase">
                                        Send New Alerts
                                    </span>{" "}
                                    &gt; Planned Outages
                                    {alertType.length &&
                                    alertType !== "Send Alerts By" ? (
                                        <span style={{ textTransform: "capitalize" }}>
                                            &nbsp;&gt; By {alertType}
                                        </span>
                                    ) : null}
                                </h4>
                            </a>
                            {errorMessage?.length > 0 ? (
                                <Alert color="danger">{errorMessage}</Alert>
                            ) : null}

                            {successMessage?.length > 0 ? (
                                <Alert>
                                    <div>{successMessage}</div>
                                    <Link to={dashboardLink} onClick={handleGoBack}>
                                        Go to dashboard
                                    </Link>
                                </Alert>
                            ) : null}
                        </div>

                        <div className="d-flex flex-column align-items-center mt-3">
                            <Input
                                onChange={handleAlertOptionChanged}
                                value={alertType}
                                type="select"
                                placeholder="Send Alerts By"
                                style={{ width: "200px" }}
                                className="p-2"
                            >
                                <option label="Send Alerts By">Send Alerts By</option>
                                <option value="location" label="Location">
                                    Location
                                </option>
                                {settings.useFeeders ? (
                                    <option value="feeder" label="Feeder">
                                        Feeder
                                    </option>
                                ) : null}
                            </Input>
                        </div>

                        {renderUploadComponent()}

                        <div className="d-flex flex-column pt-3">
                            <Link
                                to={dashboardLink}
                                onClick={handleGoBack}
                                className="align-items-center"
                            >
                                <img src="/images/backtodashboard_icon.png" width={20} />
                                <span className="ml-2">Go back to dashboard</span>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}

export default observer(ElectricPlannedOutageAlerts)
