import React, { Component, useState } from 'react';
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import Footer from '../../components/Footer';
import { settingToSetupStep } from '../../utils'

const StepFive = (props) =>  {
  
  const [message, setMessage] = useState(`Dear valued customer, thanks for choosing ${props.user.companyShortName} for your credit and financing needs. Please call us at <xxx xxxx> and we will help with what you need!`)

  const previousStep = () => {
    props.setSetupState(props.user.uid, "4").then(_ => {
      props.fetchSettings(props.user.uid);
    });
  }

  const settingsList =  [
    'credit-account-reminders-before-due',
    'credit-account-alerts-overdue',
    'credit-account-alerts-30-plus-days-overdue',
    'loan-account-reminders-before-due',
    'loan-account-alerts-overdue',
    'loan-account-alerts-30-plus-days-overdue',
    'alert-type-greetings-birthday',
    'alert-type-greetings-christmas',
    'alert-type-greetings-divali',
    'alert-type-greetings-eid'    
]

  const getNextStep = () => {
    for(let item of settingsList) {      
      if( props.settings[item]) {
        return item; 
      }      
    }
  }

  const saveSetting = (key, value) => {
    return props.setSettings(props.user, {[key]: value})
  }  

  const skipGreetings = () => {
    saveSetting("setup-complete", true).then(res => {
      props.goBack()
    })
  }  
  
  const nextStep = () => {
    
    props.saveAlert(props.user, "introductoryMessage", props.user.introductoryMessage).then(res => {      
      //props.fetchSettings(props.user); 
      let stepNumber = settingToSetupStep(getNextStep())

      if(stepNumber !== undefined) {
        props.setSetupState(props.user.uid, stepNumber).then(_ => {
          props.fetchSettings(props.user.uid);
        });
      }
      else {
        skipGreetings()
      }
    });
  }

    return (
    <Container>
    <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
        <h1>Setup</h1>
        <h3>Configure an introductory message</h3>

        <Form>
          <FormGroup>
            <span>You have added the following Introductory Message. If
correct, please continue. Otherwise, go <Button className="p-0" color="link" onClick={previousStep}>back</Button> to edit.</span>

          <div className="text-primary">{props.user.introductoryMessage}</div>

          </FormGroup>
        </Form>

        <div className="pt-4 pb-4">
            <Button 
              color="success" 
              disabled={false}
              onClick={nextStep}>Save and go to next</Button>
            <Button className="ml-4" onClick={() => {              
              props.goBack()
            }}>Cancel</Button>
          </div>      
        
        </Col>                
    </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default StepFive; 