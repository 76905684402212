import React, { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Alert, Input, CustomInput, Label, Form, FormGroup } from 'reactstrap';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logout from '../components/Logout';

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings as fetchSettingsAction, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  setAlert,
  saveAlert,  
  setCountryPhone as setCountryPhoneAction,  
  setSettings as setSettingsAction, 
  setAdminCountry} from '../actions'

  import { goBack as goBackAction} from 'connected-react-router'  

  const SettingsPaymentRemindersContainer = () => {
    const email = useSelector(state => state.router.location.pathname.split("/")[3])
    const user = useSelector(state => state.user) 
    const settings = useSelector(state => state.settings) 

    //const [user, setUser] = useState(null)
    //const [settings, setSettings] = useState(null)
    const dispatch = useDispatch(); 
    const fetchUser = (email) => dispatch(fetchUserAction(email))
    const fetchSettings = (uid) => dispatch(fetchSettingsAction(uid))    
  
    useEffect(() => {      

      fetchUser(email)    
    }, [email]);

    useEffect(() => {      

      fetchSettings(user ? user.uid : null)    
    }, [user]);    
    
    if(user && settings) {      
      return <SettingsPaymentReminders settings={settings} user={user} />
    }
    else {
      return null
    }      
  } 

const right = (<div>Logout</div>)  

const SettingsPaymentReminders = (props) =>  {
  
  const dispatch = useDispatch()
  const settings = useSelector(state => state.settings)
  const setSettings = (user, value) => dispatch(setSettingsAction(user, value)) 
  const goBack = () => dispatch(goBackAction())  

  const nextStep = () => {
    props.setSetupState(props.user.uid, "4").then(res => {      
      props.fetchSettings(props.user); 
    });
  }

  const saveSetting = (key, value) => {    
    setSettings(props.user, {[key]: value}).then(res => {      
    })
  }

    return (
    <Container>
      <Header right={<Logout user={props.user} />}></Header>
      <Row className="justify-content-center">
          <Col sm={6} xs={12} className="">
          <h1>Setup</h1>
          <h3>Payment Reminders</h3>
          <h5>Choose your alerts</h5>

          <strong>Introductory Alerts are sent by default</strong>

          <Form>
            <FormGroup>
              <h1 className="text-primary">Credit</h1>
              <div>
                <CustomInput 
                  type="switch" 
                  label="Credit account reminders BEFORE due" 
                  id="switch1" 
                  checked={settings['credit-account-reminders-before-due'] || false}
                  onChange={(evt) => saveSetting("credit-account-reminders-before-due", evt.target.checked)} ></CustomInput>
              </div>

              <div>
                <CustomInput 
                  type="switch" 
                  label="Credit account alerts OVERDUE" 
                  id="switch2"
                  checked={settings['credit-account-alerts-overdue'] || false}
                  onChange={(evt) => saveSetting("credit-account-alerts-overdue", evt.target.checked)} ></CustomInput>
              </div>

              <div>
                <CustomInput                
                  type="switch" 
                  label="Credit account alerts 30+ Days OVERDUE" 
                  id="switch3"
                  checked={settings['credit-account-alerts-30-plus-days-overdue'] || false} 
                  onChange={(evt) => saveSetting("credit-account-alerts-30-plus-days-overdue", evt.target.checked)} ></CustomInput>
              </div>            
            </FormGroup>

            <FormGroup>
              <h1 className="text-primary">Loan</h1>
              <div>
                <CustomInput 
                  type="switch" 
                  label="Loan account reminders BEFORE due" 
                  id="switch4" 
                  checked={settings['loan-account-reminders-before-due'] || false} 
                  onChange={(evt) => saveSetting("loan-account-reminders-before-due", evt.target.checked)} ></CustomInput>
              </div>

              <div>
                <CustomInput 
                  type="switch" 
                  label="Loan account alerts OVERDUE" 
                  id="switch5"
                  checked={settings['loan-account-alerts-overdue'] || false} 
                  onChange={(evt) => saveSetting("loan-account-alerts-overdue", evt.target.checked)} ></CustomInput>
              </div>

              <div>
                <CustomInput 
                  type="switch" 
                  label="Loan account alerts 30+ Days OVERDUE" 
                  id="switc6"
                  checked={settings['loan-account-alerts-30-plus-days-overdue'] || false} 
                  onChange={(evt) => saveSetting("loan-account-alerts-30-plus-days-overdue", evt.target.checked)} ></CustomInput>
              </div>            
            </FormGroup>       

            <div>
            All changes are saved automatically
            </div>   
          </Form>

          <div className="pt-4 pb-4">
              <Button color="link" className="ml-4" onClick={() => {                
                goBack()
              }}>Back</Button>
            </div>      
          
          </Col>                
      </Row>
    <div className="text-center">
        <Footer />
    </div>                
  </Container> 
  );
}

export default SettingsPaymentRemindersContainer; 