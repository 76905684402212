import React, { Component } from "react"
import { connect } from "react-redux"
import logo from "./logo.svg"
import "./App.css"
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
    push,
} from "react-router-redux"
import { Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    Input,
    Alert,
} from "reactstrap"

import Header from "./components/Header"
import Footer from "./components/Footer"

import { login } from "./actions"
import firebase from "./firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

class AppContainer extends Component {
    state = {
        email: "",
        success: null,
        password: null,
        errorMessage: null,
        viewPassword: false,
        resetCodeValid: null,
        vaxInvite: false,
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search)

        this.oobCode = urlParams.get("oobCode")
        this.setState({ vaxInvite: urlParams.get("s") === "vax" })

        firebase
            .auth()
            .verifyPasswordResetCode(this.oobCode || "")
            .then((email) => {
                this.setState({ resetCodeValid: true })
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message, resetCodeValid: false })
            })
    }

    toggleViewPassword = () => {
        this.setState({ viewPassword: !this.state.viewPassword })
    }

    getPasswordInputType = () => {
        if (this.state.viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    getEyeIcon = () => {
        if (this.state.viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }

    passwordValid = () => {
        return this.state.password !== null && this.state.password.length > 0
    }

    submitForm(evt) {
        evt.preventDefault()

        firebase
            .auth()
            .confirmPasswordReset(this.oobCode, this.state.password)
            .then((res) => {
                this.setState({ success: true, errorMessage: null })
            })
            .catch((error) => {
                this.setState({ success: false, errorMessage: error.message })
            })
    }

    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        {this.state.vaxInvite ? (
                            <div
                                className="d-flex flex-column align-items-center"
                                style={{ maxWidth: 220 }}
                            >
                                Your user account has been created with a default
                                password. You must set your own password before you can
                                login.
                                <p className="mt-2 font-weight-bold">
                                    Enter your password below
                                </p>
                            </div>
                        ) : (
                            <h1>New Password</h1>
                        )}
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={3} xs={12} className="">
                            {this.state.success ? (
                                <Alert color="success">
                                    Password updated. You may now{" "}
                                    <Link to="/auth/login">login</Link>.
                                </Alert>
                            ) : null}
                            {this.state.errorMessage ? (
                                <Alert color="danger">{this.state.errorMessage}</Alert>
                            ) : null}

                            {this.state.resetCodeValid && !this.state.success ? (
                                <Form onSubmit={this.submitForm.bind(this)}>
                                    <FormGroup>
                                        {!this.state.vaxInvite ? (
                                            <Label for="examplePassword">Password</Label>
                                        ) : null}
                                        <InputGroup>
                                            <Input
                                                type={this.getPasswordInputType()}
                                                name="password"
                                                id="examplePassword"
                                                placeholder="Enter password"
                                                onChange={(evt) =>
                                                    this.setState({
                                                        password: evt.target.value,
                                                    })
                                                }
                                            />
                                            <InputGroupAddon
                                                className="view-password"
                                                addonType="append"
                                                onClick={this.toggleViewPassword}
                                            >
                                                <FontAwesomeIcon
                                                    icon={this.getEyeIcon()}
                                                ></FontAwesomeIcon>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>

                                    <div className="text-center">
                                        <Button
                                            color="success"
                                            disabled={!this.passwordValid()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            ) : !this.state.success ? (
                                <Link to="/reset-password">
                                    Try resetting your password again
                                </Link>
                            ) : null}
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
