import React, { Component } from 'react';
import {connect} from 'react-redux';
import '../App.css';
import { ConnectedRouter, routerReducer, routerMiddleware, push, goBack } from 'react-router-redux'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Header from '../components/Header'; 
import Footer from '../components/Footer'; 

import countries from '../countries.json'; 

import { setRegistrationField, setCountryOfOperation } from '../actions';  

let countryTable = {}
countries.forEach(country => {
    countryTable[country.code.toLowerCase()] = country.name
});

class AppContainer extends Component {

    selectCountry(evt) {
        const code = evt.target.value; 
        this.props.dispatch(push(this.props.match.url + '/' + code)); 
        this.props.dispatch(setRegistrationField({'country': code})); 
        this.props.dispatch(setCountryOfOperation({countryCode: code, value: true}));

    }

    render() {
        let countriesOfOperation = this.props.registration.countriesOfOperation

        return (
        <div className="">
            <Header />
            <Container>

            <Row>
                <Col sm={3} xs={12} className="">
                    <h1>Register</h1>
                </Col>
            </Row>
            <Row className="justify-content-center">                
                <Col sm={6} xs={12} className="">
                <h4 className="mb-4">You selected the following countries where you do business:</h4>
                {Object.keys(countriesOfOperation).map(code => <div key={code}>{countryTable[code]}</div>)}

                <div className="mt-4">If this is correct please continue, or go <a href="#" onClick={() => this.props.dispatch(goBack())}>back</a> and correct any errors</div>

                </Col>                
            </Row>

            <Row className="justify-content-center mt-4"> 
                <Link to="/auth/credit-finance/register" className="btn btn-success">Continue</Link>
            </Row>

            <div className="text-center">
                <Footer />
            </div>            
            </Container>    
        </div>
        )
    }
}

const App = connect(state => ({
  location: state.location,
  registration: state.registration
}))(AppContainer); 

export default App;
