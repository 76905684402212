import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import { Container, Row, Col, ListGroup, ListGroupItem, Alert } from "reactstrap"

import Header from "./components/Header"
import Footer from "./components/Footer"

import { logout } from "./actions"

class AppContainer extends Component {
    logout(evt) {
        evt.preventDefault()
        this.props.logout().then(() => {
            this.props.dispatch(push("/"))
        })
    }

    render() {
        const { admin } = this.props

        let right = null
        if (admin) {
            right = (
                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {admin.email}
                        </div>
                        <div>
                            <a href="#" onClick={this.logout.bind(this)}>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div>
                        <img src={`/flag-icon-css/flags/4x3/vc.svg`} height={30} />
                    </div>
                </div>
            )
        }

        if (!this.props.admin) {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <h1>Admin</h1>
                        </Row>

                        <Row className="justify-content-center">
                            <Col sm={3} xs={12}>
                                <Alert color="danger">Not Authorized</Alert>

                                <div className="text-center">
                                    <Link to="/auth/login">Login</Link>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }
        return (
            <div>
                <Header right={right} />
                <Container>
                    <Row className="justify-content-center">
                        <div className="d-flex flex-column">
                            <img src="images/admin_icon.svg" height="100" />
                            <h1>Admin</h1>
                        </div>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={3} xs={12}>
                            <ListGroup>
                                <ListGroupItem>
                                    <Link to="/admin/credit-finance">Credit Finance</Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link to="/admin/insurance">Insurance</Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link to="/admin/vaccination">Vaccination</Link>
                                </ListGroupItem>
                                {/*<ListGroupItem>
                                    <Link to="/admin/bloodbank">Blood Bank</Link>
        </ListGroupItem>*/}
                                <ListGroupItem>
                                    <Link to="/admin/electric">Electric Utility</Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link to="/admin/other">Other</Link>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <Link to="/admin/rate-limiter">Rate Limiter</Link>
                                </ListGroupItem>
                            </ListGroup>
                            <div className="d-flex flex-row justify-content-center p-4">
                                <Link to="/admin-messaging">Send Message</Link>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    dispatch,
})

const App = connect(
    (state) => ({
        location: state.location,
        admin: state.admin,
    }),
    mapDispatchToProps
)(AppContainer)

export default App
