import React, { Component, useRef } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import "../../../App.css"
import { push as pushAction } from "react-router-redux"
import {
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    TextArea,
    Alert,
    InputGroupText,
} from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import * as Yup from "yup"
import { useFormik } from "formik"

let RegistrationSchema = Yup.object({
    bloodBankLongName: Yup.string().required("Bloodbank Long Name is required"),
    bloodBankShortName: Yup.string()
        .max(11, "Bloodbank Short Name must be less than 11 characters")
        .required("Bloodbank Short Name is required"),
    //covid19hotlineFlow: Yup.string().matches(/^\d+$/, "Not a valid phone number").length(7, "7 digit local number").required("Covid-19 FLOW Hotline is required"),
    //covid19hotlineDigicel: Yup.string().matches(/^\d+$/, "Not a valid phone number").length(7, "7 digit local number").required("Covid-19 Digicel Hotline is required"),
    phone: Yup.string()
        .matches(/^\d+$/, "Not a valid phone number")
        .length(7, "7 digit local number")
        .required("Phone is required"),
    website: Yup.string().url("Not a valid website").required("Website is required"),
    //ministryWebsiteShortURL: Yup.string().url("Not a valid website"),
    //vaccinationWebsite: Yup.string().url("Not a valid website").required("Vaccination website is required"),
    //vaccinationWebsiteShortURL: Yup.string().url("Not a valid website"),
    facebook: Yup.string().matches(/^((?!http:\/\/).)*$/, "URLs not accepted"),
    instagram: Yup.string().matches(/^((?!http:\/\/).)*$/, "URLs not accepted"),
    address1: Yup.string().required("Address is required"),
    //address2: Yup.string().required("Address is required"),
    email: Yup.string().email("Not a valid email").required("Email is required"),
    //facebook: Yup.string().url("Not a vaid website").required("Facebook page URL is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ),
})

import {
    setRegistrationFields as setRegistrationFieldsAction,
    setRegistrationField as setRegistrationFieldAction,
    registerUser as registerUserAction,
} from "../../../actions"
import ReCAPTCHA from "react-google-recaptcha"

import countries from "../../../countries-caribbean.json"

export default function HealthRegistration() {
    const [viewPassword, setViewPassword] = React.useState(false)
    const { registration } = useSelector((state) => state)
    const dispatch = useDispatch()

    const setRegistrationFields = (params) =>
        dispatch(setRegistrationFieldsAction(params))
    const setRegistrationField = (params) => dispatch(setRegistrationFieldAction(params))
    const registerUser = (info) => dispatch(registerUserAction(info))
    const push = (location) => dispatch(pushAction(location))

    const currentCountry = countries.find(
        (item) => item.code === registration?.country?.toUpperCase()
    )

    console.log("currentCountry ", currentCountry)

    const submitForm = (evt) => {
        evt.preventDefault()
        push("/auth/register/confirm")
    }

    const passwordsMatch = () => {
        const { password, confirmPassword } = registration

        if (password && confirmPassword) {
            return password === confirmPassword
        } else if (!password && !confirmPassword) {
            return true
        } else {
            return false
        }
    }

    const allFieldsFilled = () => {
        const {
            bloodBankLongName,
            bloodBankShortName,
            address1,
            address2,
            email,
            password,
            confirmPassword,
            ministryPhone,
        } = registration

        return (
            bloodBankLongName &&
            bloodBankLongName.length > 0 &&
            bloodBankShortName &&
            bloodBankShortName.length > 0 &&
            address1 &&
            address1.length > 0 &&
            address2 &&
            address2.length > 0 &&
            phone &&
            phone.length > 0 &&
            email &&
            email.length > 0 &&
            password === confirmPassword &&
            password &&
            password.length > 0 &&
            registration["g-recaptcha-response"] &&
            registration["g-recaptcha-response"].length > 0
        )
    }

    const toggleViewPassword = () => {
        setViewPassword(!viewPassword)
    }

    const getPasswordInputType = () => {
        if (viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    const getEyeIcon = () => {
        if (viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }
    const country = registration?.country ? registration.country.toLowerCase() : ""

    const flagUrl = `/flag-icon-css/flags/4x3/${country}.svg`

    const formik = useFormik({
        initialValues: {
            ...registration,
            dialCode: currentCountry.areaCodes[0],
        },
        onSubmit: (values) => {
            delete values.confirmPassword
            values.phone = `1${values.dialCode}${values.phone}`
            delete values.dialCode
            setRegistrationFields(values)
            push("/auth/bloodbank/register/confirm")
        },
        validationSchema: RegistrationSchema,
    })

    const inputRef = useRef()

    return (
        <div className="">
            <Header
                right={
                    <div>
                        <img src={flagUrl} style={{ height: "48px" }} />
                    </div>
                }
            />
            <Container>
                <Row className="justify-content-md-center justify-content-center p-2">
                    <Col md={4} xs={12}>
                        <div className="d-flex flex-column">
                            <h3>Blood Bank registration</h3>
                            <div>Fields labeled * are required</div>
                        </div>

                        <Form onSubmit={formik.handleSubmit} className="mt-4">
                            <FormGroup>
                                <Label for="organizationLongName">
                                    Blood Bank Long Name *
                                </Label>
                                {formik.touched.bloodBankLongName &&
                                    formik.errors.bloodBankLongName && (
                                        <Alert color="danger">
                                            {formik.errors.bloodBankLongName}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.bloodBankLongName}
                                    type="text"
                                    placeholder="Blood Bank Long Name"
                                    onChange={formik.handleChange("bloodBankLongName")}
                                    onBlur={formik.handleBlur("bloodBankLongName")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="bloodBankShortName">
                                    Blood Bank Short Name *
                                </Label>
                                {formik.touched.bloodBankShortName &&
                                    formik.errors.bloodBankShortName && (
                                        <Alert color="danger">
                                            {formik.errors.bloodBankShortName}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.bloodBankShortName}
                                    type="text"
                                    placeholder="Max 11 chars w spaces"
                                    onChange={formik.handleChange("bloodBankShortName")}
                                    onBlur={formik.handleBlur("bloodBankShortName")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address1">Address Line 1 *</Label>
                                {formik.touched.address1 && formik.errors.address1 && (
                                    <Alert color="danger">{formik.errors.address1}</Alert>
                                )}
                                <Input
                                    value={formik.values.address1}
                                    type="text"
                                    placeholder="Address Line 1"
                                    onChange={formik.handleChange("address1")}
                                    onBlur={formik.handleBlur("address1")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address1">Address Line 2</Label>
                                {formik.touched.address2 && formik.errors.address2 && (
                                    <Alert color="danger">{formik.errors.address2}</Alert>
                                )}
                                <Input
                                    value={formik.values.address2}
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={formik.handleChange("address2")}
                                    onBlur={formik.handleBlur("address2")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>Phone number *</Label>
                                {formik.touched.phone && formik.errors.phone && (
                                    <Alert color="danger">{formik.errors.phone}</Alert>
                                )}
                                <InputGroup>
                                    <InputGroupText>+ 1</InputGroupText>
                                    <Input
                                        type="select"
                                        ref={inputRef}
                                        value={formik.values.dialCode}
                                        onChange={formik.handleChange("dialCode")}
                                    >
                                        {currentCountry.areaCodes.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </Input>
                                    <Input
                                        style={{ flex: 3 }}
                                        value={formik.values.phone}
                                        type="number"
                                        placeholder="Local 7-digit number"
                                        onChange={formik.handleChange("phone")}
                                        onBlur={formik.handleBlur("phone")}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email *</Label>
                                {formik.touched.email && formik.errors.email && (
                                    <Alert color="danger">{formik.errors.email}</Alert>
                                )}
                                <Input
                                    value={formik.values.email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={formik.handleChange("email")}
                                    onBlur={formik.handleBlur("email")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    <strong>Website *</strong>
                                </Label>
                                {formik.touched.website && formik.errors.website && (
                                    <Alert color="danger">{formik.errors.website}</Alert>
                                )}
                                <Input
                                    value={formik.values.website}
                                    type="text"
                                    placeholder="Website"
                                    onChange={formik.handleChange("website")}
                                    onBlur={formik.handleBlur("website")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    <strong>Website</strong> short URL (optional)
                                </Label>
                                {formik.touched.websiteShortURL &&
                                    formik.errors.websiteShortURL && (
                                        <Alert color="danger">
                                            {formik.errors.websiteShortURL}
                                        </Alert>
                                    )}
                                <Input
                                    value={formik.values.websiteShortURL}
                                    type="text"
                                    placeholder="Website Short URL"
                                    onChange={formik.handleChange("websiteShortURL")}
                                    onBlur={formik.handleBlur("websiteShortURL")}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="facebook">Facebook (Optional)</Label>
                                {formik.touched.facebook && formik.errors.facebook && (
                                    <Alert color="danger">{formik.errors.facebook}</Alert>
                                )}
                                <InputGroup>
                                    <InputGroupText>fb.me/</InputGroupText>
                                    <Input
                                        value={formik.values.facebook}
                                        type="text"
                                        placeholder="Facebook"
                                        onChange={formik.handleChange("facebook")}
                                        onBlur={formik.handleBlur("facebook")}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="instagram">Instagram (Optional)</Label>
                                {formik.touched.instagram && formik.errors.instagram && (
                                    <Alert color="danger">
                                        {formik.errors.instagram}
                                    </Alert>
                                )}
                                <InputGroup>
                                    <InputGroupText>@</InputGroupText>
                                    <Input
                                        value={formik.values.instagram}
                                        type="text"
                                        placeholder="Instagram"
                                        onChange={formik.handleChange("instagram")}
                                        onBlur={formik.handleBlur("instagram")}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label for="password">Password</Label>
                                {formik.touched.password && formik.errors.password && (
                                    <Alert color="danger">{formik.errors.password}</Alert>
                                )}

                                <InputGroup>
                                    <Input
                                        required
                                        type={getPasswordInputType()}
                                        name="password"
                                        placeholder="Password"
                                        onChange={formik.handleChange("password")}
                                        onBlur={formik.handleBlur("password")}
                                    />
                                    <InputGroupAddon
                                        className="view-password"
                                        addonType="append"
                                        onClick={toggleViewPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={getEyeIcon()}
                                        ></FontAwesomeIcon>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Label>Confirm Password</Label>
                                {formik.touched.confirmPassword &&
                                    formik.errors.confirmPassword && (
                                        <Alert color="danger">
                                            {formik.errors.confirmPassword}
                                        </Alert>
                                    )}

                                <InputGroup>
                                    <Input
                                        required
                                        type={getPasswordInputType()}
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        onChange={formik.handleChange("confirmPassword")}
                                        onBlur={formik.handleBlur("confirmPassword")}
                                    />
                                    <InputGroupAddon
                                        className="view-password"
                                        addonType="append"
                                        onClick={toggleViewPassword}
                                    >
                                        <FontAwesomeIcon
                                            icon={getEyeIcon()}
                                        ></FontAwesomeIcon>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>

                            <ReCAPTCHA
                                className="captcha"
                                ref={(e) => {
                                    console.log("reCaptcha : ", e)
                                }}
                                sitekey="6LcfRjsUAAAAAOF5AgszQeZRBqtfNZVhf9MnU95h"
                                onChange={(response) => {
                                    console.log("captcha response", response)
                                    if (response) {
                                        formik.setFieldValue(
                                            "g-recaptcha-response",
                                            response
                                        )
                                        //setRegistrationField({ 'g-recaptcha-response': response })
                                    }
                                }}
                            />

                            <div className="text-center p-4">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={!formik.isValid}
                                >
                                    Register
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}
