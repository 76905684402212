import React, { Component, useState, useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { goBack } from 'react-router-redux'
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import classnames from 'classnames';
import { countryCodeToCountry, monthValueToMonthName } from '../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons'
import Header from '../components/Header'; 
import Footer from '../components/Footer';
import Logout from '../components/Logout';
import TokenExpired from '../components/TokenExpired';
import CreditFinanceStat from '../components/CreditFinanceStat'; 

import _ from 'lodash'; 

import { 
  fetchCreditFinanceStats as fetchCreditFinanceStatsAction, 
  fetchCreditFinanceEmailStats as fetchCreditFinanceEmailStatsAction 
} from '../actions'

const CreditFinanceStatsCountryDetailContainer = () => {
  let user = useSelector(state => state.user)

  if(!user) {
    return <TokenExpired />
  }
  else {
    return <CreditFinanceStatsCountryDetail user={user} />
  }
}

const CreditFinanceStatsCountryDetail = ({user}) => {

  let { country } = useParams()
  let dispatch = useDispatch()

  useEffect(() => {    
    fetchCreditFinanceStats(user, "creditPaymentReminderBeforeDueDate")  
    fetchCreditFinanceEmailStats(user, "creditPaymentReminderBeforeDueDate")
    
    fetchCreditFinanceStats(user, "overdueCreditPayment")  
    fetchCreditFinanceEmailStats(user, "overdueCreditPayment")
    
    fetchCreditFinanceStats(user, "criticallyOverdueCreditPayment")  
    fetchCreditFinanceEmailStats(user, "criticallyOverdueCreditPayment")
    
    fetchCreditFinanceStats(user, "loanPaymentReminderBeforeDueDate")  
    fetchCreditFinanceEmailStats(user, "loanPaymentReminderBeforeDueDate")    

    fetchCreditFinanceStats(user, "overdueLoanPayment")
    fetchCreditFinanceEmailStats(user, "overdueLoanPayment")

    fetchCreditFinanceStats(user, "criticallyOverdueLoanPayment")
    fetchCreditFinanceEmailStats(user, "criticallyOverdueLoanPayment")    

    fetchCreditFinanceStats(user, "creditFinanceBirthdayCustomers")
    fetchCreditFinanceEmailStats(user, "creditFinanceBirthdayCustomers")

    fetchCreditFinanceStats(user, "creditFinanceChristmasCustomers")
    fetchCreditFinanceEmailStats(user, "creditFinanceChristmasCustomers")
    
    fetchCreditFinanceStats(user, "creditFinanceDivaliCustomers")
    fetchCreditFinanceEmailStats(user, "creditFinanceDivaliCustomers")
    
    fetchCreditFinanceStats(user, "creditFinanceEidCustomers")
    fetchCreditFinanceEmailStats(user, "creditFinanceEidCustomers")    

    fetchCreditFinanceEmailStats(user, "creditFinanceCustom")
    fetchCreditFinanceStats(user, "creditFinanceCustom")    
  }, [user])

  let creditFinanceCustomStats = useSelector(state => state.creditFinanceStats.creditFinanceCustom ? state.creditFinanceStats.creditFinanceCustom : null)
  let creditFinanceCustomEmailStats = useSelector(state => state.creditFinanceEmailStats.creditFinanceCustom ? state.creditFinanceEmailStats.creditFinanceCustom : null)

  let creditPaymentReminderBeforeDueDateStats = useSelector(state => state.creditFinanceStats.creditPaymentReminderBeforeDueDate)
  let creditPaymentReminderBeforeDueDateEmailStats = useSelector(state => state.creditFinanceEmailStats.creditPaymentReminderBeforeDueDate)

  let overdueCreditPaymentStats = useSelector(state => state.creditFinanceStats.overdueCreditPayment)
  let overdueCreditPaymentEmailStats = useSelector(state => state.creditFinanceEmailStats.overdueCreditPayment)

  let criticallyOverdueCreditPaymentStats = useSelector(state => state.creditFinanceStats.criticallyOverdueCreditPayment)
  let criticallyOverdueCreditPaymentEmailStats = useSelector(state => state.creditFinanceEmailStats.criticallyOverdueCreditPayment)

  let loanPaymentReminderBeforeDueDateStats = useSelector(state => state.creditFinanceStats.loanPaymentReminderBeforeDueDate)
  let loanPaymentReminderBeforeDueDateEmailStats = useSelector(state => state.creditFinanceEmailStats.loanPaymentReminderBeforeDueDate)

  let overdueLoanPaymentStats = useSelector(state => state.creditFinanceStats.overdueLoanPayment)
  let overdueLoanPaymentEmailStats = useSelector(state => state.creditFinanceEmailStats.overdueLoanPayment)

  let criticallyOverdueLoanPaymentStats = useSelector(state => state.creditFinanceStats.criticallyOverdueLoanPayment)
  let criticallyOverdueLoanPaymentEmailStats = useSelector(state => state.creditFinanceEmailStats.criticallyOverdueLoanPayment)

  let creditFinanceBirthdayCustomersStats = useSelector(state => state.creditFinanceStats.creditFinanceBirthdayCustomers)
  let creditFinanceBirthdayCustomersEmailStats = useSelector(state => state.creditFinanceEmailStats.creditFinanceBirthdayCustomers)

  let creditFinanceChristmasCustomersStats = useSelector(state => state.creditFinanceStats.creditFinanceChristmasCustomers)
  let creditFinanceChristmasCustomersEmailStats = useSelector(state => state.creditFinanceEmailStats.creditFinanceChristmasCustomers)  

  let creditFinanceDivaliCustomersStats = useSelector(state => state.creditFinanceStats.creditFinanceDivaliCustomers)
  let creditFinanceDivaliCustomersEmailStats = useSelector(state => state.creditFinanceEmailStats.creditFinanceDivaliCustomers)  

  let creditFinanceEidCustomersStats = useSelector(state => state.creditFinanceStats.creditFinanceEidCustomers)
  let creditFinanceEidCustomersEmailStats = useSelector(state => state.creditFinanceEmailStats.creditFinanceEidCustomers)    

  let rates = useSelector(state => state.rates)

  let fetchCreditFinanceStats = (user, type) => dispatch(fetchCreditFinanceStatsAction(user, type))
  let fetchCreditFinanceEmailStats = (user, type) => dispatch(fetchCreditFinanceEmailStatsAction(user, type))
  let creditFinanceAggregateStats =  useSelector(state => state.creditFinanceAggregateStats)
  let creditFinanceAggregateEmailStats =  useSelector(state => state.creditFinanceAggregateEmailStats)  
  let uniqueCreditFinanceCustomers =  useSelector(state => state.uniqueCreditFinanceCustomers)

  const getCountryInfo = () => {
    if(uniqueCreditFinanceCustomers.customers) {
      return uniqueCreditFinanceCustomers.customers.find(item => item.countryCode === country)
    }
    else {
      return {}
    }
  }

  let countryInfo = getCountryInfo()

  return (
    <Container>
      <Header right={<Logout user={user} />} />     
      <Row className="justify-content-center">
        <Col sm={6} xs={12} className="">
          <h1>Country Summary</h1>
          <h5>{user.companyFullName}</h5>
          <div>                
            <FontAwesomeIcon icon={faUsers} size="2x"></FontAwesomeIcon>
            {!_.isEmpty(uniqueCreditFinanceCustomers) ? <span color="link" className="ml-2 mt-2 mb-2">{uniqueCreditFinanceCustomers.total} unique customers from {uniqueCreditFinanceCustomers.customers.length} {uniqueCreditFinanceCustomers.customers.length === 1 ? "country" : "countries"}</span> : <span>Empty</span>}                                            
          </div>  

          <div className="d-flex flex-row">
            <img src={`/flag-icon-css/flags/4x3/${country.toLowerCase()}.svg`} height={30} />
            <div className="d-flex flex-column ml-2">
              <h5><strong>Activity Summary - {countryCodeToCountry(country)}</strong></h5>
              <div>{countryInfo ? countryInfo.count : 0 } Unique customers</div>
            </div>
          </div>     

          <div className="d-flex flex-column">

            <h3 className="text-primary mt-4 mb-4">Credit</h3>    

            <CreditFinanceStat
              type="credit"
              title="BEFORE DUE CREDIT Reminders"
              stats={creditPaymentReminderBeforeDueDateStats}
              emailStats={creditPaymentReminderBeforeDueDateEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              type="credit"
              title="OVERDUE CREDIT Alerts"
              stats={overdueCreditPaymentStats}
              emailStats={overdueCreditPaymentEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              type="credit"
              title="CRITICALLY OVERDUE CREDIT Alerts"
              stats={criticallyOverdueCreditPaymentStats}
              emailStats={criticallyOverdueCreditPaymentEmailStats}
              country={country}     
              rates={rates}         
              />

            <h3 className="text-primary mt-4 mb-4">Loans</h3>    

            <CreditFinanceStat
              type="loans"
              title="BEFORE DUE LOAN Payment Reminders"
              stats={loanPaymentReminderBeforeDueDateStats}
              emailStats={loanPaymentReminderBeforeDueDateEmailStats}
              country={country}     
              rates={rates}         
              />  

            <CreditFinanceStat
              type="loans"
              title="OVERDUE LOAN Payment Alerts"
              stats={overdueLoanPaymentStats}
              emailStats={overdueLoanPaymentEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              type="loans"
              title="CRITICALLY OVERDUE LOAN Alerts"
              stats={criticallyOverdueLoanPaymentStats}
              emailStats={criticallyOverdueLoanPaymentEmailStats}
              country={country}     
              rates={rates}         
              />

            <h3 className="text-primary mt-4 mb-4">GREETINGS</h3>

            <CreditFinanceStat
              icon={<img src="/images/birthday_icon.svg" style={{width: 48}}/>}
              type="greetings"
              title="Birthday Greetings"
              stats={creditFinanceBirthdayCustomersStats}
              emailStats={creditFinanceBirthdayCustomersEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              icon={<img src="/images/christmas_tree_icon.svg" style={{width: 48}}/>}
              type="greetings"
              title="Christmas Greetings"
              stats={creditFinanceChristmasCustomersStats}
              emailStats={creditFinanceChristmasCustomersEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              icon={<img src="/images/divali_icon.svg" style={{width: 48}}/>}
              type="greetings"
              title="Divali Greetings"
              stats={creditFinanceDivaliCustomersStats}
              emailStats={creditFinanceDivaliCustomersEmailStats}
              country={country}     
              rates={rates}         
              />

            <CreditFinanceStat
              icon={<img src="/images/eid_icon.svg" style={{width: 48}}/>}
              type="greetings"
              title="Eid Greetings"
              stats={creditFinanceEidCustomersStats}
              emailStats={creditFinanceEidCustomersEmailStats}
              country={country}     
              rates={rates}         
              />                                                                                                                                                  

            <CreditFinanceStat
              icon={<img src="/images/custom_message.svg" style={{width: 48}}/>}
              type="custom"
              title="Custom Messages"
              stats={creditFinanceCustomStats}
              emailStats={creditFinanceCustomEmailStats}
              country={country}     
              rates={rates}         
              />                                                             
          </div>    

          <small>Custom messages include introductory messages</small>

          <div className="text-center mt-5 mb-5">
            <Button color="link" onClick={() => dispatch(goBack())}>Go back to dashboard</Button>
          </div>          

          <div className="text-center">
            <Footer />
          </div>         
        </Col>
      </Row>
      
    </Container>
  )
}

export default CreditFinanceStatsCountryDetailContainer