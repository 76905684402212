import React from "react"
import Header from "../../../components/Header"
import { Button, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { push, goBack as goBackAction } from "react-router-redux"
import { logout as logoutAction, fetchVaccines } from "../../../actions"
import FirstVaccinationForm from "../components/FirstVaccinationForm"
import vaccinationRepository from "../lib/vaccinationRepository"

export default function FirstVaccination() {
    const { user, vaccines } = useSelector((state) => state)
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())

    React.useEffect(() => {
        dispatch(fetchVaccines())
    }, [])

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header right={right}></Header>
            {user && vaccines?.length > 0 ? (
                <>
                    <FirstVaccinationForm
                        vaccinationRepository={vaccinationRepository}
                        goBack={goBack}
                        user={user}
                        vaccines={vaccines}
                    ></FirstVaccinationForm>
                    <div className="text-center">
                        <Button
                            onClick={goBack}
                            color="link"
                            style={{ cursor: "pointer" }}
                        >
                            Go back to your dashboard
                        </Button>
                    </div>
                </>
            ) : (
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                </div>
            )}
        </>
    )
}
