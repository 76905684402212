import _ from "lodash"
import { makeAutoObservable, toJS } from "mobx"
import axios from "../../../lib/axios"
import { electricStore } from "./electric.store"

let instance

class EmergencyOutageStore {
    step = 0
    tagInput = ""
    locations = []
    selectedLocationsMap = {}
    sameTimes = false
    differentTimes = false
    restorationTime = {}
    restorationTimes = {}
    restorationTimeUnknown = false
    restorationTimeTomorrow = false
    inputRef = null

    constructor() {
        if (instance) {
            throw new Error("You can only create one instance!")
        }
        instance = this
        makeAutoObservable(this)
    }

    addToast = (text, options) => {}
    setAddToast = (func) => {
        this.addToast = func
    }

    setInputRef = (ref) => {
        this.inputRef = ref
    }

    resetFields = () => {
        this.step = 0
        this.tagInput = ""
        this.selectedLocationsMap = {}
        this.sameTimes = false
        this.differentTimes = false
        this.restorationTime = {}
        this.restorationTimes = {}
        this.restorationTimeUnknown = false
    }

    setStep = (step) => {
        this.step = step
    }
    nextStep = () => {
        this.step = this.step + 1
    }

    previousStep = () => {
        this.step = this.step > 0 ? this.step - 1 : 0
    }

    setTagInput = (value) => {
        this.tagInput = value
    }

    setLocations = (locations) => {
        this.locations = locations
    }

    toggleSameTime = () => {
        this.sameTimes = !this.sameTimes
    }

    setSameTimes = (value) => {
        if (value) {
            this.differentTimes = false
        }
        this.sameTimes = value
    }

    setDifferentTimes = (value) => {
        if (value) {
            this.sameTimes = false
        }
        this.differentTimes = value
    }

    multiMinDate = (location) => {
        if (this.restorationTimes[location.outageLocation]?.restorationTimeTomorrow) {
            return null
        } else {
            return new Date()
        }
    }

    toggleRestorationTimeUnknown = () => {
        this.restorationTimeUnknown = !this.restorationTimeUnknown
    }

    setRestorationTime = (time) => {
        this.restorationTime.time = time
        if (time === "Unknown") {
            this.setRestorationTimeTomorrow(false)
        }
    }

    setMultiRestorationTime = (location, time) => {
        let oldvalue = this.restorationTimes[location.outageLocation]
        this.restorationTimes[location.outageLocation] = {
            ...this.restorationTimes[location.outageLocation],
            time,
            outageLocation: location,
            restorationTimeTomorrow:
                time === "Unknown" ? false : oldvalue?.restorationTimeTomorrow,
        }
    }

    setMultiRestorationTimeTomorrow = (location, value) => {
        let oldvalue = this.restorationTimes[location.outageLocation]
        this.restorationTimes[location.outageLocation] = {
            ...this.restorationTimes[location.outageLocation],
            restorationTimeTomorrow: value,
        }

        if (this.restorationTimes[location.outageLocation].time === "Unknown") {
            this.restorationTimes[location.outageLocation].time = value
                ? ""
                : oldvalue?.time
        } else {
            this.restorationTimes[location.outageLocation].time = ""
        }
    }

    setRestorationTimeUnknown = (value) => {
        this.restorationTimeUnknown = value
        value && this.setRestorationTimeTomorrow(false)
    }

    setRestorationTimeTomorrow = (value) => {
        this.setRestorationTime("")
        value && this.setRestorationTimeUnknown(false)
        this.restorationTimeTomorrow = value
    }

    fetchLocations = async (name) => {
        const uid = electricStore.user.uid
        let result = await axios.get(
            `/api/electric/find-locations?uid=${uid}&name=${name}`
        )

        console.log("find locations result ", result)

        this.setLocations(result.data)
    }

    handleTagInputChanged = async (evt) => {
        console.log("setting tagInput ", evt.target.value)
        this.fetchLocations(evt.target.value)
        this.setTagInput(evt.target.value)
    }

    handleSelectLocation = (location) => {
        return () => {
            this.selectedLocationsMap[location.outageLocation] = location
            this.setLocations([])
            this.inputRef.current.value = ""
            this.inputRef.current.focus()
        }
    }

    handleRemoveLocation = (location) => {
        return () => {
            delete this.selectedLocationsMap[location.outageLocation]
            this.setLocations([])
        }
    }

    debouncedHandleTagInputChanged = _.debounce(this.handleTagInputChanged, 300)

    get selectedLocations() {
        return Object.values(this.selectedLocationsMap)
    }

    get multiSelectedLocations() {
        return Object.values(this.restorationTimes)
    }

    get isRestorationTimeValid() {
        console.log("checking validity ", this.restorationTime.time)
        let values = []
        let isValid = false
        if (this.sameTimes) {
            isValid = this.restorationTime?.time && this.restorationTime?.time !== ""
        } else if (this.differentTimes) {
            for (let key in this.selectedLocationsMap) {
                console.log("key ", key)
                if (
                    this.restorationTimes[key]?.time &&
                    this.restorationTimes[key]?.time !== ""
                ) {
                    values.push(true)
                } else {
                    values.push(false)
                }
            }
            isValid =
                values.length > 0 && values.find((item) => item === false) === undefined
        }

        if (this.restorationTimeUnknown) {
            isValid = true
        }

        console.log("values ", values, toJS(this.restorationTimes))

        return isValid
    }

    get minDate() {
        if (this.restorationTimeTomorrow) {
            return null
        } else {
            return new Date()
        }
    }

    sendMultiAlerts = async () => {
        console.log("alerts ", toJS(this.restorationTimes))
        try {
            const uid = electricStore.user.uid
            let result = await axios.post(
                `/api/electric/send-location-emergency-alerts`,
                {
                    accountId: uid,
                    restorationTimes: this.restorationTimes,
                }
            )

            console.log("send alerts result ", result)
            if (result.success) {
                this.nextStep()
            } else {
                this.addToast(result.errorMessage, {
                    autoDismiss: true,
                    appearance: "error",
                })
            }
        } catch (err) {
            this.addToast(
                "Problem sending alerts: " + err?.response?.data?.errorMessage,
                {
                    autoDismiss: true,
                    appearance: "error",
                }
            )
        }
    }

    sendAlerts = async () => {
        let obj = {}

        for (let key in this.selectedLocationsMap) {
            obj[key] = {
                outageLocation: this.selectedLocationsMap[key],
                restorationTimeTomorrow: this.restorationTimeTomorrow,
                time: this.restorationTimeUnknown ? "Unknown" : this.restorationTime.time,
            }
        }

        const uid = electricStore.user.uid

        try {
            let result = await axios.post(
                `/api/electric/send-location-emergency-alerts`,
                {
                    accountId: uid,
                    restorationTimes: obj,
                }
            )

            if (result.success) {
                this.nextStep()
            } else {
                this.addToast(result.errorMessage, {
                    autoDismiss: true,
                    appearance: "error",
                })
            }
        } catch (err) {
            this.addToast(
                "Problem sending alerts: " + err?.response?.data?.errorMessage,
                {
                    autoDismiss: true,
                    appearance: "error",
                }
            )
        }
    }
}

export const emergencyOutageStore = new EmergencyOutageStore()
