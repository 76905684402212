import React from "react"
import firebase from "../firebase"
import axios from "../lib/httpclient"
import { push } from "react-router-redux"
import { differenceInMinutes } from "date-fns"
import IdleTimer from "react-idle-timer"
import * as _ from "lodash"

import {
    setUser,
    setAdmin,
    fetchStats,
    fetchCustomMessageStats,
    fetchHealthStats,
    fetchPropertyStats,
    fetchBirthdayStats,
    fetchChristmasStats,
    fetchEmailStats,
    fetchRates,
    logout,
    fetchDivaliStats,
    fetchUniqueCustomers,
    fetchEidStats,
    registerUser,
    fetchSettings,
} from "../actions"
import { useDispatch, useSelector } from "react-redux"
import config from "../config"
import { electricStore } from "../features/electric/data/electric.store"

const AuthContext = React.createContext()

export default function AuthProvider({ children }) {
    const dispatch = useDispatch()
    const { user, rates } = useSelector((state) => state)

    React.useEffect(() => {
        if (user) {
            let lastActiveTime = localStorage.getItem("lastActiveTime")
            if (lastActiveTime) {
                lastActiveTime = new Date(parseInt(lastActiveTime))
                let difference = differenceInMinutes(new Date(), lastActiveTime)

                let timeout
                if (user?.category === "vaccination") {
                    timeout = config.vaccinationTimeout
                } else {
                    timeout = config.defaultTimeout
                }

                if (difference > timeout) {
                    dispatch(logout()).then(() => {
                        //dispatch(push("/"))
                    })
                }
            }
        }
    }, [user])

    React.useEffect(() => {
        dispatch(fetchRates())
    }, [])

    const handleAuth = () => {
        let subscription = firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in.

                let token = await user.getIdToken()
                electricStore.setToken(token)

                localStorage.setItem("token", token)
                let response = await axios.get("/api/me")

                let registeredUser = response && response.data && response.data.user

                if (registeredUser && registeredUser.admin) {
                    dispatch(setAdmin({ email: registeredUser.email }))
                    dispatch(setUser(registeredUser))
                    if (
                        window.location.pathname !== "/" &&
                        window.location.pathname != "/admin"
                    ) {
                        dispatch(push(`/admin`))
                    }
                } else if (
                    registeredUser &&
                    (registeredUser.category === "vaccination" ||
                        registeredUser.category == "blood-bank" ||
                        registeredUser.category == "electric")
                ) {
                    dispatch(setUser(registeredUser))
                    dispatch(fetchSettings(registeredUser.uid))
                    if (
                        window.location.pathname !== "/" &&
                        window.location.pathname != "/admin"
                    ) {
                        dispatch(
                            push(
                                `/${registeredUser.category}/${
                                    registeredUser.countryCode ||
                                    registeredUser.country?.isoCode
                                }/${registeredUser.email}`
                            )
                        )
                    }
                } else {
                    firebase
                        .database()
                        .ref(`registrations/${user.uid}`)
                        .once("value")
                        .then((snap) => {
                            const registeredUser = snap.val()
                            dispatch(setUser(registeredUser))
                            Promise.all([
                                dispatch(fetchStats(registeredUser)),
                                dispatch(fetchPropertyStats(registeredUser)),
                                dispatch(fetchCustomMessageStats(registeredUser)),
                                dispatch(fetchHealthStats(registeredUser)),
                                dispatch(fetchDivaliStats(registeredUser)),
                                dispatch(fetchEidStats(registeredUser)),
                                dispatch(fetchBirthdayStats(registeredUser)),
                                dispatch(fetchChristmasStats(registeredUser)),
                                dispatch(fetchEmailStats(registeredUser, "auto")),
                                dispatch(fetchEmailStats(registeredUser, "property")),
                                dispatch(fetchEmailStats(registeredUser, "health")),
                                dispatch(fetchEmailStats(registeredUser, "birthday")),
                                dispatch(fetchEmailStats(registeredUser, "christmas")),
                                dispatch(fetchEmailStats(registeredUser, "custom")),
                                dispatch(fetchEmailStats(registeredUser, "divali")),
                                dispatch(fetchEmailStats(registeredUser, "eid")),
                                dispatch(fetchUniqueCustomers(registeredUser)),
                            ])
                                .then(() => {
                                    if (
                                        window.location.pathname !== "/" &&
                                        window.location.pathname != "/admin" &&
                                        window.location.pathname === "/auth/login"
                                    ) {
                                        dispatch(
                                            push(
                                                `/${registeredUser.category}/${registeredUser.country}/${registeredUser.email}`
                                            )
                                        )
                                    } else {
                                        dispatch(push(window.location.pathname))
                                    }
                                })
                                .catch((error) => {
                                    console.log("problem ", error)
                                })
                        })
                }
            } else {
                dispatch(setUser(null))

                localStorage.removeItem("token")
            }
        })

        return subscription
    }

    React.useEffect(() => {
        let unsubscribe = handleAuth()

        return () => {
            unsubscribe()
        }
    }, [])

    let idleTimer = React.createRef()

    return (
        <AuthContext.Provider value={null}>
            <IdleTimer
                ref={(ref) => {
                    idleTimer = ref
                }}
                timeout={
                    1000 *
                    60 *
                    (user?.category === "vaccination"
                        ? config.vaccinationTimeout
                        : config.defaultTimeout)
                }
                onAction={(event) => {
                    localStorage.setItem("lastActiveTime", new Date().getTime())
                }}
                onActive={() => {
                    localStorage.setItem("lastActiveTime", idleTimer?.getLastActiveTime())
                }}
                onIdle={(event) => {
                    localStorage.setItem("lastActiveTime", idleTimer?.getLastActiveTime())

                    if (user !== null) {
                        dispatch(logout()).then(() => {
                            //dispatch(push("/"))
                        })
                    }
                }}
            />
            {!_.isEmpty(rates) ? children : null}
        </AuthContext.Provider>
    )
}
