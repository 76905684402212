import React from "react"
import Header from "../../../components/Header"
import { Button, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { push, goBack as goBackAction } from "react-router-redux"

import {
    logout as logoutAction,
    fetchVaccines,
    fetchPatientVaccinations,
} from "../../../actions"
import FirstVaccinationForm from "../components/FirstVaccinationForm"
import vaccinationRepository from "../lib/vaccinationRepository"
import PhoneInputForm from "../components/SecondVaccinationInputPhoneForm"
import SecondVaccinationCompleteForm from "../components/SecondVaccinationCompleteForm"
import SecondVaccinationNumberNotFoundForm from "../components/SecondVaccinationNumberNotFoundForm"
import SecondVaccinationMissingNumberForm from "../components/SecondVaccinationMissingNumberForm"

export default function SecondVaccination() {
    const { user, vaccines, patientVaccinations } = useSelector((state) => state)
    const [vaccination, setVaccination] = React.useState()
    const [numberNotFound, setNumberNotFound] = React.useState(false)
    const [showMissingNumberForm, setShowMissingNumberForm] = React.useState(false)
    const [mobileNumber, setMobileNumber] = React.useState()
    const dispatch = useDispatch()
    const goBack = () => dispatch(goBackAction())
    const logout = () => dispatch(logoutAction())

    React.useEffect(() => {
        dispatch(fetchVaccines())
    }, [])

    let right = null
    if ((user && user.active) || (user && admin)) {
        right = (
            <div className="d-flex flex-column align-items-center">
                <div>
                    <img src={user.logo} height="50" />
                </div>

                <div className="d-flex">
                    <div className="mr-2">
                        <div className="text-truncate" style={{ minWidth: 0 }}>
                            {user?.delegate?.email || user.email}
                        </div>
                        <div>
                            <Button className="m-0 p-0" color="link" onClick={logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                    <div>
                        <img
                            src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                            height={30}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const handlePhoneNumberSubmit = (number) => {
        vaccinationRepository
            .fetchVaccinationByMobileNumber(number, user.uid, 1)
            .then((res) => {
                console.log("mobileNumer ", res)
                setVaccination(res.data?.data)
                if (res.data?.data === null) {
                    setNumberNotFound(true)
                    setMobileNumber(number)
                } else {
                    setNumberNotFound(false)
                }
            })
            .catch((e) => {
                console.log("fetch vaccination error ", e)
            })
    }

    const tryAnotherNumber = () => {
        setNumberNotFound(false)
    }

    const goToNext = () => {
        setNumberNotFound(false)
        setShowMissingNumberForm(true)
    }

    const resetForm = () => {
        setShowMissingNumberForm(false)
        setVaccination(null)
        setNumberNotFound(false)
    }

    const renderForm = () => {
        if (numberNotFound) {
            return (
                <SecondVaccinationNumberNotFoundForm
                    tryAnotherNumber={tryAnotherNumber}
                    next={goToNext}
                />
            )
        } else if (showMissingNumberForm) {
            return (
                <SecondVaccinationMissingNumberForm
                    user={user}
                    mobileNumber={mobileNumber}
                    resetForm={resetForm}
                    vaccinationRepository={vaccinationRepository}
                    goBack={goBack}
                    vaccines={vaccines}
                />
            )
        } else if (!numberNotFound && !vaccination) {
            return <PhoneInputForm onSubmit={handlePhoneNumberSubmit} />
        } else if (!numberNotFound && vaccination) {
            return (
                <SecondVaccinationCompleteForm
                    user={user}
                    vaccinationRepository={vaccinationRepository}
                    goBack={goBack}
                    resetForm={resetForm}
                    vaccines={vaccines}
                    vaccination={vaccination}
                    onSubmit={handlePhoneNumberSubmit}
                />
            )
        }
    }

    return (
        <>
            <Header right={right}></Header>
            {user && vaccines?.length > 0 ? (
                <>
                    {renderForm()}
                    <div className="text-center">
                        <Button
                            onClick={goBack}
                            color="link"
                            style={{ cursor: "pointer" }}
                        >
                            Go back to your dashboard
                        </Button>
                    </div>
                </>
            ) : (
                <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                </div>
            )}
        </>
    )
}
