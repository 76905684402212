import React, { Component } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

import _ from "lodash"

export default class UpdateDatabaseForm extends Component {
    state = {
        processing: false,
        messageProcessing: false,
        customerCount: 0,
        newCustomerCount: 0,
        success: false,
        customerSuccess: false,
        propertySuccess: false,
        healthSuccess: false,
        birthdaySuccess: false,
        divaliSuccess: false,
        eidSuccess: false,
        christmasSuccess: false,
        messageSuccess: false,
        propertyCustomerCount: 0,
        healthCustomerCount: 0,
        newPropertyCustomerCount: 0,
        newHealthCustomerCount: 0,
        birthdayCount: 0,
        newBirthdayCount: 0,
        christmasCount: 0,
        newChristmasCount: 0,
        divaliCount: 0,
        newDivaliCount: 0,
        eidCount: 0,
        newEidCount: 0,
        autoKey: 0,
        propertyKey: 0,
        birthdayKey: 0,
        christmasKey: 0,
        divaliKey: 0,
        eidKey: 0,
        healthKey: 0,
        autoError: false,
        multiAutoError: false,
        propertyError: false,
        healthError: false,
        birthdayError: false,
        christmasError: false,
        divaliError: false,
        eidError: false,
        errorMessage: null,
        modal: false,
        sendMessageButtonClicked: false,
    }

    componentDidMount() {
        this.props.fetchUniqueCustomers(this.props.user)
    }

    messageLength = () => {
        return 160 - this.props.message.content.length
    }

    messageValid = () =>
        this.props.message &&
        this.props.message.content.length <= 160 &&
        this.props.message.content.length > 0 &&
        this.noInvalidMessageCharacters()

    multiAutoUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            this.state.errorMessage
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadMultipleVehicleCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "auto"),
                            ]).then((results) => {
                                this.setState({
                                    customerSuccess: res.value.data.success,
                                    customerCount: res.value.data.customerCount,
                                    processing: false,
                                    multiAutoError: false,
                                    newCustomerCount: res.value.data.newCustomerCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            multiAutoError: true,
                            processing: false,
                            autoKey: this.state.autoKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    autoUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            this.state.errorMessage
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props.uploadCustomers(this.props.user, binaryFileLoaded).then((res) => {
                if (res.value.data.success) {
                    setTimeout(() => {
                        Promise.all([
                            this.props.fetchStats(this.props.user),
                            this.props.fetchEmailStats(this.props.user, "auto"),
                        ]).then((results) => {
                            this.setState({
                                customerSuccess: res.value.data.success,
                                customerCount: res.value.data.customerCount,
                                processing: false,
                                autoError: false,
                                newCustomerCount: res.value.data.newCustomerCount,
                            })
                        })
                    }, 2000)
                } else {
                    this.setState({
                        errorMessage: res.value.data.message,
                        autoError: true,
                        processing: false,
                        autoKey: this.state.autoKey + 1,
                    })
                }
            })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    propertyUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadPropertyCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchPropertyStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "property"),
                            ]).then((results) => {
                                this.setState({
                                    propertySuccess: res.value.data.success,
                                    propertyCustomerCount: res.value.data.customerCount,
                                    processing: false,
                                    propertyError: false,
                                    newPropertyCustomerCount:
                                        res.value.data.newCustomerCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            processing: false,
                            propertyError: true,
                            propertyKey: this.state.propertyKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    healthUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadHealthCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchHealthStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "health"),
                            ]).then((results) => {
                                this.setState({
                                    healthSuccess: res.value.data.success,
                                    healthCustomerCount: res.value.data.customerCount,
                                    processing: false,
                                    healthError: false,
                                    newHealthCustomerCount:
                                        res.value.data.newCustomerCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            processing: false,
                            healthError: true,
                            healthKey: this.state.healthKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    birthdayUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadBirthdayCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchBirthdayStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "birthday"),
                            ]).then((results) => {
                                this.setState({
                                    birthdaySuccess: res.value.data.success,
                                    birthdayCount: res.value.data.birthdayCount,
                                    processing: false,
                                    birthdayError: false,
                                    newBirthdayCount: res.value.data.newBirthdayCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            birthdayError: true,
                            processing: false,
                            birthdayKey: this.state.birthdayKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    christmasUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadChristmasCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchChristmasStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "christmas"),
                            ]).then((results) => {
                                this.setState({
                                    christmasSuccess: res.value.data.success,
                                    christmasCount: res.value.data.christmasCount,
                                    processing: false,
                                    christmasError: false,
                                    newChristmasCount: res.value.data.newChristmasCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            christmasError: true,
                            processing: false,
                            christmasKey: this.state.christmasKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    divaliUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadDivaliCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchDivaliStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "divali"),
                            ]).then((results) => {
                                this.setState({
                                    divaliSuccess: res.value.data.success,
                                    divaliCount: res.value.data.divaliCount,
                                    processing: false,
                                    divaliError: false,
                                    newDivaliCount: res.value.data.newDivaliCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            divaliError: true,
                            processing: false,
                            divaliKey: this.state.divaliKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    eidUploadChanged(event) {
        var fileToLoad = event.target.files[0]

        if (
            fileToLoad.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            this.setState({ errorMessage: "Not a valid excel file" })
            return
        } else {
            this.setState({ errorMessage: null })
        }

        var fileReader = new FileReader()
        fileReader.onload = async (fileLoadedEvent) => {
            var binaryFileLoaded = fileLoadedEvent.target.result

            this.setState({ processing: true })
            this.props
                .uploadEidCustomers(this.props.user, binaryFileLoaded)
                .then((res) => {
                    if (res.value.data.success) {
                        setTimeout(() => {
                            Promise.all([
                                this.props.fetchEidStats(this.props.user),
                                this.props.fetchEmailStats(this.props.user, "eid"),
                            ]).then((results) => {
                                this.setState({
                                    eidSuccess: res.value.data.success,
                                    eidCount: res.value.data.eidCount,
                                    eidError: false,
                                    processing: false,
                                    newEidCount: res.value.data.newEidCount,
                                })
                            })
                        }, 2000)
                    } else {
                        this.setState({
                            errorMessage: res.value.data.message,
                            eidError: true,
                            processing: false,
                            eidKey: this.state.eidKey + 1,
                        })
                    }
                })
        }
        fileReader.readAsBinaryString(fileToLoad)
    }

    toggleConfirmationDialog = () => this.setState({ modal: !this.state.modal })
    sendMessage = () => {
        this.toggleConfirmationDialog()
        const { sendMessage, user, message } = this.props
        this.setState({ messageProcessing: true })
        sendMessage(user, message.content, this.emailCheck || false).then((res) => {
            if (res.value.data.success) {
                this.setState({ messageProcessing: false })
                this.props.fetchCustomMessageStats(this.props.user),
                    this.props.fetchEmailStats(this.props.user, "custom")
                setTimeout(() => {
                    this.setState({ sendMessageButtonClicked: false })
                    this.props.resetMessage()
                }, 3000)
            } else {
                this.setState({ sendMessageButtonClicked: true })
            }
        })
    }

    clearSendMessageField = () => {
        this.setState({ sendMessageButtonClicked: false })
        this.props.resetMessage()
    }

    noInvalidMessageCharacters = () => {
        const { message } = this.props

        const re = /^[\!\?:;,\.A-Za-z\s\d]*$/
        if (message.content) {
            return re.exec(message.content) ? true : false
        } else {
            return true
        }
    }

    render() {
        const { user, onMessageChanged, message } = this.props

        return (
            <Container>
                <Row className="justify-content-center">
                    <Col sm={6} xs={12} className="">
                        <h1>Update Database</h1>
                        <Button color="link" onClick={this.props.goBack}>
                            Go to Dashboard
                        </Button>
                        <Alert color="info">
                            You have a total of {this.props.uniqueCustomers.count} unique
                            customers in your database.
                        </Alert>
                        <Modal
                            isOpen={this.state.modal}
                            toggle={this.toggleConfirmationDialog}
                        >
                            <ModalHeader toggle={this.toggleConfirmationDialog}>
                                Send Message
                            </ModalHeader>
                            <ModalBody>
                                You are about to message every customer in your database.
                                Are you sure?
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" onClick={this.sendMessage}>
                                    YES
                                </Button>{" "}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleConfirmationDialog}
                                >
                                    No
                                </Button>
                            </ModalFooter>
                        </Modal>
                        <Alert color="info">
                            This function allows you to add new customers to the database.
                            If you have no new customers to add, click{" "}
                            <a href="#" onClick={this.props.goBack}>
                                Cancel
                            </a>
                            .
                        </Alert>

                        {this.state.processing ? (
                            <Alert color="info">Processing upload...</Alert>
                        ) : null}

                        {this.state.customerSuccess ? (
                            <Alert color="success">
                                You have successfully added {this.state.newCustomerCount}{" "}
                                new customers to your database, which now contains{" "}
                                {this.state.customerCount} Auto customers. Click here to
                                go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.propertySuccess ? (
                            <Alert color="success">
                                You have successfully added{" "}
                                {this.state.newPropertyCustomerCount} new Property
                                customers to your database, which now contains{" "}
                                {this.state.propertyCustomerCount} Property customers.
                                Click here to go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.healthSuccess ? (
                            <Alert color="success">
                                You have successfully added{" "}
                                {this.state.newHealthCustomerCount} new Health customers
                                to your database, which now contains{" "}
                                {this.state.healthCustomerCount} Health customers. Click
                                here to go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.birthdaySuccess ? (
                            <Alert color="success">
                                You have successfully added {this.state.newBirthdayCount}{" "}
                                new Birthday customers to your database, which now
                                contains {this.state.birthdayCount} birthdays. Click here
                                to go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.christmasSuccess ? (
                            <Alert color="success">
                                You have successfully added {this.state.newChristmasCount}{" "}
                                new Christmas customers to your database, which now
                                contains {this.state.christmasCount} Christmas greetings.
                                Click here to go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.divaliSuccess ? (
                            <Alert color="success">
                                You have successfully added {this.state.newDivaliCount}{" "}
                                new Divali customers to your database, which now contains{" "}
                                {this.state.divaliCount} Divali greetings. Click here to
                                go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.eidSuccess ? (
                            <Alert color="success">
                                You have successfully added {this.state.newEidCount} new
                                Eid-ul-Fitr customers to your database, which now contains{" "}
                                {this.state.eidCount} Eid-ul-Fitr greetings. Click here to
                                go to your{" "}
                                <Link
                                    to={`/${user.category}/${user.country}/${user.email}`}
                                >
                                    dashboard
                                </Link>
                                .
                            </Alert>
                        ) : null}

                        {this.state.errorMessage ? (
                            <Alert color="danger">{this.state.errorMessage}</Alert>
                        ) : null}

                        <FormGroup
                            className={classnames({
                                highlight: this.state.autoError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img src="/images/add_auto_icon.svg" height="60" />
                                </Col>
                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>
                                            Upload Auto Insurance Excel file here:
                                        </strong>
                                    </Label>
                                    <Input
                                        key={this.state.autoKey}
                                        type="file"
                                        placeholder="Auto Insurance Excel file"
                                        onChange={this.autoUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.multiAutoError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img
                                        src="/images/add_multiple_vehicles_new.png"
                                        height="60"
                                    />
                                </Col>
                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>
                                            Send Reminders for Multiple Vehicles here:
                                        </strong>
                                    </Label>
                                    <Input
                                        key={this.state.autoKey}
                                        type="file"
                                        placeholder="Auto Insurance Excel file"
                                        onChange={this.multiAutoUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.propertyError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img
                                        src="/images/add_property_icon.svg"
                                        height="60"
                                    />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>
                                            Upload Property Insurance Excel file here:
                                        </strong>
                                    </Label>
                                    <Input
                                        type="file"
                                        placeholder=""
                                        key={this.state.propertyKey}
                                        onChange={this.propertyUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.healthError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img
                                        src="/images/add_healthcare_icon.png"
                                        height="60"
                                    />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>
                                            Upload Health Insurance Excel file here:
                                        </strong>
                                    </Label>
                                    <Input
                                        type="file"
                                        placeholder=""
                                        key={this.state.healthKey}
                                        onChange={this.healthUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.birthdayError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img
                                        src="/images/add_birthday_icon.svg"
                                        height="60"
                                    />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>Upload Birthday Excel file here:</strong>
                                    </Label>
                                    <Input
                                        key={this.state.birthdayKey}
                                        type="file"
                                        placeholder=""
                                        onChange={this.birthdayUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.christmasError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img
                                        src="/images/add_christmas_icon.svg"
                                        height="60"
                                    />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>Upload Christmas Excel file here</strong>
                                    </Label>
                                    <Input
                                        key={this.state.christmasKey}
                                        type="file"
                                        placeholder=""
                                        onChange={this.christmasUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.divaliError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img src="/images/add_divali_icon.svg" height="60" />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>Upload Divali Excel file here</strong>
                                    </Label>
                                    <Input
                                        key={this.state.divaliKey}
                                        type="file"
                                        placeholder=""
                                        onChange={this.divaliUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup
                            className={classnames({
                                highlight: this.state.eidError,
                                "p-2": true,
                            })}
                        >
                            <Row>
                                <Col xs="12" sm="2">
                                    <img src="/images/add_eid_icon.svg" height="60" />
                                </Col>

                                <Col xs="12" sm="10">
                                    <Label>
                                        <strong>
                                            Upload Eid-ul-Fitr Excel file here
                                        </strong>
                                    </Label>
                                    <Input
                                        key={this.state.eidKey}
                                        type="file"
                                        placeholder=""
                                        onChange={this.eidUploadChanged.bind(this)}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup className={classnames({ "p-2": true })}>
                            <Row>
                                <Col xs="12" sm="10">
                                    <h4 style={{ textTransform: "uppercase" }}>
                                        Premium option coming soon
                                    </h4>
                                    <Label>
                                        <strong>
                                            Send a message to all{" "}
                                            {this.props.uniqueCustomers.count} customers
                                            in the database:
                                        </strong>
                                    </Label>
                                    {this.state.messageProcessing ? (
                                        <Alert color="info">Processing messages</Alert>
                                    ) : null}
                                    {message.success ? (
                                        <Alert color="success">
                                            Messages sent successfully!
                                        </Alert>
                                    ) : null}
                                    {!message.success &&
                                    this.state.sendMessageButtonClicked ? (
                                        <Alert color="danger">
                                            Error sending messages!
                                        </Alert>
                                    ) : null}
                                    {!this.noInvalidMessageCharacters() ? (
                                        <Alert color="danger">
                                            Message contains invalid characters!
                                        </Alert>
                                    ) : null}
                                    <Input
                                        disabled={true}
                                        placeholder="Type your message here. Max 160 characters including spaces. Special characters not allowed."
                                        value={message.content}
                                        type="textarea"
                                        onChange={(evt) =>
                                            onMessageChanged(evt.target.value)
                                        }
                                    ></Input>
                                    <div className="text-right">
                                        {this.messageLength()}
                                    </div>
                                    <Input
                                        disabled={true}
                                        className="ml-1"
                                        type="checkbox"
                                        onChange={(evt) => {
                                            if (evt.target.checked) {
                                                this.emailCheck = true
                                            } else {
                                                this.emailCheck = false
                                            }
                                        }}
                                    />{" "}
                                    <span className="pl-4">
                                        Also send message by email
                                    </span>
                                    <div className="text-center">
                                        <Button
                                            onClick={this.toggleConfirmationDialog}
                                            className="sendmessage-button"
                                            disabled={true /*!this.messageValid()*/}
                                        >
                                            Send Message
                                        </Button>
                                        <Button
                                            disabled={true}
                                            onClick={this.clearSendMessageField}
                                            color="danger"
                                            className="cancel-message-button"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                        <Button color="link" onClick={this.props.goBack}>
                            Go to Dashboard
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}
