import React, { Component } from "react"
import { connect } from "react-redux"
import logo from "./logo.svg"
import "./App.css"
import config from "./config"
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
    push,
} from "react-router-redux"
import { Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Label,
    Input,
    Alert,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Header from "./components/Header"
import Footer from "./components/Footer"

import { login } from "./actions"
import firebase from "./firebase"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

class AppContainer extends Component {
    state = {
        email: "",
        password: "",
        recaptcha: null,
        errorCode: null,
        viewPassword: false,
    }

    recaptchaRef = React.createRef()

    submitForm = (evt) => {
        evt.preventDefault()

        axios
            .post(`${config.serverURL}/api/verify-captcha`, {
                "g-recaptcha-response": this.state.recaptcha,
            })
            .then((res) => {
                if (res.data.success) {
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(this.state.email, this.state.password)
                        .then((res) => {})
                        .catch((error) => {
                            console.log("login error: ", error.message, error.code)
                            this.setState({ errorCode: error.code })
                            this.recaptchaRef.current.reset()
                        })
                } else {
                    console.log("catpcha not verified")
                }
            })
    }

    getErrorMessage = () => {
        if (this.state.errorCode === "auth/wrong-password") {
            return "The password is incorrect"
        } else if (this.state.errorCode == "auth/too-many-requests") {
            return "Too many unsuccessful attempts"
        } else {
            return "An error occured"
        }
    }

    toggleViewPassword = () => {
        this.setState({ viewPassword: !this.state.viewPassword })
    }

    getPasswordInputType = () => {
        if (this.state.viewPassword) {
            return "text"
        } else {
            return "password"
        }
    }

    getEyeIcon = () => {
        if (this.state.viewPassword) {
            return faEye
        } else {
            return faEyeSlash
        }
    }

    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <h1>Login</h1>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={4} xs={12} className="">
                            {this.state.errorCode ? (
                                <Alert color="danger">{this.getErrorMessage()}</Alert>
                            ) : null}
                            <Form onSubmit={this.submitForm.bind(this)}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter login email"
                                        onChange={(evt) =>
                                            this.setState({ email: evt.target.value })
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="examplePassword">Password</Label>
                                    <InputGroup>
                                        <Input
                                            type={this.getPasswordInputType()}
                                            name="password"
                                            id="examplePassword"
                                            placeholder="Enter password"
                                            onChange={(evt) =>
                                                this.setState({
                                                    password: evt.target.value,
                                                })
                                            }
                                        />
                                        <InputGroupAddon
                                            className="view-password"
                                            addonType="append"
                                            onClick={this.toggleViewPassword}
                                        >
                                            <FontAwesomeIcon
                                                icon={this.getEyeIcon()}
                                            ></FontAwesomeIcon>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>

                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    className="captcha"
                                    sitekey="6LcfRjsUAAAAAOF5AgszQeZRBqtfNZVhf9MnU95h"
                                    onChange={(response) => {
                                        this.setState({ recaptcha: response })
                                    }}
                                />

                                <div className="text-center mt-4">
                                    <Button
                                        color="success"
                                        disabled={!this.state.recaptcha}
                                    >
                                        Login
                                    </Button>
                                </div>
                            </Form>

                            <div className="text-center mt-3">
                                <Link to="/reset-password">Reset Password</Link>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
