import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import { logout as logoutAction } from "../../../actions"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { useRateLimiterPresenter } from "../rateLimiter.presenter"
import { useToasts } from "react-toast-notifications"

export default function RateLimiterAdminCategory() {
    const {
        setMessageLimit,
        setTimeout,
        timeout,
        messageLimit,
        admin,
        settings,
        saveSettings,
    } = useRateLimiterPresenter()

    const dispatch = useDispatch()

    const logout = () => dispatch(logoutAction())

    const { addToast } = useToasts()

    const performSaveSettings = () => {
        saveSettings()
            .then((res) => {
                addToast("Settings updated", {
                    autoDismiss: true,
                    appearance: "success",
                })
            })
            .catch((err) => {
                console.log("err ", err)
                addToast(err?.response?.data?.errorMessage || "Server Error", {
                    autoDismiss: true,
                    appearance: "error",
                })
            })
    }

    let right = null
    if (admin) {
        right = (
            <div className="d-flex">
                <div className="mr-2">
                    <div className="text-truncate" style={{ minWidth: 0 }}>
                        {admin.email}
                    </div>
                    <div>
                        <a href="#" onClick={logout}>
                            Logout
                        </a>
                    </div>
                </div>
                <div>
                    <img src={`/flag-icon-css/flags/4x3/vc.svg`} height={30} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Header right={right} />
            <Container>
                <Row className="justify-content-center">
                    <div className="d-flex flex-column">
                        {/*<img src="/images/admin_icon.svg" height="100" /> */}
                        <h1>Rate Limiter</h1>
                        <Form>
                            <FormGroup>
                                <Label for="messageLimit">Message Limit</Label>
                                <Input
                                    min={1}
                                    value={messageLimit || settings.rlMessageLimit}
                                    onChange={(evt) => setMessageLimit(evt.target.value)}
                                    id="messageLimit"
                                    name="messageLimit"
                                    placeholder="Message Limit"
                                    type="number"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="timeout">Timeout in minutes</Label>
                                <Input
                                    min={1}
                                    value={timeout || settings.rlTimeoutMinutes}
                                    onChange={(evt) => setTimeout(evt.target.value)}
                                    id="timeout"
                                    name="timeout"
                                    placeholder="Timeout in minutes"
                                    type="number"
                                />
                            </FormGroup>

                            <div className="d-flex justify-content-center">
                                <Button onClick={performSaveSettings}>Save</Button>
                            </div>
                        </Form>
                    </div>
                </Row>

                <Row className="justify-content-center">
                    <Col sm={3} xs={12}></Col>
                </Row>
                <div className="text-center">
                    <Footer />
                </div>
            </Container>
        </div>
    )
}
