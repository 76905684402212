import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"

import Header from "./components/Header"
import Footer from "./components/Footer"

import firebase from "./firebase"

class AppContainer extends Component {
    state = {
        email: "",
        success: false,
        errorMessage: null,
    }

    componentDidMount() {}

    submitForm(evt) {
        evt.preventDefault()

        firebase
            .auth()
            .sendPasswordResetEmail(this.state.email)
            .then((res) => {
                this.setState({ success: true, errorMessage: null })
            })
            .catch((error) => {
                console.log("error ", error)
                this.setState({ success: false, errorMessage: error.message })
            })
    }

    render() {
        return (
            <div className="">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <h1>Reset Password</h1>
                    </Row>

                    <Row className="justify-content-center">
                        <Col sm={3} xs={12} className="">
                            {this.state.success ? (
                                <Alert color="success">
                                    A reset code has been delivered to your email.
                                </Alert>
                            ) : null}
                            {this.state.errorMessage ? (
                                <Alert color="danger">{this.state.errorMessage}</Alert>
                            ) : null}

                            {!this.state.success ? (
                                <Form onSubmit={this.submitForm.bind(this)}>
                                    <FormGroup>
                                        <Label for="email">Enter your email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter your email"
                                            onChange={(evt) =>
                                                this.setState({ email: evt.target.value })
                                            }
                                        />
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button color="success">Reset</Button>
                                    </div>
                                </Form>
                            ) : null}
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
