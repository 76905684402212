import React, { Component } from 'react';
import {connect} from 'react-redux';
import './App.css';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';

import Header from './components/Header'; 
import Footer from './components/Footer';  

class AppContainer extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <div className="">
        <Header />
        <Container>
          <Row className="justify-content-center">
          <Col sm={2} xs={12}>
              <img src="/images/organisations_icon.svg" height="150"/>
            </Col>

            <Col sm={6} xs={12}>
              <h1>Organizations</h1>

              <p>Organizations ready to delight their customers with a superior level 
                of care and customer service are already using Yes, Text Me! for their 
                custom messaging needs.  Contact us at info@myeiminc.com to talk about 
                customizing your messaging needs!</p>
              {/*
              <p>Yes, Text Me! is for organizations ready to delight their customers with a superior level of care and customer service.</p>

              <ul>
                <li>Insurance companies will send automated text reminders to policyholders that payments are coming due -- giving customers time to plan and budget for the payments;</li>                  
                <li>Government licensing departments will send text reminders and alerts to their customers that their drivers licences and vehicle registrations are coming due;</li>
                <li>Utilities will send location-specific text messages to their customers in advance of planned supply interruptions and can send updates in emergency situations;</li>
                <li>Health departments will send location-specific text messages to households in advance of mosquito fogging, etc.</li>
              </ul>
              */}
                <div className="text-center">
                    <Link to="/">Back</Link>
                </div>
            </Col>
          </Row>
          <div className="text-center">
            <Footer />
          </div>          
        </Container>    
      </div>
    )
  }
}

const App = connect(state => ({
  location: state.location
}))(AppContainer); 

export default App;
