import React, { Component } from 'react';
import {connect} from 'react-redux';
import './App.css';
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap';

import Header from './components/Header'; 
import Footer from './components/Footer'; 

class AppContainer extends Component {
  componentDidMount() {
      
  }
  render() {
    return (
      <div className="">
        <Header />
        <Container>
          <Row className="justify-content-center">
            <Col sm={2} xs={12}>
              <img src="/images/individuals_icon.svg" height="150"/>
            </Col>

            <Col sm={6} xs={12}>
              <h1>Individuals</h1>
              <p>Yes, Text Me! is a service that gives you important, timely text message reminders and alerts – about the things that matter to YOU!</p>

                <p>No spam, no bulk blasts advertising stuff you don’t care about – just the information you want, when you need it.</p>

                <p>More details coming soon!</p>
                <div className="text-center">
                    <Link to="/">Back</Link>
                </div>
            </Col>
          </Row>
          <div className="text-center">
            <Footer />
          </div>
        </Container>    
      </div>
    )
  }
}

const App = connect(state => ({
  location: state.location
}))(AppContainer); 

export default App;
