import React, { Component } from "react"
import { connect } from "react-redux"
import "../App.css"
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
    push,
} from "react-router-redux"
import { Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import countries from "../countries.json"
import { setRegistrationField } from "../actions"

class AppContainer extends Component {
    state = {
        flag: "",
    }

    componentDidMount() {
        const parts = this.props.match.url.split("/")
        const category = parts[parts.length - 1]
        this.props.dispatch(setRegistrationField({ category: category }))
    }

    renderLogo() {
        const url = this.props.match.url
        const parts = url.split("/")
        const category = _.last(parts)

        console.log("category ", category)

        if (category === "credit-finance") {
            return (
                <div className="d-flex flex-column align-items-center p-4">
                    <img
                        src={"/images/credit_finance.png"}
                        width="100"
                        style={{ borderRadius: "50px" }}
                    />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="">
                <Header right={<div>Credit & Finance</div>} />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={7} xs={12} className="">
                            {this.renderLogo()}
                            <h1 className="text-center">First time here?</h1>
                            <h2 className="text-center">
                                <Link to="/auth/credit-finance/select-country">
                                    Please Register
                                </Link>
                            </h2>

                            <h3 className="text-center mt-5">Already a customer?</h3>
                            <h4 className="text-center">
                                <Link to="/auth/login">Please login.</Link>
                            </h4>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(AppContainer)

export default App
