import React, { Component } from "react"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import logo from "../logo.svg"
import "../App.css"
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware,
    push,
    goBack,
} from "react-router-redux"
import { Route, Switch, Redirect } from "react-router"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"
import {
    login,
    setRegistrationField,
    registerUser,
    requestAccountActivation,
} from "../actions"

import Header from "../components/Header"
import Footer from "../components/Footer"

class AppContainer extends Component {
    confirmRegistration() {
        this.props
            .registerUser(this.props.registration)
            .then((user) => {})
            .catch((e) => {})
    }

    goBack() {
        this.props.setRegistrationField({ "g-recaptcha-response": null })
        this.props.goBack()
    }

    handleBackPress = () => {
        this.props.setRegistrationField({ error: null })
        this.goBack()
    }

    componentDidMount() {
        //ReactDOM.findDOMNode(this).scrollTop = 0
        window.scrollTo(0, 0)
    }

    requestActivation = () => {
        this.props.requestAccountActivation(this.props.registration).then((res) => {})
    }

    render() {
        const {
            companyFullName,
            companyShortName,
            address1,
            address2,
            phone,
            email,
            website,
            password,
            confirmPassword,
        } = this.props.registration

        if (!(this.props.registration && this.props.registration.companyFullName)) {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <Alert color="info">No information to confirm.</Alert>
                        </Row>
                    </Container>
                </div>
            )
        } else if (this.props.registration && this.props.registration.error) {
            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="text-left">
                                <div>
                                    <Alert color="danger">
                                        The email address is already in use by another
                                        account.
                                    </Alert>
                                </div>
                                <div className="pt-4 pb-4 text-center">
                                    <Button onClick={this.handleBackPress}>Back</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        } else if (this.props.registration && this.props.requestActivation) {
            return (
                <Redirect
                    to={`/${this.props.registration.category}/${this.props.registration.country}/${this.props.registration.email}`}
                />
            )
        } else if (this.props.registration && this.props.registration.success) {
            const { registration } = this.props

            return (
                <div>
                    <Header />
                    <Container>
                        <Row className="justify-content-center">
                            <Col sm={6} xs={12} className="text-left">
                                <div>
                                    <p>
                                        Congratulations! You have successfully registered{" "}
                                        {registration.companyFullName}.
                                    </p>
                                    <p>Your account now requires activation.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Button color="success" onClick={this.requestActivation}>
                                Click to request activation
                            </Button>
                            <Link className="ml-4" to="/"></Link>
                        </Row>
                    </Container>
                </div>
            )
        }

        return (
            <div className="App">
                <Header />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={6} xs={12} className="text-left">
                            <Alert color="info">
                                Thanks for registering. You have submitted the following
                                information:
                            </Alert>
                            <p>
                                <strong>Company Full Name</strong>: {companyFullName}
                            </p>
                            <p>
                                <strong>Company Short Name</strong>: {companyShortName}
                            </p>
                            <p>
                                <strong>Address Line 1</strong>: {address1}
                            </p>
                            <p>
                                <strong>Address Line 2</strong>: {address2}
                            </p>
                            <p>
                                <strong>Phone</strong>: {phone}
                            </p>
                            <p>
                                <strong>Email: </strong>
                                {email}
                            </p>
                            <p>
                                <strong>Website: </strong>
                                {website}
                            </p>

                            <p>
                                If these are correct, click the Complete Registration
                                button to complete registration. Otherwise, go{" "}
                                <Button
                                    color="link"
                                    className="m-0 p-0"
                                    onClick={this.goBack.bind(this)}
                                >
                                    back
                                </Button>{" "}
                                and correct any errors.
                            </p>

                            <div className="pt-4 pb-4 text-center">
                                <Button
                                    color="success"
                                    onClick={this.confirmRegistration.bind(this)}
                                >
                                    Complete Registration
                                </Button>
                                <Link className="ml-4" to="/">
                                    Cancel
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    location: state.location,
    registration: state.registration,
    requestActivation: state.requestActivation,
})

const mapDispatchToProps = (dispatch) => ({
    setRegistrationField: (params) => dispatch(setRegistrationField(params)),
    registerUser: (info) => dispatch(registerUser(info)),
    requestAccountActivation: (user) => dispatch(requestAccountActivation(user)),
    goBack: () => dispatch(goBack()),
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
