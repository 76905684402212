import React, { Component } from "react"
import { connect } from "react-redux"
import "../../../App.css"
import { push, goBack } from "react-router-redux"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import {
    fetchUser,
    logout,
    setSettings,
    fetchSettings,
    uploadImage,
    fetchVaccines,
    fetchVaccinesForAccount,
    selectVaccines,
} from "../../../actions"
import VaccinationSettingsForm from "../components/VaccinationSettingsForm"

import _ from "lodash"
import axios from "../../../lib/axios"

class AppContainer extends Component {
    constructor(props) {
        super(props)
        this.state = { reminder1: 50, reminder2: 25, reminder3: 7 }
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.fetchSettings(this.props.user).then((res) => {
                this.setState(res.value.val())
            })
            this.props.fetchVaccines()
            this.props.fetchVaccinesForAccount(this.props.user.uid)
        }
    }

    submitForm(evt) {
        evt.preventDefault()
    }

    selectCountry(evt) {
        const code = evt.target.value
        this.props.dispatch(push(this.props.match.url + "/" + code))
    }

    logout(evt) {
        evt.preventDefault()
        this.props.logout().then(() => {
            this.props.dispatch(push("/"))
        })
    }

    toggleActivation(evt) {
        const proceed = window.confirm("Proceed with this operation?")

        if (proceed) {
            this.props.setUserActive(this.props.user, !this.props.user.active)
        }
    }

    isSelected(option) {
        if (this.props.settings) {
            if (this.props.settings.message && this.props.settings.message === option) {
                return true
            }
        } else {
            return false
        }
    }

    uploadFile(file) {
        let data = new FormData()
        data.append("file", file)
        axios
            .post("/api/data/upload-image", data)
            .then((res) => {})
            .catch((err) => {
                console.log("problem uploading image ", err)
            })
    }

    render() {
        const {
            user,
            admin,
            rates,
            autoStats,
            birthdayStats,
            christmasStats,
            propertyStats,
        } = this.props

        let right = null
        if ((user && user.active) || (user && admin)) {
            right = (
                <div className="d-flex flex-column align-items-center">
                    <div>
                        <img src={user.logo} height="50" />
                    </div>
                    <div className="d-flex">
                        <div className="mr-2">
                            <div className="text-truncate" style={{ minWidth: 0 }}>
                                {user?.delegate?.email || user.email}
                            </div>
                            <div>
                                <a href="#" onClick={this.logout.bind(this)}>
                                    Logout
                                </a>
                            </div>
                        </div>
                        <div>
                            <img
                                src={`/flag-icon-css/flags/4x3/${user.countryCode}.svg`}
                                height={30}
                            />
                        </div>
                    </div>
                </div>
            )
        } else if (!user) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={10} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    <Link to="/auth/login">Login</Link> required.
                                </Alert>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }

        if (user && !user.active && !admin) {
            return (
                <div>
                    <Header right={right} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={10} xs={12} className="">
                                <h1>Dashboard</h1>

                                <Alert color="info">
                                    Your account is pending activation
                                </Alert>
                            </Col>
                        </Row>

                        <div className="text-center">
                            <Footer />
                        </div>
                    </Container>
                </div>
            )
        }

        return (
            <div>
                <Header right={right} />
                <Container>
                    {this.props.user &&
                    this.props.settings &&
                    !_.isEmpty(this.props.settings) &&
                    this.props.vaccines ? (
                        <VaccinationSettingsForm
                            selectVaccines={this.props.selectVaccines}
                            vaccines={this.props.vaccines}
                            goBack={this.props.goBack}
                            user={this.props.user}
                            settings={this.props.settings}
                            setSettings={this.props.setSettings}
                            uploadFile={this.uploadFile}
                            fetchUser={this.props.fetchUser}
                            uploadImage={this.props.uploadImage}
                        />
                    ) : null}
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    vaccines: state.accountVaccines,
    selectedVaccines: state.selectedVaccines,
    settings: state.settings,
})

const mapDispatchToProps = (dispatch) => ({
    fetchUser: (email) => dispatch(fetchUser(email)),
    fetchSettings: (user) => dispatch(fetchSettings(user.uid)),
    fetchVaccines: () => dispatch(fetchVaccines()),
    fetchVaccinesForAccount: (uid) => dispatch(fetchVaccinesForAccount(uid)),
    selectVaccines: (uid, vaccines) => dispatch(selectVaccines(uid, vaccines)),
    goBack: () => dispatch(goBack()),
    setSettings: (user, values) => dispatch(setSettings(user, values)),
    logout: () => dispatch(logout()),
    uploadImage: (user, data) => dispatch(uploadImage(user, data)),
    dispatch,
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer)

export default App
