import React from "react"
import {
    Container,
    Alert,
    Row,
    Col,
    Input,
    Button,
    Form,
    Label,
    FormGroup,
    CustomInput,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import "../../../App.css"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import * as Yup from "yup"
import { useFormik } from "formik"
import { useToasts } from "react-toast-notifications"

let VaccinationSchema = Yup.object({
    vaccineId: Yup.string().required("Vaccine must be selected"),
    firstVaccinationDate: Yup.date("Please enter a valid date")
        .required("Please enter a valid date")
        .nullable(),
    mobileNumber: Yup.string()
        .required("Please enter a valid 7-digit mobile number")
        .matches(/^\d+$/, "Must be a valid phone number")
        .length(7, "Please enter a valid 7-digit mobile number"),
    confirmMobileNumber: Yup.string()
        .required("Mobile numbers must match")
        .oneOf([Yup.ref("mobileNumber"), null], "Mobile numbers must match"),
    secondShotAdministeredToday: Yup.boolean().required(),
})

export default function SecondVaccinationMissingNumberForm({
    vaccination,
    resetForm,
    mobileNumber,
    user,
    vaccines,
    goBack,
    onSubmit,
    vaccinationRepository,
}) {
    const [errorMessage, setErrorMessage] = React.useState("")
    const [submitSuccess, setSubmitSuccess] = React.useState(false)
    const [loadingResult, setLoadingResult] = React.useState(false)

    const { addToast } = useToasts()

    const formik = useFormik({
        validationSchema: VaccinationSchema,
        initialValues: {
            accountId: user.uid,
            mobileNumber,
            email: "",
            secondShotAdministeredToday: false,
            vaccinationDate: null,
            firstVaccinationDate: null,
            vaccinationIndex: 2,
            vaccineId: "astrazeneca",
        },
        onSubmit: (values) => {
            if (values.secondShotAdministeredToday) {
                values.vaccinationDate = new Date()
            }
            delete values.secondShotAdministeredToday
            delete values.confirmMobileNumber
            console.log("submitted values ", values)
            addVaccination(values).then((res) => {
                setTimeout(() => {
                    resetForm()
                }, 1500)
            })
        },
    })

    global.formik = formik

    const submitAndClose = () => {
        let values = formik.values
        if (values.secondShotAdministeredToday) {
            values.vaccinationDate = new Date()
        }
        delete values.confirmMobileNumber
        delete values.secondShotAdministeredToday

        addVaccination(values)
            .then(() => {
                goBack()
            })
            .catch((e) => {})
    }

    const addVaccination = (values) => {
        setLoadingResult(true)
        return vaccinationRepository
            .addSecondVaccination(values)
            .then((res) => {
                setLoadingResult(false)
                setSubmitSuccess(true)
                window.scrollTo(0, 0)
                console.log("add vaccination result", res)
                addToast("Vaccination event successfully added", {
                    autoDismiss: true,
                    appearance: "success",
                })
                return Promise.resolve()
            })
            .catch((err) => {
                console.log("err ", err)
                setLoadingResult(false)
                window.scrollTo(0, 0)
                setErrorMessage(err?.response.data.errorMessage || "Server Error")
                console.log("Add vaccination error ", err?.response)
                addToast(err?.response.data.errorMessage || "Server Error", {
                    autoDismiss: true,
                    appearance: "error",
                })
                return Promise.reject()
            })
    }

    const submitForm = () => {
        formik.handleSubmit()
    }

    console.log("form errors ", formik.errors)
    const setCurrentDate = () => {
        formik.setFieldValue("vaccinationDate", new Date())
    }

    const isFormValid = () => {
        return (
            (formik.isValid && formik.values.secondShotAdministeredToday === true) ||
            (formik.isValid && formik.vaccinationDate)
        )
    }

    const getVaccineById = (vaccineId) => {
        return vaccines.find((item) => item.id === vaccineId)
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} xs={12}>
                    <div className="d-flex flex-row align-items-center mb-4">
                        <img src="/images/jab2.svg" style={{ height: 50, width: 50 }} />
                        <h3 className="ml-2 mr-2">Add Second Vaccination</h3>
                        {submitSuccess ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                size={"3x"}
                                color={"green"}
                            />
                        ) : null}
                    </div>

                    <Form>
                        {(errorMessage && (
                            <Alert color="danger" className="mt-2 mb-2">
                                {errorMessage}
                            </Alert>
                        )) ||
                            null}

                        <FormGroup>
                            <Label className="font-weight-bold">Vaccine Name</Label>
                            {formik.touched.vaccineId && formik.errors.vaccineId && (
                                <Alert color="danger">{formik.errors.vaccineId}</Alert>
                            )}
                            <Input
                                value={formik.values.vaccineId}
                                type="select"
                                placeholder="Vaccine Name"
                                onBlur={formik.handleBlur("vaccineId")}
                                onChange={formik.handleChange("vaccineId")}
                            >
                                {vaccines.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">Mobile Number</Label>
                            {formik.touched.mobileNumber &&
                                formik.errors.mobileNumber && (
                                    <Alert color="danger">
                                        {formik.errors.mobileNumber}
                                    </Alert>
                                )}
                            <Input
                                type="number"
                                placeholder="Enter 7-digit mobile number"
                                value={formik.values.mobileNumber}
                                onBlur={formik.handleBlur("mobileNumber")}
                                onChange={formik.handleChange("mobileNumber")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">
                                Confirm Mobile Number
                            </Label>
                            {formik.touched.confirmMobileNumber &&
                                formik.errors.confirmMobileNumber && (
                                    <Alert color="danger">
                                        {formik.errors.confirmMobileNumber}
                                    </Alert>
                                )}
                            <Input
                                type="number"
                                placeholder="Confirm number"
                                value={formik.values.confirmMobileNumber}
                                onBlur={formik.handleBlur("confirmMobileNumber")}
                                onChange={formik.handleChange("confirmMobileNumber")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">Email</Label>
                            {formik.touched.email && formik.errors.email && (
                                <Alert color="danger">{formik.errors.email}</Alert>
                            )}
                            <Input
                                placeholder="Email (Optional)"
                                value={formik.values.email}
                                onBlur={formik.handleBlur("email")}
                                onChange={formik.handleChange("email")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="font-weight-bold">
                                Enter the date of 1st vaccination (from the vaccination
                                card)
                            </Label>
                            {formik.touched.firstVaccinationDate &&
                                formik.errors.firstVaccinationDate && (
                                    <Alert color="danger">
                                        {formik.errors.firstVaccinationDate}
                                    </Alert>
                                )}
                            <div className="d-flex flex-row align-items-center">
                                <DatePicker
                                    placeholderText="Enter the date of 1st vaccination (from the vaccination card)"
                                    selected={formik.values.firstVaccinationDate}
                                    onBlur={formik.handleBlur("firstVaccinationDate")}
                                    onChange={(date) => {
                                        formik.setFieldValue("firstVaccinationDate", date)
                                    }}
                                />
                                <FontAwesomeIcon
                                    onClick={setCurrentDate}
                                    size="2x"
                                    icon={faCalendarAlt}
                                    style={{ marginLeft: 8, cursor: "pointer" }}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <CustomInput
                                checked={formik.values.secondShotAdministeredToday}
                                type="checkbox"
                                id="check"
                                name="check"
                                label="2nd Shot administered today"
                                onChange={(evt) =>
                                    formik.setFieldValue(
                                        "secondShotAdministeredToday",
                                        evt.target.checked
                                    )
                                }
                            />
                        </FormGroup>

                        <FormGroup>
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex flex-row align-items-center">
                                    {loadingResult ? (
                                        <Spinner color="success" className="mr-2" />
                                    ) : null}
                                    <Button
                                        onClick={submitForm}
                                        disabled={!isFormValid()}
                                        color="success"
                                        type="button"
                                    >
                                        Finish and add another
                                    </Button>
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <Button
                                        onClick={submitAndClose}
                                        disabled={!isFormValid()}
                                        color="link"
                                        type="button"
                                    >
                                        Finish and close
                                    </Button>
                                </div>

                                <Button color="link" onClick={goBack}>
                                    Cancel
                                </Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
