import React, { Component } from "react"
import { connect } from "react-redux"
import "../../../App.css"
import { push } from "react-router-redux"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import countries from "../data/electric-countries.json"
import ElectricIntroVideo from "./components/ElectricIntroVideo"

import { setRegistrationField } from "../../../actions"

class ElectricCategory extends Component {
    componentDidMount() {
        console.log("Electric category")
        const url = this.props.match.url
        const parts = url.split("/")
        const category = _.last(parts)

        this.props.dispatch(setRegistrationField({ category }))
    }

    selectCountry(evt) {
        const code = evt.target.value
        this.props.dispatch(push(this.props.match.url + "/" + code))
        this.props.dispatch(setRegistrationField({ country: code }))
    }

    renderLogo() {
        const url = this.props.match.url
        const parts = url.split("/")
        const category = _.last(parts)

        console.log("category ", category)

        if (category === "electric") {
            return (
                <div className="d-flex flex-column align-items-center p-4">
                    <img
                        src={"/images/utilities_icon_LP.svg"}
                        width="100"
                        style={{ borderRadius: "50px" }}
                    />
                    <div className="pt-4">
                        <ElectricIntroVideo />
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="">
                <Header right={<div className="text-capitalize">Electric Utility</div>} />
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={3} xs={12} className="">
                            {this.renderLogo()}
                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={this.selectCountry.bind(this)}
                                >
                                    <option value="select-country">
                                        Select your territory
                                    </option>
                                    {countries.map((country) => (
                                        <option
                                            value={country.code.toLowerCase()}
                                            key={country.code.toLowerCase()}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Footer />
                    </div>
                </Container>
            </div>
        )
    }
}

const App = connect((state) => ({
    location: state.location,
}))(ElectricCategory)

export default App
