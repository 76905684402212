import React, { useState, useReducer, useEffect, Fragment} from 'react';
import { Container, Row, Col, Button, Form, FormGroup, CustomInput, Label, Input } from 'reactstrap';
import Media from 'react-media';
import Header from '../components/Header'; 
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { goBack as goBackAction} from 'connected-react-router'
import config from '../config'
import countries from '../countries-caribbean'
import Logout from '../components/Logout';
import TokenExpired from '../components/TokenExpired';

import { 
  getSetupState, 
  setSetupState, 
  fetchSettings, 
  uploadImage as uploadImageAction, 
  fetchUser as fetchUserAction, 
  setAlert,
  saveAlert,  
  setCountryPhone as setCountryPhoneAction,  
  setSettings as setSettingsAction, 
  setCountryOfOperation as setCountryOfOperationAction} from '../actions'
import { stripLeadingSlash } from 'history/PathUtils';

const CreditContainer = () => {  
  const user = useSelector(state => state.user)
  const dispatch = useDispatch(); 
  const fetchUser = (email) => dispatch(fetchUserAction(email))
  
  if(user) {
    return <CreditFinanceSettingsCountriesPhones user={user} />
  }
  else {
    return <TokenExpired />
  }      
} 

const right = (<div>Logout</div>)

const CreditFinanceSettingsCountriesPhones = (props) =>  {

function renderTable(countries) {
  return (
    <div className="pt-4 pb-1">
    {countries.map((country, index) => 
      <CountryTableRow country={country} user={props.user} />
    )}
    </div>
  )
}  

const CountryTableRow = ({country, user}) => {

  const getPhoneNumber = (code) => {
    if(user.countryPhoneNumbers) {
      return user.countryPhoneNumbers[code];
    }
    else {
      return ""
    }
  }

  const updatePhoneNumber = () => {
    setCountryPhone(user.uid, country.code.toLowerCase(), phoneNumber).then((res) => {
      
      if(res.value.data.success) {
        setEditable(false)
      }
    });    
  }

  const dispatch = useDispatch()
  const setCountryOfOperation = (uid, code, value) => dispatch(setCountryOfOperationAction(uid, code, value))
  const [editable, setEditable] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(getPhoneNumber(country.code.toLowerCase()) || "")

  return (
    <div className="d-flex flex-column mt-2 mb-2">
      <div className="d-flex flex-row" key={country.code}>
        <div style={{width: 150}}>
          <CustomInput
            onChange={(evt) => setCountryOfOperation(user.uid, country.code.toLowerCase(), evt.target.checked)}
            checked={user.countriesOfOperation[country.code.toLowerCase()]}
            id={"check" + country.code} 
            name={"check" + country.code} 
            type="checkbox" 
            label={country.name} 
            ></CustomInput>
        </div>

        {user.countriesOfOperation[country.code.toLowerCase()] ? 
        <div className="d-flex flex-row">
          <img src={`/flag-icon-css/flags/4x3/${country.code.toLowerCase()}.svg`} height={30} />        
          <div className="ml-2 mt-2">{getPhoneNumber(country.code.toLowerCase())}</div>
          <Button color="link" onClick={() => setEditable(true)}>Edit Number</Button>
        </div>
        : null } 

      </div>

      {editable ?
      <div className="d-flex flex-row mt-2 mb-2">   
        <Input 
          type="number"
          value={phoneNumber}
          onChange={(evt) =>  setPhoneNumber(evt.target.value)} />     
        <Button
          onClick={updatePhoneNumber}
          color="success" 
          className="ml-2"
          disabled={!(phoneNumber && phoneNumber.length > 0 && phoneNumber.length === 7)}>Save</Button>
        <Button color="danger" className="ml-2" onClick={() => setEditable(false)}>Cancel</Button>
      </div> : null }
    </div>  
  )
}

function renderSmallTable(countries) {
  return (
    <div className="pt-4 pb-1">
    {countries.map((country, index) => 
      <CountryTableRow country={country} user={props.user} />
    )}
    </div>
  )
}

  const PhoneNumber = ({country, user}) => {
    const [editable, setEditable] = useState(false)

    const countryCode = country.code.toLowerCase()
    return (
      <div className="d-flex flex-row">
        {editable ? 
          <Input type="text" value={""}></Input>
        :
          <div>{""}</div> }
        <Button onClick={() => setEditable(true)} color="link">Edit Number</Button>
      </div>
    )
  }

    const dispatch = useDispatch()
    const setCountryPhone = (uid, code, phone) => dispatch(setCountryPhoneAction(uid, code, phone))
    const goBack = () => dispatch(goBackAction())  

    return (
    <Container>
      <Header right={<Logout user={props.user}/>}></Header>
      <Row className="justify-content-center">
          <Col sm={6} xs={12} className="">
          <h1>Settings</h1>
          <h4>Edit business countries & phone numbers</h4>

          <Media queries={{
            small: "(max-width: 599px)",
            large: "(min-width: 600px)"            
          }}>
          {matches => (
            <Fragment>
              {matches.small ? renderTable(countries) : null}              
              {matches.large ? renderTable(countries) : null}
            </Fragment>
          )}
          </Media>

          <div className="pt-1 pb-1 text-center">
              <Button className="ml-4" color="link" onClick={() => {                
                goBack()
              }}>Back</Button>
          </div>          
          </Col>                
      </Row>
      <div className="text-center">
          <Footer />
      </div>                
  </Container> 
  );
}

export default CreditContainer;